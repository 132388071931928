<div class="p-5">
<h2>Skladové položky</h2>
  <div  *ngIf="invItems !== undefined" class="m-3 mt-4">
   
    <form  class="form-inline mb-3" #form ="ngForm" (ngSubmit)="loadItems()" novalidate>
<div class="form-inline w-100">
      <div class="form-group px-1 ">
        <input type="text" class="form-control" placeholder="Název" [(ngModel)]="filterParams.name" name="name"/>
      </div>
      <div class="form-group px-1 ">
        <input type="text" class="form-control" placeholder="Sériové číslo" [(ngModel)]="filterParams.serialNumber" name="serialNumber"/>
      </div>

      <div class="form-group px-2">
        <label for="company">Dodavatel: </label>
        <select class="form-control ml-1" style="width: 150px" id="companyId" [(ngModel)]="filterParams.supplierId" name="supplierId">
          <option value="0"></option>
          <option *ngFor="let company of companies" [value] = "company.id">
            {{company.companyName}}
          </option>
        </select>
      </div>

      <div class="form-group px-2">
        <label for="company">Klient: </label>
        <select class="form-control ml-1" style="width: 150px" id="companyId" [(ngModel)]="filterParams.clientId" name="clientId">
          <option value="0"></option>
          <option *ngFor="let company of companies" [value] = "company.id">
            {{company.companyName}}
          </option>
        </select>
      </div>
      <div class="form-group px-2">
        <label for="company">Příznak: </label>
        <select class="form-control ml-1" style="width: 150px" id="companyId" [(ngModel)]="filterParams.flag" name="flag">
          <option value="">Vyberte příznak</option>
          <option value="r">Režie</option>
          <option value="zb">Zboží</option>
          <option value="sl">Služba</option>
          <option value="slr">Služba režie</option>
        </select>
      </div>
      <button type="submit" class="btn btn-primary" style="margin-left:10px" (click)="setFilters()" >Filtrovat</button>
      <button type="button" class="btn btn-info" style="margin-left:10px" (click)="resetFilters()" >Reset Filtrů</button>
    </div>

    <div  class="form-group form-inline ml-3">
      <input type="checkbox" class=" mr-2" placeholder="Název" [(ngModel)]="filterParams.isContained" name="isContained"/>
      <a (click)="filterParams.isContained = !filterParams.isContained" >Obsaženo v jiné položce </a>

    </div>

    <div  class="form-group form-inline ml-3">
      <input type="checkbox" class=" mr-2" placeholder="Název" [(ngModel)]="filterParams.hasContained" name="hasContained"/>
      <a (click)="filterParams.hasContained = !filterParams.hasContained" >Složeno z více položek</a>

    </div>

    <div  class="form-group form-inline ml-3">
      <input type="checkbox" class=" mr-2" placeholder="Název" [(ngModel)]="filterParams.delivered" name="delivered"/>
      <a (click)="loadItems()" (click)="filterParams.delivered = !filterParams.delivered">Doručeno zákazníkovi</a>
    </div>

    <div  class="form-group form-inline ml-3">
      <input type="checkbox" class=" mr-2" placeholder="Název" [(ngModel)]="filterParams.invoicedToClient" name="invoicedToClient"/>
      <a (click)="filterParams.invoicedToClient = !filterParams.invoicedToClient">Fakturovano zákazníkovi</a>
    </div>

    <div  class="form-group form-inline ml-3">
      <input type="checkbox" class=" mr-2" placeholder="Název" [(ngModel)]="filterParams.reklamace" name="reklamace"/>
      <a (click)="filterParams.reklamace = !filterParams.reklamace">Reklamace</a>
    </div>

    <div  class="form-group form-inline ml-3 ">
      <input type="checkbox" class=" mr-2" placeholder="Název" [(ngModel)]="filterParams.inWarehouse" name="inWarehouse"/>
      <a (click)="filterParams.inWarehouse = !filterParams.inWarehouse">Je na skladě</a>
    </div>



     

     
    </form>
    <div *ngIf="invItems!=null && invItems.length === 0" style="text-align: center">
      <h1>Zatím nejsou na skladě žádné položky</h1>
    </div>
  <div class="row" *ngIf="invItems !== null && invItems.length > 0">
    <table class="table table-hover" style="cursor: pointer">
      <div class="form-inline ">
        <button class="btn btn-primary" (click)="$event.stopPropagation()" (click)="createNewItem()" >Vytvořit nový výrobek</button>
      </div>
      <tr>
        <th style="width: 20%">Název</th>
        <th style="width: 10%">Množství</th>
        <th style="width: 15%">Prodejní cena</th>
        <th style="width: 15%">Prodejní cena (s DPH)</th>
        <th style="width: 20%">Dodavatel</th>
        <th style="width: 20%">Klient</th>
        <th style="width: 20%">Příznak</th>

        <th style="width: 15%"></th>
        
      </tr>
      <tr *ngFor="let invItem of invItems" >
        <td (click)="getDetailModal(invItem)" >{{invItem.name}}</td>
        <td (click)="getDetailModal(invItem)" >{{invItem.amount}}</td>
        <td (click)="getDetailModal(invItem)" >{{invItem.sellingPrice}}</td>
        <td (click)="getDetailModal(invItem)" >{{invItem.sellingPrice*1.21}}</td>
        <td (click)="getDetailModal(invItem)" >{{invItem.supplierCompany.companyName}}</td>
        <td (click)="getDetailModal(invItem)" >{{invItem.clientCompany.companyName}}</td>
        <td (click)="getDetailModal(invItem)" >{{invItem.flag}}</td>

        <td>
          <div *ngIf="!invItem.invoicedToClient" class="form-inline">
            <button class="btn btn-primary" (click)="$event.stopPropagation()" (click)="getEditModal(invItem)" ><i class="fa fa-edit ml-1"></i></button>
          </div>
        </td>
      </tr>
    </table>

  </div>

</div>

<div *ngIf="invItems !== undefined" class="d-flex justify-content-center">
    <pagination [maxSize]="3"
                [boundaryLinks]="true" 
                [totalItems]="pagination.totalItems"
                [itemsPerPage]="pagination.itemsPerPage"
                [(ngModel)]="pagination.currentPage"
                (pageChanged)="pageChanged($event)"
              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
  </pagination>
  </div>