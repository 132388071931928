import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Company } from 'src/app/_models/company';
import { Invoice } from 'src/app/_models/invoice';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { AuthService } from 'src/app/_services/auth.service';
import { PriceListItem } from 'src/app/_models/priceListItem';
import { PriceListService } from 'src/app/_services/pricelist.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { DatePipe } from '@angular/common';
import { OrderService } from 'src/app/_services/order.service';
import { InventoryItem } from 'src/app/_models/inventoryItem';
import { InventoryItemService } from 'src/app/_services/inventoryItem.service';
import { WorkreportService } from 'src/app/_services/workreport.service';
import { ToolsService } from 'src/app/_services/tools.service';
import { ReportItemsBag } from 'src/app/_models/reportItemsBag';
import { InvoiceKeys } from 'src/app/constants/constants';
@Component({
  selector: 'app-invoiceedit-modal',
  templateUrl: './invoiceedit-modal.component.html',
  styleUrls: ['./invoiceedit-modal.component.css']
})
export class InvoiceEditModalComponent implements OnInit {
  @Output() editinvoice = new EventEmitter();
  invoice: Invoice;
  invoiceEditForm: FormGroup;
  companies: Company[];
  companyId: string;
  priceList: PriceListItem[];
  priceListOriginal: PriceListItem[];
  totalPrice: number;
  totalPriceWithDPH: number;
  invItems: InventoryItem[];
  priceListMonthHours: PriceListItem;
  maxDate: Date;
  hoursToInvoice: ReportItemsBag[] = [];
  priceRas:number;
  priceOns:number;
  priceKm:number;
  pricePC:number;
  priceProPC:number;
  priceServer:number;
  priceSQL:number;
  priceFiles:number;
  priceExchange:number;
  invoiceConstants = InvoiceKeys;


  constructor(public bsModalRef: BsModalRef, private fb: FormBuilder, private companyService: CompanyDialService, private authService: AuthService,private toolsService:ToolsService,
   private wpService:WorkreportService, private priceListService: PriceListService, private alertify: AlertifyService, private datePipe: DatePipe, private orderService: OrderService,private inventoryService:InventoryItemService) {}

  ngOnInit() {
  
    this.maxDate = new Date();
    this.maxDate.setDate(1);
    // this.maxDate.setMonth( this.maxDate.getMonth()-1);

    this.createForm();
    this.companyService.getCompanies(1, 1,null,null,false,true).subscribe(data =>
    {
      this.companies = data.result;
      if (this.companyId !== '0')
        {
          this.companies = data.result.filter(x => x.id.toString() === this.companyId);
        }
      else
        {
          this.companies = data.result;
        }
        this.getOrders();
    });
  }
  roundTo(num)
  {
    return this.toolsService.roundTo(num);
  }

  createForm(){
    this.invoiceEditForm = this.fb .group({
      companyId: [this.invoice.companyId, Validators.required],
      forMonth: [this.datePipe.transform(this.invoice.forMonth, 'yyyy-MM'), Validators.required],
      invoiceNumber: [this.invoice.invoiceNumber, Validators.required],
      dateToPay: [this.datePipe.transform(this.invoice.dateToPay, 'yyyy-MM-dd'), Validators.required],
      paid: [this.invoice.paid],
      paidDate: [this.datePipe.transform(this.invoice.paidDate, 'yyyy-MM-dd')],

      constantSymbol: [this.invoice.constantSymbol],
      variableSymbol: [this.invoice.variableSymbol, Validators.required],
      supplierCompanyId:[this.authService.decodedToken.CompanyId,Validators.required],

      formOfPayment: [this.invoice.formOfPayment, Validators.required],
      userId: [this.authService.curretnUser.id],
      duzp: [this.datePipe.transform(this.invoice.duzp, 'yyyy-MM-dd'), Validators.required],
      price: [this.invoice.price],
      priceWithDPH: [this.invoice.priceWithDPH],
      ticketId: [this.invoice.ticketId],
      dateOfCreation: [this.datePipe.transform(this.invoice.dateOfCreation, 'yyyy-MM-dd')],
    });
  }
  getInventoryItems()
  {
    if(this.invoiceEditForm.value.companyId != '' && this.invoiceEditForm.value.companyId !=0)
    {
    var filterParams:any = {};
      filterParams.name = '';
      // filterParams.hasContained = null;
      // filterParams.delivered = null;
      filterParams.supplierId = 0;
      // filterParams.reklamace = null;
      filterParams.inWarehouse = null;

    // filterParams.invoicedToClient = null;
    filterParams.clientId = this.invoiceEditForm.value.companyId;
    filterParams.isForInvoice = false;
    // filterParams.isContained = false;
    filterParams.clientInvoiceId = this.invoice.id;
    filterParams.getPrimaryCompany = false;


    filterParams.name = '';
    filterParams.isContained = false;
    filterParams.hasContained = false;
    filterParams.delivered = false;
    filterParams.invoicedToClient = null;
    filterParams.reklamace = false;
    // filterParams.inWarehouse = false;
    // filterParams.clientId = 0;
    // filterParams.supplierId = 0;
    // filterParams.isForInvoice = true;
    // filterParams.getPrimaryCompany = false;


    this.inventoryService.getInvenotryItems(1, 1,0,filterParams).subscribe(data => {
      this.invItems = data.result;
      this.getTotalPrice();
      this.totalPriceWithDPH = this.getTotalPriceWithDPH();
    })
    }
  }
  editInvoice()
  {
    if (this.invoiceEditForm.valid)
    {

      this.priceList.forEach(priceItem => {
        // priceItem.order.invoiceId = 1;
        this.orderService.updateOrder(priceItem.order.id,priceItem.order).subscribe(() => {
        }, error => {
          this.alertify.error('Slevu se nepodařilo upravit');
        }, () => {
        });
      });
    

      this.invoiceEditForm.value.forMonth = new Date(this.invoiceEditForm.value.forMonth);
      this.invoiceEditForm.value.dateToPay = new Date(this.invoiceEditForm.value.dateToPay);
      this.invoiceEditForm.value.paidDate = new Date(this.invoiceEditForm.value.paidDate);
      this.invoiceEditForm.value.dateOfCreation = new Date(this.invoiceEditForm.value.dateOfCreation);
      // var dphItems = this.getDPHOfInvItems();
      // var totalPriceWithDPH =this.totalPrice+ (this.totalPrice*0.21)+ dphItems;//kravina protoze invitems 2x dan
      this.invoiceEditForm.value.priceWithDPH = this.getTotalPriceWithDPH();
      this.invoiceEditForm.value.price = this.totalPrice;
      this.invoice = Object.assign({}, this.invoiceEditForm.value);
      this.editinvoice.emit({invoice: this.invoice,items: this.invItems});
      this.bsModalRef.hide();
    }
  }

  getOrders()
  {
    this.wpService.getWpsForInvoice(this.invoiceEditForm.value.companyId,this.invoiceEditForm.value.forMonth,true,false,true,this.invoice.id).subscribe(data=>
      {

        if(this.invoice.domainInvoiced)
        {
          this.hoursToInvoice = [];
          data.forEach((item) => this.hoursToInvoice.push(item));
        }
     
        if(this.invoiceEditForm.value.companyId != '' && this.invoiceEditForm.value.forMonth != '')
        {
          var filterParams:any =  {};
          filterParams.companyId = this.invoiceEditForm.value.companyId;
          filterParams.month = this.invoiceEditForm.value.forMonth;
          // this.priceListService.getPriceListItemsByInvoiceId(this.invoice.id)

          var filterParams:any =  {};
          filterParams.companyId = this.invoiceEditForm.value.companyId;
          filterParams.month = this.invoiceEditForm.value.forMonth;
          filterParams.filterInvoiced =true;
          filterParams.deductHours = true;
          filterParams.getHours =false;

          this.priceListService.getPriceListItemsForOrderList(filterParams)
          .subscribe((res) => {
            this.priceRas = this.getPrice(res, InvoiceKeys.RASCode);
            this.priceOns = this.getPrice(res, InvoiceKeys.ONSCode);
            this.pricePC = this.getPrice(res, InvoiceKeys.PCCode);
            this.priceProPC = this.getPrice(res, InvoiceKeys.PCProCode);
            this.priceServer = this.getPrice(res, InvoiceKeys.ServerCode);
            this.priceSQL = this.getPrice(res, InvoiceKeys.SQLCode);
            this.priceFiles = this.getPrice(res, InvoiceKeys.FileCode);
            this.priceExchange = this.getPrice(res, InvoiceKeys.ExchangeCode);
            this.priceKm = this.getPrice(res, InvoiceKeys.KmCode);

            this.priceList =res.body.filter(x=> x.order.amount >0 && x.order.invoiceId === this.invoice.id);
            this.priceListOriginal = JSON.parse(JSON.stringify(this.priceList));
            this.setOriginalDicounts();
            this.getTotalPrice();
            this.totalPriceWithDPH = this.getTotalPriceWithDPH();

            //  this.priceListMonthHours = this.priceList.filter(x=>x.companyName === 'Vykázané hodiny')[0];
            // this.priceListMonthHours= res.body.filter(x=>x.companyName.includes('Vykázané hodiny') && x.order.amount >0 )[0];

          }, error => {
            this.alertify.error(error);
          });
        }
        this.getInventoryItems();
    });
  }

  getPrice(res: any, key: string){
    var values = res.body.filter(x => x.code === key);
    if(values && values.length > 0){
      return values[0]?.price ?? 0;
    }
    return 0
  }

  DiscountChange(priceListItem: PriceListItem)
  {
    if(priceListItem.order.discountPercent <= 100 && priceListItem.order.discountPercent >=0)
    {
      priceListItem.price = this.priceListOriginal.filter(x=>x.id === priceListItem.id)[0].price*((100-priceListItem.order.discountPercent)/100);
      this.getTotalPrice();
      this.totalPriceWithDPH = this.getTotalPriceWithDPH();

    }
    else
    {
      priceListItem.order.discountPercent = 0;
    }
   
  }

  setOriginalDicounts()
  {
    this.priceList.forEach(element => {
      element.price = element.price*((100-element.order.discountPercent)/100);
    });
  }

  getTotalPrice()
  {
    this.totalPrice = 0;
    if(this.priceList != undefined)
    {
    this.priceList.forEach(element => {
      this.totalPrice = this.totalPrice+element.order.amount * element.price
    });
  }
    if(this.invItems != undefined)
    {
    this.invItems.forEach(element => {
      if(element.invoicedToClient)
      {
      this.totalPrice = this.totalPrice+element.amount * element.sellingPrice
      }
    });
    }
    if(this.hoursToInvoice !== undefined && this.hoursToInvoice.length > 0)
    {
      var hourSum = this.GetHoursSumForAllItems();

      this.totalPrice =this.totalPrice + hourSum;
    }
    // + (this.hoursToInvoice[0].value*this.priceRas) + (this.hoursToInvoice[1].value*this.priceOns)+ (this.hoursToInvoice[2].value*this.priceKm);
  }

  GetHoursSumForAllItems(): number{
    var hourSum = ((this.hoursToInvoice.filter(x=>x.code === InvoiceKeys.SQLCode)[0].count/100)*this.priceSQL)
      + ((this.hoursToInvoice.filter(x=>x.code === InvoiceKeys.ExchangeCode)[0].count/100)*this.priceExchange)
      + ((this.hoursToInvoice.filter(x=>x.code === InvoiceKeys.FileCode)[0].count/100)*this.priceFiles)
      + (this.hoursToInvoice.filter(x=>x.code === InvoiceKeys.PCCode)[0].count*this.pricePC)
      + (this.hoursToInvoice.filter(x=>x.code === InvoiceKeys.PCProCode)[0].count*this.priceProPC)
      + (this.hoursToInvoice.filter(x=>x.code === InvoiceKeys.ServerCode)[0].count*this.priceServer)
      + (this.hoursToInvoice.filter(x=>x.code === InvoiceKeys.RASCode)[0].count*this.priceRas)
      + (this.hoursToInvoice.filter(x=>x.code === InvoiceKeys.ONSCode)[0].count*this.priceOns)
      + (this.hoursToInvoice.filter(x=>x.code === InvoiceKeys.KmCode)[0].count*this.priceKm)
      ;

      return hourSum;
  }

  getTotalPriceWithDPH()
  {
   var res = 0;
    if(this.priceList != undefined)
    {
    this.priceList.forEach(element => {
      res = res+element.order.amount * element.price
    });
  }
  if(this.hoursToInvoice !== undefined && this.hoursToInvoice.length > 0)
  {
    var hourSum = this.GetHoursSumForAllItems();

    res = res + hourSum;
  }

    res =res*1.21;
    if(this.invItems != undefined)
    {
    this.invItems.forEach(element => {
      if(element.invoicedToClient)
      {
      res = res+((element.amount * element.sellingPrice)*(1+(element.dphSelling/100)));
      }
    });
    }

    return res;
  }

  // getDPHOfInvItems()
  // {
  //   var result = 0;
 
  //   if(this.invItems != undefined)
  //   {
  //   this.invItems.forEach(element => {
  //     if(element.invoicedToClient)
  //     {
  //     result = result + ((element.amount * element.sellingPrice)* (element.dphSelling/100))
  //     }
  //   });
  //   }
  //   return result;
    
  // }
}

