import {User} from './user';

export class Invoice {
  id: number;
  guid: string;
  price: number;
  priceWithDPH: number;
  paid: boolean;
  paidDate: Date;
  invoiceNumber: string;
  dateToPay: string;
  dateOfCreation: Date;
  companyId: number;
  supplierCompanyId: number;
  ticketId: number;
  forMonth: string;
  companyName: string;

  userId: number;
  user: User;
  constantSymbol: string;
  variableSymbol: string;
  formOfPayment: string;
  recievedInvoice: boolean;
  isEditable: boolean;
  duzp: string;
  domainInvoiced: boolean;

}
