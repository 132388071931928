<div class="m-5">
  <div class="m-5">
    <div *ngIf="groups.length == 0" style="text-align: center">
      <h1>Zatím nejsou přidané žádné skupiny</h1>
    <button class="btn btn-primary m-3" (click)="createModal()" >Vytvořit novou skupinu</button>
    </div>

  <div class="row" *ngIf="groups.length > 0">
    <table class="table table-hover" style="cursor: pointer">
    <h2>Skupiny uživatelů</h2>

      <button class="btn btn-primary" (click)="createModal()" >Přidat</button>
      <tr>
        <th style="width: 80%">Skupina</th>
        <th style="width: 20%"></th>
      </tr>
      <tr *ngFor="let group of groups" >
        <td>{{group.groupName}}</td>
        <td>
          <div class="form-inline">
              <button class="btn btn-primary" (click)="$event.stopPropagation()" (click)="getEditModal(group)" ><i class="fa fa-edit ml-1"></i></button>
              <button class="btn btn-danger" (click)="$event.stopPropagation()" (click)="deleteObject(group.id)" ><i class="fa fa-trash"></i></button>
          </div>
        </td>
      </tr>
    </table>

  </div>

</div>

<div class="d-flex justify-content-center">
    <pagination [maxSize]="3"
                [boundaryLinks]="true" 
                [totalItems]="pagination.totalItems"
                [itemsPerPage]="pagination.itemsPerPage"
                [(ngModel)]="pagination.currentPage"
                (pageChanged)="pageChanged($event)"
              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
  </pagination>
  </div>