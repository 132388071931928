import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { UserService } from '../_services/user.service';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';
import { InventoryItem } from '../_models/inventoryItem';
import { InventoryItemService } from '../_services/inventoryItem.service';
import { ItemOrderService } from '../_services/itemOrder.service';
import { DatePipe } from '@angular/common';
import { LicenceService } from '../_services/licence.service';
import { Licence } from '../_models/licence';
@Injectable()
export class LicenceReportResolver implements Resolve<Licence[]> {
  pageNumber = 1;
  pageSize = 15;

  constructor(
    private licenceService: LicenceService,
    private router: Router,
    private alertify: AlertifyService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private route: ActivatedRoute
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Licence[]> {
    var filterParams: any= {};
    filterParams.companyId = 0;
    filterParams.orderDate = this.datePipe.transform(Date.now(), 'yyyy-MM-dd HH:mm');

    return this.licenceService.getLicenceReport(filterParams).pipe(
      catchError((error) => {
        this.alertify.error('Problem retrieving messages');
        this.router.navigate(['//adminHome']);
        return of(null);
      })
    );
  }
}
