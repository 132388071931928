export class OrderedItem {
  id: number;
  itemOrderId: number;
  name: string;
  amount: number;
  price: number;
  deleted: boolean;
  dynamicId: number;
}




