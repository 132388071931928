<div class="m-5">
  <h2>Licence</h2>

  <form *appHasRole="['CompanyFilter']" class="form-inline" #form ="ngForm" (ngSubmit)="loadLicences()" novalidate>
    <input class="ml-1" type="month" [(ngModel)]="filterParams.orderDate" name="month" (change)="loadLicences()">
    <!-- <input class=" form-control" type="text" [(ngModel)]="companyFilter" placeholder="Filtr dle názvu" (ngModelChange)="loadCompanies()"> -->
    <div class="form-group px-2">
      <label for="company">Pro firmu: </label>
      <select class="form-control ml-1" style="width: 130px" id="companyId" [(ngModel)]="filterParams.companyId" name="companyId">
        <option *ngFor="let company of companies" [value] = "company.id">
          {{company.companyName}}
        </option>
      </select>
    </div>
    <button type="submit" class="btn btn-primary" style="margin-left:10px">Filtrovat</button>
    <button type="button" class="btn btn-info" style="margin-left:10px" (click)="resetFilters()" >Reset Filtrů</button>
  </form>
  <br> 
  <div *ngIf="licences !==undefined" class="m-5">
    <div *ngIf="licences.length === 0" style="text-align: center">
      <h1>Zatím nejsou vyfakturované žádné licence</h1>
    </div>

  <div class="row" *ngIf="licences.length > 0">
    <table class="table table-hover" style="cursor: pointer">
      <button *appHasRole="['CategoryEdit']" class="btn btn-primary" (click)="createModal()" >Přidat</button>
      <tr>
        <th style="width: 20%">Kód</th>
        <th style="width: 20%">Název</th>
        <th style="width: 20%">Dodavatel</th>
        <th style="width: 20%">Cena</th>
        <th style="width: 20%">Množství</th>
        <!-- <th style="width: 20%">Frekvence ve dnech</th> -->
      </tr>
      <tr *ngFor="let licence of licences" >
        <td>{{licence.licenceCode}}</td>
        <td>{{licence.name}}</td>
        <td>{{licence.supplierCompany.companyName}}</td>
        <td>{{licence.licencePrice}}</td>
        <td>{{licence.amount}}</td>
        
      </tr>
    </table>

  </div>

</div>
