import {Injectable} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';

const dateRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)$/;

@Injectable()
export class QueryStringsHelper {
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router) {
  }

  setFilterToQueryString(
    currentFilter: object,
    excludedProperties?: string[] | null) {
    const queryParams = this.activatedRoute?.snapshot?.queryParams ? Object.assign({}, this.activatedRoute.snapshot.queryParams) : {};
    const properties = Object.getOwnPropertyNames(currentFilter);
    for (const property of properties) {

      if (excludedProperties && excludedProperties.length > 0 && excludedProperties.indexOf(property) > -1) {
        continue;
      }

      if (currentFilter[property] == null) {
        queryParams[property] = null;
        continue;
      }

      if (Array.isArray(currentFilter[property])) { // Array
        if (currentFilter[property].length === 0) {
          queryParams[property + '[]'] = null;
          continue;
        }
        this.serializeArrayValues(queryParams, property, currentFilter[property]);
      } else {  // Date, string, number etc.
        queryParams[property] = this.returnFormattedValueInput(currentFilter[property]);
      }
    }
    this.router.navigate([], {relativeTo: this.activatedRoute, queryParams, replaceUrl: true});
  }

  private returnFormattedValue(value: any) {
    if (value == null || value === '') {
      return null;
    }
    if (value instanceof Date) {
      return this.returnFormattedDateString(value);
    } else if (this.isValidDateString(value)) {
      return new Date(value);
    } else if (value.toString().toLowerCase().trim() === 'true' || value.toString().toLowerCase().trim() === 'false') { // Cast to boolean
      return value.toString().toLowerCase().trim() === 'true';
    } else if (!isNaN(Number(value))) {
      return parseInt(value.toString(), 10);  // Cast to number
    } else {
      return this.returnDateOrString(value);
    }
  }

  private returnFormattedValueInput(value: any) {
    if (value == null || value === '') {
      return null;
    }
    if (value instanceof Date) {
      return this.returnFormattedDateString(value);
    } else if (value.toString().toLowerCase().trim() === 'true' || value.toString().toLowerCase().trim() === 'false') { // Cast to boolean
      return value.toString().toLowerCase().trim() === 'true';
    } else if (!isNaN(Number(value))) {
      return parseInt(value.toString(), 10);  // Cast to number
    } else {
      return this.returnDateOrString(value);
    }
  }

  private returnFormattedDateString(date: Date) {
    const day = date.getDate();       // yields date
    const month = date.getMonth() + 1; // yields month (add one as '.getMonth()' is zero indexed)
    const year = date.getFullYear();  // yields year
    const hour = date.getHours();     // yields hours
    const minute = date.getMinutes(); // yields minutes
    const second = date.getSeconds(); // yields seconds

    // After this construct a string with the above results as below
    // tslint:disable-next-line:max-line-length
    return year + '-' + ('0' + month).slice(-2) + '-' + ('0' + day).slice(-2) + 'T' + ('0' + hour).slice(-2) + ':' + ('0' + minute).slice(-2) + ':' + ('0' + second).slice(-2);
  }

  private serializeArrayValues(queryParams: Params, propertyName: string, propertyValue: Array<any>) {
    let serializedValue = '';
    for (let index = 0; index < propertyValue.length; index++) {
      const element = propertyValue[index];
      serializedValue += element;
      if (index < propertyValue.length - 1) {
        serializedValue += ',';
      }
    }
    queryParams[propertyName + '[]'] = serializedValue;
  }

  private deserializeArrayValues(value: string) {
    const splittedValues = value.split(',');
    const result = new Array<any>();
    for (let index = 0; index < splittedValues.length; index++) {
      const element = splittedValues[index];
      result.push(this.returnFormattedValue(element));
    }
    return result;
  }

  public updateFilteringParamsFromUrl(filterObject: object) {
    const filterObjectProperties = Object.getOwnPropertyNames(filterObject);
    const queryParamsProperties = Object.getOwnPropertyNames(this.activatedRoute.snapshot.queryParams);
    for (const property of queryParamsProperties) {
      // Deserialize array values and set to filtering object
      if (property.indexOf('[]') > -1) {
        filterObject[property.replace('[]', '')] = this.deserializeArrayValues(this.activatedRoute.snapshot.queryParams[property]);
        continue;
      }

      if (filterObjectProperties.indexOf(property) > -1) {
        if (this.activatedRoute.snapshot.queryParams[property] == null) {
          continue;
        } // Skip properties with null value
        filterObject[property] = this.returnFormattedValue(this.activatedRoute.snapshot.queryParams[property]);
      }
    }
  }

  private returnDateOrString(value: any): any {
    if (typeof value === 'string') {
      return value;
    }
    if (value === '0001-01-01T00:00:00Z') {
      return null;
    }

    const isDateString = dateRegex.exec(value);
    if (!isDateString && typeof value !== 'string') {
      return value;
    }

    return new Date(value);
  }

  private isValidDateString(value: any) {
    return dateRegex.exec(value);
  }
}
