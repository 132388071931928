import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TestBed } from '@angular/core/testing';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { element } from 'protractor';
import { Company } from 'src/app/_models/company';
import { InventoryItem } from 'src/app/_models/inventoryItem';
import { Invoice } from 'src/app/_models/invoice';
import { ItemOrder } from 'src/app/_models/itemOrder';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { InventoryItemService } from 'src/app/_services/inventoryItem.service';
import { InvoiceService } from 'src/app/_services/invoice.service';
import { ItemOrderService } from 'src/app/_services/itemOrder.service';

@Component({
  selector: 'app-itemOrderEdit-modal',
  templateUrl: './itemOrderEdit-modal.component.html',
  styleUrls: ['./itemOrderEdit-modal.component.scss']
})
export class ItemOrderEditModalComponent implements OnInit {
  @Output() editItemOrder = new EventEmitter();
  @Output() reloadItems = new EventEmitter();
  itemOrder: ItemOrder;
  itemOrderEditForm: FormGroup;
  invoiceAddForm: FormGroup;
  companies: Company[];
  companiesClient: Company[];
  filterParams:any = {};
  header:string;
  duplicityFlag=false;
  invoice: Invoice;
  totalPrice=0;
  itemsValid :boolean=false;


  companyId: string;
  // month: string;
  invItems: InventoryItem[]= [];
  // totalPrice: number;

  constructor(public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private companyService: CompanyDialService,
    private authService: AuthService,
    // private priceListService: PriceListService,
    // private inventoryService: InventoryItemService,
    private alertify: AlertifyService, private invService: InventoryItemService, 
    private itemOrderService: ItemOrderService, 
    private invoiceService:InvoiceService,
    private datePipe: DatePipe) {}

  ngOnInit() {
    this.setHeader();
    this.createForm();
    if(this.itemOrder.orderState.includes( 'invoice'))
    {
      this.createInvoiceForm();
    }
    this.companyService.getCompanies(1,1).subscribe(data => {
      this.companies = data.result;
      this.companiesClient = data.result.filter(x=>x.client ==true || x.isPrimary == true);

      if (this.companyId !== '0')
      {
        this.companies = data.result.filter(x => x.id.toString() === this.companyId.toString());
      }
      else
      {
        this.companies = data.result;
      }
      this.companies = this.companies.filter(x=>x.supplier ==true);

    // this.addEmptyInvoiceItem();
    });
    this.getInvItems();
  }


  // tslint:disable-next-line: typedef
  createForm(){
    var tempWarranty= 
    this.itemOrderEditForm = this.fb .group({
      id: [this.itemOrder.id],
      dateOfOrder: [this.datePipe.transform(this.itemOrder.dateOfOrder, 'yyyy-MM-dd'), Validators.required],
      createdById: [this.itemOrder.createdById, Validators.required],
      supplierId: [this.itemOrder.supplierId, Validators.required],
      customerId: [this.itemOrder.customerId, Validators.required],
      orderState: [this.itemOrder.orderState],
      deleted: [false],
      
    });

   if(this.companyId !='0')
   {
    this.itemOrderEditForm.patchValue({supplierId: this.companyId})
   }
  
  }
  createOrder()
  {
    if (this.itemOrderEditForm.valid)
    {

      this.itemOrder = Object.assign({}, this.itemOrderEditForm.value);
      this.itemOrder.items = this.invItems;
      if(this.itemOrder.orderState === 'receipt')
      {
        this.itemOrder.dateOfDeliveryList = new Date();
      }
      this.editItemOrder.emit(this.itemOrder);
      this.bsModalRef.hide();
    }
  }

  forwardToReceipt()
  {
    if (this.itemOrderEditForm.valid)
    {
      this.itemOrder = Object.assign({}, this.itemOrderEditForm.value);
      this.itemOrder.items = this.invItems;
      this.itemOrder.orderState = 'receipt';
      
      

      this.itemOrderService.updateOrder(this.itemOrder.id, this.itemOrder).subscribe(() => {
        this.itemOrderEditForm.patchValue({orderState: 'receipt'})
        // this.alertify.success('Úspěšně upraveno');
        this.setHeader();
       
        this.reloadItems.emit();
        
      }, error => {
        this.alertify.error('Fakturu se nepodařilo upravit');
      }, () => {
      });

    }
  }

  forwardToInvoice()
  {
    if (this.itemOrderEditForm.valid)
      {
        this.itemOrder = Object.assign({}, this.itemOrderEditForm.value);
        this.itemOrder.orderState = 'accepted invoice';
        this.itemOrder.items = this.invItems;
        this.itemOrder.dateOfDeliveryList = new Date();
        this.itemOrderService.updateOrder(this.itemOrder.id, this.itemOrder).subscribe(() => {
          this.itemOrderEditForm.patchValue({orderState: 'accepted invoice'})
          // this.alertify.success('Úspěšně upraveno');
          this.setHeader();
          this.createInvoiceForm();
          this.reloadItems.emit();
        }, error => {
          this.alertify.error('Fakturu se nepodařilo upravit');
        }, () => {
        });

      }
  }

  addEmptyInvoiceItem()
  {
    let  emptyInvItem = {} as InventoryItem;
    emptyInvItem.clientCompanyId =0;
    emptyInvItem.deliveredToClient=false;
    emptyInvItem.isContainedInAnotherItem=false;
    emptyInvItem.isInWarehouse=true;
    emptyInvItem.isOnComplaint=false;
    emptyInvItem.deleted=false;
    emptyInvItem.idOfItemInWitchContained=0;
    emptyInvItem.invoiceId = this.invItems.reduce((op, item) => op = op > item.invoiceId ? op : item.invoiceId, 0)+1;
    emptyInvItem.invoicedToClient =false;
    emptyInvItem.clientInvoiceId =0;

    emptyInvItem.amount =0;
    emptyInvItem.orderedAmount =0;
    emptyInvItem.flag ="";
    emptyInvItem.serialNumber ="";
    emptyInvItem.productNumber ="";
    emptyInvItem.itemOrderId = this.itemOrder.id;
    emptyInvItem.warrantyTo = new Date(new Date(this.itemOrder.dateOfOrder).setFullYear(new Date(this.itemOrder.dateOfOrder).getFullYear()+2));
      // warrantyTo: [this.datePipe.transform(new Date(this.itemOrder.dateOfOrder).setFullYear(new Date(this.itemOrder.dateOfOrder).getFullYear()+2), 'yyyy-MM-dd'), Validators.required],


    
    this.invItems.push(emptyInvItem);
  }


  removeInvoiceItem(id: number)
  {
    this.invItems.filter(x=>x.id === id)[0].deleted=true;
  }


  getInvItems()
  {
    this.filterParams.name = '';
    this.filterParams.isContained = false;
    this.filterParams.hasContained = false;
    this.filterParams.delivered = false;
    this.filterParams.invoicedToClient = false;
    this.filterParams.reklamace = false;
    this.filterParams.inWarehouse = true;
    this.filterParams.clientId = 0;
    this.filterParams.supplierId = 0;
    this.filterParams.isForInvoice = false;
    this.filterParams.getPrimaryCompany = false;
    this.filterParams.itemOrderId = this.itemOrder.id;

    
    this.invService.getInvenotryItems(1, 1,0,this.filterParams).subscribe(x=>{
      this.invItems =  x.result
    
      this.checkInvItems();
      // this.getTotalPrice();
    })
  }

  setHeader(){
    this.checkInvItems();

    switch(this.itemOrder.orderState) { 
      case 'new order': { 
          this.header = "Nová objednávka"
         break; 
      } 
      case 'receipt': { 
        this.header = "Příjemka"
         break; 
      } 
      case 'accepted invoice': { 
        this.header = "Přijatá faktura"
         break; 
      } 
      // case 'accepted order': { 
      //   this.header = "Přijatá objednávka"
      //    break; 
      // } 
      // case 'issued invoice': { 
      //   this.header = "Vydaná faktura"
      //    break; 
      // } 
      default: { 
         this.header='';
         break; 
      } 
   } 
   
  }

  checkDuplicty()
  {

    const suniqueValues = new Set(this.invItems.map(v => v.serialNumber));

    if ( suniqueValues.size !== this.invItems.length) {
      this.duplicityFlag=true;
    }
    else
    {
      this.duplicityFlag=false;
    }
   

  }


  createInvoiceForm(){


    if(this.itemOrder.orderState !== 'filled invoice')
    {
      this.invoiceAddForm = this.fb .group({
        companyId: [this.companyId, Validators.required],
        forMonth: [this.datePipe.transform(new Date(), 'yyyy-MM'), Validators.required],
        invoiceNumber: ['', Validators.required],
        dateToPay: [this.datePipe.transform(new Date(), 'yyyy-MM-dd'), Validators.required],
        paid: [false],
        paidDate: [''],
        constantSymbol: ['', Validators.required],
        variableSymbol: ['', Validators.required],
        formOfPayment: ['Bankovní převod', Validators.required],
        userId: [this.authService.curretnUser.id],
        duzp: [this.datePipe.transform(new Date(), 'yyyy-MM-dd'), Validators.required],
        supplierCompanyId:[''],
        price: [''],
        ticketId: ['0'],
        dateOfCreation: [''],
      });
      if(this.companyId !='0')
      {
        this.invoiceAddForm.patchValue({companyId: this.companyId})
      }
      // if(this.month !== undefined)
      // {
        this.invoiceAddForm.patchValue({forMonth: this.datePipe.transform(this.itemOrder.dateOfOrder, 'yyyy-MM')})
      // }
    }
    else
    {
      ///TODO doplnit hodnoty
      this.invoiceService.getInvoiceById(this.itemOrder.invId).subscribe(data=>{
        this.invoice = data;
        this.invoiceAddForm = this.fb .group({
          id: [this.invoice.id, Validators.required],
          companyId: [this.invoice.companyId, Validators.required],
          forMonth: [this.datePipe.transform(this.invoice.forMonth, 'yyyy-MM'), Validators.required],
          invoiceNumber: [this.invoice.invoiceNumber, Validators.required],
          dateToPay: [this.datePipe.transform(this.invoice.dateToPay, 'yyyy-MM-dd'), Validators.required],
          paid: [this.invoice.paid],
          paidDate: [this.invoice.paidDate],
          constantSymbol: [this.invoice.constantSymbol, Validators.required],
          variableSymbol: [this.invoice.variableSymbol, Validators.required],
          formOfPayment: [this.invoice.formOfPayment, Validators.required],
          userId: [this.invoice.userId],
          duzp: [this.invoice.duzp, Validators.required],
          supplierCompanyId:[this.invoice.supplierCompanyId],
    
          price: [this.invoice.price],
          ticketId: [this.invoice.ticketId],
          dateOfCreation: [this.invoice.dateOfCreation],
        });
      });

     
    }
   

   
  }



  createInvoice()
  {
    if (this.invoiceAddForm.valid)
    {
      this.invoiceAddForm.patchValue({dateOfCreation : this.datePipe.transform(new Date(), 'yyyy-MM-dd')})
      this.invoiceAddForm.patchValue({price: this.totalPrice})

      this.invoiceAddForm.patchValue({companyId: this.authService.decodedToken.CompanyId})
      this.invoiceAddForm.patchValue({supplierCompanyId: this.itemOrderEditForm.get('supplierId').value})
     

      this.invoice = Object.assign({}, this.invoiceAddForm.value);
      
      this.invoice.recievedInvoice = true;
      this.getTotalPrice();
      
      this.invoice.priceWithDPH = this.getTotalPriceWithDPH();;
      this.invoice.price = this.totalPrice;

      this.invoiceService.addRecievedInvoice(this.invoice,this.invItems).subscribe(data => {
        this.alertify.success('Faktura úspěšně vytvořena');
        //TODO zmenit stav orderu
          this.itemOrder.orderState = 'filled invoice';
          this.itemOrder.invId = data['invoice'].id;
          // this.itemOrder.orderState = 'filled invoice';
          this.itemOrderService.updateOrder(this.itemOrder.id, this.itemOrder).subscribe(() => {
            this.reloadItems.emit();
          }, error => {
          }, () => {
          });

      }, error => {
        this.alertify.error('Fakturu se nepodařilo vytvořit');
      }, () => {
        this.bsModalRef.hide();
      });
      // this.createNewRecievingInvoice.emit({invoice: this.invoice, items: this.invItems});
    }
  }
  saveInvoice()
  {
    if (this.invoiceAddForm.valid)
    {
      // this.invoiceAddForm.value.dateOfCreation = new Date();
      this.invoiceAddForm.value.price = this.totalPrice;
      this.invoiceAddForm.patchValue({companyId: this.authService.decodedToken.CompanyId})
      this.invoiceAddForm.patchValue({supplierCompanyId: this.itemOrderEditForm.get('supplierId').value})
      this.invoice = Object.assign({}, this.invoiceAddForm.value);
      this.invoice.recievedInvoice = true;
      this.getTotalPrice();
      this.invoice.priceWithDPH = this.getTotalPriceWithDPH();
      this.invoice.price = this.totalPrice;
      this.invoiceService.updateRecievedInvoice(this.invoice,this.invItems,this.invoice.id).subscribe((data) => 
      {
        this.alertify.success('Faktura úspěšně uložena');
        //TODO zmenit stav orderu
          this.itemOrder.orderState = 'filled invoice';
          if(data!=null)
          {
            this.itemOrder.invId = data.id;
            this.itemOrder.items.forEach(element => {
              element.invoiceId = data.id;
            }); 
          }

          // this.itemOrder.orderState = 'filled invoice';
          this.itemOrderService.updateOrder(this.itemOrder.id, this.itemOrder).subscribe(() => {
          }, error => {
          }, () => {
          });

      }, error => {
        this.alertify.error('Fakturu se nepodařilo vytvořit');
      }, () => {
        this.bsModalRef.hide();

      });
    }
  }

  getTotalPrice()
  {
    this.invItems.forEach(invItem => {
      this.totalPrice = this.totalPrice + (invItem.amount*invItem.buyingPrice);
    });
        // this.totalPrice = this.totalPrice*1.21;
  }

  getTotalPriceWithDPH()
  {
    var priceWithDPH =0;
    this.invItems.forEach(invItem => {
      priceWithDPH = priceWithDPH + ((invItem.amount*invItem.buyingPrice)*(1 + (invItem.dphBuying/100)));
    });
    return priceWithDPH; 
        // this.totalPrice = this.totalPrice*1.21;
  }


  checkInvItems()
  {
    var error = this.invItems.filter(x=>
      x.orderedAmount === 0 || x.orderedAmount === null ||
       x.buyingPrice === undefined || x.buyingPrice === null ||
       x.name === undefined || x.name === '' || x.name === null ||
       x.flag === undefined || x.flag ==='' 
       
      ).length>0;
    if( !error && this.itemOrder.orderState === 'receipt' )
    {
      error = this.invItems.filter(x=>
        x.productNumber === '' || x.productNumber === null ||
        x.serialNumber === '' || x.serialNumber === null ||
        x.amount === null ||
        x.warrantyTo === undefined || x.warrantyTo === null 
        ).length>0;
    }
    this.itemsValid = !error;
  }

 
}
