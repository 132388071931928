import {MonthlyReport} from '../_models/monthlyReport';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Invoice} from '../_models/invoice';


@Injectable({
  providedIn: 'root'
})
export class MonthlyReportService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  getBasicReport(companyId: number, month: number, year: number): Observable<MonthlyReport> {
    // const date = new Date(inv.forMonth);
    // const month = date.getMonth() + 1;
    // const year = date.getFullYear();
    return this.http.get<MonthlyReport>(this.baseUrl + 'Report/' + month + '/' + year + '/' + companyId);
  }

  getMonthlyReport(inv: Invoice): Observable<MonthlyReport> {
    const date = new Date(inv.forMonth);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return this.http.get<MonthlyReport>(this.baseUrl + 'Report/' + month + '/' + year + '/' + inv.companyId);
  }

  getMonthlyPdfReport(inv: Invoice): Observable<any> {
    const date = new Date(inv.forMonth);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.http.get<any>(this.baseUrl + 'Report/pdf/' + month + '/' + year + '/' + inv.companyId, httpOptions);
  }
}
