import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Company } from 'src/app/_models/company';
import { User } from 'src/app/_models/user';
import { WorkPost } from 'src/app/_models/workPost';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { WorkPostService } from 'src/app/_services/workPost.service';

@Component({
  selector: 'app-registerUser-modal',
  templateUrl: './registerUser-modal.component.html',
  styleUrls: ['./registerUser-modal.component.css']
})
export class RegisterUserModalComponent implements OnInit {
  @Output() registerNewUser = new EventEmitter();
  user: User;
  registerForm: FormGroup;
  bsConfig: Partial<BsDatepickerConfig>;
  // companyList: {value: number, display: string}[];
  companies: Company[];
  workPosts: WorkPost[];
  filterParams: any = {};

  constructor(public bsModalRef: BsModalRef,
    private authService: AuthService,
    private fb: FormBuilder,
    private companyService: CompanyDialService,
    private workPostService: WorkPostService,
    ) { 
   
  }

  ngOnInit()
  {
    this.bsConfig = {
      containerClass: 'theme-red'
    },
    this.createRegisterForm();
        this.workPostService.getWorkPosts(1, 1,null).subscribe(x=>{this.workPosts= x.result} )
        this.companyService.getCompanies(1, 1,null,null,false,false).subscribe(x=>{
          this.companies = x.result;
          if (this.filterParams.companyId !== '0')
          {
            this.companies = this.companies.filter(x => x.id.toString() === this.filterParams.companyId);
          }
          if(this.authService.decodedToken.IsPrimary !== 'True')
          {
            this.companies = this.companies.filter(x => x.id.toString() === this.authService.decodedToken.CompanyId);
          }
        });
        

  }
  
  Register()
  {
    if (this.registerForm.valid)
    {
      this.user = Object.assign({}, this.registerForm.value);
      this.registerNewUser.emit(this.user);
      this.bsModalRef.hide();
    }
  }


  createRegisterForm(){
    this.registerForm = this.fb .group({
      email: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      workPost: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      companyId: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      note: [''],
      sendInvoices: [false],
      password: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50),Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{4,50}$/)]]

    }, {validator: this.passwordMatchValidator});
  }


  passwordMatchValidator(g: FormGroup)
  {
    return g.get('password').value === g.get('confirmPassword').value ? null : { mismatch: true };
  }
 
}
