import {DatePipe} from '@angular/common';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Company} from 'src/app/_models/company';
import {Invoice} from 'src/app/_models/invoice';
import {ItemOrder} from 'src/app/_models/itemOrder';
import {OrderedItem} from 'src/app/_models/orderedItem';
import {AlertifyService} from 'src/app/_services/alertify.service';
import {AuthService} from 'src/app/_services/auth.service';
import {CompanyDialService} from 'src/app/_services/companyDial.service';
import {InvoiceService} from 'src/app/_services/invoice.service';
import {ItemOrderService} from 'src/app/_services/itemOrder.service';

@Component({
  selector: 'app-issueEdit-modal',
  templateUrl: './issueEdit-modal.component.html',
  styleUrls: ['./issueEdit-modal.component.scss']
})
export class IssueEditModalComponent implements OnInit {
  @Output() editItemOrder = new EventEmitter();
  @Output() reloadItems = new EventEmitter();
  @Output() openInvoice = new EventEmitter();
  itemOrder: ItemOrder;
  itemOrderAddForm: FormGroup;
  invoiceAddForm: FormGroup;
  companies: Company[];
  companiesClient: Company[];
  itemsValid: boolean = false;
  header: string = '';
  invoice: Invoice;
  companyId: string;
  orderedItems: OrderedItem[] = [];

  constructor(public bsModalRef: BsModalRef,
              private fb: FormBuilder,
              private companyService: CompanyDialService,
              private authService: AuthService,
              private alertify: AlertifyService,
              private itemOrderService: ItemOrderService,
              private invoiceService: InvoiceService,
              private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.setHeader();
    this.orderedItems = this.itemOrder.orderedItems;
    this.createForm();
    this.companyService.getCompanies(1, 1).subscribe(data => {
      this.companies = data.result;
      if (this.companyId !== '0') {
        this.companies = data.result.filter(x => x.id.toString() === this.companyId.toString());
      } else {
        this.companies = data.result;
      }
      this.companiesClient = this.companies.filter(x => x.client == true || x.isPrimary == true);
      this.companies = this.companies.filter(x => x.supplier == true);
    });
  }


  // tslint:disable-next-line: typedef
  createForm() {
    this.itemOrderAddForm = this.fb.group({
      dateOfOrder: [this.datePipe.transform(new Date(), 'yyyy-MM-dd'), Validators.required],

      createdById: [this.authService.decodedToken.nameid, Validators.required],
      supplierId: [0],
      customerId: [this.itemOrder.customerId, Validators.required],
      orderState: [this.itemOrder.orderState],
      deleted: [false],
      id: [this.itemOrder.id],

    });

    if (this.companyId != '0') {
      this.itemOrderAddForm.patchValue({supplierId: this.companyId})
    }
    this.reloadItems.emit();

  }

  createOrder() {
    if (this.itemOrderAddForm.valid) {

      this.itemOrder = Object.assign({}, this.itemOrderAddForm.value);
      this.itemOrder.orderedItems = this.orderedItems;

      this.editItemOrder.emit(this.itemOrder);
      this.reloadItems.emit();
      this.bsModalRef.hide();
    }
  }

  addEmptyInvoiceItem() {
    const emptyInvItem = {} as OrderedItem;
    emptyInvItem.amount = 0;
    emptyInvItem.name = "";
    emptyInvItem.price = 0;
    emptyInvItem.itemOrderId = 0;
    emptyInvItem.deleted = false;
    emptyInvItem.dynamicId = this.orderedItems.reduce((op, item) => op = op > item.dynamicId ? op : item.dynamicId, 0) + 1;
    this.orderedItems.push(emptyInvItem);
    this.checkInvItems();

  }

  removeInvoiceItem(id: number) {
    this.orderedItems = this.orderedItems.filter(x => x.dynamicId != id);
    this.checkInvItems();
  }

  checkInvItems() {
    if (this.orderedItems.length <= 0) {
      this.itemsValid = true;
      return;
    }

    var error = this.orderedItems.filter(x =>
        x.amount === 0 || x.amount === null ||
        x.price === undefined || x.price === null ||
        x.name === undefined || x.name === '' || x.name === null
      //  x.flag === undefined || x.flag ===''
    ).length > 0;

    this.itemsValid = !error;
  }

  forwardToInvoice() {
    if (this.itemOrderAddForm.valid) {
      this.itemOrder = Object.assign({}, this.itemOrderAddForm.value);
      this.itemOrder.orderState = 'issued invoice';
      this.itemOrder.orderedItems = this.orderedItems;
      this.itemOrderService.updateOrder(this.itemOrder.id, this.itemOrder).subscribe(() => {
        this.itemOrderAddForm.patchValue({orderState: 'issued invoice'})
        this.setHeader();
        // this.createInvoiceForm();
        this.openInvoice.emit();
        this.bsModalRef.hide();
      }, error => {
        this.alertify.error('Fakturu se nepodařilo upravit');
      }, () => {
      });

    }
  }

  setHeader() {
    this.checkInvItems();

    switch (this.itemOrder.orderState) {
      case 'new issue': {
        this.header = "Objednávka"
        break;
      }
      case 'issued invoice': {
        this.header = "Faktura"
        break;
      }
      // case 'accepted order': {
      //   this.header = "Přijatá objednávka"
      //    break;
      // }
      // case 'issued invoice': {
      //   this.header = "Vydaná faktura"
      //    break;
      // }
      default: {
        this.header = '';
        break;
      }
    }

  }


}
