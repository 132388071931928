import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PaginatedResult } from '../_models/pagination';
import { PurchaseDepartmentOrder } from '../_models/purchaseDepartmentOrder';

@Injectable({
  providedIn: 'root'
})
export class PurchaseDepartmentService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}


  addPDO(priority: PurchaseDepartmentOrder)
  {
    return this.http.post(this.baseUrl + 'PurchaseDepartment/', priority );
  }

  removePDO(priorityId: number)
  {
    return this.http.delete(this.baseUrl + 'PurchaseDepartment/' + priorityId );
  }

  getPDOsPaginated( page?, itemsPerPage?, userParams?)
  {
    const paginatedResult: PaginatedResult<PurchaseDepartmentOrder[]> = new PaginatedResult<PurchaseDepartmentOrder[]>();
    let params = new HttpParams();

    if (page != null && itemsPerPage != null)
    {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
    if ( userParams != null)
    {
      params = params.append('Name', userParams.name);
      params = params.append('OrderedById', userParams.orderedById);
      params = params.append('OrderedFromId', userParams.orderedFromId);
    }
    return this.http.get<PurchaseDepartmentOrder[]>(this.baseUrl + 'PurchaseDepartment/Paginated', {observe: 'response', params} )
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') !== null)
        {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }

        return paginatedResult;
      })
    );
  }

  getPDOs( userParams?)
  {
    // const paginatedResult: PurchaseDepartmentOrder[] = [];
    let params = new HttpParams();
    if ( userParams != null)
    {
      params = params.append('Name', userParams.name);
      params = params.append('OrderedById', userParams.orderedById);
      params = params.append('OrderedFromId', userParams.orderedFromId);
    }
    return this.http.get<PurchaseDepartmentOrder[]>(this.baseUrl + 'PurchaseDepartment', {observe: 'response', params} )
    .pipe(
      map(response => {
       
        return response.body;
      })
    );
  }

  updatePDO(id: number, priority: PurchaseDepartmentOrder)
  {
    return this.http.put(this.baseUrl + 'PurchaseDepartment/' + id, priority);
  }
}
