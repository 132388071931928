<div class="m-5">
  <div class="row justify-content-center">
<form [formGroup]="userEditForm" (ngSubmit)="edit()">
<!-- <div *appHasRole="['CompanyFilter']" class="form-group">
  <div class="form-inline">
    <select formControlName="companyId" class="form-control" id="companySelect"  placeholder="Firma"  name="companySelect" 
    [ngClass]="{ 'is-invalid': userEditForm.get('companyId').errors && userEditForm.get('companyId').touched}">
      <option value="">Vyberte firmu</option>
      <option *ngFor="let companyItem of companies" [value] = "companyItem.id">
        {{companyItem.companyName}}
      </option>
    </select>
  <div class="invalid-feedback" *ngIf="userEditForm.get('companyId').touched && userEditForm.get('companyId').hasError('required')">Firma je povinné pole</div>
  <button
    class="btn btn-success"
    (click)="setNewCompany()">
    Uložit
  </button>
</div>
</div> -->

<div class="form-group">
  <input
    type="password"
    [ngClass]="{
      'is-invalid':
        userEditForm.get('oldPassword').errors &&
        userEditForm.get('oldPassword').touched
    }"
    class="form-control"
    formControlName="oldPassword"
    placeholder="Staré heslo"
  />
  <div
    class="invalid-feedback"
    *ngIf="
      userEditForm.get('oldPassword').hasError('required') &&
      userEditForm.get('oldPassword').touched
    "
  >
    Staré heslo je povinné pole
  </div>
</div>

<div class="form-group">
  <input
    type="password"
    [ngClass]="{
      'is-invalid':
        userEditForm.get('newPassword').errors &&
        userEditForm.get('newPassword').touched
    }"
    class="form-control"
    formControlName="newPassword"
    placeholder="Nové heslo"
  />
  <div
    class="invalid-feedback"
    *ngIf="
      userEditForm.get('newPassword').hasError('required') &&
      userEditForm.get('newPassword').touched
    "
  >
    Heslo je povinné pole
  </div>
  <div
    class="invalid-feedback"
    *ngIf="
      userEditForm.get('newPassword').hasError('minlength') &&
      userEditForm.get('newPassword').touched
    "
  >
    Heslo musí mít nejméně 4 znaky
  </div>
  <div
    class="invalid-feedback"
    *ngIf="
      userEditForm.get('newPassword').hasError('maxlength') &&
      userEditForm.get('c').touched
    "
  >
    Heslo nesmí přesáhnout délku 50ti znaků
  </div>
  <div
  class="invalid-feedback"
  *ngIf="
  userEditForm.get('newPassword').hasError('pattern') &&
    userEditForm.get('newPassword').touched
  "
>
  Heslo musí obsahovat alespoň jedno číslo, jedno malé a velké písmeno
</div>
</div>

<div class="form-group">
  <input
    type="password"
    [ngClass]="{
      'is-invalid':
        (userEditForm.get('confirmPassword').errors &&
          userEditForm.get('confirmPassword').touched) ||
        (userEditForm.get('confirmPassword').touched &&
          userEditForm.hasError('mismatch'))
    }"
    class="form-control"
    formControlName="confirmPassword"
    placeholder="Potvrzení hesla"
  />
  <div
    class="invalid-feedback"
    *ngIf="
      userEditForm.get('confirmPassword').hasError('required') &&
      userEditForm.get('confirmPassword').touched
    "
  >
    Heslo je povinné pole
  </div>
  <div
    class="invalid-feedback"
    *ngIf="
      userEditForm.hasError('mismatch') &&
      userEditForm.get('confirmPassword').touched
    "
  >
    Hesla se neschodují
  </div>
</div>

<div class="form-group text-center">
  <button
    [disabled]="!userEditForm.valid"
    class="btn btn-success"
    type="submit">
    Změnit heslo
  </button>
</div>
</form>
  </div>
</div>