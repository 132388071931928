import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Company } from 'src/app/_models/company';
import { ItemOrder } from 'src/app/_models/itemOrder';
import { Pagination } from 'src/app/_models/pagination';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { InventoryItemService } from 'src/app/_services/inventoryItem.service';
import { InvoiceService } from 'src/app/_services/invoice.service';
import { ItemOrderService } from 'src/app/_services/itemOrder.service';
import { ItemOrderDetailModalComponent } from '../itemOrderDetail-modal/itemOrderDetail-modal.component';

@Component({
  selector: 'app-itemOrderReceiptOverview',
  templateUrl: './itemOrderReceiptOverview.component.html',
  styleUrls: ['./itemOrderReceiptOverview.component.scss']
})
export class ItemOrderReceiptOverviewComponent implements OnInit {
  bsModalRef: BsModalRef;
  itemOrders: ItemOrder[];
  pagination: Pagination;
  companies: Company[];
  filterParams: any = {};
  stepsviewflag: any;
  a:Date;
  b:Date;

 

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private companyService: CompanyDialService,
    private itemOrderService: ItemOrderService,
    private invService: InventoryItemService,
    private invoiceService: InvoiceService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.companyService.getCompanies(1,10000,"",'true',false,true).subscribe((data) => {
      this.companies = data.result;
    });
    this.route.data.subscribe((data) => {
      this.pagination = data['orders'].pagination;
      this.itemOrders = data['orders'].result;
    });
    this.route.params.subscribe(params => {
      
      this.filterParams.companyId = params['companyId'];
     
      this.filterParams.dateFrom = '1990-01-01';
      this.filterParams.dateTo = '1990-01-01';
      this.filterParams.filterType=2;
      this.filterParams.invoicePaid=0;
      this.filterParams.docId ="";
      this.filterParams.invoiceId = 0;
      // this.filterParams.docId="";
    });
  }

  
resetFilters()
{
  this.filterParams.companyId = "0";
  this.filterParams.dateFrom = '1990-01-01';
  this.filterParams.dateTo = '1990-01-01';
  this.filterParams.filterType=2;
  this.filterParams.invoicePaid=0;
  this.filterParams.docId="";
  this.filterParams.invoiceId=0;
  // this.filterParams.orderDateTime = "";
  // this.filterParams.orderDateTime = "";
  this.loadOrders();
}
  loadOrders()
  {
    this.filterParams.isSupplier=true;
  if(this.filterParams.dateFrom === "")
  this.filterParams.dateFrom = '1990-01-01';
  if(this.filterParams.dateTo === "")
  this.filterParams.dateTo = '1990-01-01';

  this.itemOrderService.getOrders(this.pagination.currentPage, this.pagination.itemsPerPage,this.filterParams).subscribe(data => {
      this.pagination = data.pagination;
      this.itemOrders = data.result;

      if(this.filterParams.dateFrom === '1990-01-01')
      this.filterParams.dateFrom = "";
      if(this.filterParams.dateTo === '1990-01-01')
      this.filterParams.dateTo = "";

    });
    // var filterParams: any= {};
    // filterParams.orderDateTime = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    // filterParams.companyId = 0;
    
    // return this.itemOrderService.getOrders(this.pageNumber, this.pageSize,filterParams).subscribe(x=>
    //   {

    //   });
  }
  pageNumber(pageNumber: any, pageSize: any, filterParams: any) {
    throw new Error('Method not implemented.');
  }
  pageSize(pageNumber: any, pageSize: any, filterParams: any) {
    throw new Error('Method not implemented.');
  }

 

  getDetailModal(itemOrder: ItemOrder, viewName)
  {
    
      const initialState: any= {
        itemOrder,
        viewName: viewName,
        companyId: this.filterParams.companyId,
      };
      this.bsModalRef = this.modalService.show(ItemOrderDetailModalComponent, {initialState, backdrop:'static', class:'modal-lg'});
  }

  deleteInvoice(itemOrder: ItemOrder)
  {
    this.itemOrderService.canDeleteOrderItems(itemOrder.id).subscribe(res => {
      if(res['content'] === "")
      {
      this.alertify.confirm('Opravdu chcete vymazat tuto fakturu ?', () => {
        var invId = 0;
        if(itemOrder.inv !== null)
          invId = itemOrder.inv.id;
  
        this.invoiceService.deleteInvoice(invId)
        .subscribe(() => {
          this.itemOrderService.deleteOrder(itemOrder.id).subscribe(data =>
            {
              this.loadOrders()
              this.alertify.success('Úspěšně odstraněno');
            });
        
        }, error => {
          this.alertify.error('Fakturu se nepodařilo odstranit');
        });
      });
     }
     else
     {
       this.alertify.warning(res['content']);
     }

    });
   
  }

}
