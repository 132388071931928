import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PaginatedResult } from '../_models/pagination';
import { WorkPost } from '../_models/workPost';

@Injectable({
  providedIn: 'root'
})
export class WorkPostService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}


  addWorkPost(workpost: WorkPost)
  {
    return this.http.post(this.baseUrl + 'WorkPost/', workpost );
  }

  deleteWorkPost(workpostId: number)
  {
    return this.http.delete(this.baseUrl + 'WorkPost/' + workpostId );
  }

  getWorkPosts( page?, itemsPerPage?, userParams?)
  {
    const paginatedResult: PaginatedResult<WorkPost[]> = new PaginatedResult<WorkPost[]>();
    let params = new HttpParams();

    if (page != null && itemsPerPage != null)
    {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
    // if ( userParams != null)
    // {
    //   params = params.append('CompanyId', userParams.companyId);
    // }
    return this.http.get<WorkPost[]>(this.baseUrl + 'WorkPost', {observe: 'response', params} )
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') !== null)
        {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })
    );
  }

  updateWorkPost(id: number, workpost: WorkPost)
  {
    return this.http.put(this.baseUrl + 'WorkPost/' + id, workpost);
  }
}