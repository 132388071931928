<div class="modal-header ">
  <h4 class="modal-title pull-left">Nastavení notifikací {{user.userName}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form #rolesForm="ngForm" id="rolesForm">
    <div class="form-check" *ngFor="let notif of userNotifs">
      <input type="checkbox" class="form-check-input" value="{{notif.notification}}" [checked]="notif.checked"
      (change)="notif.checked =! notif.checked">
      <label>{{notif.notification}}</label>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button type="button" class="btn btn-success" form="rolesForm" (click)="callEvenet()">Uložit</button>
</div>