import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Company } from '../_models/company';
import { PaginatedResult, Pagination } from '../_models/pagination';
import { AlertifyService } from '../_services/alertify.service';
import { AuthService } from '../_services/auth.service';
import { InvoiceService } from '../_services/invoice.service';
import { CompanyDialService } from '../_services/companyDial.service';
import { Invoice } from '../_models/invoice';
import { InvoiceCreateModalComponent } from './invoicecreate-modal/invoicecreate-modal.component';
import { PriceListService } from '../_services/pricelist.service';
import { OrderService } from '../_services/order.service';
import { InvoiceEditModalComponent } from './invoiceedit-modal/invoiceedit-modal.component';
import { InvoiceDetailModalComponent } from './invoicedetail-modal/invoicedetail-modal.component';
import { InvoiceWpsModalComponent } from './invoiceWps-modal/invoiceWps-modal.component';
import { ToolsService } from '../_services/tools.service';

@Component({
  selector: 'app-priority',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {
  bsModalRef: BsModalRef;
  invoices: Invoice[];
  pagination: Pagination;
  companies: Company[];
  filterParams: any = {};

  constructor(
    private invoiceService: InvoiceService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private companyService: CompanyDialService,
    private priceListService: PriceListService,
    private orderService: OrderService,
    private toolsService:ToolsService
  ) {}

  ngOnInit() {
    
    this.route.data.subscribe((data) => {
      this.pagination = data['invoices'].pagination;
    });
    this.companyService.getCompanies(1,10000,"",'true',false,true).subscribe((data) => {
      this.companies = data.result;
      this.route.params.subscribe(params => {
        this.filterParams.companyId = params['filterParam'];
        if(this.authService.decodedToken.IsPrimary !== 'True')
        {
          this.filterParams.companyId = this.authService.decodedToken.CompanyId;
        }
       
        this.filterParams.recievedInvoice = false;
        this.loadInvoices();
      });
    });
  }
  roundTo(num)
  {
    return this.toolsService.roundTo(num);
  }
  deleteInvoice(id: number)
  {
    this.alertify.confirm('Opravdu chcete vymazat tuto fakturu ?', () => {
      this.invoiceService.deleteInvoice(id)
      .subscribe(() => {
        this.invoices.splice(this.invoices.findIndex(m => m.id === id ), 1);
        this.alertify.success('Úspěšně odstraněno');
      }, error => {
        this.alertify.error('Fakturu se nepodařilo odstranit');
      });
    });
  }

  loadInvoices() {
    this.invoiceService.getInvoices( this.pagination.currentPage, this.pagination.itemsPerPage, this.filterParams)
    .subscribe((res: PaginatedResult<Invoice[]>) => {
      this.invoices = res.result;
      this.pagination.totalPages =res.pagination.totalPages;
      this.pagination.itemsPerPage =res.pagination.itemsPerPage;
      this.pagination.totalItems =res.pagination.totalItems;
    }, error => {
      this.alertify.error(error);
    });
   }

  pageChanged(event: any): void{
    this.pagination.currentPage = event.page;
    this.loadInvoices();
  }

  createModal()
  {
    const initialState: any= {
      companyId: this.filterParams.companyId
    };
    this.bsModalRef = this.modalService.show(InvoiceCreateModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.createNewInvoice.subscribe((values) =>
    {
      this.invoiceService.addInvoice(values.invoice,values.items).subscribe(() => {
        this.alertify.success('Úspěšně přidáno');
      }, error => {
        this.alertify.error('Fakturu se nepodařilo přidat');
      }, () => {
        this.loadInvoices();
      });
    });
  }

  getEditModal(invoice: Invoice)
  {
    const initialState: any= {
      invoice,
      companyId: this.filterParams.companyId
    };
    this.bsModalRef = this.modalService.show(InvoiceEditModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.editinvoice.subscribe((values) =>
    {
      this.invoiceService.updateInvoice(invoice.id, values.invoice, values.items).subscribe(() => {
        this.alertify.success('Úspěšně upraveno');
      }, error => {
        this.alertify.error('Fakturu se nepodařilo upravit');
      }, () => {
        this.loadInvoices();
      });
    });
  }


  getDetailModal(invoice: Invoice)
  {
    const initialState: any= {
      invoice,
    };
    this.bsModalRef = this.modalService.show(InvoiceDetailModalComponent, {initialState, backdrop:'static', class:'modal-lg'});
  }

  sendInvoiceLink(invoice: Invoice)
  {
   this.invoiceService.sendInvoiceLink(invoice.id,"").subscribe(()=>{});
  }

  getWpsModal(invoice: Invoice)
  {
    const initialState: any= {
      invoice,
      companyId: invoice.companyId
    };
    this.bsModalRef = this.modalService.show(InvoiceWpsModalComponent, {initialState, backdrop:'static', class:'modal-lg'});
  }

  resetFilters()
  {
    this.filterParams.companyId = '0';
    this.loadInvoices();
  }

}
