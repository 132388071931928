import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Company } from 'src/app/_models/company';
import { Licence } from 'src/app/_models/licence';
import { Pagination, PaginatedResult } from 'src/app/_models/pagination';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { LicenceService } from 'src/app/_services/licence.service';
import { LicencecreateModalComponent } from '../licencecreate-modal/licencecreate-modal.component';
import { LicenceeditModalComponent } from '../licenceedit-modal/licenceedit-modal.component';

@Component({
  selector: 'app-licenceReport',
  templateUrl: './licenceReport.component.html'
})
export class LicenceReportComponent implements OnInit {
  bsModalRef: BsModalRef;
  licences: Licence[];
  companies: Company[];
  filterParams: any = {};

  constructor(
    private licenceService: LicenceService,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private companyService: CompanyDialService,
    private authService: AuthService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit() {

    this.route.data.subscribe((data) => {
      this.licences = data['licence'].result;

    });
    this.companyService.getCompanies(1, 1,null,null,false,true).subscribe((data) => {
      this.companies = data.result;
      this.route.params.subscribe(params => {
        this.filterParams.companyId = params['companyId'];
        if(this.authService.decodedToken.IsPrimary !== 'True')
        {
          this.filterParams.companyId = this.authService.decodedToken.CompanyId;
        }
        if(this.filterParams.companyId === '0')
         {
           this.filterParams.companyId = this.authService.decodedToken.CompanyId;
         }
        this.resetFilters()

        // this.loadLicences();

      });
    });
  }



  loadLicences() {
    this.licenceService.getLicenceReport( this.filterParams)
    .subscribe((res) => {
      this.licences = res;
    }, error => {
      this.alertify.error(error);
    });
   }

  resetFilters()
  {
    // this.filterParams.companyId = this.authService.decodedToken.CompanyId;
    this.filterParams.suplierId = 0;
    this.filterParams.licenceCode = '';
    this.filterParams.companyId = this.companies[0].id;
    this.filterParams.orderDate = this.datePipe.transform(Date.now(), 'yyyy-MM');
    this.loadLicences();
  }



}
