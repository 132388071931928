<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  
  <h4><strong>Název:</strong> {{company.companyName}}</h4>
  <h4><strong>IČO:</strong> {{company.ico}}</h4>
  <h4><strong>DIČ:</strong> {{company.dico}}</h4>
  <h4><strong>Telefoní číslo:</strong> {{company.phoneNumber}}</h4>
  <h4><strong>Email:</strong> {{company.email}}</h4>
  <h4><strong>PSČ:</strong> {{company.adressPSC}}</h4>
  <h4><strong>Okres:</strong> {{company.adressOkres}}</h4>
  <h4><strong>Obec:</strong> {{company.adressObec}}</h4>
  <h4><strong>Část obce:</strong> {{company.adressCastObce}}</h4>
  <!-- <h4>Mop: {{company.adressMop}}</h4> -->
  <h4><strong>Ulice:</strong> {{company.adressUlice}}</h4>
  <h4><strong>Číslo popisné:</strong> {{company.adressCisloPopisne}}</h4>
  <h4><strong>Číslo or:</strong> {{company.adressCisloOr}}</h4>
  <h4><strong>Délka splatnosti:</strong> {{company.daysToPay}} dní</h4>
  <h4><strong>IBAN:</strong> {{company.accountNumber}}</h4>
  <h4><strong>Číslo účtu:</strong> {{accountNumber}}</h4>
  <h4 ><strong>Odběratel/Dodavatel: </strong><strong *ngIf="company.client" class="text-success mr-5">O</strong> 
    <strong *ngIf="company.supplier"class="text-danger">D</strong></h4>

  <div *ngIf="company.splitHours">
    <h4><strong>Paušál RAS:</strong> {{company.rasHours}} h</h4>
    <h4><strong>Paušál ONS:</strong> {{company.onsHours}} h</h4>
  </div>
  <h4 *ngIf="!company.splitHours"><strong>Paušál:</strong> {{company.hours}} h</h4>
  <h4><strong>Paušál km:</strong> {{company.prepaidKm}} </h4>
  <h4><strong>Standartně ujeté km:</strong> {{company.standardKms}}</h4>

  
  <h4><strong>Poznámka:</strong> {{company.note}}</h4>

</div>
<div class="modal-footer">
  <a [routerLink]="['/priorities', company.id]" target="_blank" class=" btn btn-primary FullViewLink">Priority</a>
  <a [routerLink]="['/category', company.id]" target="_blank" class=" btn btn-primary FullViewLink">Kategorie</a>
  <!-- <a [routerLink]="['/register', company.id]" target="_blank" class=" btn btn-primary FullViewLink">Registrace</a> -->
  <a [routerLink]="['/tickets', company.id]" target="_blank" class=" btn btn-primary FullViewLink">Tickety</a>
  <a [routerLink]="['/admin', company.id]" target="_blank" class=" btn btn-primary FullViewLink">Role</a>
  <a [routerLink]="['/pricelist', company.id]" target="_blank" class=" btn btn-primary FullViewLink">Ceník</a>
  <a [routerLink]="['/orders', company.id]" target="_blank" class=" btn btn-primary FullViewLink">Objednané služby</a>
  <!-- <a [routerLink]="['/invoices', company.id]" target="_blank" class=" btn btn-primary FullViewLink">Faktury</a> -->
  <a [routerLink]="['/domain', company.id]" target="_blank" class=" btn btn-primary FullViewLink">Domény</a>

  <!-- <button class="btn btn-primary"  [routerLink]="['/priorities', company.id]" (click)="bsModalRef.hide()">Priority</button>
  <button class="btn btn-primary"  [routerLink]="['/category', company.id]" (click)="bsModalRef.hide()">Kategorie</button>
  <button class="btn btn-primary"  [routerLink]="['/register', company.id]" (click)="bsModalRef.hide()">Registrace</button>
  <button class="btn btn-primary"  [routerLink]="['/tickets', company.id]" (click)="bsModalRef.hide()">Tickety</button>
  <button class="btn btn-primary"  [routerLink]="['/admin', company.id]" (click)="bsModalRef.hide()"> Role</button>
  <button class="btn btn-primary"  [routerLink]="['/pricelist', company.id]" (click)="bsModalRef.hide()"> Ceník</button>
  <button class="btn btn-primary"  [routerLink]="['/orders', company.id]" (click)="bsModalRef.hide()"> Objednávky</button>
  <button class="btn btn-primary"  [routerLink]="['/invoices', company.id]" (click)="bsModalRef.hide()"> Faktury</button>
  <button class="btn btn-primary"  [routerLink]="['/domain', company.id]" (click)="bsModalRef.hide()"> Domény</button> -->
  
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
</div>