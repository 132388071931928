<div class="card h-100" style="min-height: 170px;" >
  <div class="card-body " style="height: 40em;" #scrollframe id="scrollframe" >
    <!-- <div *ngIf="messages.length === 0">
      <p>No messages yet say Hi by using a message box bellow</p>
    </div> -->
    <ul class="chat" >
      <li *ngFor="let message of messages">
         <!-- to them -->
         <div *ngIf="message.senderId != currentUserId">
            <!-- <span class="chat-img float-left">
              <a>{{message.sender.firstName}} {{message.sender.lastName}}</a>
            </span> -->
            <div class="chat-body">
              <div class="header">
                <strong class="primary-font">{{message.sender.firstName}}</strong>
                <small class="text-muted float-right">
                  <span class="fa fa-clock-o">{{message.messageSent | timeago}}</span>
                </small>
              </div>
              <div (click)="getFile(message)"  class="form-inline">
                <i *ngIf="message.filePath !== null" class="fa fa-file fa-2x" aria-hidden="true" style="color:#E95420"></i>
                <i *ngIf="message.content.startsWith('Na tento ticket byl vytvořen výkaz práce s id')" class="fa fa-link fa-2x" aria-hidden="true" style="color:#E95420"></i>
                <p  class="m-2 text-break">{{message.content}}</p>
              </div>
            </div>
          </div>
         <!-- to me -->
         <div *ngIf="message.senderId == currentUserId">
          <span class="chat-img float-right">
          </span>
          <div class="chat-body">
            <div class="header">
              <small class="text-muted">
                <span class="fa fa-clock-o">{{message.messageSent | timeago}}</span>
                <span *ngIf="message.isRead" class="text-success">(Read {{message.dateRead | timeago}})</span>
              </small>
              <strong class="primary-font float-right">{{message.sender.firstName}}</strong>
            </div>
            <div (click)="getFile(message)" class="form-inline">
              <i *ngIf="message.filePath !== null" class="fa fa-file fa-2x" aria-hidden="true" style="color:#E95420"></i>
              <i *ngIf="message.content.startsWith('Na tento ticket byl vytvořen výkaz práce s id')" class="fa fa-link fa-2x" aria-hidden="true" style="color:#E95420"></i>

              <p  class="m-2 text-break">{{message.content}}</p>
            </div>
           


          </div>
        </div>
      </li>
    </ul>

  </div>
  <div class="card-footer">
    <form #messageForm="ngForm" (ngSubmit)="messageForm.valid && sendMessage()">
      <div class="input-group" *ngIf="isSolver || isIssuer"> 
        <!-- <input type="text" [(ngModel)]="newMessage.content" name="content" required class="form-control input-sm" placeholder="Send a private message"> -->
        <textarea (keyup)="autoGrowTextZone($event)" type="text"  [(ngModel)]="newMessage.content" name="content" required class="form-control input-sm" placeholder="" ></textarea>
        <div class="form-inline" style="height: 10%; margin-top: auto;">
          <button class="btn mr-3 "  [disabled]="!messageForm.valid"><i class="fa fa-paper-plane fa-2x" style="color:#E95420 ;"></i></button>
          <app-uploadComponent [ticketId]='ticketIdValue' (onUploadFinished)="loadMessages()" ></app-uploadComponent>
        </div>

      </div>
    </form>

  </div>
</div>