import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { userNotification } from '../_models/userNotification';


@Injectable({
  providedIn: 'root'
})
export class UserNotificationService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}

  addUserNotifications(selectedUserNotifications: userNotification[])
  {
    return this.http.post(this.baseUrl + 'UserNotification/', selectedUserNotifications );
  }
  addDefaultUserNotifications(userId:number)
  {
    return this.http.post(this.baseUrl + 'UserNotification/addDefault/'+userId,{});
  }


  removeUserNotification(id: number)
  {
    return this.http.delete(this.baseUrl + 'UserNotification/' + id );
  }

  getUserNotification(userId: number){
    return this.http.get<userNotification[]>(this.baseUrl + 'UserNotification/' + userId);
  }

}
