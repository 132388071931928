import {NgModule} from '@angular/core';

import {KeysPipeModule} from './keys.pipe';
import {ToArrayPipeModule} from './to-array.pipe';
import {DefaultsPipeModule} from './defaults.pipe';

@NgModule({
  exports: [
    KeysPipeModule,
    ToArrayPipeModule,
    DefaultsPipeModule],
})
export class CommonObjectPipesModule {
}
