import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { User } from 'src/app/_models/user';
import { WorkReport } from 'src/app/_models/workReport';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-workreportdetail-modal',
  templateUrl: './workreportdetail-modal.component.html',
  styleUrls: ['./workreportdetail-modal.component.scss']
})
export class WorkReportDetailModalComponent implements OnInit {
  @Output() createNewCompany = new EventEmitter();
  workReport: WorkReport;
  createdByuser: User;

  constructor(public bsModalRef: BsModalRef, private fb: FormBuilder, private userService: UserService) {}

  ngOnInit() {
    this.userService.getUser(this.workReport.createdByUserId).subscribe(x => {
      this.createdByuser = x;
    });

  }

}
