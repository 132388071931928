import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { UserService } from '../_services/user.service';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';
import { InventoryItem } from '../_models/inventoryItem';
import { InventoryItemService } from '../_services/inventoryItem.service';
import { ItemOrderService } from '../_services/itemOrder.service';
import { DatePipe } from '@angular/common';
@Injectable()
export class ItemOrdersResolver implements Resolve<InventoryItem[]> {
  pageNumber = 1;
  pageSize = 15;

  constructor(
    private itemOrderService: ItemOrderService,
    private router: Router,
    private alertify: AlertifyService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private route: ActivatedRoute
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<InventoryItem[]> {
    var filterParams: any= {};
    filterParams.orderDateTime = this.datePipe.transform('1990-01-01', 'yyyy-MM-dd');
    filterParams.companyId = 0;
    filterParams.isSupplier=!route.routeConfig.path.includes("issue");
    filterParams.dateFrom = '1990-01-01';
    filterParams.dateTo = '1990-01-01';
    filterParams.filterType=1;
    filterParams.invoicePaid=0;
    filterParams.docId="";
    filterParams.deleted =false;
    filterParams.invoiceId =0;

    return this.itemOrderService.getOrders(this.pageNumber, this.pageSize,filterParams).pipe(
      catchError((error) => {
        this.alertify.error('Problem retrieving messages');
        this.router.navigate(['//adminHome']);
        return of(null);
      })
    );
  }
}
