import {NgModule, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'where',
})
export class WherePipe implements PipeTransform {
  /**
   * Support a function or a value or the shorthand ['key', value] like the lodash shorthand.
   */
  transform(input: any, fn: any): any {
    if (!this._isArray(input)) {
      return input;
    }

    if (this._isFunction(fn)) {
      return input.filter(fn);
    } else if (this._isArray(fn)) {
      const [key, value] = fn;

      if (!key && !value || key && !value) {
        return input;
      }

      if (key == null) {
        return input.filter((item: any) => this._isString(value) ? item?.toLowerCase().indexOf(value.toLowerCase()) > -1 : item === value);
      } else {
        return input.filter((item: any) => this._isString(value) ? this._getProperty(item, key)?.toLowerCase().indexOf(value.toLowerCase()) > -1 : this._getProperty(item, key) === value);
      }
    } else if (fn) {
      return input.filter((item: any) => item === fn);
    } else {
      return input;
    }
  }

  private _isString(value: any): value is string {
    return typeof value === 'string';
  }

  private _isArray(value: any): boolean {
    return Array.isArray(value);
  }

  private _isFunction(value: any): boolean {
    return typeof value === 'function';
  }

  private _isNil(value: any): value is null | undefined {
    return value === null || typeof value === 'undefined';
  }

  private _isObject(value: any): boolean {
    return value !== null && typeof value === 'object';
  }

  private _getProperty(value: { [key: string]: any }, key: string): any {
    if (this._isNil(value) || !this._isObject(value)) {
      return undefined;
    }
    const keys: string[] = key.split('.');
    // tslint:disable-next-line:no-non-null-assertion
    let result: any = value[keys.shift()!];
    for (const singleKey of keys) {
      if (this._isNil(result) || !this._isObject(result)) {
        return undefined;
      }
      result = result[singleKey];
    }
    return result;
  }
}


@NgModule({
  declarations: [WherePipe],
  exports: [WherePipe],
})
export class WherePipeModule {
}
