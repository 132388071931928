import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Company} from '../_models/company';
import {Licence} from '../_models/licence';
import {Pagination, PaginatedResult} from '../_models/pagination';
import {AlertifyService} from '../_services/alertify.service';
import {AuthService} from '../_services/auth.service';
import {CompanyDialService} from '../_services/companyDial.service';
import {LicenceService} from '../_services/licence.service';
import {LicencecreateModalComponent} from './licencecreate-modal/licencecreate-modal.component';
import {LicenceeditModalComponent} from './licenceedit-modal/licenceedit-modal.component';

@Component({
  selector: 'app-licence',
  templateUrl: './licence.component.html'
})
export class LicenceComponent implements OnInit {
  bsModalRef: BsModalRef;
  licences: Licence[];
  pagination: Pagination;
  companies: Company[];
  filterParams: any = {};

  constructor(
    private licenceService: LicenceService,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private companyService: CompanyDialService,
    private authService: AuthService,
  ) {
  }

  ngOnInit() {

    this.route.data.subscribe((data) => {
      this.pagination = data['licence'].pagination;
    });
    this.companyService.getCompanies(1, 1, null, null, false, true).subscribe((data) => {
      this.companies = data.result;
      this.route.params.subscribe(params => {
        this.filterParams.companyId = params['companyId'];
        if (this.authService.decodedToken.IsPrimary !== 'True') {
          this.filterParams.companyId = this.authService.decodedToken.CompanyId;
        }
        if (this.filterParams.companyId === '0') {
          this.filterParams.companyId = this.authService.decodedToken.CompanyId;
        }
        this.resetFilters()

        // this.loadLicences();

      });
    });
  }

  loadLicences() {
    this.licenceService.getLicences(this.pagination.currentPage, this.pagination.itemsPerPage, this.filterParams)
      .subscribe((res: PaginatedResult<Licence[]>) => {
        this.licences = res.result;
        this.pagination.totalPages = res.pagination.totalPages;
        this.pagination.itemsPerPage = res.pagination.itemsPerPage;
        this.pagination.totalItems = res.pagination.totalItems;
      }, error => {
        this.alertify.error(error);
      });
  }

  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.loadLicences();
  }

  createModal() {
    const initialState: any = {
      companyId: this.filterParams.companyId
    };
    this.bsModalRef = this.modalService.show(LicencecreateModalComponent, {initialState, backdrop: 'static'});
    this.bsModalRef.content.createNewLicence.subscribe((values) => {
      this.licenceService.addLicence(values).subscribe(() => {
        this.alertify.success('Úspěšně přidáno');
      }, error => {
        this.alertify.error('Licenci se nepodařilo přidat');
      }, () => {
        this.loadLicences();
      });
    });
  }

  getEditModal(licence: Licence) {
    const initialState: any = {
      licence,
      companyId: this.filterParams.companyId
    };
    this.bsModalRef = this.modalService.show(LicenceeditModalComponent, {initialState, backdrop: 'static'});
    this.bsModalRef.content.editLicence.subscribe((values) => {
      this.licenceService.updateLicence(licence.id, values).subscribe(() => {
        this.alertify.success('Úspěšně upraveno');
      }, error => {
        this.alertify.error('Licenci se nepodařilo upravit');
      }, () => {
        this.loadLicences();
      });
    });
  }

  deleteLicence(licence: Licence) {
    this.alertify.confirm('Opravdu chcete vymazat tuto licenci?', () => {
      this.licenceService.deleteLicence(licence.id)
        .subscribe(() => {
          this.alertify.success('Licence byla úspěšně odstraněna');
          this.loadLicences();
        }, error => {
          this.alertify.error('Licenci se nepodařilo odstranit');
        });
    }, 'Opravdu odstranit?', 'Ano, odstranit');
  }

  resetFilters() {
    // this.filterParams.companyId = this.authService.decodedToken.CompanyId;
    this.filterParams.suplierId = 0;
    this.filterParams.licenceCode = '';

    this.loadLicences();
  }

  // compareIds(id1, id2)
  // {
  //   return id1.toString()===id2;
  // }

}
