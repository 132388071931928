import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Company } from 'src/app/_models/company';
import { Category } from 'src/app/_models/category';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { CategoryService } from 'src/app/_services/category.service';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-companycreate-modal',
  templateUrl: './categorycreate-modal.component.html',
  styleUrls: ['./categorycreate-modal.component.scss']
})
export class CategoryCreateModalComponent implements OnInit {
  @Output() createNewCategory = new EventEmitter();
  category: Category;
  categoryAddForm: FormGroup;
  companies: Company[];
  companyId: string;


  constructor(public bsModalRef: BsModalRef, private fb: FormBuilder, private companyService: CompanyDialService, private authService: AuthService) {}

  ngOnInit() {
    this.createForm();
    this.companyService.getCompanies(1, 1,null,null,false,true).subscribe(data => {
      this.companies = data.result;
      if (this.companyId !== '0')
      {
        this.companies = data.result.filter(x => x.id.toString() === this.companyId);
      }
      else
      {
        this.companies = data.result;
      }
    });
  }


  // tslint:disable-next-line: typedef
  createForm(){
    this.categoryAddForm = this.fb .group({
      name: ['', Validators.required],
      companyid: ['', Validators.required],
    });
  }
  createCategory()
  {
    if (this.categoryAddForm.valid)
    {
      this.category = Object.assign({}, this.categoryAddForm.value);
      this.createNewCategory.emit(this.category);
      this.bsModalRef.hide();
    }
  }
}
