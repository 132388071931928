import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {Select3MenuComponent} from './select3-menu/select3-menu.component';
import {Select3Component} from './select3.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {IsArrayPipeModule} from '../../pipes/_common/boolean/is-array.pipe';
import {IsNullOrEmptyPipeModule} from '../../pipes/_common/boolean/is-null.pipe';
import {PipesModule} from '../../pipes/pipes.module';


@NgModule({
  imports: [
    CommonModule,
    PipesModule,
    FormsModule,
    ScrollingModule,
    IsArrayPipeModule,
    IsNullOrEmptyPipeModule
  ],
  exports: [Select3Component, Select3MenuComponent],
  declarations: [Select3Component, Select3MenuComponent]
})
export class Select3Module {
}
