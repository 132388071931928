import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Company} from 'src/app/_models/company';
import {Invoice} from 'src/app/_models/invoice';
import {CompanyDialService} from 'src/app/_services/companyDial.service';
import {InvoiceService} from 'src/app/_services/invoice.service';
import {AuthService} from 'src/app/_services/auth.service';
import {PriceListService} from 'src/app/_services/pricelist.service';
import {OrderItemModel, PriceListItem} from 'src/app/_models/priceListItem';
import {AlertifyService} from 'src/app/_services/alertify.service';
import {OrderService} from 'src/app/_services/order.service';
import {DatePipe} from '@angular/common';
import {InventoryItemService} from 'src/app/_services/inventoryItem.service';
import {InventoryItem} from 'src/app/_models/inventoryItem';
import {WorkreportService} from 'src/app/_services/workreport.service';
import {ToolsService} from 'src/app/_services/tools.service';
import {ReportItemsBag} from 'src/app/_models/reportItemsBag';
import {InvoiceKeys} from 'src/app/constants/constants';

@Component({
  selector: 'app-companycreate-modal',
  templateUrl: './invoicecreate-modal.component.html',
  styleUrls: ['./invoicecreate-modal.component.scss']
})
export class InvoiceCreateModalComponent implements OnInit {
  @Output() createNewInvoice = new EventEmitter();
  invoice: Invoice;
  invoiceAddForm: FormGroup;
  companyId: number;
  company: Company;
  month: string;
  priceList: PriceListItem[];
  priceListOriginal: PriceListItem[];
  totalPrice: number;
  totalPriceWithDPH: number;
  priceListMonthHours: PriceListItem;
  invItems: InventoryItem[];
  maxDate: Date;
  hoursToInvoice: ReportItemsBag[] = [];
  priceRas: number;
  priceOns: number;
  priceKm: number;
  pricePC: number;
  priceProPC: number;
  priceServer: number;
  priceSQL: number;
  priceFiles: number;
  priceExchange: number;
  domainAlreadyInvoiced: boolean = false;
  invoiceConstants = InvoiceKeys;
  orderItems: OrderItemModel[];


  constructor(public bsModalRef: BsModalRef, private fb: FormBuilder, private companyService: CompanyDialService, private authService: AuthService,
              private priceListService: PriceListService,
              private orderService: OrderService,
              private alertify: AlertifyService,
              private wpService: WorkreportService,
              private datePipe: DatePipe, private invoiceService: InvoiceService, private inventoryService: InventoryItemService, private toolsService: ToolsService) {
  }

  ngOnInit() {
    this.maxDate = new Date();
    this.maxDate.setDate(1);
    this.maxDate.setMonth(this.maxDate.getMonth() - 1);

    this.invoice = new Invoice();
    this.invoice.userId = this.authService.curretnUser.id;
    this.invoice.supplierCompanyId = this.authService.decodedToken.CompanyId;
    this.invoice.companyId = this.companyId;
    this.invoice.forMonth = this.datePipe.transform(new Date(), 'yyyy-MM');
    this.invoice.duzp = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.invoice.formOfPayment = 'Bankovní převod';

    // this.createForm();

    this.companyService.GetCompany(this.companyId).subscribe(data => {
      this.company = data;

      const result = new Date();
      result.setDate(result.getDate() + this.company.daysToPay);
      this.invoice.dateToPay = this.datePipe.transform(result, 'yyyy-MM-dd');
      // this.invoice.forMonth = new Date();
      this.loadOrderData();
    });

    this.invoiceService.getLastInvoiceNumber(this.invoice.companyId, this.invoice.forMonth).subscribe(invoivceNumberRes => {
      this.invoice.invoiceNumber = invoivceNumberRes;
      this.invoice.variableSymbol = invoivceNumberRes;
    });
  }


  roundTo(num: number) {
    if (num === undefined || num === null) {
      return 0;
    }

    return this.toolsService.roundTo(num);
  }

  // tslint:disable-next-line: typedef
  createForm() {
    this.invoiceAddForm = this.fb.group({
      companyId: [this.companyId, Validators.required],
      forMonth: [this.datePipe.transform(this.maxDate, 'yyyy-MM'), Validators.required],
      invoiceNumber: ['', Validators.required],
      dateToPay: [Date.now, Validators.required],
      duzp: [this.datePipe.transform(new Date(), 'yyyy-MM-dd'), Validators.required],
      paid: [false],
      paidDate: [''],
      constantSymbol: [''],
      variableSymbol: ['', Validators.required],
      formOfPayment: ['Bankovní převod', Validators.required],
      userId: [this.authService.curretnUser.id],
      supplierCompanyId: [this.authService.decodedToken.CompanyId, Validators.required],
      price: [''],
      ticketId: ['0'],
      dateOfCreation: [''],

    });

    if (this.companyId != 0) {
      this.invoiceAddForm.patchValue({companyId: this.companyId})
    }
    // if(this.month !== undefined)
    // {
    //   this.invoiceAddForm.patchValue({forMonth: this.datePipe.transform(this.month, 'yyyy-MM')})
    // }
  }

  createInvoice() {
    //Uložit všechny orders kvůli slevám a ošetřit to aby se sleva neprojevovala u stejných orders měsíčních
    if (this.invoiceAddForm.valid) {
      this.priceList.forEach(priceItem => {
        // priceItem.order.invoiceId = 1;
        if (priceItem.order.id == 0) {
          priceItem.order.priceListItemId = priceItem.id;
          priceItem.order.companyId = priceItem.companyId;
        }
        this.orderService.updateOrder(priceItem.order.id, priceItem.order).subscribe(() => {
        }, error => {
          this.alertify.error('Slevu se nepodařilo upravit');
        }, () => {
        });
      });

      this.invoiceAddForm.value.dateOfCreation = new Date();
      this.invoiceAddForm.value.priceWithDPH = this.getTotalPriceWithDPH();
      this.invoiceAddForm.value.price = this.totalPrice;
      this.invoice = Object.assign({}, this.invoiceAddForm.value);
      this.invoice.domainInvoiced = this.domainAlreadyInvoiced;
      // this.createNewInvoice.emit(this.invoice);
      this.createNewInvoice.emit({invoice: this.invoice, items: this.invItems});
      this.bsModalRef.hide();
    }
  }

  getTotalPrice() {
    this.totalPrice = 0;
    if (this.priceList != undefined) {
      this.priceList.forEach(element => {
        this.totalPrice = this.totalPrice + element.order.amount * element.price
      });
    }
    if (this.invItems != undefined) {
      this.invItems.forEach(element => {
        if (element.invoicedToClient) {
          this.totalPrice = this.totalPrice + element.amount * element.sellingPrice
        }
      });
    }
    if (this.hoursToInvoice !== undefined && !(this.hoursToInvoice.length <= 0)) {
      var hourSum = this.GetHoursSumForAllItems();

      this.totalPrice = this.totalPrice + hourSum;
    }
  }

  GetHoursSumForAllItems(): number {
    var hourSum = ((this.hoursToInvoice.filter(x => x.code === InvoiceKeys.SQLCode)[0].count / 100) * this.priceSQL)
      + ((this.hoursToInvoice.filter(x => x.code === InvoiceKeys.ExchangeCode)[0].count / 100) * this.priceExchange)
      + ((this.hoursToInvoice.filter(x => x.code === InvoiceKeys.FileCode)[0].count / 100) * this.priceFiles)
      + (this.hoursToInvoice.filter(x => x.code === InvoiceKeys.PCCode)[0].count * this.pricePC)
      + (this.hoursToInvoice.filter(x => x.code === InvoiceKeys.PCProCode)[0].count * this.priceProPC)
      + (this.hoursToInvoice.filter(x => x.code === InvoiceKeys.ServerCode)[0].count * this.priceServer)
      + (this.hoursToInvoice.filter(x => x.code === InvoiceKeys.RASCode)[0].count * this.priceRas)
      + (this.hoursToInvoice.filter(x => x.code === InvoiceKeys.ONSCode)[0].count * this.priceOns)
      + (this.hoursToInvoice.filter(x => x.code === InvoiceKeys.KmCode)[0].count * this.priceKm)
    ;

    return hourSum;
  }

  getTotalPriceWithDPH() {
    var res = 0;
    if (this.priceList != undefined) {
      this.priceList.forEach(element => {
        res = res + element.order.amount * element.price
      });
    }
    if (this.hoursToInvoice !== undefined && this.hoursToInvoice.length > 0) {
      var hourSum = this.GetHoursSumForAllItems();

      res = res + hourSum;
    }

    res = res * 1.21;
    if (this.invItems != undefined) {
      this.invItems.forEach(element => {
        if (element.invoicedToClient) {
          res = res + ((element.amount * element.sellingPrice) * (1 + (element.dphSelling / 100)));
        }
      });
    }

    return res;
  }


  getOrders() {
    this.wpService.getWpsForInvoice(this.invoiceAddForm.value.companyId, this.invoiceAddForm.value.forMonth, false, false, false, 0).subscribe(data => {
      if (data.length <= 0) {
        this.domainAlreadyInvoiced = true;
      }

      this.hoursToInvoice = [];
      data.forEach((item) => this.hoursToInvoice.push(item));

      //set datum splatnosti a cislo faktury
      if (this.invoiceAddForm.value.companyId != '0' && this.invoiceAddForm.value.companyId != 0) {
        //set datum splatnosti
        let date = new Date();
        var days = this.company.daysToPay;
        date.setDate(date.getDate() + days);
        this.invoiceAddForm.patchValue({dateToPay: this.datePipe.transform(date, 'yyyy-MM-dd')})
        //set cislo faktury
        let invoiceNumber: string;
        this.invoiceService.getLastInvoiceNumber(this.invoiceAddForm.value.companyId, this.invoiceAddForm.value.forMonth).subscribe(invoivceNumberRes => {
          this.invoiceAddForm.patchValue({invoiceNumber: invoivceNumberRes})
          this.invoiceAddForm.patchValue({variableSymbol: invoivceNumberRes})
        });
      }
      //set orders
      if (this.invoiceAddForm.value.companyId != '0' && this.invoiceAddForm.value.forMonth != '') {
        var filterParams: any = {};
        filterParams.companyId = this.invoiceAddForm.value.companyId;
        filterParams.month = this.invoiceAddForm.value.forMonth;
        filterParams.filterInvoiced = true;
        filterParams.deductHours = true;
        filterParams.getHours = false;
        this.priceListService.getPriceListItemsForOrderList(filterParams)
          .subscribe((res) => {
            this.priceRas = this.getPrice(res, InvoiceKeys.RASCode);
            this.priceOns = this.getPrice(res, InvoiceKeys.ONSCode);
            this.pricePC = this.getPrice(res, InvoiceKeys.PCCode);
            this.priceProPC = this.getPrice(res, InvoiceKeys.PCProCode);
            this.priceServer = this.getPrice(res, InvoiceKeys.ServerCode);
            this.priceSQL = this.getPrice(res, InvoiceKeys.SQLCode);
            this.priceFiles = this.getPrice(res, InvoiceKeys.FileCode);
            this.priceExchange = this.getPrice(res, InvoiceKeys.ExchangeCode);
            this.priceKm = this.getPrice(res, InvoiceKeys.KmCode);

            this.priceList = res.body.filter(x => x.order.amount > 0 && x.order.invoiceId === 0);//x.companyName !== 'Vykázané hodiny' &&
            this.priceListOriginal = JSON.parse(JSON.stringify(this.priceList));
            this.setOriginalDicounts();
            this.getTotalPrice();
            this.totalPriceWithDPH = this.getTotalPriceWithDPH();
            this.priceListMonthHours = res.body.filter(x => x.companyName.includes('Vykázané hodiny') && x.order.amount > 0)[0];
          }, error => {
            this.alertify.error(error);
          });
      }

      if (this.invoiceAddForm.value.companyId != '0') {
        this.getInventoryItems();
      }
    });
  }

  getPrice(res: any, key: string) {
    var values = res.body.filter(x => x.code === key);
    if (values && values.length > 0) {
      return values[0]?.price ?? 0;
    }
    return 0
  }

  DiscountChange(priceListItem: PriceListItem) {
    if (priceListItem.order.discountPercent < 100 && priceListItem.order.discountPercent > 0) {
      priceListItem.price = this.priceListOriginal.filter(x => x.id === priceListItem.id)[0].price * ((100 - priceListItem.order.discountPercent) / 100);
      this.getTotalPrice();
      this.totalPriceWithDPH = this.getTotalPriceWithDPH();
    } else {
      priceListItem.order.discountPercent = 0;
    }

  }

  setOriginalDicounts() {
    this.priceList.forEach(element => {
      element.price = element.price * ((100 - element.order.discountPercent) / 100);
    });
  }

  getInventoryItems() {
    if (this.invoiceAddForm.value.companyId != '' && this.invoiceAddForm.value.companyId != 0) {
      var filterParams: any = {};
      filterParams.name = '';
      filterParams.hasContained = null;
      filterParams.delivered = null;
      filterParams.supplierId = 0;
      filterParams.reklamace = null;
      filterParams.inWarehouse = null;

      filterParams.invoicedToClient = false;
      filterParams.clientId = this.invoiceAddForm.value.companyId;
      filterParams.isForInvoice = false;
      filterParams.isContained = false;
      filterParams.getPrimaryCompany = false;


      this.inventoryService.getInvenotryItems(1, 1, 0, filterParams).subscribe(data => {
        this.invItems = data.result;
        if (this.invItems != null) {
          this.invItems.forEach(element => {
            element.invoicedToClient = true;

          });
        }

        this.getTotalPrice();
        this.totalPriceWithDPH = this.getTotalPriceWithDPH();
      });
    }
  }

  private loadOrderData() {
    this.wpService.getWorkReportsItemsForInvoice(this.invoice.companyId, this.invoice.forMonth, false, false, false, 0)
      .subscribe(data => {
        this.orderItems = data;
      }, error => {
        this.alertify.error(error);
      });
  }
}
