import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DATE } from 'ngx-bootstrap/chronos/units/constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { PaginatedResult, Pagination } from 'src/app/_models/pagination';
import { Ticket } from 'src/app/_models/ticket';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { TicketService } from 'src/app/_services/ticket.service';
import { Category } from '../_models/category';
import { Company } from '../_models/company';
import { User } from '../_models/user';
import { CategoryService } from '../_services/category.service';
import { CompanyDialService } from '../_services/companyDial.service';
import { UserService } from '../_services/user.service';
import { TicketaddModalComponent } from './ticketadd-modal/ticketadd-modal.component';

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements OnInit {
  bsModalRef: BsModalRef;
  tickets: Ticket[];
  ticketAddFlag = false;
  filterParams: any = {};
  filterChangedFlag: boolean;
  issuerticket = 'issuerTickets';
  solverticket = 'solverTickets';
  ticketsToSolve = 'ticketsToSolve';
  companies: Company[];
  users: User[];
  categories: Category[];
  isCompanyFilter: boolean;
  constructor(
    private ticketService: TicketService,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private companyService: CompanyDialService,
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private authService: AuthService,
    private userService: UserService,
  ) {}
  @ViewChild('ticketTabs') staticTabs: TabsetComponent;

  ngOnInit() {
    this.companyService.getCompanies(1,1).subscribe((data) => {
      this.companies = data.result;
    });
    this.setCompanyFilter();
    if (this.isCompanyFilter)
    {
      this.categoryService.getCategories(1, 1, { companyId: 0} ).subscribe((data) => {this.categories = data.result;  });
    }
    else
    {
      this.categoryService.getCategories(1, 1, { companyId: this.authService.decodedToken.CompanyId} ).subscribe((data) => {this.categories = data.result; });
    }

    this.userService.getUsers().subscribe((data) => {
      this.users = data.result;//.filter(x => x.companyId === this.ticket.issuerCompanyId );
    });

    this.filterParams.companyId = 0;
    this.filterParams.state = '';
    this.filterParams.solver = '';
    this.route.params.subscribe(params => {
      this.filterParams.ticketId= params['ctFilter'];
    });
    // this.filterParams.ticketId=0;

    this.filterParams.issuer = '';
    this.filterParams.orderBy = 'created';
    this.filterParams.categoryId = 0;
    this.filterParams.dateFrom = '1990-01-01';
    this.filterParams.dateTo = '2100-01-01';
    // this.filterParams.dateFrom = new Date();
    // this.filterParams.dateTo = new Date();
    // this.staticTabs.tabs[2].active = true;
  }

  setCompanyForDynamicFilter($event: number)  {
    // console.log($event);
    // this.filterParams.companyId = $event;
    this.categoryService.getCategories(1, 1, { companyId: this.filterParams.companyId} ).subscribe((data) => {this.categories = data.result;  });
  }

  setCompanyFilter()
  {
    const role: string[] = this.authService.decodedToken.role;
    if (typeof(role) === 'string')
    {
      if(role === 'CompanyFilter')
      {
        this.isCompanyFilter = true;
      }
      else
      {
        this.isCompanyFilter = false;
      }
    }
    else
    {
      this.isCompanyFilter = role.filter(x => x === 'CompanyFilter').length === 1;
    }
  }
  ngAfterViewInit()
  {
    //todo funguje ale vyhazuje error
    this.route.params.subscribe(params => {
      if (params['companyFilter'] !== '0')
      {
        this.staticTabs.tabs[2].active = true;
      }
      if (params['ctFilter'] !== '0')
      {
        this.filterParams.ticketId= params['ctFilter'];

      }
    });
  }
  setFilters()
  {
     this.filterChangedFlag = !this.filterChangedFlag;
  }

  resetFilters()
  {
    this.filterParams.companyId = '0';
    // this.filterParams.companyId = this.authService.decodedToken.CompanyId;
    this.filterParams.state = '';
    this.filterParams.solver = '';
    this.filterParams.issuer = '';
    this.filterParams.categoryId = 0;
    this.filterParams.ticketId=0;
    this.filterChangedFlag = !this.filterChangedFlag;
    // this.filterParams.dateFrom = new Date();
    // this.filterParams.dateTo = new Date();
    this.filterParams.dateFrom = '1990-01-01';
    this.filterParams.dateTo = '2100-01-01';
  }
  getTicketAddModal()
  {
    // this.uploadSuccess.emit(true);
    const initialState: any= {
      filterParams:this.filterParams,
      parentTicketId:0
    };
    this.bsModalRef = this.modalService.show(TicketaddModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.createNewTicket.subscribe((values) =>
    {
      this.ticketService.addTicket(values).subscribe(data => {
        this.alertify.success('Ticket úspěšně vytvořen');
      }, error => {
        this.alertify.error('Ticket se nepodařilo vytvořit');
      }, () => {
        // this.uploadSuccess.emit(true);
        this.ticketAddFlag = !this.ticketAddFlag;
      });
    });
  }
}
