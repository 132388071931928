import {Component, OnInit} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Company} from '../_models/company';
import {Pagination} from '../_models/pagination';
import {AlertifyService} from '../_services/alertify.service';
import {MonthlyReportService} from '../_services/monthlyReport.service';
import {DomainsReportEntity, MonthlyReport} from '../_models/monthlyReport';
import {DatePipe} from '@angular/common';
import {MonthlyReportEntity} from '../_models/monthlyReportEntity';
import {QueryStringsHelper} from '../core/_helpers/query-strings.helper';

@Component({
  selector: 'app-reports-component',
  templateUrl: './reports.component.html'
})
export class ReportsComponent implements OnInit {
  bsModalRef: BsModalRef;
  pagination: Pagination;
  companies: Company[];
  filterParams: any = {};

  monthlyReport: MonthlyReport;
  monthlyReportEntities: MonthlyReportEntity[];
  monthlyServerReportEntities: MonthlyReportEntity[] = [];
  monthlyPCReportEntities: MonthlyReportEntity[] = [];
  PCCount = 0;
  ServerCount = 0;
  mailboxes: DomainsReportEntity[];
  sqlItems: DomainsReportEntity[];
  fileReportItems: DomainsReportEntity[];

  constructor(
    private alertify: AlertifyService,
    private reportService: MonthlyReportService,
    private datePipe: DatePipe,
    private queryStringHelper: QueryStringsHelper
  ) {
  }

  ngOnInit() {
    this.initFilter();
  }

  initFilter(): void {
    this.filterParams = Object.assign({
      companyId: 0,
      created: this.datePipe.transform(Date.now(), 'yyyy-MM')
    }, {});
    this.queryStringHelper.updateFilteringParamsFromUrl(this.filterParams);
    this.loadReport();
  }

  loadReport() {
    this.queryStringHelper.setFilterToQueryString(this.filterParams);

    if (this.filterParams.companyId === 0) {
      return;
    }

    const date = new Date(this.filterParams.created);

    this.reportService.getBasicReport(this.filterParams.companyId, date.getMonth() + 1, date.getFullYear())
      .subscribe((data: MonthlyReport) => {
        this.monthlyReport = data;
        this.monthlyReport.month = data.pcReport.reportedEntities[0]?.month;
        this.monthlyReport.year = data.pcReport.reportedEntities[0]?.year;
        this.monthlyReportEntities = data.pcReport.reportedEntities;
        this.mailboxes = data.mailboxes;
        this.sqlItems = data.sqlItems;
        this.fileReportItems = data.fileReportItems;

        this.monthlyServerReportEntities = [];
        this.monthlyPCReportEntities = [];
        this.ServerCount = 0;
        this.PCCount = 0;

        data.pcReport.reportedEntities.forEach(x => {
          const isServer = x.name.startsWith('S', 0);

          if (isServer) {
            this.monthlyServerReportEntities.push(x);
            this.ServerCount++;
          } else {
            this.monthlyPCReportEntities.push(x);
            this.PCCount++;
          }
        });

      }, error => {
        this.alertify.error(error);
      });
  }

  resetFilters() {
    this.filterParams = {};
    this.filterParams.companyId = 0;
    this.filterParams.created = this.datePipe.transform(Date.now(), 'yyyy-MM');
    this.queryStringHelper.setFilterToQueryString(this.filterParams);
    this.loadReport();
  }

  setCompanyToFilter($event: number) {
    this.filterParams.companyId = $event;
    this.loadReport();
  }
}
