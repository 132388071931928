<div class=" mr-5 ml-5">
  
  <div *ngIf="tickets !== undefined && tickets.length === 0 && ticketFilter != 'solverTickets'" style="text-align: center">
    
    <h1>Zatím jste nevytvořil žádný ticket</h1>
  <button class="btn btn-primary m-3" (click)="$event.stopPropagation()" (click)="getTicketAddModal()" >Vytvořit nový ticket</button>
  </div>
  <div *ngIf="tickets !== undefined && tickets.length === 0 && ticketFilter == 'solverTickets'" style="text-align: center">
    <h1>Zatím vám nebyl přidělen žádný ticket</h1>
  </div>
  <div class="row" *ngIf="tickets !== undefined && tickets.length > 0">
    <table class="table table-hover" style="cursor: pointer">
      <!-- <button class="btn btn-primary" (click)="createCompanyModal()" >Přidat</button> -->
      <tr>
        <!-- <div *ngIf="!adminHome"> -->
        <th *ngIf="!adminHome" style="width: 3%"></th>
        <th *ngIf="!adminHome" style="width: 5%">Č.t</th>
        <th *ngIf="!adminHome" style="width: 20%">Název</th>
        <th *ngIf="!adminHome" style="width: 10%">Firma</th>
        <th *ngIf="!adminHome" style="width: 15%">Zadavatel</th>
        <th *ngIf="!adminHome" style="width: 15%">Řešitel</th>
        <th *ngIf="!adminHome" style="width: 10%">DeadLine</th>
        <th *ngIf="!adminHome" style="width: 10%">Status</th>
        <th *ngIf="!adminHome" style="width: 10%">Kategorie</th>
        <!-- </div> -->

        <!-- <div *ngIf="adminHome"> -->
          <th *ngIf="adminHome" style="width: 3%"></th>
          <th *ngIf="adminHome" style="width: 80%">Název</th>
          <th *ngIf="adminHome" style="width: 17%">Status</th>
        <!-- </div> -->

        <!-- <th style="width: 2%"></th> -->
      </tr>
      <!-- <tr *ngFor="let ticket of tickets" (click)="getCompanyDetailModal(company)"> -->
        <tr *ngFor="let ticket of tickets"     (click)="getTicketDetailModal(ticket)">
          <!-- [routerLink]="['/ticketsdetail/', ticket.id]" -->
          <div *ngIf="ticket.status !== 'Uzavřený'">
            <td *ngIf="ticket.deadLineInDays > 0"><i class="fa fa-circle" style="color:green"></i></td>
            <td *ngIf="ticket.deadLineInDays === 0"><i class="fa fa-circle" style="color:orangered"></i></td>
            <td *ngIf="ticket.deadLineInDays < 0"><i class="fa fa-circle" style="color:red"></i></td>
          </div>
            <td *ngIf="ticket.status === 'Uzavřený'"><i class="fa fa-check-circle-o" style="color:grey"></i></td>
            <td>{{ticket.id}}</td>
            <td>{{ticket.title | slice:0:20}}</td>
            <td *ngIf="!adminHome"><strong>{{ticket.issuerCompany.companyName}} </strong></td>
            <td *ngIf="!adminHome"><strong>{{ticket.issuer.firstName}} {{ticket.issuer.lastName}}</strong></td>
            <td *ngIf="!adminHome"><strong *ngFor="let solver of ticket.solvers">{{solver.user.firstName}} {{solver.user.lastName}}, </strong></td>
            <td *ngIf="!adminHome"><strong> {{ticket.deadLine | date:"dd.MM.yyyy"}}</strong></td>

          <td ><strong>{{ticket.status}}</strong></td>
          <td *ngIf="!adminHome"><strong>{{ticket.category.name}}</strong></td> 
        
          <!-- <td> -->
          <!-- <div class="form-inline">
            <button class="btn btn-info" (click)="$event.stopPropagation()" (click)="getCompanyEditModal(company)" ><i class="fa fa-edit ml-1"></i></button>
            <button class="btn btn-danger" (click)="$event.stopPropagation()" (click)="deleteCompany(company.id)" ><i class="fa fa-trash"></i></button>
          </div> -->
        <!-- </td> -->
      </tr>
    </table>
  </div>
</div>
<div  class="d-flex justify-content-center">
  <pagination [maxSize]="3"
              [boundaryLinks]="true" 
              [totalItems]="pagination.totalItems"
              [itemsPerPage]="pagination.itemsPerPage"
              [(ngModel)]="pagination.currentPage"
              (pageChanged)="pageChanged($event)"
            previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
</pagination>
</div>