import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';
import { Category } from '../_models/category';
import { CategoryService } from '../_services/category.service';
@Injectable()
export class CategoriesResolver implements Resolve<Category[]> {
  pageNumber = 1;
  pageSize = 15;

  constructor(
    private categoryService: CategoryService,
    private router: Router,
    private alertify: AlertifyService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Category[]> {

    return this.categoryService.getCategories(this.pageNumber, this.pageSize).pipe(
      catchError((error) => {
        this.alertify.error('Problem retrieving categories');
        this.router.navigate(['//adminHome']);
        return of(null);
      })
    );
  }
}
