import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Invoice} from 'src/app/_models/invoice';
import {NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels} from '@techiediaries/ngx-qrcode';
import {WorkReport} from 'src/app/_models/workReport';
import {WorkreportService} from 'src/app/_services/workreport.service';
import {WorkReportNoInternalNote} from 'src/app/_models/workReportsNoInternalNote';


@Component({
  selector: 'app-invoiceWps-modal',
  templateUrl: './invoiceWps-modal.component.html',
  styleUrls: ['./invoiceWps-modal.component.css']
})
export class InvoiceWpsModalComponent implements OnInit {
  @Output() Detailinvoice = new EventEmitter();
  invoice: Invoice;
  companyName: string;
  workReports: WorkReport[];
  workReportsFiltered: WorkReportNoInternalNote[];
  hkm: number[];

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = '';
  private blob: Blob;

  constructor(public bsModalRef: BsModalRef, private wpService: WorkreportService) {
  }

  ngOnInit() {
    var userParams: any = {invoiceId: this.invoice.id};
    this.wpService.getWorkReportsNoInternal(1, 1, userParams).subscribe(data => {
      this.workReportsFiltered = data.result;
    });
    this.wpService.GetWorkReportsNoPaging(userParams).subscribe(data => {
      this.hkm = data;
    });
  }

  public downloadAsPDF() {
    this.wpService.getPdfReport(this.invoice.id)
      .subscribe(data => {
        this.blob = new Blob([data], {type: 'application/pdf'});
        const downloadURL = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = 'work_report.pdf';
        link.click();
      });
  }
}

