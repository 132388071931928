import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Company } from 'src/app/_models/company';
import { WorkPost } from 'src/app/_models/workPost';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { WorkPostService } from 'src/app/_services/workPost.service';

@Component({
  selector: 'app-workPostCreate-modal',
  templateUrl: './workPostCreate-modal.component.html',
  styleUrls: ['./workPostCreate-modal.component.scss']
})
export class WorkPostCreateModalComponent implements OnInit {
  @Output() createNewWorkPost = new EventEmitter();
  workPost: WorkPost;
  workPostAddForm: FormGroup;
  // companies: Company[];
  // companyId: string;

  constructor(public bsModalRef: BsModalRef, private fb: FormBuilder, private workPostService: WorkPostService, private companyService: CompanyDialService) {}

  ngOnInit() {
    this.createForm();
    // this.companyService.getCompanies(1, 1,null,null,false,true).subscribe(data =>{
    //   this.companies = data.result;
    //   if (this.companyId !== '0')
    //   {
    //     this.companies = data.result.filter(x => x.id.toString() === this.companyId);
    //   }
    //   else
    //   {
    //     this.companies = data.result;
    //   }
    // });
  }


  createForm(){
    this.workPostAddForm = this.fb .group({
      name: ['', Validators.required],
      deleted: [false]
    });
  }
  createWorkPost()
  {
    if (this.workPostAddForm.valid)
    {
      this.workPost = Object.assign({}, this.workPostAddForm.value);
      this.createNewWorkPost.emit(this.workPost);
      this.bsModalRef.hide();
    }
  }
}
