import { group } from '@angular/animations';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { GroupService } from 'src/app/_services/group.service';

@Component({
  selector: 'app-addGroup',
  templateUrl: './addGroup.component.html',
  styleUrls: ['./addGroup.component.css']
})
export class AddGroupComponent implements OnInit {
  @Output() addNewGroup = new EventEmitter();
  roles = [];
  rolesToSend = [];
  groupName: string;
  constructor(public bsModalRef: BsModalRef, private groupService: GroupService) {}

  ngOnInit() {
    this.groupService.getRoles().subscribe(data=>{
      data.forEach(element => {
        this.roles.push({checked:false,name: element})
      });
    })
  }


  updateRows()
  {
    this.roles.filter(x=>x.checked === true).forEach(element => {
      this.rolesToSend.push(element.name);
    });
    this.addNewGroup.emit({roles: this.rolesToSend, gn: this.groupName});
    this.bsModalRef.hide();
  }
}
