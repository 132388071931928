import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {BasePriceListItem} from 'src/app/_models/basePriceListItem';
import {LicenceService} from 'src/app/_services/licence.service';
import {Licence} from 'src/app/_models/licence';
import {deepCopy} from '../../core/_helpers/deep-copy';

@Component({
  selector: 'app-base-price-list-item-edit-modal',
  templateUrl: './basepriceListItemEdit-modal.component.html'
})
export class BasePriceListItemEditModalComponent implements OnInit {
  @Output() editBasePriceListItemEvent = new EventEmitter();

  minPrice: number = 0;

  basePriceListItem: BasePriceListItem;
  filteredLicences: Licence[];
  licences: Licence[];
  selectedLicence: Licence;
  setLicences: Licence[];
  selectedLicenceId: number;


  constructor(
    public bsModalRef: BsModalRef,
    private licenceService: LicenceService) {
  }

  ngOnInit() {
    this.licenceService.getLicencesNoPagination().subscribe(data => {
      this.licences = data;
      this.filteredLicences = deepCopy(this.licences);
      this.loadSetLicences();

      if (this.filteredLicences.length > 0) {
        this.selectedLicence = this.filteredLicences[0];
        this.selectedLicenceId = this.filteredLicences[0].id;
      } else {
        this.selectedLicence = new Licence();
      }
    });
  }

  editBasePriceListItem() {
    this.editBasePriceListItemEvent.emit({values: this.basePriceListItem, licence: this.setLicences});
    this.bsModalRef.hide();
  }

  recalculatePrice() {
    let licencePrice = 0;

    for (const item of this.setLicences) {
      licencePrice = licencePrice + item.amount * item.licencePrice;
    }
    this.basePriceListItem.priceInLicences = licencePrice;
  }

  addLicence(selectedLicence: Licence) {
    if (selectedLicence.amount === undefined || selectedLicence.amount === 0) {
      return;
    }

    this.setLicences.push(selectedLicence);
    this.recalculatePrice();

    this.filterLicences();
  }

  filterLicences() {
    this.filteredLicences = deepCopy(this.licences);
    for (const item of this.setLicences) {
      this.filteredLicences = this.filteredLicences.filter(x => x.id !== item.id);
    }

    if (this.filteredLicences.length > 0) {
      this.selectedLicence = this.filteredLicences[0];
      this.selectedLicenceId = this.filteredLicences[0].id;
    } else {
      this.selectedLicence = null;
    }
  }

  deleteLicence(index: number) {
    this.setLicences.splice(index, 1);
    this.recalculatePrice();
    this.filterLicences();
  }

  changeSelectedLicenceId($event: Event) {
    this.selectedLicence = this.licences.find(x => x.id === +this.selectedLicenceId);
  }

  private loadSetLicences() {
    this.licenceService.getLto(this.basePriceListItem.id).subscribe(data => {
      data.forEach(element => {
        const item = this.licences.find(x => x.id === element.licenceId);
        if (item != null) {
          item.amount = element.amount;
        }
      });

      this.licences.filter(x => x.amount === undefined).forEach(element => {
        element.amount = 0;
      });

      this.setLicences = deepCopy(this.licences.filter(x => x.amount > 0));
      // this.setMinPrice();
      this.filterLicences();
    });
  }
  changeUnitIsVariable($event: any) {
    if ($event) {
      this.basePriceListItem.variableType = 1;
    } else {
      this.basePriceListItem.variableType = null;
    }
  }
}
