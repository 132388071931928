import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ItemOrder } from '../_models/itemOrder';
import { PaginatedResult } from '../_models/pagination';

@Injectable({
  providedIn: 'root'
})
export class ItemOrderService {

  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}


  addOrder(order: ItemOrder)
  {
    return this.http.post<number>(this.baseUrl + 'ItemOrder/', order );
  }

  deleteOrder(orderId: number)
  {
    return this.http.delete(this.baseUrl + 'ItemOrder/' + orderId );
  }

  getOrders( page?, itemsPerPage?, userParams?)
  {
    // let params = new HttpParams();
    // if ( userParams != null)
    // {
    //   params = params.append('CompanyId', userParams.companyId);
    //   params = params.append('OrderDateTime', userParams.orderDateTime);
    // }
    // return this.http.get<ItemOrder[]>(this.baseUrl + 'ItemOrder', {observe: 'response', params} );


    const paginatedResult: PaginatedResult<ItemOrder[]> = new PaginatedResult<ItemOrder[]>();
    let params = new HttpParams();
   
 
    params = params.append('CompanyId', userParams.companyId);
    params = params.append('isSupplier', userParams.isSupplier);
    // params = params.append('OrderDateTime', userParams.orderDateTime);
    params = params.append('DateFrom', userParams.dateFrom);
    params = params.append('DateTo', userParams.dateTo);
    params = params.append('FilterType', userParams.filterType);
    params = params.append('InvoicePaid', userParams.invoicePaid);
    params = params.append('DocId', userParams.docId);
    params = params.append('InvoiceId', userParams.invoiceId);
    
    if (page != null && itemsPerPage != null)
    {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
     
    }
    return this.http.get<ItemOrder[]>(this.baseUrl + 'ItemOrder', {observe: 'response', params} )
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') !== null)
        {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }

        return paginatedResult;
      })
    );
  }

  updateOrder(id: number, order: ItemOrder)
  {
    return this.http.put(this.baseUrl + 'ItemOrder/' + id, order);
  }

  getOrderById(id: number)
  {
    return this.http.get<ItemOrder>(this.baseUrl + 'ItemOrder/GetItemOrder/' + id);
  }

  canDeleteOrderItems(id: number)
  {
    var res = this.http.get(this.baseUrl + 'ItemOrder/CanDeleteOrderItems/' + id);
    return res;
  }

  

}
