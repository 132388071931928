import { Component, EventEmitter, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DATE } from 'ngx-bootstrap/chronos/units/constants';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { PaginatedResult, Pagination } from 'src/app/_models/pagination';
import { Ticket } from 'src/app/_models/ticket';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { TicketService } from 'src/app/_services/ticket.service';
import { TicketaddModalComponent } from '../tickets/ticketadd-modal/ticketadd-modal.component';
import { Category } from '../_models/category';
import { Company } from '../_models/company';
import { CategoryService } from '../_services/category.service';
import { CompanyDialService } from '../_services/companyDial.service';
import { TicketEditModalComponent } from './ticketEdit-modal/ticketEdit-modal.component';

@Component({
  selector: 'app-ticketsForRepeat',
  templateUrl: './ticketsForRepeat.component.html',
  styleUrls: ['./ticketsForRepeat.component.scss']
})
export class TicketsForRepeatComponent implements OnInit {
  bsModalRef: BsModalRef;
  tickets: Ticket[];
  ticketAddFlag = false;
  filterParams: any = {};
  filterChangedFlag: boolean;
  companies: Company[];
  categories: Category[];
  isCompanyFilter: boolean;
  pagination: Pagination;
  private ngInitFlag = false ;
  constructor(
    private ticketService: TicketService,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private companyService: CompanyDialService,
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private authService: AuthService
  ) {}
  @ViewChild('ticketTabs') staticTabs: TabsetComponent;

  ngOnInit() {
    this.companyService.getCompanies(1, 1,null,null,false,true).subscribe((data) => {
      this.companies = data.result;
    });
    this.setCompanyFilter();
    if (this.isCompanyFilter)
    {
      this.categoryService.getCategories(1, 1, { companyId: 0} ).subscribe((data) => {this.categories = data.result; });
    }
    else
    {
      this.categoryService.getCategories(1, 1, { companyId: this.authService.decodedToken.CompanyId} ).subscribe((data) => {this.categories = data.result; });
    }
    this.filterParams.companyId = 0;
    this.filterParams.state = '';
    this.filterParams.solver = '';
    this.filterParams.issuer = '';
    this.filterParams.orderBy = 'created';
    this.filterParams.categoryId = 0;
    this.filterParams.dateFrom = '1990-01-01';
    this.filterParams.dateTo = '2100-01-01';
    this.filterParams.ticketId=0;
    this.filterParams.repeat = true;
    this.route.data.subscribe((data) => {
    this.pagination = data['tickets'].pagination;
    this.loadTickets();
    this.ngInitFlag = true;
    });
  }
  loadTickets() {
    if (this.pagination !== undefined)
    {
      this.ticketService.getTickets( this.pagination.currentPage, this.pagination.itemsPerPage, this.filterParams, this.authService.decodedToken.nameid, "")
      .subscribe((res: PaginatedResult<Ticket[]>) => {
        this.tickets = res.result;
        // this.pagination = res.pagination;
        this.pagination.totalPages =res.pagination.totalPages;
        this.pagination.itemsPerPage =res.pagination.itemsPerPage;
        this.pagination.totalItems =res.pagination.totalItems;
      }, error => {
        this.alertify.error(error);
      });
    }

   }

  pageChanged(event: any): void{
    this.pagination.currentPage = event.page;
    this.loadTickets();
  }
  setCompanyFilter()
  {
    const role: string[] = this.authService.decodedToken.role;
    if (typeof(role) === 'string')
    {
      if(role === 'CompanyFilter')
      {
        this.isCompanyFilter = true;
      }
      else
      {
        this.isCompanyFilter = false;
      }
    }
    else
    {
      this.isCompanyFilter = role.filter(x => x === 'CompanyFilter').length === 1;
    }
  }
  
  setFilters()
  {
     this.filterChangedFlag = !this.filterChangedFlag;
  }

  resetFilters()
  {
    this.filterParams.companyId = 0;
    this.filterParams.state = '';
    this.filterParams.solver = '';
    this.filterParams.issuer = '';
    this.filterParams.categoryId = 0;
    this.filterParams.ticketId=0;

    this.filterChangedFlag = !this.filterChangedFlag;
    this.filterParams.dateFrom = '1990-01-01';
    this.filterParams.dateTo = '2100-01-01';
    this.loadTickets();
  }
  getTicketAddModal()
  {
    // this.uploadSuccess.emit(true);
    const initialState: any= {
      repeatCreate: true,
      parentTicketId:0
    };
    this.bsModalRef = this.modalService.show(TicketaddModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.createNewTicket.subscribe((values) =>
    {
      this.ticketService.addTicket(values).subscribe(data => {
        this.alertify.success('Úloha úspěšně vytvořen');
        this.loadTickets();
      }, error => {
        this.alertify.error('Úlohu se nepodařilo vytvořit');
      }, () => {
      });
    });
  }

  getTicketEditModal(id:number)
  {
    const initialState: any= {
      ticket: this.tickets.filter(x=>x.id  == id)[0],
      
    };

    this.bsModalRef = this.modalService.show(TicketEditModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.editTicket.subscribe((values) =>
    {
      this.ticketService.editTicket(id,values).subscribe(data => {
        this.alertify.success('Úloha úspěšně upravena');
        this.loadTickets();
      }, error => {
        this.alertify.error('Úlohu se nepodařilo upravit');
      }, () => {
      });
    });
  }
}
