import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { User } from 'src/app/_models/user';

@Component({
  selector: 'app-psswdChange-modal',
  templateUrl: './psswdChange-modal.component.html',
  styleUrls: ['./psswdChange-modal.component.css']
})
export class PsswdChangeModalComponent implements OnInit {
  user: User;
  userEditForm: FormGroup;
  constructor(private fb: FormBuilder,public bsModalRef: BsModalRef) { }
  @Output() changePsswd = new EventEmitter();
  
  ngOnInit() {
    this.createUserEditForm();
  }

  passwordMatchValidator(g: FormGroup)
  {
    return g.get('newPassword').value === g.get('confirmPassword').value ? null : { mismatch: true };
  }

  createUserEditForm(){
    this.userEditForm = this.fb .group({
      // companyId: [this.authService.decodedToken.CompanyId],
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50),Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{4,50}$/)]],
      confirmPassword: ['', Validators.required]
    }, {validator: this.passwordMatchValidator});
  }


  CallEvent()
  {
    this.changePsswd.emit(this.userEditForm.get('newPassword').value);
    this.bsModalRef.hide();
  }

}
