import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Company } from 'src/app/_models/company';
import { Invoice } from 'src/app/_models/invoice';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { InvoiceService } from 'src/app/_services/invoice.service';
import { AuthService } from 'src/app/_services/auth.service';
import { PriceListService } from 'src/app/_services/pricelist.service';
import { PriceListItem } from 'src/app/_models/priceListItem';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { DatePipe } from '@angular/common';
import { InventoryItem } from 'src/app/_models/inventoryItem';
import { InventoryItemService } from 'src/app/_services/inventoryItem.service';

@Component({
  selector: 'app-inventoryItemCreate-modal',
  templateUrl: './inventoryItemCreate-modal.component.html',
  styleUrls: ['./inventoryItemCreate-modal.component.css']
})
export class InventoryItemCreateModalComponent implements OnInit {
  @Output() createNewInventoryItem = new EventEmitter();
  // invoice: Invoice;
  itemAddForm: FormGroup;
  companies: Company[];
  companyId: string;
  month: string;
  invItemsToAdd: InventoryItem[]= [];
  idsToAdd: Number[]= [];
  invItems: InventoryItem[]= [];
  invItem: InventoryItem;
  filterParams: any = {};

  constructor(public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private companyService: CompanyDialService,
    private authService: AuthService,
    // private priceListService: PriceListService,
    // private inventoryService: InventoryItemService,
    private alertify: AlertifyService,
    private invService: InventoryItemService,
    private datePipe: DatePipe) {}

  ngOnInit() {
    this.createForm();

    
    this.companyService.getCompanies(1, 1,null,null,false,true).subscribe(data => {
      this.companies = data.result;
      // if (this.companyId !== '0')
      // {
      //   this.companies = data.result.filter(x => x.id.toString() === this.companyId.toString());
      // }
      // else
      // {
        // this.companies = data.result;
      // }
      this.addEmptyInvoiceItem();
    });
  }

  loadItems()
  {
    if(this.itemAddForm.value.clientCompanyId != 0)
    {
    this.filterParams.name = '';
    this.filterParams.isContained = false;
    this.filterParams.hasContained = false;
    this.filterParams.delivered = false;
    this.filterParams.invoicedToClient = false;
    this.filterParams.reklamace = false;
    this.filterParams.inWarehouse = true;
    this.filterParams.clientId = this.itemAddForm.value.clientCompanyId;
    this.filterParams.supplierId = 0;
    this.filterParams.isForInvoice = false;
    this.filterParams.getPrimaryCompany = true;


    this.invService.getInvenotryItems(1, 1,0,this.filterParams).subscribe(x=>
      {
      this.invItems = x.result
      });
   }
  }


  // tslint:disable-next-line: typedef
  createForm(){
    this.itemAddForm = this.fb .group({
      name: ['', Validators.required],
      amount: [1, Validators.required],
      sellingPrice: [0, Validators.required],
      clientCompanyId: ["", Validators.required],

      deliveredToClient: [false],
      isContainedInAnotherItem: [false],
      isInWarehouse: [true],
      isOnComplaint: [false],
      deleted: [false],
      idOfItemInWitchContained: [0],
      invoiceId: [0],
      invoicedToClient: [false],
      clientInvoiceId: [0],
      id: [0]
    });

   if(this.companyId !='0')
   {
    this.itemAddForm.patchValue({companyId: this.companyId})
   }
  if(this.month !== undefined)
  {
    this.itemAddForm.patchValue({forMonth: this.datePipe.transform(this.month, 'yyyy-MM')})
  }
  }
  createInvoice()
  {
    if (this.itemAddForm.valid)
    {
      this.invItem = Object.assign({}, this.itemAddForm.value);
      this.invItemsToAdd.forEach(element => {
        this.idsToAdd.push(element.id);
      });
      this.createNewInventoryItem.emit({invItem: this.invItem, invItemsToAdd: this.idsToAdd});
      this.bsModalRef.hide();
    }
  }

  addEmptyInvoiceItem()
  {
    let emptyInvItem = {} as InventoryItem;
    emptyInvItem.clientCompanyId =0;
    emptyInvItem.deliveredToClient=false;
    emptyInvItem.isContainedInAnotherItem=true;
    emptyInvItem.isInWarehouse=true;
    emptyInvItem.isOnComplaint=false;
    emptyInvItem.deleted=false;
    emptyInvItem.idOfItemInWitchContained=0;
    emptyInvItem.invoiceId = this.invItemsToAdd.reduce((op, item) => op = op > item.invoiceId ? op : item.invoiceId, 0)+1;
    emptyInvItem.invoicedToClient =false;
    emptyInvItem.clientInvoiceId =0;
    emptyInvItem.id =0;
    
    this.invItemsToAdd.push(emptyInvItem);
  }

  removeInvoiceItem(id: number)
  {
    this.invItemsToAdd = this.invItemsToAdd.filter(x=>x.invoiceId != id);
    this.filterItems();
  }

  filterItems()
  {
    this.invService.getInvenotryItems(1, 1,0,this.filterParams).subscribe(x=>
    {
      var originalItems = x.result;
      originalItems.forEach(element => {
        var item = this.invItems.filter(x=>x.id.toString() === element.id.toString())[0];
        item.amount =  element.amount;
        
      });

      this.invItemsToAdd.filter(x=>x.id !== 0 ).forEach(element => {
        var item = this.invItems.filter(x=>x.id.toString() === element.id.toString())[0];
          if(item !== undefined)
          item.amount= item.amount-1;
        });


    });
 
  }



}
