import {DOCUMENT} from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Injector,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {fromEvent, Observable, race, Subject, Subscription} from 'rxjs';
import {Select3MenuPlacement, Select3MenuPlacementArray} from './select3-menu/select3-menu-placement';
import {Select3MenuComponent} from './select3-menu/select3-menu.component';
import {Select3PositioningService} from './select3-positioning.service';
import {Select3Service} from './select3-service';

import {delay, filter, map, takeUntil, tap, withLatestFrom} from 'rxjs/operators';
import {isNullOrUndefined} from '../../_helpers/common-helpers';

import {Key} from '../../components/_helpers/key';
import {closest} from '../../components/_helpers/util';

let nextId = 0;

@Component({
  selector: 'app-select3',
  templateUrl: './select3.component.html'
})
export class Select3Component implements OnInit, OnDestroy, OnChanges, AfterViewInit {

  /**
   * A selector specifying the element the popover should be appended to.
   *
   * Currently only supports `body`.
   */
  @Input() value: any | Array<any>;
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  @Input() filterBy: string | string[] = null;
  @Input() loadingData = false;

  @Input() disableNull = false;
  @Input() selectId: string;
  @Input() selectMenuClass: string = null;

  @Input() nullOptionLabel = 'výchozí';
  @Input() emptyLabel = 'Vyberte z možností';
  @Input() noResultsText = 'Žádné výsledky';
  @Input() noItemsText = 'Žádné možnosti k výběru';

  @Input() minDropdownMenuWidthPx: number = null;
  @Input() itemSize = 40;

  /**
   * A selector specifying the element the popover should be appended to.
   *
   * Currently only supports `body`.
   */
  @Input() items: Array<any>;

  /**
   * A name of property that is used as a primary key and will be propagated as a value to FormControlAccessor
   */
  @Input() primaryKey: string | null;

  /**
   * The preferred placement of the dropdown.
   *
   * Possible values are `"top"`, `"bottom"`
   *
   */
  @Input() placement: Select3MenuPlacementArray = ['bottom', 'top'];

  /**
   * A selector specifying the element the popover should be appended to.
   *
   * Currently only supports `body`.
   */
  @Input() container: string;

  /**
   * Indicates whether the dropdown should be closed when clicking one of dropdown items or pressing ESC.
   *
   * * `true` - the dropdown will close on both outside and inside (menu) clicks.
   * * `false` - the dropdown can only be closed manually via `close()` or `toggle()` methods.
   * * `"inside"` - the dropdown will close on inside menu clicks, but not outside clicks.
   * * `"outside"` - the dropdown will close only on the outside clicks and not on menu clicks.
   */
  @Input() autoClose: boolean | 'outside' | 'inside';

  /**
   * Indicates whether multiple values could be selected
   */
  @Input() multiple = false;


  /**
   * Indicates whether input element is disabled
   */
  @Input() disabled = false;

  /**
   * Indicates whether dropdown element is disabled
   */
  @Input() disabledFn: (item: any) => boolean;

  /**
   * A template of one single item in menu
   *
   * Currently only supports `body`.
   */
  @ContentChildren('itemTemplate') itemTemplate: QueryList<TemplateRef<any>>;

  /**
   * A template of selected item
   *
   * Currently only supports `body`.
   */
  @ContentChildren('selectedItemTemplate') selectedItemTemplate: QueryList<TemplateRef<any>>;

  /**
   * A template of selected item
   *
   * Currently only supports `body`.
   */
  @ContentChildren('footerTemplate') footerTemplate: QueryList<TemplateRef<any>>;

  @ViewChild('customSelectElement', {static: true}) customSelectElement: ElementRef;

  public opened = false;
  public selectedItem: any;

  /* Templates*/
  public itemCustomTemplate: TemplateRef<any> | null;
  public selectedItemCustomTemplate: TemplateRef<any> | null;
  public footerCustomTemplate: TemplateRef<any> | null;
  private _menuRef: ComponentRef<Select3MenuComponent> | null = null;
  private _bodyContainer: HTMLElement | null = null;
  private _isMobile: boolean;
  private _appSelect3MenuId = `app-select3-${nextId++}`;
  private _dropdownService: Select3Service<Select3MenuComponent>;

  /* Subjects */
  private _closed$ = new Subject<void>();

  /* Subscriptions - all should be unsubscribed in onDestroy! */
  private zoneSubscription: Subscription;
  private itemSelectSubscription: Subscription;
  private closeSubscription: Subscription;

  constructor(
    private _changeDetector: ChangeDetectorRef,
    @Inject(DOCUMENT) private _document: any,
    private _ngZone: NgZone,
    private _elementRef: ElementRef<HTMLElement>,
    private _renderer: Renderer2,
    private _injector: Injector,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _viewContainerRef: ViewContainerRef
  ) {
    this._dropdownService = new Select3Service<Select3MenuComponent>(Select3MenuComponent, this._injector, this._viewContainerRef, this._componentFactoryResolver);
    this.zoneSubscription = _ngZone.onStable.subscribe(() => {

      if (!this.opened) {
        return;
      }

      this._ngZone.runOutsideAngular(() => {
        this._positionMenu();
      });
    });
  }

  @HostListener('window:resize')
  onResize() {
    if (!this.opened) {
      return;
    }
    this._positionMenu();
  }

  ngOnInit() {
    if (this.multiple && this.value && !Array.isArray(this.value)) {
      throw new Error('Passed single value to multiple values picker');
    }
    this._isMobile = this._checkIsMobile();
  }

  ngAfterViewInit() {
    this._tryPreselectValue(this.value);

    /* Load templates from inside of <app-select3> tag */
    if (this.itemTemplate && this.itemTemplate.length > 0) {
      this.itemCustomTemplate = this.itemTemplate.first;
    }

    if (this.selectedItemTemplate && this.selectedItemTemplate.length > 0) {
      this.selectedItemCustomTemplate = this.selectedItemTemplate.first;
    }

    if (this.footerTemplate && this.footerTemplate.length > 0) {
      this.footerCustomTemplate = this.footerTemplate.first;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.value && !changes.value.firstChange) {
      this.value = changes.value.currentValue;
      this._tryPreselectValue(this.value);
      this._propagateValueToDropdownIfOpened(this.value);
    }

    if (changes.emptyLabel && !changes.emptyLabel.firstChange) {
      this.emptyLabel = changes.emptyLabel.currentValue;
    }

    if (changes.items && !changes.items.firstChange) {
      this.items = changes.items.currentValue;
      this._tryPreselectValue(this.value);
      this._propagateValueToDropdownIfOpened(this.value);
    }

    if (changes.loadingData && !changes.loadingData.firstChange) {
      this.loadingData = changes.loadingData.currentValue;
      this.close();
      this._tryPreselectValue(this.value);
      this._propagateValueToDropdownIfOpened(this.value);
    }

    if (changes.disabledFn && !changes.disabledFn.firstChange) {
      this.disabledFn = changes.disabledFn.currentValue;
      this.close();
      this._tryPreselectValue(this.value);
      this._propagateValueToDropdownIfOpened(this.value);
    }

    if (this.multiple && this.value && !Array.isArray(this.value)) {
      throw new Error('Passed single value to multiple values picker');
    }
  }

  ngOnDestroy() {
    this._resetContainer();
    this._closed$.next();
    this.zoneSubscription?.unsubscribe();
  }

  /**
   * Opens the popover.
   */
  open() {
    if (!this._menuRef && !this.disabled && !this.loadingData) {
      this.opened = true;
      this._menuRef = this._dropdownService.open();
      this._menuRef.instance.id = this._appSelect3MenuId;
      this._menuRef.instance.items = this.items;
      this._menuRef.instance.itemTemplate = this.itemCustomTemplate;
      this._menuRef.instance.footerTemplate = this.footerCustomTemplate;
      this._menuRef.instance.multiple = this.multiple;
      this._menuRef.instance.selectedItem = this.value;
      this._menuRef.instance.primaryKey = this.primaryKey;
      this._menuRef.instance.filterBy = this.filterBy;
      this._menuRef.instance.disableNull = this.disableNull;
      this._menuRef.instance.nullOptionLabel = this.nullOptionLabel;
      this._menuRef.instance.minDropdownMenuWidthPx = this.minDropdownMenuWidthPx;
      this._menuRef.instance.selectMenuClass = this.selectMenuClass;
      this._menuRef.instance.noResultsText = this.noResultsText;
      this._menuRef.instance.noItemsText = this.noItemsText;
      this._menuRef.instance.itemSize = this.itemSize;
      this._menuRef.instance.disabledFn = this.disabledFn;

      const closeEmitter = new EventEmitter();
      this._menuRef.instance.close = closeEmitter;
      this.closeSubscription = closeEmitter.subscribe(x => this.close());

      this._renderer.setAttribute(this._elementRef.nativeElement, 'aria-describedby', this._appSelect3MenuId);

      if (this.container === 'body') {
        this._document.querySelector(this.container).appendChild(this._menuRef.location.nativeElement);
      }

      this._menuRef.changeDetectorRef.detectChanges();
      this._menuRef.changeDetectorRef.markForCheck();

      this._positionMenu();
      this._menuRef.location.nativeElement.focus();

      this.itemSelectSubscription = this._menuRef.instance.onItemSelect.subscribe(
        selectedItem => {
          this._propagateSelectedValue(selectedItem);
          if (!this.multiple) {
            this.close();
          }
        }
      );

      this._bindAutoClose(this._ngZone, this._document, this.autoClose, () => this.close(), this._closed$,
        this._menuRef ? [this._menuRef.location.nativeElement] : [], [],
        '.b-form__dropdown-menu-item');
    }
  }

  /**
   * Closes the popover.
   */
  close(): void {
    if (this._menuRef) {
      this.opened = false;
      this._renderer.removeAttribute(this._elementRef.nativeElement, 'aria-describedby');
      this._dropdownService.close();
      this._menuRef = null;
      this._closed$.next();
      this.closeSubscription.unsubscribe();
      this.itemSelectSubscription.unsubscribe();
      this._changeDetector.markForCheck();
    }
  }

  /**
   * Toggles the popover.
   *
   * This is considered to be a "manual" triggering of the popover.
   */
  toggle(): void {
    if (this._menuRef) {
      this.close();
    } else {
      this.open();
    }
  }

  onKeyDown(event: KeyboardEvent) {
    switch (event.code) {
      case 'Space':
        event.preventDefault();
        if (!this.opened) {
          this.open();
        }
        break;
      case 'Escape':
        event.preventDefault();
        this.close();
        break;
    }
  }

  private _propagateSelectedValue(selectedItem: any | Array<any>) {
    if (!this.items) {
      return;
    }
    if (this.multiple) {
      if (selectedItem) {
        if (this.primaryKey) {
          this.value = this.items.filter(x => selectedItem.indexOf(x[this.primaryKey]) > -1).map(x => x[this.primaryKey]);
          this.selectedItem = this.items.filter(x => selectedItem.indexOf(x[this.primaryKey]) > -1);
          this.change.emit(this.value);
        } else {
          this.value = selectedItem;
          this.selectedItem = selectedItem;
          this.change.emit(this.value);
        }
      } else {
        this.value = null;
        this.selectedItem = null;
        this.change.emit(null);
      }
    } else {
      if (selectedItem) {
        if (this.primaryKey && (selectedItem as any).hasOwnProperty(this.primaryKey)) {
          this.value = selectedItem[this.primaryKey];
          this.selectedItem = selectedItem;
          this.change.emit(this.value);
        } else {
          this.value = selectedItem;
          this.selectedItem = selectedItem;
          this.change.emit(this.value);
        }
      } else {
        this.value = null;
        this.selectedItem = null;
        this.change.emit(null);
      }
    }
    this._changeDetector.markForCheck();
    this._changeDetector.detectChanges();
  }

  private _tryPreselectValue(selectedValue: any) {
    if (!this.items || this.items.length === 0) {
      return;
    }

    if (this.multiple) {
      if (selectedValue !== null && selectedValue !== undefined) {
        if (this.primaryKey) {
          const resultArray = [];
          const selectedItems = this.items.filter(x => selectedValue.indexOf(x[this.primaryKey]) > -1);
          selectedItems.forEach(x => {
            if (!isNullOrUndefined(this.disabledFn) && this.disabledFn(x)) {
              return;
            }

            if ((x as any).hasOwnProperty(this.primaryKey)) {
              resultArray.push(x);
            }
          });
          this.selectedItem = resultArray;
        } else {
          if (!isNullOrUndefined(this.disabledFn) && this.disabledFn(selectedValue)) {
            this.selectedItem = null;
          } else {
            this.selectedItem = selectedValue;
          }
        }
      } else {
        this.selectedItem = null;
      }
    } else {
      if (selectedValue !== null && selectedValue !== undefined) {
        if (this.primaryKey) {
          const originalItem = this.items.find(x => x[this.primaryKey] === selectedValue);
          if (!isNullOrUndefined(this.disabledFn) && this.disabledFn(selectedValue)) {
            this.selectedItem = null;
          } else {
            if (originalItem && (originalItem as any).hasOwnProperty(this.primaryKey)) {
              this.selectedItem = originalItem;
            } else {
              this.selectedItem = null;
            }
          }
        } else {
          this.selectedItem = selectedValue;
        }
      } else {
        this.selectedItem = null;
      }
    }

    this._changeDetector.markForCheck();
    this._changeDetector.detectChanges();
  }

  private _propagateValueToDropdownIfOpened(selectedValue: any | Array<any>) {
    if (!this.opened || !this._menuRef) {
      return;
    }

    this._menuRef.instance.selectedItem = selectedValue;
  }

  private _positionMenu() {
    const menu = this._menuRef;
    if (this.opened && menu) {
      this._positionElementAndApplyClasses(
        this.customSelectElement.nativeElement,
        this._bodyContainer || this._menuRef.location.nativeElement,
        this.placement,
        this.container === 'body'
      );
    }
  }

  private _positionElementAndApplyClasses(hostElement: HTMLElement, targetElement: HTMLElement, placement: string | Select3MenuPlacement | Select3MenuPlacementArray, appendToBody?: boolean) {
    const placementSeparator = /\s+/;
    const positionService = new Select3PositioningService();
    const placementVals: Array<Select3MenuPlacement> = Array.isArray(placement) ? placement : placement.split(placementSeparator) as Array<Select3MenuPlacement>;
    const allowedPlacements = [
      'top', 'bottom'
    ];

    // replace auto placement with other placements
    let hasAuto = placementVals.findIndex(val => val === 'auto');
    if (hasAuto >= 0) {
      allowedPlacements.forEach((obj) => {
        if (placementVals.find(val => val.search('^' + obj) !== -1) == null) {
          placementVals.splice(hasAuto++, 1, obj as Select3MenuPlacement);
        }
      });
    }

    // Required for transform:
    const style = targetElement.style;
    style.position = 'absolute';
    style.top = '0';
    style.left = '0';
    style['will-change'] = 'transform';
    style['z-index'] = 9999;

    let testPlacement: Select3MenuPlacement | null = null;
    let isInViewport = false;
    for (testPlacement of placementVals) {
      if (positionService.positionElements(hostElement, targetElement, testPlacement, appendToBody)) {
        isInViewport = true;
        break;
      }
    }

    if (!isInViewport) {
      testPlacement = placementVals[0];
      positionService.positionElements(hostElement, targetElement, testPlacement, appendToBody);
    }
  }

  private _checkIsMobile() {
    const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) ||
      (/Macintosh/.test(navigator.userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2);
    const isAndroid = () => /Android/.test(navigator.userAgent);

    return typeof navigator !== 'undefined' ? !!navigator.userAgent && (isIOS() || isAndroid()) : false;
  }

  private _bindAutoClose(zone: NgZone, document: any, type: boolean | 'inside' | 'outside', close: () => void, closed$: Observable<any>, insideElements: HTMLElement[], ignoreElements?: HTMLElement[], insideSelector?: string) {
    if (type) {
      zone.runOutsideAngular(this._wrapAsyncForMobile(() => {
        const shouldCloseOnClick = (event: MouseEvent) => {
          const element = event.target as HTMLElement;
          if (event.button === 2 || this._isContainedIn(element, ignoreElements)) {
            return false;
          }
          if (type === 'inside') {
            return this._isContainedIn(element, insideElements) && this._matchesSelectorIfAny(element, insideSelector);
          } else if (type === 'outside') {
            return !this._isContainedIn(element, insideElements);
          } else /* if (type === true) */ {
            return this._matchesSelectorIfAny(element, insideSelector) || !this._isContainedIn(element, insideElements);
          }
        };

        const escapes$ = fromEvent<KeyboardEvent>(document, 'keydown')
          .pipe(
            takeUntil(closed$),
            filter(e => e.which === Key.Escape), tap(e => e.preventDefault())
          );

        const mouseDowns$ =
          fromEvent<MouseEvent>(document, 'mousedown').pipe(map(shouldCloseOnClick), takeUntil(closed$));
        const closeableClicks$ = fromEvent<MouseEvent>(document, 'mouseup')
          .pipe(
            withLatestFrom(mouseDowns$), filter(([_, shouldClose]) => shouldClose), delay(0),
            takeUntil(closed$)) as unknown as Observable<MouseEvent>;

        race<Event>([escapes$, closeableClicks$]).subscribe(() => {
          zone.run(close);
        });
      }));
    }
  }

  private _wrapAsyncForMobile(fn: any) {
    return this._isMobile ? () => setTimeout(() => fn(), 100) : fn;
  }

  private _isContainedIn(element: HTMLElement, array?: HTMLElement[]) {
    return array ? array.some(item => item.contains(element)) : false;
  }

  private _matchesSelectorIfAny(element: HTMLElement, selector?: string) {
    return !selector || closest(element, selector) != null;
  }

  private _resetContainer() {
    if (!this._menuRef) {
      return;
    }
    const renderer = this._renderer;
    const menuElement = this._menuRef.location;
    if (menuElement) {
      const dropdownElement = this._elementRef.nativeElement;
      const dropdownMenuElement = menuElement.nativeElement;

      renderer.appendChild(dropdownElement, dropdownMenuElement);
      renderer.removeStyle(dropdownMenuElement, 'position');
      renderer.removeStyle(dropdownMenuElement, 'transform');
    }
    if (this._bodyContainer) {
      renderer.removeChild(this._document.body, this._bodyContainer);
      this._bodyContainer = null;
    }
  }
}
