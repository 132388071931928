// idea from https://github.com/a8m/angular-filter

import {NgModule, Pipe, PipeTransform} from '@angular/core';
import {isString, isUndefined} from '../utils/utils';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'safeResourceUrl',
})
export class SafeResourceUrlPipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer) {
  }

  transform(input: string): any {
    if (!isString(input) || isUndefined(input)) {
      return input;
    }

    return this.domSanitizer.bypassSecurityTrustUrl(input);
  }
}

@NgModule({
  declarations: [SafeResourceUrlPipe],
  exports: [SafeResourceUrlPipe],
})
export class SafeResourceUrlPipeModule {
}
