import { DatePipe } from '@angular/common';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Company } from 'src/app/_models/company';
import { ItemOrder } from 'src/app/_models/itemOrder';
import { OrderedItem } from 'src/app/_models/orderedItem';
import { PaginatedResult } from 'src/app/_models/pagination';
import { Reminder } from 'src/app/_models/reminder';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { ReminderService } from 'src/app/_services/reminder.service';

@Component({
  selector: 'app-sendReminder-modal',
  templateUrl: './sendReminder-modal.component.html',
  styleUrls: ['./sendReminder-modal.component.scss']
})
export class SendReminderModalComponent implements OnInit {
  @Output() sendReminder = new EventEmitter();
  companies: Company[];
  companiesClient: Company[];
  itemsValid :boolean=true;
  reminders: Reminder[];

  companyId: string;
  orderedItems: OrderedItem[]= [];
  selectedText: string="Zasíláme vám fakturu a přehled výkazu hodin, pro správné zobrazení je nutné soubory stáhnout.";

  constructor(public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private companyService: CompanyDialService,
    private authService: AuthService,
    private alertify: AlertifyService,
    private reminderService: ReminderService,
    private datePipe: DatePipe) {}

  ngOnInit() {
    var params:any = [];
    params.companyId = this.companyId;

    this.reminderService.getReminders( 1,10000,params)
    .subscribe((res) => { 
      if(res.result.length > 0)
      {
        this.selectedText = res.result[0].text; 
        this.reminders = res.result;
      }
     
      });
  }


  
  send()
  {
      this.sendReminder.emit(this.selectedText);
      this.bsModalRef.hide();
  }
}
