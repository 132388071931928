import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Ticket } from 'src/app/_models/ticket';
import { User } from 'src/app/_models/user';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-ticketSolverEdit-modal',
  templateUrl: './ticketSolverEdit-modal.component.html',
  styleUrls: ['./ticketSolverEdit-modal.component.scss']
})
export class TicketSolverEditModalComponent implements OnInit {
  @Output() updateSelectedSolvers = new EventEmitter();
  ticket: Ticket;
  coreUsers: any[];

  constructor(public bsModalRef: BsModalRef, private userService: UserService) {}

  ngOnInit() {
    this.userService.getCoreUsers().subscribe((data) => {
      this.coreUsers = data;
      this.coreUsers.forEach(user => {
        if(this.ticket.solvers.filter(x => x.userId === user.id).length === 1)
        {
          user.checked = true;
        }
      });
    });
  }


  updateRows()
  {
    this.updateSelectedSolvers.emit(this.coreUsers);
    this.bsModalRef.hide();
  }
}