import {Order} from './order';

export class PriceListItem {
  id: number;
  name: string;
  price: number;
  companyId: number;
  companyName: string;
  order: Order;
  isMonthly: boolean;
  isImported: boolean;
  unitIsVariable: boolean;
  variableType: number;
  basePriceListId: number;
  serviceDescription: string;
  unitDefinition: string;
  unitCount: number;
  priceInLicences: number;
  code: string;
}

export class OrderItemModel {
  orderItemId: number;
  invoiceId: number;
  priceListId: number;
  name: string;
  reportCount: number;
  unitCount: number;
  price: number;
  companyId: number;
  isMonthly: boolean;
  unitIsVariable: boolean;
  variableType: number;
  code: string;
}
