import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PurchaseDepartmentItem } from 'src/app/_models/purchaseDepartmentItem';
import { PurchaseDepartmentOrder } from 'src/app/_models/purchaseDepartmentOrder';
import { User } from 'src/app/_models/user';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-purchaseDepartmentDetail-Modal',
  templateUrl: './purchaseDepartmentDetail-Modal.component.html',
  styleUrls: ['./purchaseDepartmentDetail-Modal.component.css']
})
export class PurchaseDepartmentDetailModalComponent implements OnInit {
  @Output() editPDO = new EventEmitter();
  purchaseDepartmentOrder: PurchaseDepartmentOrder;
  purchaseDepartmentOrderAddForm: FormGroup;
  coreUsers: User[];
  itemsValid :boolean=true;
  pdo:PurchaseDepartmentOrder

  
  purchaseDepartmentItems: PurchaseDepartmentItem[]= [];

  constructor(public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private companyService: CompanyDialService,
    private authService: AuthService,
    private alertify: AlertifyService,
    private userService:UserService,
    private datePipe: DatePipe) {}

  ngOnInit() {
    this.userService.getCoreUsers().subscribe((data) => {this.coreUsers = data;});
    this.purchaseDepartmentItems = this.pdo.pdis;
  }
  getCoreUser(id)
  {
    if(this.coreUsers !== undefined)
    {
      var res =  this.coreUsers.filter(x=>x.id == id)[0];
      return res.firstName+" "+res.lastName;
    }
  }


 
}
