import {ComponentFactoryResolver, ComponentRef, Injector, ViewContainerRef} from '@angular/core';

export class Select3Service<T> {
  private windowRef: ComponentRef<T> | null = null;

  constructor(
    private type: any,
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver) {
  }

  open(): ComponentRef<T> {
    if (!this.windowRef) {
      this.windowRef = this.viewContainerRef.createComponent(
        this.componentFactoryResolver.resolveComponentFactory<T>(this.type), this.viewContainerRef.length,
        this.injector);
    }
    return this.windowRef;
  }

  close() {
    if (this.windowRef) {
      this.viewContainerRef.remove(this.viewContainerRef.indexOf(this.windowRef.hostView));
      this.windowRef = null;
    }
  }
}
