<div class="modal-header dontPrint">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<!-- dataToPrint -->
<div *ngIf="supplier !== undefined" id="" class="printView ml-5 mr-5 mt-2 ">
  <div >
    <h5 class="pull-left">{{supplier.companyName}}</h5>
    <h5 class="pull-right"><strong>FAKTURA -DAŇOVÝ DOKLAD č. {{invoice.invoiceNumber}}</strong></h5>
  </div>

  <div class="form-inline w-100 border border-primary">
    <div class="w-50">
      <div class="ml-3">
        <h5>Dodavatel:</h5>
        <h5>{{supplier.companyName}}</h5>
        <h5>{{this.supplier.adressUlice}} {{this.supplier.adressCisloPopisne}}/{{this.supplier.adressCisloOr}}</h5>
        <h5>{{this.supplier.adressPSC}} {{this.supplier.adressObec}} {{this.supplier.adressCastObce}}</h5>
        <h5>Česká republika </h5>
        <h5>IČO {{this.supplier.ico}} </h5>
        <h5>DIČ {{this.supplier.dico}} </h5>
      </div>

      <div class="ml-3 ">
        <div class="pull-right border border-primary">
          <h5 class="mr-1 mt-1 ml-5 mb-1">{{supplier.accountNumber}}</h5>
        </div>
        <h5 class="mt-3 w-50 mb-3">Číslo účtu:</h5>
        <div class="pull-right border border-primary">
          <h6 class="mr-1 mb-1 mt-1"> {{invoice.dateOfCreation | date:"dd.MM.yyyy"}}</h6>
        </div>
        <h5 class="mr-5">Datum vystavení</h5>
        <div class="pull-right border border-primary">
          <h6 class="mr-1 mb-1 mt-1"> {{invoice.dateToPay | date:"dd.MM.yyyy"}}</h6>
        </div>
        <h5 class=" mr-5 mt-1">Datum splatnosti</h5>
        <div *ngIf="invoice.duzp !==null" class="pull-right border border-primary">
          <h6 class="mr-1 mb-1 mt-1"> {{invoice.duzp | date:"dd.MM.yyyy"}}</h6>
        </div>
        <h5 class="mr-5 ">Datum uskutečnění plnění</h5>
        <div class="pull-right">
          <h6 class="mr-1 mb-1 mt-1"> {{invoice.formOfPayment}}</h6>
        </div>
        <h5 class=" mr-5">Forma úhrady</h5>
      </div>
    </div>

    <div style="margin-left: auto;" class="w-50 border border-primary">
      <div class="m-3">
        <h5>Variabilní symbol: {{invoice.variableSymbol}} </h5>
        <h5>Konstantí symbol: {{invoice.constantSymbol}}</h5>
        <div class=" w-100 mb-3 mt-3">
          <div  class="pull-right ">
            <h6 style="margin-right: 1.5cm;">ze dne:</h6>
          </div>
          <h6>Objednávka č.:</h6>
          <!-- <h6 style="margin-left: 1cm;">ze dne:</h6> -->
        </div>
      </div>
      <div class="w-100 border border-primary">
        <div class="m-3" *ngIf="this.subscriber !== undefined">
          <h5>Odběratel: </h5>
          <h5 >IČO {{this.subscriber.ico}} </h5>
          <h5>DIČ {{this.subscriber.dico}} </h5>
          <h5>{{this.subscriber.companyName}}</h5>
          <h5>{{this.subscriber.adressUlice}} {{this.subscriber.adressCisloPopisne}}/{{this.subscriber.adressCisloOr}}</h5>
          <h5>{{this.subscriber.adressPSC}} {{this.subscriber.adressObec}} {{this.subscriber.adressCastObce}}</h5>
          <br>
        </div>
      </div>

      <div class="w-100 border border-primary">
        <div class="m-1">
          <h5>Konečný příjemce: </h5>
          <br>
        </div>
      </div>
    </div>
  </div>

  <table class="table table-hover border border-primary mb-0 " style="cursor: pointer;" >

    <tr>
      <th style="width: 40%">Označení objednávky</th>
      <th style="width: 5%">Množství</th>
      <th style="width: 15%">J. cena</th>
      <th style="width: 5%">Sleva %</th>
      <th style="width: 10%">Cena</th>
      <th style="width: 10%">DPH %</th>
      <th style="width: 5%">DPH</th>
      <th style="width: 10%">Kč Celkem</th>
    </tr>

    <td colspan="8" style="padding: 1%;">
      <h6 class="ml-3">Fakturujeme vám za období {{invoice.forMonth | date:"MM.yyyy"}}</h6>
    </td>

    <tr *ngFor="let price of priceList" >
      <td>{{price.name}}</td>
      <td>{{price.order.amount}}</td>
      <td>{{price.price}}</td>
      <td>{{price.order.discountPercent}}</td>
      <td>{{price.order.amount*price.price}}</td>
      <td>21%</td>
      <td>{{(price.order.amount*price.price)/100*21}}</td>
      <td>{{(price.order.amount*price.price)/100*21+(price.order.amount*price.price)}}</td>
    </tr>

    <ng-container *ngIf="hoursToInvoice!= undefined" >
      <tr *ngFor="let hours of hoursToInvoice" >
        <ng-container *ngIf="hours.count !==0">
          <td>{{hours.name}} </td>
          <td>{{hours.count}}</td>
          <td *ngIf="hours.code === invoiceConstants.RASCode">{{this.roundTo(priceRas)}}</td>
          <td *ngIf="hours.code === invoiceConstants.ONSCode">{{this.roundTo(priceOns)}}</td>
          <td *ngIf="hours.code === invoiceConstants.KmCode">{{this.roundTo(priceKm)}}</td>
          <td *ngIf="hours.code === invoiceConstants.PCCode">{{this.roundTo(pricePC)}}</td>
          <td *ngIf="hours.code === invoiceConstants.PCProCode">{{this.roundTo(priceProPC)}}</td>
          <td *ngIf="hours.code === invoiceConstants.ServerCode">{{this.roundTo(priceServer)}}</td>
          <td *ngIf="hours.code === invoiceConstants.SQLCode">{{this.roundTo(priceSQL)}}</td>
          <td *ngIf="hours.code === invoiceConstants.FileCode">{{this.roundTo(priceFiles)}}</td>
          <td *ngIf="hours.code === invoiceConstants.ExchangeCode">{{this.roundTo(priceExchange)}}</td>
          <td>0</td>
          <td *ngIf="hours.code === invoiceConstants.RASCode">{{this.roundTo(hours.count*priceRas)}}</td>
          <td *ngIf="hours.code === invoiceConstants.ONSCode">{{this.roundTo(hours.count*priceOns)}}</td>
          <td *ngIf="hours.code === invoiceConstants.KmCode">{{this.roundTo(hours.count*priceKm)}}</td>
          <td *ngIf="hours.code === invoiceConstants.PCCode">{{this.roundTo(hours.count*pricePC)}}</td>
          <td *ngIf="hours.code === invoiceConstants.PCProCode">{{this.roundTo(hours.count*priceProPC)}}</td>
          <td *ngIf="hours.code === invoiceConstants.ServerCode">{{this.roundTo(hours.count*priceServer)}}</td>
          <td *ngIf="hours.code === invoiceConstants.SQLCode">{{this.roundTo((hours.count/100)*priceSQL)}}</td>
          <td *ngIf="hours.code === invoiceConstants.FileCode">{{this.roundTo((hours.count/100)*priceFiles)}}</td>
          <td *ngIf="hours.code === invoiceConstants.ExchangeCode">{{this.roundTo((hours.count/100)*priceExchange)}}</td>
          <td>21%</td>
          <td *ngIf="hours.code === invoiceConstants.RASCode">{{this.roundTo(0.21*hours.count*priceRas)}}</td>
          <td *ngIf="hours.code === invoiceConstants.ONSCode">{{this.roundTo(0.21*hours.count*priceOns)}}</td>
          <td *ngIf="hours.code === invoiceConstants.KmCode">{{this.roundTo(0.21*hours.count*priceKm)}}</td>
          <td *ngIf="hours.code === invoiceConstants.PCCode">{{this.roundTo(0.21*hours.count*pricePC)}}</td>
          <td *ngIf="hours.code === invoiceConstants.PCProCode">{{this.roundTo(0.21*hours.count*priceProPC)}}</td>
          <td *ngIf="hours.code === invoiceConstants.ServerCode">{{this.roundTo(0.21*hours.count*priceServer)}}</td>
          <td *ngIf="hours.code === invoiceConstants.SQLCode">{{this.roundTo((0.21*hours.count/100)*priceSQL)}}</td>
          <td *ngIf="hours.code === invoiceConstants.FileCode">{{this.roundTo((0.21*hours.count/100)*priceFiles)}}</td>
          <td *ngIf="hours.code === invoiceConstants.ExchangeCode">{{this.roundTo((0.21*hours.count/100)*priceExchange)}}</td>
          <td *ngIf="hours.code === invoiceConstants.RASCode">{{this.roundTo(1.21*hours.count*priceRas)}}</td>
          <td *ngIf="hours.code === invoiceConstants.ONSCode">{{this.roundTo(1.21*hours.count*priceOns)}}</td>
          <td *ngIf="hours.code === invoiceConstants.KmCode">{{this.roundTo(1.21*hours.count*priceKm)}}</td>
          <td *ngIf="hours.code === invoiceConstants.PCCode">{{this.roundTo(1.21*hours.count*pricePC)}}</td>
          <td *ngIf="hours.code === invoiceConstants.PCProCode">{{this.roundTo(1.21*hours.count*priceProPC)}}</td>
          <td *ngIf="hours.code === invoiceConstants.ServerCode">{{this.roundTo(1.21*hours.count*priceServer)}}</td>
          <td *ngIf="hours.code === invoiceConstants.SQLCode">{{this.roundTo((1.21*hours.count/100)*priceSQL)}}</td>
          <td *ngIf="hours.code === invoiceConstants.FileCode">{{this.roundTo((1.21*hours.count/100)*priceFiles)}}</td>
          <td *ngIf="hours.code === invoiceConstants.ExchangeCode">{{this.roundTo((1.21*hours.count/100)*priceExchange)}}</td>
        </ng-container>
      </tr>
    </ng-container>

    <tr *ngFor="let item of invItems" >
      <ng-container *ngIf="invoice.recievedInvoice">
        <td>{{item.name}}</td>
        <td>{{item.amount}}</td>
        <td>{{this.roundTo(item.buyingPrice)}}</td>
        <td>{{0}}</td>
        <td>{{this.roundTo(item.amount*item.buyingPrice)}}</td>
        <td >{{this.roundTo(item.dphBuying)}}</td>
        <td>{{this.roundTo(item.amount*item.buyingPrice)*(item.dphBuying/100)}}</td>
        <td>{{this.roundTo((item.amount*item.buyingPrice)*(1+(item.dphBuying/100)))}}</td>
      </ng-container>
      <ng-container *ngIf="!invoice.recievedInvoice">
        <td>{{item.name}}</td>
        <td>{{item.amount}}</td>
        <td>{{this.roundTo(item.sellingPrice)}}</td>
        <td>{{0}}</td>
        <td>{{this.roundTo(item.amount*item.sellingPrice)}}</td>
        <td >{{this.roundTo(item.dphSelling)}}</td>
        <td>{{this.roundTo((item.amount*item.sellingPrice)*(item.dphSelling/100))}}</td>
        <td>{{this.roundTo((item.amount*item.sellingPrice)*(1+(item.dphSelling/100)))}}</td>
      </ng-container>
    </tr>
  </table>

  <div class="border border-primary">
    <div class=" w-100 form-inline">
      <h6 style="width: 56%" class="ml-3">Součet položek</h6>
        <h6 style="margin-right: 10%;">{{this.roundTo(totalPrice)}}</h6>
        <h6 style="margin-right: 10%;">{{this.roundTo(this.getTotalPriceWithDPH()-totalPrice)}}</h6>
    </div>
    <div class="">
      <div  class="pull-right ">
        <h6 class="mr-5 "><strong>{{this.roundTo(this.getTotalPriceWithDPH())}}</strong></h6>
      </div>
      <h6 class="ml-3"><strong>Celkem k úhradě</strong></h6>
    </div>
  </div>

  <div class="border border-primary w-100 form-inline">
    <h6 class="ml-3 w-100" style="margin-bottom: 1cm;">Vystavil: {{invoice.user.firstName}}  {{invoice.user.lastName}}</h6>
    <h6 class="ml-3 mb-1">{{supplier.invoiceFooterText}}</h6>
  </div>
  <div class="border border-primary w-100 form-inline">
    <div class="form-inline w-100">
      <ngx-qrcode
      [elementType]="elementType" style="margin-left: 2px; margin-right: 2px;"
      [errorCorrectionLevel]="correctionLevel"
      [value]="value"
      alt="QR Code"
      cssClass="bshadow"></ngx-qrcode>

      <table class=" table table-hover mb-0 " style="cursor: pointer; width: 66%;" >
        <tr>
          <th style="width: 40%">Rekapitulace DPH v Kč:</th>
          <th >Základ v Kč</th>
          <th >Sazba</th>
          <th >DPH v Kč</th>
          <th >Celkem s DPH v Kč</th>
        </tr>
        <tr *ngFor="let ls of listSazeb " >
          <td></td>
          <td>{{this.roundTo(ls.price)}}</td>
          <td>{{this.roundTo(ls.sazbaDPH)}}%</td>
          <td>{{this.roundTo(ls.price*(ls.sazbaDPH/100))}}</td>
          <td>{{this.roundTo(ls.price*((ls.sazbaDPH/100)+1))}}</td>
        </tr>
      </table>
    </div>

    <div class=" w-100 form-inline mb-3 mt-3">
      <h6 style="margin-left: 1cm;">Převzal:</h6>
      <h6 style="margin-left: 5cm;">Razítko:</h6>
    </div>
  </div>
</div>

<div class="modal-footer dontPrint">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button type="button" class="btn btn-primary" (click)="onBtnPrintClick()">Tisk</button>
</div>
