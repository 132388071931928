import { Routes } from '@angular/router';
import { AdminPanelComponent } from './admin/admin-panel/admin-panel.component';
import { UserManagementComponent } from './admin/admin-panel/user-management/user-management.component';
import { CategoryComponent } from './category/category.component';
import { CompanyComponent } from './company/company.component';
import { DomainComponent } from './domain/domain.component';
import { AdminHomeComponent } from './home/adminHome/adminHome.component';
import { HomeComponent } from './home/home.component';
import { InventoryItemsComponent } from './inventoryItems/inventoryItems.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { IssueInvoiceOverviewComponent } from './Issues/issueInvoiceOverview/issueInvoiceOverview.component';
import { IssuesComponent } from './Issues/Issues.component';
import { IssuesDeliveryListOverviewComponent } from './Issues/IssuesDeliveryListOverview/IssuesDeliveryListOverview.component';
import { IssuesOrderOverviewComponent } from './Issues/IssuesOrderOverview/IssuesOrderOverview.component';
import { ItemOrderInvoiceOverviewComponent } from './itemOrders/itemOrderInvoiceOverview/itemOrderInvoiceOverview.component';
import { ItemOrderOverviewComponent } from './itemOrders/itemOrderOverview/itemOrderOverview.component';
import { ItemOrderReceiptOverviewComponent } from './itemOrders/itemOrderReceiptOverview/itemOrderReceiptOverview.component';
import { ItemOrdersComponent } from './itemOrders/itemOrders.component';
import { LicenceComponent } from './licence/licence.component';
import { LicenceReportComponent } from './licence/licenceReport/licenceReport.component';
import { OrdersComponent } from './orders/orders.component';
import { BasePriceListComponent } from './basepricelist/basepricelist.component';
import { PriceListComponent } from './pricelist/pricelist.component';
import { PriorityComponent } from './priority/priority.component';
import { PurchaseDepartmentComponent } from './purchaseDepartment/purchaseDepartment.component';
import { ReminderComponent } from './reminder/reminder.component';
import { RolegroupsComponent } from './rolegroups/rolegroups.component';
import { MyTicketsComponent } from './tickets/my-tickets/my-tickets.component';
import { TicketMessagesComponent } from './tickets/ticket-messages/ticket-messages.component';
import { TicketsComponent } from './tickets/tickets.component';
import { TicketsForRepeatComponent } from './ticketsForRepeat/ticketsForRepeat.component';
import { UserEditComponent } from './user/userEdit/userEdit.component';
import { WorkPostComponent } from './workPost/workPost.component';
import { WorkReportsComponent } from './workreports/workreports.component';
import { AuthGuard } from './_guards/auth.guard';
import { AdminHomeResolver } from './_resolvers/adminHome.resolver';
import { CategoriesResolver } from './_resolvers/categories.resolver';
import { CompaniesResolver } from './_resolvers/companies.resolver';
import { DomainResolver } from './_resolvers/domains.resolver';
import { GroupsResolver } from './_resolvers/groups.resolver';
import { InventoryItemsResolver } from './_resolvers/InventoryItems.resolver';
import { InvoicesResolver } from './_resolvers/invoices.resolver';
import { ItemOrdersResolver } from './_resolvers/ItemOrders.resolver';
import { LicenceResolver } from './_resolvers/licence.resolver';
import { LicenceReportResolver } from './_resolvers/licenceReport.resolver';
import { ListsResolver } from './_resolvers/lists.resolver';
import { MemberDetailResolver } from './_resolvers/member-detail.resolver';
import { MemberEditResolver } from './_resolvers/member-edit.resolver';
import { MemberListResolver } from './_resolvers/member-list.resolver';
import { MessagesResolver } from './_resolvers/messages.resolver';
import { OrdersResolver } from './_resolvers/orders.resolver';
import { BasePriceListResolver } from './_resolvers/basePriceList.resolver';
import { PriceListItemsResolver } from './_resolvers/priceListItems.resolver';
import { PrioritiesResolver } from './_resolvers/priorities.resolver';
import { PurchaseDepartmentResolver } from './_resolvers/purchaseDepartment.resolver';
import { RemindersResolver } from './_resolvers/reminders.resolver';
import { TicketResolver } from './_resolvers/ticket.resolver';
import { TicketsResolver } from './_resolvers/tickets.resolver';
import { userEditResolver } from './_resolvers/userEdit.resolver';
import { UserWithRolesResolver } from './_resolvers/userWithRoles.resolver';
import { WorkPostResolver } from './_resolvers/workPosts.resolver';
import { WorkReportResolver } from './_resolvers/workreport.resolver';
import {ReportsComponent} from "./reports/reports.component";

export const approutes: Routes = [
    {path: '', component: HomeComponent},
    {
        path: '',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        children: [
            //NotificationSet
            {path: 'messages', component: TicketMessagesComponent, resolve: {messages: MessagesResolver}},
            {path: 'admin/:filterParam', component: AdminPanelComponent, resolve: {users: UserWithRolesResolver}, data: {roles: ['UserEdit']}},
            {path: 'rolesgroups/:filterParam', component: RolegroupsComponent, resolve: {groups: GroupsResolver}, data: {roles: ['RoleGroupsEdit']}},
            {path: 'company', component: CompanyComponent,  resolve: {companies: CompaniesResolver}, data: {roles: ['Companies']}},
            {path: 'priorities/:filterParam', component: PriorityComponent,  resolve: {priorities: PrioritiesResolver}, data: {roles: ['Priority']}},
            {path: 'tickets/:companyFilter/:ctFilter', component: TicketsComponent, resolve: {tickets: TicketsResolver},  data: {}},
            {path: 'ticketsForRepeat/:companyFilterr/:ctFilter', component: TicketsForRepeatComponent, resolve: {tickets: TicketsResolver},  data: {roles: ['RepeatedTickets']}},
            {path: 'category/:filterParam', component: CategoryComponent,  resolve: {categories: CategoriesResolver}, data: {roles: ['Category']}},
            {path: 'workreports/:filterParam', component: WorkReportsComponent,  resolve: {workreports: WorkReportResolver}, data: {roles: ['WorkReports']}},
            {path: 'adminHome', component: AdminHomeComponent,  resolve: {resolver: AdminHomeResolver}, data: {}},
            {path: 'basepricelist', component: BasePriceListComponent,  resolve: {basepricelistitems: BasePriceListResolver}, data: {roles: ['DefaultPriceList']}},
            {path: 'pricelist', component: PriceListComponent,  resolve: {pricelistitems: PriceListItemsResolver}, data: {roles: ['PriceList']}},
            {path: 'pricelist/:filterParam', component: PriceListComponent,  resolve: {pricelistitems: PriceListItemsResolver}, data: {roles: ['PriceList']}},
            {path: 'orders/:filterParam', component: OrdersComponent,  resolve: {orders: OrdersResolver}, data: {roles: ['Order']}},
            {path: 'invoices/:filterParam', component: InvoiceComponent,  resolve: {invoices: InvoicesResolver}, data: {roles: ['Invoice']}},
            {path: 'userEdit',resolve: {companies: userEditResolver}, component: UserEditComponent},
            {path: 'inventoryItems', component: InventoryItemsComponent,  resolve: {invItems: InventoryItemsResolver}, data: {roles: ['InventoryItems']}},
            {path: 'domain/:filterParam', component: DomainComponent,  resolve: {domains: DomainResolver}, data: {roles: ['DomainEdit']}},
            {path: 'workPost', component: WorkPostComponent,  resolve: {workPosts: WorkPostResolver}, data: {roles: ['WorkPost']}},

            {path: 'itemOrders/:companyId/:invoiceId', component: ItemOrdersComponent,  resolve: {orders: ItemOrdersResolver}, data: {roles: ['Invoice']}},
            {path: 'issues/:filterParam/:invoiceId', component: IssuesComponent,  resolve: {issues: ItemOrdersResolver}, data: {roles: ['Invoice']}},

            {path: 'reminders/:filterParam', component: ReminderComponent,  resolve: {reminders: RemindersResolver}, data: {roles: ['Priority']}},

            {path: 'issuesOrderOverview/:filterParam/:invoiceId', component: IssuesOrderOverviewComponent,  resolve: {issues: ItemOrdersResolver}, data: {roles: ['Invoice']}},
            {path: 'issuesDeliveryListOverviewComponent/:filterParam/:invoiceId', component: IssuesDeliveryListOverviewComponent,  resolve: {issues: ItemOrdersResolver}, data: {roles: ['Invoice']}},
            {path: 'issueInvoiceOverviewComponent/:filterParam/:invoiceId', component: IssueInvoiceOverviewComponent,  resolve: {issues: ItemOrdersResolver}, data: {roles: ['Invoice']}},

            {path: 'itemOrderOverviewComponent/:companyId/:invoiceId', component: ItemOrderOverviewComponent,  resolve: {orders: ItemOrdersResolver}, data: {roles: ['Invoice']}},
            {path: 'itemOrderReceiptOverviewComponent/:companyId/:invoiceId', component: ItemOrderReceiptOverviewComponent,  resolve: {orders: ItemOrdersResolver}, data: {roles: ['Invoice']}},
            {path: 'itemOrderInvoiceOverviewComponent/:companyId/:invoiceId', component: ItemOrderInvoiceOverviewComponent,  resolve: {orders: ItemOrdersResolver}, data: {roles: ['Invoice']}},
            {path: 'licence/:companyId', component: LicenceComponent,  resolve: {licence: LicenceResolver}, data: {roles: ['Order']}},
            {path: 'licenceReport/:companyId', component: LicenceReportComponent,  resolve: {licence: LicenceReportResolver}, data: {roles: ['Order']}},
            {path: 'reports', component: ReportsComponent,  resolve: {licence: LicenceReportResolver}, data: {roles: ['Order']}},
            {path: 'purchaseDepartment', component: PurchaseDepartmentComponent,  resolve: {pds: PurchaseDepartmentResolver}, data: {roles: ['Order']}},




        ]
    },

    {path: '**', redirectTo: '', pathMatch: 'full'}
];
