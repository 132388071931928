import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Company } from 'src/app/_models/company';
import { Reminder } from 'src/app/_models/reminder';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { ReminderService } from 'src/app/_services/reminder.service';

@Component({
  selector: 'app-reminderCreate-modal',
  templateUrl: './reminderCreate-modal.component.html',
  styleUrls: ['./reminderCreate-modal.component.scss']
})
export class ReminderCreateModalComponent implements OnInit {
  @Output() createNewReminder = new EventEmitter();
  reminder: Reminder;
  reminderAddForm: FormGroup;
  companies: Company[];
  companyId: string;

  constructor(public bsModalRef: BsModalRef, private fb: FormBuilder, private reminderService: ReminderService, private companyService: CompanyDialService) {}

  ngOnInit() {
    this.createForm();
    this.companyService.getCompanies(1, 1,null,null,false,true).subscribe(data =>{
      this.companies = data.result;
      if (this.companyId !== '0')
      {
        this.companies = data.result.filter(x => x.id.toString() === this.companyId);
      }
      else
      {
        this.companies = data.result;
      }
    });
  }


  createForm(){
    this.reminderAddForm = this.fb .group({
      text: ['', Validators.required],
      companyid: ['', Validators.required],
      deleted: [false],
    });
  }
  createReminder()
  {
    if (this.reminderAddForm.valid)
    {
      this.reminder = Object.assign({}, this.reminderAddForm.value);
      this.createNewReminder.emit(this.reminder);
      this.bsModalRef.hide();
    }
  }
}
