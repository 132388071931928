import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { WorkPost } from 'src/app/_models/workPost';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { WorkPostService } from 'src/app/_services/workPost.service';

@Component({
  selector: 'app-workPostEdit-modal',
  templateUrl: './workPostEdit-modal.component.html',
  styleUrls: ['./workPostEdit-modal.component.css']
})
export class WorkPostEditModalComponent implements OnInit {
  @Output() workPostEdit = new EventEmitter();
  workPost: WorkPost;
  workPostEditForm: FormGroup;

  constructor(public bsModalRef: BsModalRef, private fb: FormBuilder, private workPostService: WorkPostService, private companyService: CompanyDialService) {}

  ngOnInit() {
    this.createForm();
  }


  createForm(){
    this.workPostEditForm = this.fb .group({
      id: [this.workPost.id],
      name: [this.workPost.name, Validators.required],
      deleted: [false]
    });
  }
  editWorkPost()
  {
    if (this.workPostEditForm.valid)
    {
      this.workPost = Object.assign({}, this.workPostEditForm.value);
      this.workPostEdit.emit(this.workPost);
      this.bsModalRef.hide();
    }
  }
}
