import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { User } from 'src/app/_models/user';
import { userNotification } from 'src/app/_models/userNotification';
import { UserNotificationService } from 'src/app/_services/userNotification.service';

@Component({
  selector: 'app-notificationsSet-modal',
  templateUrl: './notificationsSet-modal.component.html',
  styleUrls: ['./notificationsSet-modal.component.scss']
})
export class NotificationsSetModalComponent implements OnInit {
  @Output() updateSelectedNotifSettings = new EventEmitter();
  user: User;
  userNotifs: userNotification[];
  defaultUserNotifs: string[]= [];
  constructor(public bsModalRef: BsModalRef, private userNotifSerivce: UserNotificationService) {}

  ngOnInit() {
    this.userNotifSerivce.getUserNotification(this.user.id).subscribe(data=>{
      this.userNotifs = data; 

      this.defaultUserNotifs.push('Nová zpráva' );
      this.defaultUserNotifs.push('Změna statusu' );
      this.defaultUserNotifs.push('Změna řešitele' );
      // this.defaultUserNotifs.push('Změna zakladatele' );
      this.defaultUserNotifs.push('Změna deadline' );
      this.defaultUserNotifs.push('Změna kategorie' );
      this.defaultUserNotifs.push('Změna nadpisu' );
      this.defaultUserNotifs.push('Změna vlastníka' );
      this.defaultUserNotifs.push('Změna firmy' );
          this.defaultUserNotifs.push('Vytvoření ticketu' );
      this.defaultUserNotifs.push('Uzavření ticketu' );
      
      this.userNotifs.forEach(element => {
        element.checked=true;
      });

      this.defaultUserNotifs.forEach(defNotif => {
        if(data.filter(x=>x.notification === defNotif).length < 1)
        {
          this.userNotifs.push({id:0, userId:this.user.id, notification:defNotif, checked:false} as userNotification);
        }
      });
    })
    
  }


  callEvenet()
  {
    this.userNotifs = this.userNotifs.filter(x=>x.checked);
    this.updateSelectedNotifSettings.emit(this.userNotifs);
    this.bsModalRef.hide();
  }
}
