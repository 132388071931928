<div class="modal-header">
  <div class="form-group m-0 p-0">
    <label for="month">Za měsíc: </label>
    <input class="ml-1" type="month" [(ngModel)]="rParams.date" name="month" (change)="loadReportData()">
  </div>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="ml-3 mr-3 mt-2 ">

  <h4><strong>Název:</strong> {{domain.name}}</h4>
  <h4><strong>Název:</strong> {{domain.company.companyName}}</h4>
  <br/>
  <h4><strong>Počítače a  servery:</strong></h4>
  <div *ngFor="let pcGroup of pcAndServerGroups">
    <h4 *ngIf="pcGroup.pcName === ''" (click)="setPcName(pcGroup.name)" >{{pcGroup.value}} x {{pcGroup.name}} <i class="fa fa-caret-down"></i></h4>
    <h4 *ngIf="pcGroup.pcName !== ''" (click)="setPcName(pcGroup.name)">{{pcGroup.value}} x {{pcGroup.name}} <i class="fa fa-caret-right"></i></h4>

    <ng-container *ngIf="pcGroup.pcName === ''">
      <div *ngFor="let pc of pcAndServer">
        <h4 *ngIf='pc.name === pcGroup.name'>{{pc.pcName}}</h4>
      </div>
    </ng-container>
    
  </div>
  

  <br/>
  <h4>SQL: {{sqlCount}} MB</h4>
  <h4>Soubory: {{files}} MB</h4>
  <h4>Exchange: {{exchange}} MB</h4>
  <!-- <h4><strong>Počítače:</strong></h4>
  <div *ngFor="let pc of computersToAdd">
    <h4>{{pc.name}} {{pc.computerId}}</h4>
  </div> -->
  <hr/>
 
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
</div>