import {Order} from './order';

export class BasePriceListItem {
  id: number;
  name: string;
  price: number;
  companyName: string;
  order: Order;
  isMonthly: boolean;
  isImported: boolean;
  unitIsVariable: boolean;
  variableType: number;
  code: string;
  serviceDescription: string;
  unitDefinition: string;
  unitCount: number;
  priceInLicences: number;
}
