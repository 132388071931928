import { group } from '@angular/animations';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PaginatedResult } from '../_models/pagination';
import { User } from '../_models/user';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
baseUrl = environment.apiUrl;
constructor( private http: HttpClient) { }

// tslint:disable-next-line: typedef
getUsersWithRoles(page?, itemsPerPage?, userParams?): Observable<PaginatedResult<User[]>>
{
  const paginatedResult: PaginatedResult<User[]> = new PaginatedResult<User[]>();

  let params = new HttpParams();
  if (page != null && itemsPerPage != null)
    {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
  if ( userParams != null)
    {
      params = params.append('CompanyId', userParams.companyId);
      params = params.append('WorkPost', userParams.workPost);
    }
  return this.http.get<User[]>(this.baseUrl + 'admin/usersWithRoles', { observe : 'response', params})
  .pipe(
    map(response => {
      paginatedResult.result = response.body;
      if (response.headers.get('Pagination') != null)
      {
        paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
      }
      return paginatedResult;
    })
    );
}

// tslint:disable-next-line: typedef
updateUserRoles(user: User, groups:any)
{
  return this.http.post(this.baseUrl + 'admin/editroles/' + user.userName, groups);
}
}
