<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="ml-3 mr-3 mt-2 ">

  <form  (ngSubmit)="send()">
    <div class="form-group">
      
      <select class="form-control" id="solverSelect"  placeholder="Firma"  name="solverSelect" [(ngModel)]="selectedText">
      <option  value="Zasíláme vám fakturu a přehled výkazu hodin, pro správné zobrazení je nutné soubory stáhnout.">Vyberte text</option>
      <!-- <option *ngIf="" value="">Vyberte dodavatele</option> -->
      <option *ngFor="let reminder of reminders" [value] = "reminder.text">
         {{reminder.text}} 
       </option>
      </select>
    </div>
  </form>

</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button type="button" class="btn btn-success" form="itemOrderAddForm" (click)="send()">Odeslat</button>
  <!-- && orderedItems != undefined && orderedItems.length > 0 -->
</div>