import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';
import { WorkreportService } from '../_services/workreport.service';
import { WorkReport } from '../_models/workReport';
@Injectable()
export class WorkReportResolver implements Resolve<WorkReport[]> {
  pageNumber = 1;
  pageSize = 15;
  isDeleted = false;

  constructor(
    private workreportService: WorkreportService,
    private router: Router,
    private alertify: AlertifyService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<WorkReport[]> {
    return this.workreportService.getWorkReports(this.pageNumber, this.pageSize, this.isDeleted).pipe(
      catchError((error) => {
        this.alertify.error('Problem retrieving work reports');
        this.router.navigate(['/adminHome']);
        return of(null);
      })
    );
  }
}
