<style>
ul {
  background-color: #ffffff;
  padding-left: 0px;
  color: #495057;
}

.active {
  background-color: #cacbcc;
}
</style>

<ul (keydown)="keyDown($event)"
    [@overlayAnimation]="{value: 'visible', params: {showTransitionParams: showTransitionOptions, hideTransitionParams: hideTransitionOptions}}"
    [ngStyle]="{'min-width.px': minDropdownMenuWidthPx}" aria-expanded="true" class="s3__dropdown-menu"
    [class]="selectMenuClass" role="listbox">

  <li *ngIf="filterBy" class="s3__dropdown-menu-item s3__dropdown-filter input-group">
    <input #filteringInput
           [(ngModel)]="filteredText"
           (ngModelChange)="onFilter()"
           class="form-control"
           [placeholder]="'hledat...'"
           type="text"/>
  </li>

  <cdk-virtual-scroll-viewport *ngIf="items && items.length" [itemSize]="itemSize"
                               [ngStyle]="{'height': maxHeight + 'px'}"
                               (scrolledIndexChange)="scrollToSelectedVirtualScrollElement()">

    <!-- If filtering applied (and filtering is array), but no results-->
    <li
      *ngIf="!(filteredText | isNullOrEmpty) && (this.filterBy | isArray) && (items | where: prepareFilterPredicateFromArray()).length === 0 && disableNull"
      class="s3__dropdown-menu-item" role="option">
      {{ noResultsText }}
    </li>

    <!-- If filtering applied (and filtering is NOT array), but no results-->
    <li
      *ngIf="!(filteredText | isNullOrEmpty) && !(this.filterBy | isArray) && (items | where: [filterBy, filteredText]).length === 0 && disableNull"
      class="s3__dropdown-menu-item" role="option">
      {{ noResultsText }}
    </li>

    <!-- If null value is allowed-->
    <li (click)="selectItem(null)" *ngIf="!multiple && !disableNull" [class.active]="virtualScrollSelectedIndex == -1"
        class="s3__dropdown-menu-item" role="option">
      {{nullOptionLabel }}
    </li>

    <ng-container *cdkVirtualFor="let item of (
        !(filteredText | isNullOrEmpty)
          ? (
            (filterBy | isArray)
              ? (items | where: prepareFilterPredicateFromArray())
              : (items | where: [filterBy, filteredText])
            )
          : items);
        let i = index; let c = count; let f = first; let l = last; let e = even; let o = odd">

      <!-- Option item-->
      <li (click)="selectItem(item)"
          [class.active]="virtualScrollSelectedIndex === i"
          [class.disabled]="isItemDisabled(item)"
          class="s3__dropdown-menu-item"
          role="option">

        <ng-container *ngIf="multiple">
          <div class="form-check">
            <label class="form-check-label" [for]="'checkbox' + i">
            </label>
            <input [checked]="isItemChecked(item)" [name]="'checkbox'+i" type="checkbox" class="form-check-input"/>
          </div>
        </ng-container>

        <ng-container
          *ngTemplateOutlet="(itemTemplate ? itemTemplate : defaultItemTemplate); context: {$implicit: item}">
        </ng-container>

      </li>
    </ng-container>
  </cdk-virtual-scroll-viewport>

  <!-- If no items to be shown-->
  <ng-container *ngIf="!items || !items.length">
    <div class="text-muted p-3">
      {{ noItemsText }}
    </div>
  </ng-container>

  <!--  If footer template is presented-->
  <ng-container *ngIf="footerTemplate">
    <div class="b-form__dropdown-menu-footer">
      <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </div>
  </ng-container>

</ul>

<ng-template #defaultItemTemplate let-item>
  {{item.name}}
</ng-template>
