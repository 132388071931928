import {Component, Input, OnInit} from '@angular/core';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Licence} from '../_models/licence';
import {LicenceToOrder} from '../_models/licenceToOrder';
import {PaginatedResult, Pagination} from '../_models/pagination';
import {PriceListItem} from '../_models/priceListItem';
import {AlertifyService} from '../_services/alertify.service';
import {LicenceService} from '../_services/licence.service';
import {PriceListService} from '../_services/pricelist.service';
import {PriceListItemCreateModalComponent} from './priceListItemCreate-modal/priceListItemCreate-modal.component';
import {PriceListItemEditModalComponent} from './priceListItemEdit-modal/priceListItemEdit-modal.component';
import {QueryStringsHelper} from '../core/_helpers/query-strings.helper';

@Component({
  selector: 'app-price-list',
  templateUrl: './pricelist.component.html'
})
export class PriceListComponent implements OnInit {
  bsModalRef: BsModalRef;
  priceLists: PriceListItem[];
  pagination: Pagination;

  filterParams: any = {};
  private filteringTimeout: any;
  licence: Licence[];
  alreadyImported: boolean = true;
  @Input() defaultPriceList: boolean;

  constructor(
    private priceListService: PriceListService,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private licenceService: LicenceService,
    private queryStringHelper: QueryStringsHelper
  ) {
  }

  ngOnInit() {
    this.initFilter();
    // this.route.data.subscribe((data) => {
    //   this.pagination = data['pricelistitems'].pagination;
    //   // this.priceList = data['pricelistitems'].result;
    // });
    //
    //
    // this.companyService.getCompanies(1, 1, null, null, false, true).subscribe((data) => {
    //   this.companies = data.result;
    //   this.route.params.subscribe(params => {
    //     this.filterParams.companyId = params['filterParam'];
    //     if (this.filterParams.companyId === '0' || this.filterParams.companyId === undefined) {
    //       this.filterParams.companyId = this.companies[0].id;
    //     }
    //     if (this.authService.decodedToken.IsPrimary !== 'True') {
    //       this.filterParams.companyId = parseFloat(this.authService.decodedToken.CompanyId);
    //     }
    //
    //     this.loadPriceList();
    //   });
    // });
  }

  initFilter(): void {
    this.pagination = new Pagination();
    this.filterParams = Object.assign({
      companyId: null,
      name: '',
      page: 1,
      pageSize: 15
    }, {});
    this.queryStringHelper.updateFilteringParamsFromUrl(this.filterParams);
    this.loadPriceList();
  }

  deletePriceListItem(id: number) {
    this.alertify.confirm('Opravdu chcete vymazat tuto položku?', () => {
      this.priceListService.deletePriceListItem(id)
        .subscribe(() => {
          this.loadPriceList();
          this.alertify.success('Položka byla odstraněna');
        }, error => {
          this.alertify.error('Položku se nepodařilo odstranit');
        });
    });
  }

  loadPriceList() {
    this.queryStringHelper.setFilterToQueryString(this.filterParams);
    this.priceListService.getPriceListItems(this.filterParams.page, this.filterParams.pageSize, this.filterParams)
      .subscribe((res: PaginatedResult<PriceListItem[]>) => {
        this.priceLists = res.result;
        this.pagination.totalPages = res.pagination.totalPages;
        this.pagination.itemsPerPage = res.pagination.itemsPerPage;
        this.pagination.totalItems = res.pagination.totalItems;
        this.alreadyImported = this.priceLists.filter(x => x.isImported === true).length > 0;
      }, error => {
        this.alertify.error(error);
      });
  }

  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.filterParams.page = this.pagination.currentPage;
    this.filterParams.pageSize = this.pagination.itemsPerPage;
    this.loadPriceList();
  }

  createModal() {
    const initialState: any = {
      companyId: this.filterParams.companyId
    };
    this.bsModalRef = this.modalService.show(PriceListItemCreateModalComponent, {initialState, backdrop: 'static'});
    this.bsModalRef.content.createNewPriceListItem.subscribe((values) => {
      this.priceListService.addPriceListItem(values['values']).subscribe(res => {
        values['licence'].forEach(l => {
          if (l.amount > 0) {
            const lto: LicenceToOrder = {
              deleted: false,
              licenceId: l.id,
              orderId: res,
              lastPayment: new Date(),
              id: 0,
              amount: l.amount
            };
            this.licenceService.addLTO(lto).subscribe(data => {
            });
          }
        });
        this.alertify.success('Úspěšně přidáno');
      }, error => {
        this.alertify.error('Položku se nepodařilo vytvořit');
      }, () => {
        this.loadPriceList();
      });
    });
  }

  getEditModal(priceListItem: PriceListItem) {
    const initialState: any = {
      priceListItem,
      companyId: this.filterParams.companyId,
    };
    this.bsModalRef = this.modalService.show(PriceListItemEditModalComponent, {initialState, backdrop: 'static'});
    this.bsModalRef.content.editPriceListItemEvent.subscribe((values) => {

      this.priceListService.updatePriceListItem(priceListItem.id, values['values']).subscribe(res => {
        //TODO add licence
        values['licence'].forEach(l => {
          if (l.amount > 0) {
            const lto: LicenceToOrder = {
              deleted: false,
              licenceId: l.id,
              orderId: res,
              lastPayment: new Date(),
              id: 0,
              amount: l.amount
            };
            this.licenceService.addLTO(lto).subscribe(data => {
            });
          }
        });


        this.alertify.success('Položka byla upravena');
      }, error => {
        this.alertify.error('Položku se nepodařilo upravit');
      }, () => {
        this.loadPriceList();
      });
    });
  }

  resetFilters() {
    this.filterParams = Object.assign({
      companyId: null,
      name: null,
      page: 1,
      pageSize: 15
    }, {});
    this.loadPriceList();
  }

  importPriceList() {
    this.priceListService.importDefaultPriceList(this.filterParams.companyId).subscribe(x => {
      this.alertify.success('Import základního ceníku proběhl úspěšně');
      this.loadPriceList();
    }, error => {
      this.alertify.error('Import základního ceníku proběhl neúspěšně');
    });
  }

  setCompanyToFilter($event: number) {
    this.filterParams.companyId = $event;
    this.filterParams.page = 1;
    this.pagination.currentPage = 1;
    this.loadPriceList();
  }

  loadWithDelay(event: any) {
    if (this.filteringTimeout) {
      clearTimeout(this.filteringTimeout);
      this.filteringTimeout = null;
    }
    this.filteringTimeout = setTimeout(() => {
      this.filterParams.page = 1;
      this.pagination.currentPage = 1;
      this.loadPriceList();
    }, 400);
  }
}
