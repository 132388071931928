import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Company } from 'src/app/_models/company';
import { Invoice } from 'src/app/_models/invoice';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { InvoiceService } from 'src/app/_services/invoice.service';
import { AuthService } from 'src/app/_services/auth.service';
import { PriceListService } from 'src/app/_services/pricelist.service';
import { PriceListItem } from 'src/app/_models/priceListItem';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { DatePipe } from '@angular/common';
import { InventoryItem } from 'src/app/_models/inventoryItem';
import { InventoryItemService } from 'src/app/_services/inventoryItem.service';

@Component({
  selector: 'app-inventoryItemEdit-modal',
  templateUrl: './inventoryItemEdit-modal.component.html',
  styleUrls: ['./inventoryItemEdit-modal.component.css']
})
export class InventoryItemEditModalComponent implements OnInit {

  @Output() editInventoryItem = new EventEmitter();
  // invoice: Invoice;
  itemEditForm: FormGroup;
  companies: Company[];
  companyId: string;
  month: string;
  invItemsToEdit: InventoryItem[]= [];
  invItems: InventoryItem[]= [];
  invItemsOriginalAdded: InventoryItem[]= [];
  idsToAdd: Number[]= [];
  invItem: InventoryItem;
  filterParams: any = {};

  constructor(public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private companyService: CompanyDialService,
    private authService: AuthService,
    // private priceListService: PriceListService,
    // private inventoryService: InventoryItemService,
    private alertify: AlertifyService,
    private invService: InventoryItemService,
    private datePipe: DatePipe) {}

  ngOnInit() {
    if(this.invItem.consistOfItems!=null)
    {
      this.invItem.consistOfItems.forEach(val => this.invItemsOriginalAdded.push(Object.assign({}, val)));
      this.invItemsToEdit = this.invItem.consistOfItems;
    }
    

    this.createForm();
    this.companyService.getCompanies(1, 1,null,null,false,true).subscribe(data => {
      this.companies = data.result;

      this.loadItems();
    });
  }

  loadItems()
  {
    if(this.itemEditForm.value.clientCompanyId != 0)
    {
    this.filterParams.name = '';
    this.filterParams.isContained = false;
    this.filterParams.hasContained = false;
    this.filterParams.delivered = false;
    this.filterParams.invoicedToClient = false;
    this.filterParams.reklamace = false;
    this.filterParams.inWarehouse = true;
    this.filterParams.clientId = this.itemEditForm.value.clientCompanyId;
    this.filterParams.supplierId = 0;
    this.filterParams.isForInvoice = false;
    this.filterParams.getPrimaryCompany = true;

    this.invService.getInvenotryItems(1, 1,0,this.filterParams).subscribe(x=>
        {
        this.invItems = x.result.filter(x=>x.id != this.invItem.id);
        if(this.invItem.consistOfItems!=null)
    {
        this.invItem.consistOfItems.forEach(val => this.invItems.push(Object.assign({}, val)));
    }
        this.filterItems();
        });
   }
  }


  // tslint:disable-next-line: typedef
  createForm(){
    this.itemEditForm = this.fb .group({
      name: [this.invItem.name, Validators.required],
      amount: [this.invItem.amount, Validators.required],
      sellingPrice: [this.invItem.sellingPrice, Validators.required],
      clientCompanyId: [this.invItem.clientCompanyId, Validators.required],
      id: [this.invItem.id],
      supplierCompanyId:[this.invItem.supplierCompanyId],
      isInWarehouse:[this.invItem.isInWarehouse],
      deleted:[this.invItem.deleted],
      forClient:[this.invItem.forClient],
      buyingPrice:[this.invItem.buyingPrice],
      clientInvoiceId:[this.invItem.clientInvoiceId],
      deliveredToClient:[this.invItem.deliveredToClient],
      idOfItemInWitchContained:[this.invItem.idOfItemInWitchContained],
      invoiceId:[this.invItem.invoiceId],
      invoicedToClient:[this.invItem.invoicedToClient],
      isContainedInAnotherItem:[this.invItem.isContainedInAnotherItem],
      isOnComplaint:[this.invItem.isOnComplaint],
      itemOrderId:[this.invItem.itemOrderId],
      orderedAmount:[this.invItem.orderedAmount],
      // inventoryItemId:[this.invItem.inventoryItemId],
      rowForInvoice:[this.invItem.rowForInvoice],
      flag:[this.invItem.flag],
      serialNumber:[this.invItem.serialNumber],
      productNumber:[this.invItem.productNumber],
      warrantyTo:[this.datePipe.transform(this.invItem.warrantyTo, 'yyyy-MM-dd')],
      dphBuying:[this.invItem.dphBuying],
      dphSelling:[this.invItem.dphSelling],
      
      
    });

   if(this.companyId !='0')
   {
    this.itemEditForm.patchValue({companyId: this.companyId})
   }
  if(this.month !== undefined)
  {
    this.itemEditForm.patchValue({forMonth: this.datePipe.transform(this.month, 'yyyy-MM')})
  }
  }
  editItem()
  {
    if (this.itemEditForm.valid)
    {
      this.invItem = Object.assign({}, this.itemEditForm.value);
      this.invItemsToEdit.forEach(element => {
        this.idsToAdd.push(element.id);
      });
      this.editInventoryItem.emit({invItem: this.invItem, invItemsToAdd: this.idsToAdd});
      this.bsModalRef.hide();
    }
  }

  addEmptyInvoiceItem()
  {
    let emptyInvItem = {} as InventoryItem;
    emptyInvItem.clientCompanyId =0;
    emptyInvItem.deliveredToClient=false;
    emptyInvItem.isContainedInAnotherItem=true;
    emptyInvItem.isInWarehouse=true;
    emptyInvItem.isOnComplaint=false;
    emptyInvItem.deleted=false;
    emptyInvItem.idOfItemInWitchContained=0;
    emptyInvItem.invoiceId = 0;
    emptyInvItem.invoicedToClient =false;
    emptyInvItem.clientInvoiceId =0;
    emptyInvItem.id =0;
    
    this.invItemsToEdit.push(emptyInvItem);
  }

  removeInvoiceItem(id: number)
  {
    this.invItemsToEdit = this.invItemsToEdit.filter(x=>x.id != id);
    this.filterItems();
  }

  filterItems()
  {

    this.invService.getInvenotryItems(1, 1,0,this.filterParams).subscribe(x=>
    {
      var originalItems =  x.result.filter(x=>x.id != this.invItem.id);
       originalItems.push(...this.invItemsOriginalAdded);


       originalItems.forEach(element => {
        var item = this.invItems.filter(x=>x.id === element.id)[0];
        item.amount =  element.amount;
      });


      this.invItemsToEdit.filter(x=>x.id !== 0 ).forEach(element => {
        var item = this.invItems.filter(x=>x.id.toString() === element.id.toString())[0];
          if(item !== undefined)
          item.amount= item.amount-1;
        });


    });
 
  }



}
