<div  class="form-inline  m-5">
<div class="container w-50">
  <div class="tab-pannel" style="position: relative;">
    <tabset #ticketTabs class="member-tabset " >
      <tab heading="Moje Tickety">
        <div class="container">
          <app-my-tickets  [adminHome]="true" [filterParams] = "filterParams" [filterChangedFlag] = "filterChangedFlag" [ticketAddFlag] = "ticketAddFlag" [ticketFilter]='issuerticket' (createTicketEvent) = "getTicketAddModal()"></app-my-tickets>
        </div>
      </tab>
      <tab heading="Tickety" *appHasRole="['AllTickets']"  >
        <div class="container">
          <app-my-tickets [adminHome]="true" [filterParams] = "filterParams" [filterChangedFlag] = "filterChangedFlag" [ticketAddFlag] = "ticketAddFlag" (createTicketEvent) = "getTicketAddModal()"></app-my-tickets>

        </div>
      </tab>
      <tab heading="Tickety k řešení" *appHasRole="['TicketsToSolve']">
        <div class="container">
          <app-my-tickets [adminHome]="true" [filterParams] = "filterParams" [filterChangedFlag] = "filterChangedFlag" [ticketAddFlag] = "ticketAddFlag" [ticketFilter]='solverticket' (createTicketEvent) = "getTicketAddModal()"></app-my-tickets>
        </div>
      </tab>
    </tabset>
    <button style="position: absolute; top: 0%; right: 0%;" class="btn btn-primary " (click)="$event.stopPropagation()" (click)="getTicketAddModal()" >Vytvořit nový ticket</button>
  </div>
</div>
<div *appHasRole="['Companies']" class="container w-50 align-top">
  <div *ngIf="!anyCompanies()&& companyFilter===''" style="text-align: center">
    <h1>Zatím nejsou přidané žádné firmy</h1>
  </div>
  <!-- <input class="w-100 form-control" type="text" [(ngModel)]="companyFilter" placeholder="Filtr dle názvu" (ngModelChange)="loadCompanies()"> -->
  <div class="row" *ngIf="anyCompanies() || companyFilter!==''">
    <div class="form-inline" style="width: 80%;">
      <input class="w-75 form-control" type="text" [(ngModel)]="companyFilter" placeholder="Filtr dle názvu" (ngModelChange)="loadCompanies()">
      <div class="form-group w-25" >
        <select class="form-control ml-1" id="odFilter" [(ngModel)]="odFilter" name="odFilter" (ngModelChange)="loadCompanies()"> 
          <option value="">Odběratel/Dodavatel</option>
          <option value="OD">Odběratel a Dodavatel</option>
          <option value="O">Odběratel</option>
          <option value="D">Dodavatel</option>
        </select>
      </div>
    </div>


  <table class="table table-hover" style="cursor: pointer">
    <tr>
      <th style="width: 70%">Název</th>
      <th style="width: 30%">Odběratel/Dodavatel</th>
    </tr>
    <tr *ngFor="let company of companies" (click)="getCompanyDetailModal(company)">
      <td >{{company.companyName}}</td>
      <td ><strong *ngIf="company.client" class="text-success ">O</strong> 
           <strong *ngIf="company.supplier"class="text-danger">D</strong></td>
    </tr>
  </table>

</div>

<div *ngIf="anyCompanies()" class="d-flex justify-content-center">
    <pagination [boundaryLinks]="true" 
                [totalItems]="companypagination.totalItems"
                [itemsPerPage]="companypagination.itemsPerPage"
                [maxlength]
                [(ngModel)]="companypagination.currentPage"
                (pageChanged)="pageChanged($event)"
                [maxSize]="10"
              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
  </pagination>
  </div>
</div>

</div>

<div class="container">
  <button class="btn btn-primary m-3" (click)="createModal()" >Vytvořit nový výkaz</button>
  <div class="form-inline">

    <div class="mt-5">
      <ngx-charts-bar-vertical  style="min-width: 100%" 
      [view]="[500,250]"
      [results]="monthHoursData"
      [xAxisLabel]="''"
      [legendTitle]="'Chart'"
      [yAxisLabel]="'hodiny'"
      [legend]="false"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [xAxis]="true"
      [yAxis]="true"
      [gradient]="true">
      </ngx-charts-bar-vertical>
    </div>
    <div class="mb-3">
    <ngx-charts-advanced-pie-chart  style="min-width: 100%" 
    [view]="[500,300]"
    [results]="comapnyPercentsData"
    [gradient]="true">
    </ngx-charts-advanced-pie-chart>
  </div>
  </div>
</div>


