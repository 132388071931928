<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
 

  <form [formGroup]="invoiceEditForm" (ngSubmit)="editInvoice()">
    <!-- <div class="form-group">
      <select (change)="getOrders()" formControlName="companyId" class="form-control" id="solverSelect"  placeholder="Firma"  name="solverSelect" 
      [ngClass]="{ 'is-invalid': invoiceEditForm.get('companyId').errors && invoiceEditForm.get('companyId').touched}">
      <option value="">Vyberte firmu</option>
      <option *ngFor="let company of companies" [value] = "company.id">
         {{company.companyName}} 
       </option>
      </select>
      <div class="invalid-feedback">Firma je povinné pole</div>
    </div> -->

    <!-- <div class="form-group form-inline ml-1">
      <h4 class="mr-2">Měsíc : </h4>
      <input (change)="getOrders()" type="month" max="{{maxDate | date:'yyyy-MM'}}" [ngClass]="{'is-invalid': invoiceEditForm.get('forMonth').errors && invoiceEditForm.get('forMonth').touched }" class="form-control is-invalid" 
      formControlName="forMonth" />
    </div> -->

    <div class="form-group">
      <input  type="text" [ngClass]="{'is-invalid': invoiceEditForm.get('invoiceNumber').errors && invoiceEditForm.get('invoiceNumber').touched }" class="form-control is-invalid" 
      formControlName="invoiceNumber" placeholder="Číslo faktury" />
      <div class="invalid-feedback">Číslo faktury je povinné pole</div>
    </div>

    <div class="form-group form-inline ml-1">
      <h4 class="mr-2">Datum splatnosti : </h4>
      <input  type="date" [ngClass]="{'is-invalid': invoiceEditForm.get('dateToPay').errors && invoiceEditForm.get('dateToPay').touched }" class="form-control is-invalid" 
      formControlName="dateToPay" />
    </div>
    <div class="form-group form-inline ml-1">
      <h4 class="mr-2">DÚZP : </h4>
      <input  type="date"  [ngClass]="{'is-invalid': invoiceEditForm.get('duzp').errors && invoiceEditForm.get('duzp').touched }" class="form-control is-invalid" 
      formControlName="duzp" />
    </div>

    <div class="form-group">
      <input  type="text" [ngClass]="{'is-invalid': invoiceEditForm.get('constantSymbol').errors && invoiceEditForm.get('constantSymbol').touched }" class="form-control is-invalid" 
      formControlName="constantSymbol" placeholder="Konstantní symbol" />
      <div class="invalid-feedback">Konstantní symbol je povinné pole</div>
    </div>

    <div class="form-group">
      <input  type="text" [ngClass]="{'is-invalid': invoiceEditForm.get('variableSymbol').errors && invoiceEditForm.get('variableSymbol').touched }" class="form-control is-invalid" 
      formControlName="variableSymbol" placeholder="Variabilní symbol" />
      <div class="invalid-feedback">Variabilní symbol je povinné pole</div>
    </div>

    <div class="form-group">
      <select formControlName="formOfPayment" class="form-control" id="formOfPaymenSelect"  placeholder="Forma úhrady"  name="formOfPaymenSelect" 
      [ngClass]="{ 'is-invalid': invoiceEditForm.get('formOfPayment').errors && invoiceEditForm.get('formOfPayment').touched}" >
      <option value="Bankovní převod">Bankovní převod</option>
      <option value="Hotovost">Hotovost</option>
      <option value="Dobírka">Dobírka</option>
      <option value="Záloha">Záloha</option>
      </select>
      <div class="invalid-feedback">Forma úhrady je povinné pole</div>
    </div>

    <div class="form-group form-inline ml-1">
      <h4 class="mr-2">Zaplaceno : </h4>
      <input type="checkbox" [ngClass]="{'is-invalid': invoiceEditForm.get('paid').errors && invoiceEditForm.get('paid').touched }" class="form-control is-invalid" 
      formControlName="paid" />
    </div>

    <div *ngIf="invoiceEditForm.get('paid').value" class="form-group form-inline ml-1">
      <h4 class="mr-2">Datum zaplacení : </h4>
      <input  type="date" [ngClass]="{'is-invalid': invoiceEditForm.get('paidDate').errors && invoiceEditForm.get('paidDate').touched }" class="form-control is-invalid" 
      formControlName="paidDate" />
    </div>

  </form>
  <table class="table table-hover" style="cursor: pointer">
    <tr>
      <th style="width: 25%">Název</th>
      <th style="width: 25%">Množství</th>
      <th style="width: 25%">Cena</th>
      <th style="width: 25%">Sleva %</th>
    </tr>
    <tr *ngFor="let price of priceList" >
      <td>{{price.name}}</td>
      <td>{{price.order.amount}}</td>
      <td>{{price.order.amount*price.price}}</td>
      <td><input type="number" min="0" max="100" class="form-control" placeholder="Řešitel" (change)="DiscountChange(price)" [(ngModel)]="price.order.discountPercent" name="solver"/></td>
    </tr>

    <tr *ngFor="let hours of hoursToInvoice" >
      <ng-container *ngIf="hours.count !==0">
      <td>{{hours.name}}</td>
      <td>{{hours.count}}</td>
      
      <td *ngIf="hours.code === invoiceConstants.RASCode">{{this.roundTo(hours.count*priceRas)}}</td>
      <td *ngIf="hours.code === invoiceConstants.ONSCode">{{this.roundTo(hours.count*priceOns)}}</td>
      <td *ngIf="hours.code === invoiceConstants.KmCode">{{this.roundTo(hours.count*priceKm)}}</td>
      <td *ngIf="hours.code === invoiceConstants.PCCode">{{this.roundTo(hours.count*pricePC)}}</td>
      <td *ngIf="hours.code === invoiceConstants.PCProCode">{{this.roundTo(hours.count*priceProPC)}}</td>
      <td *ngIf="hours.code === invoiceConstants.ServerCode">{{this.roundTo(hours.count*priceServer)}}</td>
      <td *ngIf="hours.code === invoiceConstants.SQLCode">{{this.roundTo((hours.count/100)*priceSQL)}}</td>
      <td *ngIf="hours.code === invoiceConstants.FileCode">{{this.roundTo((hours.count/100)*priceFiles)}}</td>
      <td *ngIf="hours.code === invoiceConstants.ExchangeCode">{{this.roundTo((hours.count/100)*priceExchange)}}</td>
      <td>0</td>
      </ng-container>
    </tr>
  </table>

  <table class="table table-hover" style="cursor: pointer">
    <tr>
      <th style="width: 25%">Název</th>
      <th style="width: 15%">Množství</th>
      <th style="width: 25%">Cena</th>
      <th style="width: 25%">DPH %</th>
      <th style="width: 10%">Fakturovat</th>
      <!-- <th style="width: 25%">Sleva %</th> -->
    </tr>
    <tr *ngFor="let invItem of invItems" >
      <td>{{invItem.name}}</td>
      <td>{{invItem.amount}}</td>
      <td>{{invItem.amount*invItem.sellingPrice}}</td>
      <td>
        <input (input)="getTotalPrice()" [ngClass]="{'border-danger' : invItem.dphSelling === undefined || invItem.dphSelling === null}"  type="number" min="0" class="form-control" placeholder="DPH sazba"  [(ngModel)]="invItem.dphSelling" />
      </td>
      <td><input (change)="getTotalPrice()" type="checkbox"  placeholder="Řešitel"  [(ngModel)]="invItem.invoicedToClient" name="invoicedToClient" /></td>
      <!-- <td><input type="number" min="0" max="100" class="form-control" placeholder="Řešitel" (change)="DiscountChange(price)" [(ngModel)]="price.order.discountPercent" name="solver"/></td> -->
    </tr>
  </table>
  <hr/>
  <div class="form-inline" >
    <h4 class="mr-3">Celkem</h4>
    
    <h4 *ngIf="totalPrice !== undefined" class="mr-3">{{this.roundTo(totalPrice)}}</h4>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button [disabled]="!invoiceEditForm.valid" type="button" class="btn btn-success" form="priorityEditForm" (click)="editInvoice()">Uložit</button>
</div>