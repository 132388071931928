<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
 

  <form [formGroup]="categoryEditForm" (ngSubmit)="editCategory()">
    <div class="form-group">
      <input type="text" [ngClass]="{'is-invalid': categoryEditForm.get('name').errors && categoryEditForm.get('name').touched }" class="form-control is-invalid" 
      formControlName="name" placeholder="Název firmy" />
      <div class="invalid-feedback">Název firmy je povinné pole</div>
    </div>
    <div class="form-group">
      <select formControlName="companyid" class="form-control" id="solverSelect"  placeholder="Firma"  name="solverSelect" 
      [ngClass]="{ 'is-invalid': categoryEditForm.get('companyid').errors && categoryEditForm.get('companyid').touched}">
      <option value="">Vyberte firmu</option>
      <option *ngFor="let company of companies" [value] = "company.id">
         {{company.companyName}} 
       </option>
      </select>
      <div class="invalid-feedback">Firma je povinné pole</div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button [disabled]="!categoryEditForm.valid" type="button" class="btn btn-success" form="priorityEditForm" (click)="editCategory()">Uložit</button>
</div>