import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Company } from 'src/app/_models/company';
import { User } from 'src/app/_models/user';
import { AuthService } from 'src/app/_services/auth.service';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-userDataDetail-modal',
  templateUrl: './userDataDetail-modal.component.html',
  styleUrls: ['./userDataDetail-modal.component.css']
})
export class UserDataDetailModalComponent implements OnInit {
  user: User;
  registerForm: FormGroup;
  bsConfig: Partial<BsDatepickerConfig>;
  // companyList: {value: number, display: string}[];
  company: Company;
  filterParams: any = {};

  constructor(public bsModalRef: BsModalRef,
    private authService: AuthService,
    private companyService: CompanyDialService,
    private userService: UserService
    ) { 
   
  }

  ngOnInit()
  {
    this.bsConfig = {
      containerClass: 'theme-red'
    },
    this.userService.getUser(this.user.id).subscribe(data=>{
      this.user = data

      this.companyService.getCompanies(1, 1,null,null,false,false).subscribe(x=>{
        this.company = x.result.filter(x=>x.id == this.user.companyId)[0];
      });

     })
      
        

  }
}
