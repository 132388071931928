<div class="modal-header ">
  <h4 class="modal-title pull-left">Změna hesla {{user.userName}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="userEditForm">
    <div class="form-group">
      <input
        type="password"
        [ngClass]="{
          'is-invalid':
            userEditForm.get('newPassword').errors &&
            userEditForm.get('newPassword').touched
        }"
        class="form-control"
        formControlName="newPassword"
        placeholder="Nové heslo"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          userEditForm.get('newPassword').hasError('required') &&
          userEditForm.get('newPassword').touched
        "
      >
        Heslo je povinné pole
      </div>
      <div
        class="invalid-feedback"
        *ngIf="
          userEditForm.get('newPassword').hasError('minlength') &&
          userEditForm.get('newPassword').touched
        "
      >
        Heslo musí mít nejméně 4 znaky
      </div>
      <div
        class="invalid-feedback"
        *ngIf="
          userEditForm.get('newPassword').hasError('maxlength') &&
          userEditForm.get('c').touched
        "
      >
        Heslo nesmí přesáhnout délku 50ti znaků
      </div>
      <div
      class="invalid-feedback"
      *ngIf="
      userEditForm.get('newPassword').hasError('pattern') &&
        userEditForm.get('newPassword').touched
      "
    >
      Heslo musí obsahovat alespoň jedno číslo, jedno malé a velké písmeno
    </div>
    </div>
    
    <div class="form-group">
      <input
        type="password"
        [ngClass]="{
          'is-invalid':
            (userEditForm.get('confirmPassword').errors &&
              userEditForm.get('confirmPassword').touched) ||
            (userEditForm.get('confirmPassword').touched &&
              userEditForm.hasError('mismatch'))
        }"
        class="form-control"
        formControlName="confirmPassword"
        placeholder="Potvrzení hesla"
      />
      <div
        class="invalid-feedback"
        *ngIf="
          userEditForm.get('confirmPassword').hasError('required') &&
          userEditForm.get('confirmPassword').touched
        "
      >
        Heslo je povinné pole
      </div>
      <div
        class="invalid-feedback"
        *ngIf="
          userEditForm.hasError('mismatch') &&
          userEditForm.get('confirmPassword').touched
        "
      >
        Hesla se neschodují
      </div>
    </div>
    
   
    </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Cancel</button>
  <button type="button" class="btn btn-success" form="rolesForm" (click)="CallEvent()">Uložit</button>
</div>