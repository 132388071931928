<form #f="ngForm" (ngSubmit)="f.form.valid && createInvoice()">
  <div class="modal-header">
    <h4>Vytvoření faktury</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label for="forMonth">Měsíc</label>
      <input type="month" class="form-control" [(ngModel)]="invoice.forMonth" id="forMonth" name="forMonth"
             #forMonth="ngModel" required
             (change)="getOrders()" max="{{maxDate | date:'yyyy-MM'}}"/>
    </div>

    <div class="form-group">
      <label for="invoiceNumber">Číslo faktury</label>
      <input type="text" class="form-control" [(ngModel)]="invoice.invoiceNumber" id="invoiceNumber"
             name="invoiceNumber" #invoiceNumber="ngModel"
             placeholder="Číslo faktury" required/>
      <div *ngIf="f.submitted && invoiceNumber.errors?.required" class="text-danger">Číslo faktury je povinné pole</div>
    </div>
    <div class="form-group">
      <label for="dateToPay">Datum splatnosti</label>
      <input type="date" class="form-control" [(ngModel)]="invoice.dateToPay" id="dateToPay"
             name="dateToPay" #dateToPay="ngModel"
             placeholder="Číslo faktury" required/>
      <div *ngIf="f.submitted && dateToPay.errors?.required" class="text-danger">Datum splatnosti je povinné pole</div>
    </div>
    <div class="form-group">
      <label for="duzp">DÚZP</label>
      <input type="date" class="form-control" [(ngModel)]="invoice.duzp" id="duzp"
             name="duzp" #duzp="ngModel"
             placeholder="DÚZP" required/>
      <div *ngIf="f.submitted && duzp.errors?.required" class="text-danger">DÚZP povinné pole</div>
    </div>
    <div class="form-group">
      <label for="constantSymbol">Konstantní symbol</label>
      <input type="text" class="form-control" [(ngModel)]="invoice.constantSymbol" id="constantSymbol"
             name="constantSymbol" #constantSymbol="ngModel"
             placeholder="Konstantní symbol"/>
    </div>
    <div class="form-group">
      <label for="variableSymbol">Variabilní symbol</label>
      <input type="text" class="form-control" [(ngModel)]="invoice.variableSymbol" id="variableSymbol"
             name="variableSymbol" #variableSymbol="ngModel"
             placeholder="Variabilní symbol" required/>
      <div *ngIf="f.submitted && variableSymbol.errors?.required" class="text-danger">Variabilní symbol je povinné
        pole
      </div>
    </div>
    <div class="form-group">
      <label for="formOfPayment">Forma úhrady</label>
      <select class="form-control" id="formOfPayment" name="formOfPayment"
              [(ngModel)]="invoice.formOfPayment" #formOfPayment="ngModel"
              required>
        <option value="Bankovní převod">Bankovní převod</option>
        <option value="Hotovost">Hotovost</option>
        <option value="Dobírka">Dobírka</option>
        <option value="Záloha">Záloha</option>
      </select>
      <div *ngIf="f.submitted && formOfPayment.errors?.required" class="text-danger">Forma úhrady je povinné pole</div>
    </div>

    <div class="form-group form-inline ml-1">
      <input type="checkbox" class="form-control mr-2" id="paid" name="paid" [(ngModel)]="invoice.paid" #paid="ngModel"/>
      <label for="paid">Zaplaceno</label>
    </div>

    <div class="form-group">
      <label for="paidDate">Datum zaplacení</label>
      <input type="date" class="form-control" [(ngModel)]="invoice.paidDate" id="paidDate"
             name="paidDate" #paidDate="ngModel"
             placeholder="Datum zaplacení"/>
    </div>

      <table class="table table-hover" style="cursor: pointer">
        <tr>
          <th style="width: 25%">Název</th>
          <th style="width: 25%">Počet celkem</th>
          <th style="width: 25%">Množství</th>
          <th style="width: 25%">Cena</th>
<!--          <th style="width: 25%">Sleva %</th>-->
        </tr>
        <tr *ngFor="let item of orderItems" >
          <td>{{item.name}}</td>
          <td>{{item.reportCount}}</td>
          <td>{{item.unitCount}}</td>
          <td>{{item.price}},-</td>
<!--          <td><input type="number" min="0" max="100" class="form-control" placeholder="Řešitel" (change)="DiscountChange(price)" [(ngModel)]="price.order.discountPercent" name="solver"/></td>-->
        </tr>
<!--        <tr *ngFor="let hours of hoursToInvoice" >-->
<!--          <ng-container *ngIf="hours.count !==0">-->
<!--          <td>{{hours.name}}</td>-->
<!--          <td>{{hours.count}}</td>-->

<!--          <td *ngIf="hours.code === invoiceConstants.RASCode">{{this.roundTo(hours.count*priceRas)}}</td>-->
<!--          <td *ngIf="hours.code === invoiceConstants.ONSCode">{{this.roundTo(hours.count*priceOns)}}</td>-->
<!--          <td *ngIf="hours.code === invoiceConstants.KmCode">{{this.roundTo(hours.count*priceKm)}}</td>-->
<!--          <td *ngIf="hours.code === invoiceConstants.PCCode">{{this.roundTo(hours.count*pricePC)}}</td>-->
<!--          <td *ngIf="hours.code === invoiceConstants.PCProCode">{{this.roundTo(hours.count*priceProPC)}}</td>-->
<!--          <td *ngIf="hours.code === invoiceConstants.ServerCode">{{this.roundTo(hours.count*priceServer)}}</td>-->
<!--          <td *ngIf="hours.code === invoiceConstants.SQLCode">{{this.roundTo((hours.count/100)*priceSQL)}}</td>-->
<!--          <td *ngIf="hours.code === invoiceConstants.FileCode">{{this.roundTo((hours.count/100)*priceFiles)}}</td>-->
<!--          <td *ngIf="hours.code === invoiceConstants.ExchangeCode">{{this.roundTo((hours.count/100)*priceExchange)}}</td>-->
<!--          <td>0</td>-->
<!--          </ng-container>-->
<!--        </tr>-->
        <!-- <tr *ngFor="let price of priceList" >
          <td>{{price.name}}</td>
          <td>{{price.order.amount}}</td>
          <td>{{price.order.amount*price.price}}</td>
          <td><input type="number" min="0" max="100" class="form-control" placeholder="Řešitel" (change)="DiscountChange(price)" [(ngModel)]="price.order.discountPercent" name="solver"/></td>
        </tr> -->

      </table>

      <table class="table table-hover" style="cursor: pointer">
        <tr>
          <th style="width: 25%">Název</th>
          <th style="width: 15%">Množství</th>
          <th style="width: 25%">Cena</th>
          <th style="width: 25%">DPH %</th>
          <th style="width: 10%">Fakturovat</th>
          <!-- <th style="width: 25%">Sleva %</th> -->
        </tr>
        <tr *ngFor="let invItem of invItems" >
          <td>{{invItem.name}}</td>
          <td>{{invItem.amount}}</td>
          <td>
            <input (input)="getTotalPrice()" [ngClass]="{'border-danger' : invItem.dphSelling === undefined || invItem.dphSelling === null}"  type="number" min="0" class="form-control" placeholder="DPH sazba"  [(ngModel)]="invItem.dphSelling" />
          </td>
          <td>{{invItem.amount*invItem.sellingPrice}}</td>
          <!-- <td><input type="checkbox" class="form-control is-invalid" formControlName="paid" /></td> -->
          <td><input (change)="getTotalPrice()" type="checkbox"  placeholder="Řešitel"  [(ngModel)]="invItem.invoicedToClient" name="invoicedToClient" /></td>
        </tr>
      </table>


    <div class="form-inline">
      <h4 class="mr-3">Celkem</h4>
      <h4 class="mr-3">{{this.roundTo(totalPrice)}},-</h4>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
    <button type="submit" class="btn btn-success">Uložit</button>
  </div>

</form>


<!--<div class="modal-header">-->
<!--  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">-->
<!--    <span aria-hidden="true">&times;</span>-->
<!--  </button>-->
<!--</div>-->
<!--<div class="modal-body">-->

<!--  <form [formGroup]="invoiceAddForm" (ngSubmit)="createInvoice()">-->
<!--    <div class="form-group form-inline ml-1">-->
<!--      <h4 class="mr-2">Měsíc : </h4>-->
<!--      <input (change)="getOrders()" max="{{maxDate | date:'yyyy-MM'}}" type="month" [ngClass]="{'is-invalid': invoiceAddForm.get('forMonth').errors && invoiceAddForm.get('forMonth').touched }" class="form-control is-invalid"-->
<!--      formControlName="forMonth" />-->
<!--    </div>-->

<!--    <div class="form-group">-->
<!--      <input  type="text" [ngClass]="{'is-invalid': invoiceAddForm.get('invoiceNumber').errors && invoiceAddForm.get('invoiceNumber').touched }" class="form-control is-invalid"-->
<!--      formControlName="invoiceNumber" placeholder="Číslo faktury" />-->
<!--      <div class="invalid-feedback">Číslo faktury je povinné pole</div>-->
<!--    </div>-->

<!--    <div class="form-group form-inline ml-1">-->
<!--      <h4 class="mr-2">Datum splatnosti : </h4>-->
<!--      <input  type="date" [ngClass]="{'is-invalid': invoiceAddForm.get('dateToPay').errors && invoiceAddForm.get('dateToPay').touched }" class="form-control is-invalid"-->
<!--      formControlName="dateToPay" />-->
<!--    </div>-->
<!--    <div class="form-group form-inline ml-1">-->
<!--      <h4 class="mr-2">DÚZP : </h4>-->
<!--      <input  type="date" [ngClass]="{'is-invalid': invoiceAddForm.get('duzp').errors && invoiceAddForm.get('duzp').touched }" class="form-control is-invalid"-->
<!--      formControlName="duzp" />-->
<!--    </div>-->

<!--    <div class="form-group">-->
<!--      <input  type="text" [ngClass]="{'is-invalid': invoiceAddForm.get('constantSymbol').errors && invoiceAddForm.get('constantSymbol').touched }" class="form-control is-invalid"-->
<!--      formControlName="constantSymbol" placeholder="Konstantní symbol" />-->
<!--      <div class="invalid-feedback">Konstantní symbol je povinné pole</div>-->
<!--    </div>-->

<!--    <div class="form-group">-->
<!--      <input  type="text" [ngClass]="{'is-invalid': invoiceAddForm.get('variableSymbol').errors && invoiceAddForm.get('variableSymbol').touched }" class="form-control is-invalid"-->
<!--      formControlName="variableSymbol" placeholder="Variabilní symbol" />-->
<!--      <div class="invalid-feedback">Variabilní symbol je povinné pole</div>-->
<!--    </div>-->

<!--    <div class="form-group">-->
<!--      <select formControlName="formOfPayment" class="form-control" id="formOfPaymenSelect"  placeholder="Forma úhrady"  name="formOfPaymenSelect"-->
<!--      [ngClass]="{ 'is-invalid': invoiceAddForm.get('formOfPayment').errors && invoiceAddForm.get('formOfPayment').touched}" >-->
<!--      <option value="Bankovní převod">Bankovní převod</option>-->
<!--      <option value="Hotovost">Hotovost</option>-->
<!--      <option value="Dobírka">Dobírka</option>-->
<!--      <option value="Záloha">Záloha</option>-->
<!--      </select>-->
<!--      <div class="invalid-feedback">Forma úhrady je povinné pole</div>-->
<!--    </div>-->


<!--    <div class="form-group form-inline ml-1">-->
<!--      <h4 class="mr-2">Zaplaceno : </h4>-->
<!--      <input type="checkbox" [ngClass]="{'is-invalid': invoiceAddForm.get('paid').errors && invoiceAddForm.get('paid').touched }" class="form-control is-invalid"-->
<!--      formControlName="paid" />-->
<!--    </div>-->

<!--    <div *ngIf="invoiceAddForm.get('paid').value" class="form-group form-inline ml-1">-->
<!--      <h4 class="mr-2">Datum zaplacení : </h4>-->
<!--      <input  type="date" [ngClass]="{'is-invalid': invoiceAddForm.get('paidDate').errors && invoiceAddForm.get('paidDate').touched }" class="form-control is-invalid"-->
<!--      formControlName="paidDate" />-->
<!--    </div>-->

<!--  </form>-->
<!--  <table class="table table-hover" style="cursor: pointer">-->
<!--    <tr>-->
<!--      <th style="width: 25%">Název</th>-->
<!--      <th style="width: 25%">Množství</th>-->
<!--      <th style="width: 25%">Cena</th>-->
<!--      <th style="width: 25%">Sleva %</th>-->
<!--    </tr>-->
<!--    <tr *ngFor="let price of priceList" >-->
<!--      <td>{{price.name}}</td>-->
<!--      <td>{{price.order.amount}}</td>-->
<!--      <td>{{price.order.amount*price.price}}</td>-->
<!--      <td><input type="number" min="0" max="100" class="form-control" placeholder="Řešitel" (change)="DiscountChange(price)" [(ngModel)]="price.order.discountPercent" name="solver"/></td>-->
<!--    </tr>-->

<!--    <tr *ngFor="let hours of hoursToInvoice" >-->
<!--      <ng-container *ngIf="hours.count !==0">-->
<!--      <td>{{hours.name}}</td>-->
<!--      <td>{{hours.count}}</td>-->

<!--      <td *ngIf="hours.code === invoiceConstants.RASCode">{{this.roundTo(hours.count*priceRas)}}</td>-->
<!--      <td *ngIf="hours.code === invoiceConstants.ONSCode">{{this.roundTo(hours.count*priceOns)}}</td>-->
<!--      <td *ngIf="hours.code === invoiceConstants.KmCode">{{this.roundTo(hours.count*priceKm)}}</td>-->
<!--      <td *ngIf="hours.code === invoiceConstants.PCCode">{{this.roundTo(hours.count*pricePC)}}</td>-->
<!--      <td *ngIf="hours.code === invoiceConstants.PCProCode">{{this.roundTo(hours.count*priceProPC)}}</td>-->
<!--      <td *ngIf="hours.code === invoiceConstants.ServerCode">{{this.roundTo(hours.count*priceServer)}}</td>-->
<!--      <td *ngIf="hours.code === invoiceConstants.SQLCode">{{this.roundTo((hours.count/100)*priceSQL)}}</td>-->
<!--      <td *ngIf="hours.code === invoiceConstants.FileCode">{{this.roundTo((hours.count/100)*priceFiles)}}</td>-->
<!--      <td *ngIf="hours.code === invoiceConstants.ExchangeCode">{{this.roundTo((hours.count/100)*priceExchange)}}</td>-->
<!--      <td>0</td>-->
<!--      </ng-container>-->
<!--    </tr>-->
<!--    &lt;!&ndash; <tr *ngFor="let price of priceList" >-->
<!--      <td>{{price.name}}</td>-->
<!--      <td>{{price.order.amount}}</td>-->
<!--      <td>{{price.order.amount*price.price}}</td>-->
<!--      <td><input type="number" min="0" max="100" class="form-control" placeholder="Řešitel" (change)="DiscountChange(price)" [(ngModel)]="price.order.discountPercent" name="solver"/></td>-->
<!--    </tr> &ndash;&gt;-->

<!--  </table>-->

<!--  <table class="table table-hover" style="cursor: pointer">-->
<!--    <tr>-->
<!--      <th style="width: 25%">Název</th>-->
<!--      <th style="width: 15%">Množství</th>-->
<!--      <th style="width: 25%">Cena</th>-->
<!--      <th style="width: 25%">DPH %</th>-->
<!--      <th style="width: 10%">Fakturovat</th>-->
<!--      &lt;!&ndash; <th style="width: 25%">Sleva %</th> &ndash;&gt;-->
<!--    </tr>-->
<!--    <tr *ngFor="let invItem of invItems" >-->
<!--      <td>{{invItem.name}}</td>-->
<!--      <td>{{invItem.amount}}</td>-->
<!--      <td>-->
<!--        <input (input)="getTotalPrice()" [ngClass]="{'border-danger' : invItem.dphSelling === undefined || invItem.dphSelling === null}"  type="number" min="0" class="form-control" placeholder="DPH sazba"  [(ngModel)]="invItem.dphSelling" />-->
<!--      </td>-->
<!--      <td>{{invItem.amount*invItem.sellingPrice}}</td>-->
<!--      &lt;!&ndash; <td><input type="checkbox" class="form-control is-invalid" formControlName="paid" /></td> &ndash;&gt;-->
<!--      <td><input (change)="getTotalPrice()" type="checkbox"  placeholder="Řešitel"  [(ngModel)]="invItem.invoicedToClient" name="invoicedToClient" /></td>-->
<!--    </tr>-->
<!--  </table>-->
<!--  <hr/>-->
<!--  <div class="form-inline" >-->
<!--    <h4 class="mr-3">Celkem</h4>-->
<!--    <h4 class="mr-3">{{this.roundTo(totalPrice)}}</h4>-->
<!--  </div>-->
<!--</div>-->
<!--<div class="modal-footer">-->
<!--  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>-->
<!--  <button [disabled]="!(invoiceAddForm.valid && (priceList != undefined || invItems != undefined))" type="button" class="btn btn-success" form="invoiceAddForm" (click)="createInvoice()">Uložit</button>-->
<!--</div>-->
