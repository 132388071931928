<div *ngIf="companies != undefined && companies.length > 0" class="m-5">
  <h2>Objednané služby</h2>
  <form *appHasRole="['CompanyFilter']" class="form-inline" #form ="ngForm" (ngSubmit)="loadPriceList()" novalidate>
    <div class="form-group px-2">
      <label for="company">Firma: </label>
      <select class="form-control ml-1" style="width: 130px" id="companyId" [(ngModel)]="filterParams.companyId" name="companyId">
        <option *ngFor="let company of companies" [value] = "company.id">
          {{company.companyName}}
        </option>
      </select>
    </div>
    <div class="form-group ">
      <label for="month">Za měsíc: </label>
      <input class="ml-1" type="month" [(ngModel)]="filterParams.month" name="month">
    </div>
    <button type="submit" class="btn btn-primary" style="margin-left:10px" (click)="filter()">Filtrovat</button>
    <button type="button" class="btn btn-info" style="margin-left:10px" (click)="resetFilters()" >Reset Filtrů</button>
    <div *appHasRole="['Invoice']">
      <button   *ngIf="!monthInvoiced" class="btn btn-primary" (click)="createModal()" style="margin-left: auto;">Fakturovat</button>
    </div>
    <h3   *ngIf="monthInvoiced"  (click)="createModal()" style="margin-left: auto;">Již bylo fakturováno</h3>

  </form>
  <br> 
  <div *appHasRole="['OrderEdit']" class="form-inline mt-5">
      <div *ngIf="inputChanged && !monthInvoiced" class="form-inline ml-auto" style="float: right;" >
        <h4 class="mr-3">Pro objednání potvrďte</h4>
        <button  class="btn btn-primary" (click)="Order()">Objednat</button>
      </div>
  </div>
  <div class="m-3">
    <div class="row" >
      <table class="table table-hover" style="cursor: pointer">
        
        <tr>
          <th style="width: 20%">Název</th>
          <th style="width: 10%">Cena</th>
          <th style="width: 10%">Cena (s DPH)</th>
          <th style="width: 10%">Typ</th>
          <th style="width: 20%">Množství</th>
          <th style="width: 20%"></th>
          <th style="width: 8%">Cena za mj</th>

        </tr>
        <tr *ngFor="let priceListItem of priceList" >
          <td>{{priceListItem.name}}</td>
          <!-- <td>{{priceListItem.companyName}}</td> -->
          <td>{{priceListItem.price}}</td>
          <td>{{priceListItem.price*1.21}}</td>
          <td *ngIf="priceListItem.isMonthly">Měsíční</td>
          <td *ngIf="!priceListItem.isMonthly">Jednorázové</td>
          <td>
            <div *ngIf="!isTodaysMonth || priceListItem.name.includes('Vykázané hodiny') ||monthInvoiced" class="form-group">
              <input disabled type="number" class="form-control" placeholder="Řešitel" (change)="inputChange()" [(ngModel)]="priceListItem.order.amount" name="solver"/>
            </div>
            <div *ngIf="isTodaysMonth && !priceListItem.name.includes('Vykázané hodiny') && !monthInvoiced" class="form-group">
              <input type="number" class="form-control" placeholder="Řešitel" (change)="inputChange()" [(ngModel)]="priceListItem.order.amount" name="solver"/>
            </div>
          </td>
          <!-- <td *ngIf="priceListItem.order === undefined">0</td> -->
          <!-- <td *ngIf="priceListItem.order !== undefined">{{priceListItem.order.amount}}</td> -->
          <td>
            <div class="form-inline">
              <div *ngIf="isTodaysMonth && !priceListItem.name.includes('hodiny') && !priceListItem.name.includes('km') && !monthInvoiced">
                <button  *appHasRole="['OrderEdit']" class="btn btn-info" (click)="$event.stopPropagation()" (click)="plusOne(priceListItem)" >+</button>
                <button  *appHasRole="['OrderEdit']" class="btn btn-danger" (click)="$event.stopPropagation()" (click)="minusOne(priceListItem)">-</button>
              </div>
            </div>
          </td>
          <td>{{priceListItem.price * priceListItem.order.amount}}</td>
        </tr>
      </table>
      <hr/>
          <div class="form-inline ml-auto" style="float: right;" >
            <h4 class="mr-3">Celkem</h4>
            <h4 class="mr-3">{{priceForAll}}</h4>
          </div>
    </div>

  </div>
</div>
  <div *ngIf="companies != undefined && companies.length < 1" class="m-5">
  <div class="container ">
    <div style="text-align: center">
      <h1>Zatím nejsou přidané žádné firmy</h1>
    </div>
  </div>
</div>
<!-- <div class="d-flex justify-content-center">
    <pagination [boundaryLinks]="true" 
                [totalItems]="pagination.totalItems"
                [itemsPerPage]="pagination.itemsPerPage"
                [(ngModel)]="pagination.currentPage"
                (pageChanged)="pageChanged($event)"
              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
  </pagination>
  </div> -->