<div class="modal-header dontPrint">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <!-- dataToPrint -->
  <!--  <div id="pdfTable" #pdfTable class=" pl-4 pr-4 mt-2" >-->
  <!--    <div class="row">-->

  <!--    </div>-->
  <div class="container">
    <div class="row">
      <div class="col">
        <h5 class="pull-left">Měsíční report</h5>
        <h6 class="pull-right"><strong> {{monthlyReport?.month}}/{{monthlyReport?.year}}</strong></h6>
      </div>
    </div>
<!--    <div class="row">-->
<!--      <div class="col">-->
<!--        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">-->
<!--          <li class="nav-item" role="presentation">-->
<!--            <button class="nav-link" [ngClass]="selectedTab == 'servers' ? 'active' : ''" (click)="selectTab('servers')"-->
<!--                    id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab"-->
<!--                    aria-controls="pills-home" aria-selected="true">-->
<!--              Servery-->
<!--            </button>-->
<!--          </li>-->
<!--          <li class="nav-item" role="presentation">-->
<!--            <button class="nav-link" [ngClass]="selectedTab == 'pc' ? 'active' : ''" (click)="selectTab('pc')"-->
<!--                    id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button"-->
<!--                    role="tab" aria-controls="pills-profile" aria-selected="false">-->
<!--              PC-->
<!--            </button>-->
<!--          </li>-->
<!--          <li class="nav-item" role="presentation">-->
<!--            <button class="nav-link" [ngClass]="selectedTab == 'mailboxes' ? 'active' : ''"-->
<!--                    (click)="selectTab('mailboxes')" id="pills-contact-tab" data-bs-toggle="pill"-->
<!--                    data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact"-->
<!--                    aria-selected="false">-->
<!--              E-mailvé schránky-->
<!--            </button>-->
<!--          </li>-->
<!--          <li class="nav-item" role="presentation">-->
<!--            <button class="nav-link" [ngClass]="selectedTab == 'sql' ? 'active' : ''" (click)="selectTab('sql')"-->
<!--                    id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button"-->
<!--                    role="tab" aria-controls="pills-contact" aria-selected="false">-->
<!--              SQL databáze-->
<!--            </button>-->
<!--          </li>-->
<!--          <li class="nav-item" role="presentation">-->
<!--            <button class="nav-link" [ngClass]="selectedTab == 'fileReport' ? 'active' : ''"-->
<!--                    (click)="selectTab('fileReport')" id="pills-contact-tab" data-bs-toggle="pill"-->
<!--                    data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact"-->
<!--                    aria-selected="false">-->
<!--              Report souborů-->
<!--            </button>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
<!--    </div>-->


    <div class="row w-100" *ngIf="monthlyReport != null">
      <tabset #ticketTabs class="member-tabset w-100">
        <tab heading="Servery">
          <div class="container">
            <table class="table table-hover mb-0" style="cursor: pointer;">
              <thead>
              <tr>
                <td style="width: 30%"><strong>{{monthlyReport.companyName}}</strong></td>
                <td style="width: 30%"></td>
                <td style="width: 30%"></td>
                <td style="width: 10%"><strong>Celkem: {{ServerCount}}</strong></td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td style="width: 30%">Doména</td>
                <td style="width: 30%">Server</td>
                <td style="width: 30%">OS</td>
                <td style="width: 10%">Počet</td>
              </tr>
              <tr *ngFor="let entity of monthlyServerReportEntities">
                <td style="width: 30%">{{entity?.domain}}</td>
                <td style="width: 30%">{{entity?.name}}</td>
                <td style="width: 30%">{{entity?.operatingSystem}}</td>
                <td style="width: 10%">{{entity?.count}}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </tab>
        <tab heading="PC">
          <div class="container">
            <table class="table table-hover mb-0" style="cursor: pointer;">
              <thead>
              <tr>
                <td style="width: 30%"><strong>{{monthlyReport.companyName}}</strong></td>
                <td style="width: 30%"></td>
                <td style="width: 30%"></td>
                <td style="width: 10%"><strong>Celkem: {{PCCount}}</strong></td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td style="width: 30%">Doména</td>
                <td style="width: 30%">PC</td>
                <td style="width: 30%">OS</td>
                <td style="width: 10%">Počet</td>
              </tr>
              <tr *ngFor="let entity of monthlyPCReportEntities">
                <td style="width: 30%">{{entity?.domain}}</td>
                <td style="width: 30%">{{entity?.name}}</td>
                <td style="width: 30%">{{entity?.operatingSystem}}</td>
                <td style="width: 10%">{{entity?.count}}</td>
              </tr>
              </tbody>
            </table>

          </div>
        </tab>
        <tab heading="E-mailové schránky">
          <div class="container">
            <ng-container *ngFor="let domain of mailboxes">
              {{domain.domain}}
              <table class="table table-hover mb-0" style="cursor: pointer;">
                <thead>
                <tr>
                  <td style="width: 30%"><strong>Doména</strong></td>
                  <td style="width: 50%"><strong>PrimarySMTP Adress</strong></td>
                  <td style="width: 20%"><strong>Velikost schránky (MB)</strong></td>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let mailbox of domain.mailboxes">
                  <td style="width: 30%">{{mailbox.domain}}</td>
                  <td style="width: 50%">{{mailbox.primarySmtpAddress}}</td>
                  <td style="width: 20%">{{mailbox.mailboxSizeMB}}</td>
                </tr>
                </tbody>
              </table>
            </ng-container>
          </div>
        </tab>
        <tab heading="SQL databáze">
          <div class="container">
            <ng-container *ngFor="let domain of sqlItems">
              {{domain.domain}}
              <table class="table table-hover mb-0" style="cursor: pointer;">
                <thead>
                <tr>
                  <td style="width: 30%"><strong>Instance</strong></td>
                  <td style="width: 50%"><strong>Jméno databáze</strong></td>
                  <td style="width: 20%"><strong>Velikost dat (MB)</strong></td>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let sqlItem of domain.sqlInstances">
                  <td style="width: 30%">{{sqlItem.sqlInstance}}</td>
                  <td style="width: 50%">{{sqlItem.databaseName}}</td>
                  <td style="width: 20%">{{sqlItem.sizeMB}}</td>
                </tr>
                </tbody>
              </table>
            </ng-container>
          </div>
        </tab>
        <tab heading="Report souborů">
          <div class="container">
            <ng-container *ngFor="let domain of fileReportItems">
              {{domain.domain}}
              <table class="table table-hover mb-0" style="cursor: pointer;">
                <thead>
                <tr>
                  <td style="width: 30%"><strong>Instance</strong></td>
                  <td style="width: 50%"><strong>Jméno</strong></td>
                  <td style="width: 20%"><strong>Velikost dat (MB)</strong></td>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let fileItem of domain.fileReportItems">
                  <td style="width: 30%">{{fileItem.instance}}</td>
                  <td style="width: 50%">{{fileItem.folderName}}</td>
                  <td style="width: 20%">{{fileItem.sizeMB}}</td>
                </tr>
                </tbody>
              </table>
            </ng-container>
          </div>
        </tab>
      </tabset>
    </div>

<!--    <div class="row">-->
<!--      <div class="col">-->
<!--        <ng-container [ngSwitch]="selectedTab">-->
<!--          <ng-container *ngSwitchCase="'servers'">-->
<!--            <table class="table table-hover mb-0" style="cursor: pointer;">-->
<!--              <thead>-->
<!--              <tr>-->
<!--                <td style="width: 40%"><strong>{{monthlyReport.companyName}}</strong></td>-->
<!--                <td style="width: 40%"><strong>Celkem</strong></td>-->
<!--                <td style="width: 20%"><strong>{{ServerCount}}</strong></td>-->
<!--              </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <tr>-->
<!--                <td style="width: 40%">Doména</td>-->
<!--                <td style="width: 40%">Server</td>-->
<!--                <td style="width: 20%">Počet</td>-->
<!--              </tr>-->
<!--              <tr *ngFor="let entity of monthlyServerReportEntities">-->
<!--                <td style="width: 40%">{{entity?.domain}}</td>-->
<!--                <td style="width: 40%">{{entity?.name}}</td>-->
<!--                <td style="width: 20%">{{entity?.count}}</td>-->
<!--              </tr>-->
<!--              </tbody>-->
<!--            </table>-->
<!--          </ng-container>-->
<!--          <ng-container *ngSwitchCase="'pc'">-->
<!--            <table class="table table-hover mb-0" style="cursor: pointer;">-->
<!--              <thead>-->
<!--              <tr>-->
<!--                <td style="width: 40%"><strong>{{monthlyReport.companyName}}</strong></td>-->
<!--                <td style="width: 40%"><strong>Celkem</strong></td>-->
<!--                <td style="width: 20%"><strong>{{PCCount}}</strong></td>-->
<!--              </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <tr>-->
<!--                <td style="width: 40%">Doména</td>-->
<!--                <td style="width: 40%">PC</td>-->
<!--                <td style="width: 20%">Počet</td>-->
<!--              </tr>-->
<!--              <tr *ngFor="let entity of monthlyPCReportEntities">-->
<!--                <td style="width: 40%">{{entity?.domain}}</td>-->
<!--                <td style="width: 40%">{{entity?.name}}</td>-->
<!--                <td style="width: 20%">{{entity?.count}}</td>-->
<!--              </tr>-->
<!--              </tbody>-->
<!--            </table>-->
<!--          </ng-container>-->
<!--          <ng-container *ngSwitchCase="'mailboxes'">-->
<!--            <table class="table table-hover mb-0" style="cursor: pointer;">-->
<!--              <thead>-->
<!--              <tr>-->
<!--                <td style="width: 30%"><strong>Doména</strong></td>-->
<!--                <td style="width: 50%"><strong>PrimarySMTP Adress</strong></td>-->
<!--                <td style="width: 20%"><strong>Velikost schránky (MB)</strong></td>-->
<!--              </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <tr *ngFor="let mailbox of mailboxes">-->
<!--                <td style="width: 30%">{{mailbox.domain}}</td>-->
<!--                <td style="width: 50%">{{mailbox.primarySmtpAddress}}</td>-->
<!--                <td style="width: 20%">{{mailbox.mailboxSizeMB}}</td>-->
<!--              </tr>-->
<!--              </tbody>-->
<!--            </table>-->
<!--          </ng-container>-->
<!--          <ng-container *ngSwitchCase="'sql'">-->
<!--            <table class="table table-hover mb-0" style="cursor: pointer;">-->
<!--              <thead>-->
<!--              <tr>-->
<!--                <td style="width: 30%"><strong>Instance</strong></td>-->
<!--                <td style="width: 50%"><strong>Jméno databáze</strong></td>-->
<!--                <td style="width: 20%"><strong>Velikost dat (MB)</strong></td>-->
<!--              </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <tr *ngFor="let sqlItem of sqlItems">-->
<!--                <td style="width: 30%">{{sqlItem.sqlInstance}}</td>-->
<!--                <td style="width: 50%">{{sqlItem.databaseName}}</td>-->
<!--                <td style="width: 20%">{{sqlItem.sizeMB}}</td>-->
<!--              </tr>-->
<!--              </tbody>-->
<!--            </table>-->
<!--          </ng-container>-->

<!--          <ng-container *ngSwitchCase="'fileReport'">-->
<!--            <table class="table table-hover mb-0" style="cursor: pointer;">-->
<!--              <thead>-->
<!--              <tr>-->
<!--                <td style="width: 30%"><strong>Instance</strong></td>-->
<!--                <td style="width: 50%"><strong>Jméno</strong></td>-->
<!--                <td style="width: 20%"><strong>Velikost dat (MB)</strong></td>-->
<!--              </tr>-->
<!--              </thead>-->
<!--              <tbody>-->
<!--              <tr *ngFor="let fileItem of fileReportItems">-->
<!--                <td style="width: 30%">{{fileItem.instance}}</td>-->
<!--                <td style="width: 50%">{{fileItem.folderName}}</td>-->
<!--                <td style="width: 20%">{{fileItem.sizeMB}}</td>-->
<!--              </tr>-->
<!--              </tbody>-->
<!--            </table>-->
<!--          </ng-container>-->
<!--        </ng-container>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</div>
<div class="modal-footer dontPrint">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button type="button" class="btn btn-primary" (click)="downloadAsPDF()">Tisk</button>
</div>
