import {DatePipe} from '@angular/common';
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Company} from 'src/app/_models/company';
import {ItemOrder} from 'src/app/_models/itemOrder';
import {OrderedItem} from 'src/app/_models/orderedItem';
import {AlertifyService} from 'src/app/_services/alertify.service';
import {AuthService} from 'src/app/_services/auth.service';
import {CompanyDialService} from 'src/app/_services/companyDial.service';
import {isNullOrEmpty} from "../../core/_helpers/common-helpers";

@Component({
  selector: 'app-issue-create-modal',
  templateUrl: './issueCreate-modal.component.html'
})
export class IssueCreateModalComponent implements OnInit {
  @Output() createItemOrder = new EventEmitter();
  itemOrder: ItemOrder;
  selectedOrderItem: OrderedItem;
  companies: Company[];
  companiesClient: Company[];
  itemsValid: boolean = true;

  companyId: string;

  // orderedItems: OrderedItem[] = [];

  constructor(public bsModalRef: BsModalRef,
              private fb: FormBuilder,
              private companyService: CompanyDialService,
              private authService: AuthService,
              private alertify: AlertifyService,
              private datePipe: DatePipe) {
  }

  ngOnInit() {
    this.selectedOrderItem = new OrderedItem();
    this.itemOrder = new ItemOrder();

    const date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

    this.itemOrder.dateOfOrder = new Date(date);
    this.itemOrder.createdById = this.authService.decodedToken.nameid;
    this.itemOrder.orderState = 'new issue';
    this.itemOrder.deleted = false;
    this.itemOrder.supplierId = 0;
    this.itemOrder.orderedItems = [];

    if (this.authService.decodedToken.IsPrimary !== 'True') {
      this.itemOrder.supplierId = this.companies.filter(x => x.isPrimary)[0].id;
    }

    // this.createForm();
    this.companyService.getCompanies(1, 1).subscribe(data => {
      this.companies = data.result;
      if (this.companyId !== '0') {
        this.companies = data.result.filter(x => x.id.toString() === this.companyId.toString());
      } else {
        this.companies = data.result;
      }
      this.companiesClient = this.companies.filter(x => x.client == true || x.isPrimary == true);
      this.companies = this.companies.filter(x => x.supplier == true);
      // this.addEmptyInvoiceItem();
    });
  }


  // tslint:disable-next-line: typedef
  // createForm() {
  //   this.itemOrderAddForm = this.fb.group({
  //     dateOfOrder: [this.datePipe.transform(new Date(), 'yyyy-MM-dd'), Validators.required],
  //
  //     createdById: [this.authService.decodedToken.nameid, Validators.required],
  //     supplierId: [0],
  //     customerId: ['', Validators.required],
  //     orderState: ['new issue'],
  //     deleted: [false],
  //
  //   });
  //   if (this.authService.decodedToken.IsPrimary !== 'True') {
  //     this.itemOrderAddForm.patchValue({supplierId: this.companies.filter(x => x.isPrimary)[0].id})
  //   }
  //
  // }

  createOrder() {
    console.log(this.itemOrder);
    // this.createItemOrder.emit(this.itemOrder);
    // this.bsModalRef.hide();
  }

  addOrderItem(selectedOrderItem: OrderedItem) {
    if (isNullOrEmpty(selectedOrderItem.name) || selectedOrderItem.amount === 0 || selectedOrderItem.price === 0) {
      this.alertify.error('Musíte vyplnit všechny položky');
      return;
    }

    this.itemOrder.orderedItems.push(selectedOrderItem);
    this.checkInvItems();

    this.selectedOrderItem = new OrderedItem();

    // const emptyInvItem = new OrderedItem();
    // emptyInvItem.amount = 0;
    // emptyInvItem.name = '';
    // emptyInvItem.price = 0;
    // emptyInvItem.itemOrderId = 0;
    // emptyInvItem.deleted = false;
    // // emptyInvItem.dynamicId = this.itemOrder.orderedItems.reduce((op, item) => op = op > item.dynamicId ? op : item.dynamicId, 0) + 1;
    // this.itemOrder.orderedItems.push(emptyInvItem);

  }

  removeInvoiceItem(index: number) {
    this.itemOrder.orderedItems.splice(index, 1);
    this.checkInvItems();
  }

  checkInvItems() {
    if (this.itemOrder.orderedItems.length <= 0) {
      this.itemsValid = true;
      return;
    }

    const error = this.itemOrder.orderedItems.filter(x =>
        x.amount === 0 || x.amount === null ||
        x.price === undefined || x.price === null ||
        x.name === undefined || x.name === '' || x.name === null
      //  x.flag === undefined || x.flag ===''
    ).length > 0;

    this.itemsValid = !error;
  }


  setCompanyToModel($event: number) {
    this.itemOrder.companyId = $event;
  }
}
