import {NgModule} from '@angular/core';

import {CommonAggregatePipesModule} from './aggregate/aggregate.module';
import {CommonArrayPipesModule} from './array/array.module';
import {CommonBooleanPipesModule} from './boolean/boolean.module';
import {CommonMathPipesModule} from './math/math.module';
import {CommonObjectPipesModule} from './object/object.module';
import {CommonStringPipesModule} from './string/string.module';

@NgModule({
  exports: [
    CommonArrayPipesModule,
    CommonMathPipesModule,
    CommonBooleanPipesModule,
    CommonStringPipesModule,
    CommonObjectPipesModule,
    CommonAggregatePipesModule,
  ],
})
export class CommonPipesModule {
}
