import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from 'src/app/_models/company';
import { User } from 'src/app/_models/user';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-userEdit',
  templateUrl: './userEdit.component.html',
  styleUrls: ['./userEdit.component.scss']
})
export class UserEditComponent implements OnInit {
  user: User;
  userEditForm: FormGroup;
  // companyList: {value: number, display: string}[];
  companies: Company[];
  constructor(private authService: AuthService,
    private alertify: AlertifyService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private companyDialService: CompanyDialService,
    private userService: UserService) { }

  ngOnInit() {
    this.createUserEditForm();
      // this.route.data.subscribe((data) => {
      //   this.companies = data['companies'].result;
      //     this.companies = this.companies;
      // });
  }
  createUserEditForm(){
    this.userEditForm = this.fb .group({
      // companyId: [this.authService.decodedToken.CompanyId],
      oldPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(50),Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{4,50}$/)]],
      confirmPassword: ['', Validators.required]
    }, {validator: this.passwordMatchValidator});
  }

  passwordMatchValidator(g: FormGroup)
  {
    return g.get('newPassword').value === g.get('confirmPassword').value ? null : { mismatch: true };
  }

  edit() {
    if (this.userEditForm.valid)
    {
      let userForEdit:any = {userName: this.authService.decodedToken?.unique_name, oldPassword:this.userEditForm.value.oldPassword,newPassword:this.userEditForm.value.newPassword,companyId:this.userEditForm.value.companyId};
      this.authService.editPasswordByUser(userForEdit).subscribe(
        (next) => {
          this.alertify.success('Upraveno');
          this.router.navigate(['/userEdit']);
          this.createUserEditForm();

        }, error => {
         this.alertify.error(error);
        },
        () => {
          this.router.navigate(['/userEdit']);
        }
      );
    }
  }

  // setNewCompany()
  // {
  //   let user:User;
  //   this.userService.getUser(this.authService.decodedToken.nameid).subscribe(x => {
  //     user = x;
  //     user.companyId = this.userEditForm.value.companyId;
  //     this.userService.updateUser(user.id, user).subscribe(x=>{});
  //   })
    
  // }
}
