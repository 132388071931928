import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Category } from 'src/app/_models/category';
import { Priority } from 'src/app/_models/priority';
import { Ticket } from 'src/app/_models/ticket';
import { User } from 'src/app/_models/user';
import { AuthService } from 'src/app/_services/auth.service';
import { CategoryService } from 'src/app/_services/category.service';
import { PriorityService } from 'src/app/_services/priority.service';
import { TicketService } from 'src/app/_services/ticket.service';
import { UserService } from 'src/app/_services/user.service';


@Component({
  selector: 'app-ticketEdit-modal',
  templateUrl: './ticketEdit-modal.component.html',
  styleUrls: ['./ticketEdit-modal.component.scss']
})
export class TicketEditModalComponent implements OnInit {
  @Output() editTicket = new EventEmitter();
  ticket: Ticket;
  ticketEditForm: FormGroup;
  coreUsers: User[];
  priorities: Priority[];
  categories: Category[];
  months:string[]=['Leden','Únor','Březen','Duben','Květen','Červen','Červenec','Srpen','Září','Říjen','Listopad','Prosinec'];
  days:string[]=['Po','Út','St','Čt','Pá','So','Ne'];
  order:string[]=['První','Druhý','Třetí','Čtvrtý'];


  
  constructor(public bsModalRef: BsModalRef,
              private fb: FormBuilder,
              private userService: UserService,
              private priorityServicve: PriorityService,
              private authService: AuthService,
              private categoryService: CategoryService,
              private datePipe:DatePipe
              ) {}

  ngOnInit() {
    this.userService.getCoreUsers().subscribe((data) => {this.coreUsers = data; });
    this.categoryService.getCategories(1, 1, { companyId: this.authService.decodedToken.CompanyId} ).subscribe((data) => {this.categories = data.result; });
    this.priorityServicve.getPriorities(1, 1, { companyId: this.authService.decodedToken.CompanyId} ).subscribe((data) => {this.priorities = data.result; });
    this.createForm();
  }


  createForm(){
    let solver;
    if(this.ticket.solvers !== undefined && this.ticket.solvers.length>0)
    {
      solver = this.ticket.solvers[0].userId;
    }
    else
    {
      solver=0
    }
      this.ticketEditForm = this.fb .group({
      title: [this.ticket.title, Validators.required],
      description: [this.ticket.description, Validators.required],
      priorityId: [this.ticket.priorityId, Validators.required],
      categoryId: [this.ticket.categoryId, Validators.required],
      solverId: [solver.toString(), Validators.required],
      // issuerCompanyId: [this.ticket.issuerCompanyId, Validators.required],
      issuerId: [this.ticket.issuerId, Validators.required],
      repeat:[true],
      dayCountForRepeat:[this.ticket.dayCountForRepeat],
      status:[this.ticket.status],
      lastDateOfRepeat:[this.datePipe.transform(this.ticket.lastDateOfRepeat, 'yyyy-MM-dd'), Validators.required],
      parentTicketId:[this.ticket.parentTicketId],

      weekNo:[this.ticket.weekNo],
      daysArray:[this.ticket.daysArray],
      monthArray:[this.ticket.monthArray],
      daysNoArray:[this.ticket.daysNoArray],
      // dayNo:[this.ticket.dayNo],
      repeatType:[this.ticket.repeatType],
      monthRepeatType:[this.ticket.monthRepeatType],
      daysArray2:[this.ticket.daysArray2],
      dayNoArray:[this.ticket.dayNoArray],
    });
  }

  editTicketFce()
  {
    if (this.ticketEditForm.valid)
    {
      this.ticket = Object.assign({}, this.ticketEditForm.value);
      this.editTicket.emit(this.ticket);
      this.bsModalRef.hide();
    }
  }

  // SetdaysArray(day)
  // {
  //   var days = this.ticketEditForm.get('daysArray').value;
  //   if(days.includes(day+','))
  //   {
  //     days= days.replace(day+',', ""); 
  //   }
  //   else
  //   {
  //     days = days +day+','
  //   }
  //   this.ticketEditForm.patchValue({daysArray:days});
  //   console.log(this.ticketEditForm.get('daysArray').value)
  // }
  // SetdaysArrayCheck(day)
  // {
  //   if(this.ticketEditForm.get('daysArray').value === null)
  //   {
  //     this.ticketEditForm.patchValue({daysArray:''});
  //   }
  //   return this.ticketEditForm.get('daysArray').value.includes(day+',');

  // }

  SetArray(value,prop:string)
  {
   
    var values = this.ticketEditForm.get(prop).value;
    if(values.includes(value+','))
    {
      values= values.replace(value+',', ""); 
    }
    else
    {
      values = values +value+','
    }
    // this.ticketEditForm.patchValue({prop:values});
    if(prop==='daysArray')
      {
        this.ticketEditForm.patchValue({daysArray:values});
      }
      if(prop==='monthArray')
      {
        this.ticketEditForm.patchValue({monthArray:values});
      }
      if(prop==='daysNoArray')
      {
        this.ticketEditForm.patchValue({daysNoArray:values});
      }
      if(prop==='daysArray2')
      {
        this.ticketEditForm.patchValue({daysArray2:values});
      }
      if(prop==='dayNoArray')
      {
        this.ticketEditForm.patchValue({dayNoArray:values});
      }
  }
  SetCheck(value,prop)
  {
    if(this.ticketEditForm.get(prop).value === null)
    {
      if(prop==='daysArray')
      {
        this.ticketEditForm.patchValue({daysArray:''});
      }
      if(prop==='monthArray')
      {
        this.ticketEditForm.patchValue({monthArray:''});
      }
      if(prop==='daysNoArray')
      {
        this.ticketEditForm.patchValue({daysNoArray:''});
      }
      if(prop==='daysArray2')
      {
        this.ticketEditForm.patchValue({daysArray2:''});
      }
      if(prop==='dayNoArray')
      {
        this.ticketEditForm.patchValue({dayNoArray:''});
      }
      
    }
    return this.ticketEditForm.get(prop).value.includes(value+',');

  }

  
}
