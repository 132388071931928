import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Company } from 'src/app/_models/company';
import { WorkReport } from 'src/app/_models/workReport';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { AuthService } from 'src/app/_services/auth.service';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/_services/user.service';
import { User } from 'src/app/_models/user';
@Component({
  selector: 'app-workreportedit-modal',
  templateUrl: './workreportedit-modal.component.html',
  styleUrls: ['./workreportedit-modal.component.css']
})
export class WorkReportEditModalComponent implements OnInit {
  @Output() editworkreport = new EventEmitter();
  workreport: WorkReport;
  workreportEditForm: FormGroup;
  companies: Company[];
  hoursEditabel = true;
  users: User[];

  constructor(
    public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private companyService: CompanyDialService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private userService: UserService) {}

  ngOnInit() {
    this.createForm();
    this.userService.getCoreUsers().subscribe((data) => {this.users = data;});
    this.companyService.getCompanies(1, 1,null,null,false,true).subscribe(data =>
    {
      this.companies = data.result;
      if (this.authService.decodedToken.IsPrimary !== 'True')
      {
        this.companies = this.companies.filter(x => x.id.toString() === this.authService.decodedToken.CompanyId);
      }
    });
  }


  createForm(){
    this.workreportEditForm = this.fb .group({
      title: [this.workreport.title, Validators.required],
      description: [this.workreport.description],
      internalNote: [this.workreport.internalNote],
      companyId: [this.workreport.companyId, Validators.required],
      transport: [this.workreport.transport],
      onlyTransport: [this.workreport.onlyTransport],
      transportKm: [this.workreport.transportKm],
      hours: [this.workreport.hours, Validators.required],
      ticketId: [this.workreport.ticketId],
      userId: [this.workreport.userId],
      created: [this.workreport.created],
      forDay: [this.datePipe.transform(this.workreport.forDay, 'yyyy-MM-dd'), Validators.required],
      workFrom: [ new Date(this.workreport.workFrom)],
      workTo: [ new Date(this.workreport.workTo)],
      supportType: [this.workreport.supportType, Validators.required],
      deleted: [this.workreport.deleted, Validators.required],
      createdByUserId:[this.authService.decodedToken.nameid],
      checked:[this.workreport.checked],
      updatedByUser: [''],
      updatedByUserId: [''],
      lastUpdate: Date [''],
    });


  }

  editWorkReport()
  {
    if (this.workreportEditForm.valid)
    {
      if (!this.workreport.transport)
      {
        this.workreport.transportKm = 0;
      }

      // this.workreportEditForm.patchValue({createdByUserId: this.authService.decodedToken.nameid});
      this.workreport = Object.assign({}, this.workreportEditForm.value);
      this.editworkreport.emit(this.workreport);
      this.bsModalRef.hide();
    }
  }

  setHours()
  {
    if(this.workreportEditForm.value.workFrom != '' && this.workreportEditForm.value.workTo != '')
    {
      let minutesX = this.workreportEditForm.value.workTo.getHours() *60;
      let minutesY = this.workreportEditForm.value.workFrom.getHours() *60;
      minutesX =minutesX+ this.workreportEditForm.value.workTo.getMinutes();
      minutesY =minutesY+ this.workreportEditForm.value.workFrom.getMinutes();


      var res:number = Math.floor((minutesX - minutesY)/60);
      var remainder = (minutesX - minutesY) % 60;
      if(remainder >0 && remainder<=15)
      {
        res = res +0.25;
      }
      if(remainder >15 && remainder<=30)
      {
        res = res +0.5;
      }
      if(remainder >30 && remainder<=45)
      {
        res = res +0.75;
      }
      if(remainder >45)
      {
        res = res +1;
      }
        if(res >= 0)
        {
          this.workreportEditForm.patchValue({hours: res})
        }
        this.hoursEditabel = false;
    }
  }
}
