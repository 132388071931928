import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {animate, style, transition, trigger} from '@angular/animations';
import {isArray} from '../../../pipes/_common/utils/utils';
import {isNullOrUndefined} from '../../../_helpers/common-helpers';
import {WherePipe} from '../../../pipes/_common/array/where.pipe';


@Component({
  selector: 'app-select3-menu',
  templateUrl: './select3-menu.component.html',
  providers: [WherePipe],
  animations: [
    trigger('overlayAnimation', [
      transition(':enter', [
        style({opacity: 0, transform: 'scaleY(0.8)'}),
        animate('{{showTransitionParams}}')
      ]),
      transition(':leave', [
        animate('{{hideTransitionParams}}', style({opacity: 0}))
      ])
    ])
  ]
})
export class Select3MenuComponent implements OnInit, AfterViewInit {

  public id: string;
  public items: any;
  public multiple: boolean;
  public autoClose: boolean | 'outside' | 'inside';
  public primaryKey: string;
  public selectedItem: any | Array<any>;
  public filterBy: string | string[] = null;
  public disableNull = false;
  public minDropdownMenuWidthPx: number = null;
  public selectMenuClass: string;
  public itemSize: number = 40;
  public nullOptionLabel: string ;

  public disabledFn: (item: any) => boolean;

  public filteredText: string = null;
  public noResultsText: string;
  public noItemsText: string;

  public maxHeight: number | null;
  public virtualScrollSelectedIndex = -1;
  public selectionChangedByArrows = false;

  public showTransitionOptions = '300ms ease-out';
  public hideTransitionOptions = '200ms cubic-bezier(0.86, 0, 0.07, 1)';

  /* Custom template of one dropdown item */
  public itemTemplate: TemplateRef<any>;
  public footerTemplate: TemplateRef<any>;

  public close: EventEmitter<any>;
  public onItemSelect: EventEmitter<any> = new EventEmitter<any>();

  /* Scrolling */
  public viewPortOffsetTop = 0;
  public itemsWrapper: HTMLDivElement;

  @ViewChild(CdkVirtualScrollViewport) viewPort: CdkVirtualScrollViewport;
  @ViewChild('filteringInput', {static: false}) filteringInput: ElementRef;
  private virtualAutoScrolled = false;


  constructor(
    private elementRef: ElementRef,
    private wherePipe: WherePipe
  ) {
    this.onItemSelect = new EventEmitter<any>();
    this.selectedItem = null;

    this.calculateMaxHeight();
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.keyDown(event);
  }

  ngOnInit() {
    this.elementRef.nativeElement.id = this.id;
    if (this.multiple && !this.selectedItem) {
      this.selectedItem = new Array<any>();
    }

  }

  ngAfterViewInit() {
    setTimeout(() => {

      if (this.filterBy !== null && this.filterBy !== undefined) {
        this.filteringInput.nativeElement.focus();
      }

      this.calculateMaxHeight();
      this.itemsWrapper = this.elementRef.nativeElement.querySelector('.cdk-virtual-scroll-viewport');
      this.updateVirtualScrollSelectedIndex(true);

      if (this.itemsWrapper) {
        if (this.viewPort) {
          const range = this.viewPort.getRenderedRange();
          this.updateVirtualScrollSelectedIndex(false);

          if (range.start > this.virtualScrollSelectedIndex || range.end < this.virtualScrollSelectedIndex) {
            this.viewPort.scrollToIndex(this.virtualScrollSelectedIndex);
          }
        }
      }

    });
  }

  calculateMaxHeight() {
    this.maxHeight = this.itemSize * 4;
    if (this.items == null) {
      if (this.filterBy !== null && this.filterBy !== undefined) {
        this.maxHeight = this.itemSize * 2;
      } else {
        this.maxHeight = this.itemSize;
      }
    } else if (this.items.length < 4) {
      this.maxHeight = this.items.length * this.itemSize;
      if (!this.multiple && !this.disableNull) {
        this.maxHeight = this.maxHeight + (this.itemSize * 1);
      }
    }
  }

  onFilter() {
    this.calculateMaxHeight();
    this.updateVirtualScrollSelectedIndex(true);

    if (this.itemsWrapper) {
      if (this.viewPort) {
        const range = this.viewPort.getRenderedRange();
        this.updateVirtualScrollSelectedIndex(false);

        if (range.start > this.virtualScrollSelectedIndex || range.end < this.virtualScrollSelectedIndex) {
          this.viewPort.scrollToIndex(this.virtualScrollSelectedIndex);
        }
      }
    }
  }

  scrollToSelectedVirtualScrollElement() {
    if (!this.virtualAutoScrolled) {
      if (this.viewPortOffsetTop) {
        this.viewPort.scrollToOffset(this.viewPortOffsetTop);
      } else if (this.virtualScrollSelectedIndex > -1) {
        this.viewPort.scrollToIndex(this.virtualScrollSelectedIndex);
      }
    }

    this.virtualAutoScrolled = true;
  }

  updateVirtualScrollSelectedIndex(resetOffset) {
    if (this.selectedItem && this.items && this.items.length) {
      if (resetOffset) {
        this.viewPortOffsetTop = 0;
      }
      this.virtualScrollSelectedIndex = this._findOptionIndexById(this.selectedItem);
    }
  }

  selectItem(item: any) {
    if (!isNullOrUndefined(item) && this.isItemDisabled(item)) {
      return;
    }

    if (this.multiple) {
      if (this.primaryKey) {
        /* Searching using primary key */
        const itemIndex = (this.selectedItem as Array<any>).findIndex(x => x === item[this.primaryKey]);
        if (itemIndex > -1) {
          this.selectedItem.splice(itemIndex, 1);
        } else {
          this.selectedItem.push(item[this.primaryKey]);
        }
      } else {
        /* Searching using whole object */
        const itemIndex = (this.selectedItem as Array<any>).findIndex(x => x === item);
        if (itemIndex > -1) {
          this.selectedItem.splice(itemIndex, 1);
        } else {
          this.selectedItem.push(item);
        }
      }
    } else {
      this.selectedItem = item;
    }

    setTimeout(() => {
      this.viewPortOffsetTop = this.viewPort ? this.viewPort.measureScrollOffset() : 0;
    }, 1);

    this.onItemSelect.emit(this.selectedItem);
  }

  isItemChecked(item: any) {
    if (!this.selectedItem || !this.items || this.items.length === 0) {
      return false;
    }
    let index;
    if (this.primaryKey) {
      index = (this.selectedItem as Array<any>).findIndex(x => x === item[this.primaryKey]);
      return index > -1;
    } else {
      index = (this.selectedItem as Array<any>).findIndex(x => x === item);
      return index > -1;
    }
  }

  isItemDisabled(item: any) {
    if (isNullOrUndefined(this.disabledFn)) {
      return false;
    }

    return this.disabledFn(item);
  }

  keyDown(event: KeyboardEvent) {
    let currentlySelectedItemIndex;
    switch (event.code) {
      case 'ArrowDown':

        currentlySelectedItemIndex = this.virtualScrollSelectedIndex;
        const nextEnabledOption = this._findNextOption(currentlySelectedItemIndex);
        if (nextEnabledOption) {
          this.virtualScrollSelectedIndex = this._findOptionIndex(nextEnabledOption);
        }

        this.selectionChangedByArrows = true;
        this.viewPort.scrollToIndex(this.virtualScrollSelectedIndex);
        event.preventDefault();
        event.stopPropagation();
        break;

      case 'ArrowUp':

        currentlySelectedItemIndex = this.virtualScrollSelectedIndex;

        const prevEnabledOption = this._findPrevOption(currentlySelectedItemIndex);
        if (prevEnabledOption) {
          this.virtualScrollSelectedIndex = this._findOptionIndex(prevEnabledOption);
        } else if (currentlySelectedItemIndex === 0) {
          this.virtualScrollSelectedIndex = -1;
        }

        this.selectionChangedByArrows = true;
        this.viewPort.scrollToIndex(this.virtualScrollSelectedIndex);
        event.preventDefault();
        event.stopPropagation();
        break;

      case 'Space':
      case 'Enter':

        if (!this.selectionChangedByArrows) {
          return;
        }

        currentlySelectedItemIndex = this.virtualScrollSelectedIndex;
        let item = null;
        if (currentlySelectedItemIndex !== null) {
          let items = this.items;
          if (this.filterBy && this.filteredText) {
            if (isArray(this.filterBy)) {
              items = this.wherePipe.transform(items, this.prepareFilterPredicateFromArray());
            } else {
              items = this.wherePipe.transform(items, [this.filterBy, this.filteredText]);
            }
          }
          item = items[currentlySelectedItemIndex];
        }
        this.selectItem(item);
        event.preventDefault();
        event.stopPropagation();
        break;


      case 'Tab':
        this.close.emit();
        event.preventDefault();
        event.stopPropagation();
        break;
    }
  }

  private _findOptionIndexById(id: any): number {
    if (this.multiple) {
      return null;
    }

    let items = this.items;
    if (this.filterBy && this.filteredText) {
      if (isArray(this.filterBy)) {
        items = this.wherePipe.transform(items, this.prepareFilterPredicateFromArray());
      } else {
        items = this.wherePipe.transform(items, [this.filterBy, this.filteredText]);
      }
    }
    if (this.primaryKey) {
      /* Searching using primary key */
      return items.findIndex(x => x[this.primaryKey] === id);
    } else {
      /* Searching using whole object */
      return items.findIndex(x => x === id);
    }
  }

  private _findOptionIndex(item: any): number {
    if (this.multiple) {
      return null;
    }

    if (this.primaryKey) {
      /* Searching using primary key */
      return this.items.findIndex(x => x[this.primaryKey] === item[this.primaryKey]);
    } else {
      /* Searching using whole object */
      return this.items.findIndex(x => x === item);
    }
  }

  private _findPrevOption(index) {
    let prevEnabledOption;
    if (this.items && this.items.length) {
      if (index == null && this.items.length >= 1) {
        prevEnabledOption = this.items[this.items.length - 1];
      } else if (index === 0 && !this.multiple) {
        prevEnabledOption = null;
      } else {
        for (let i = (index - 1); 0 <= i; i--) {
          prevEnabledOption = this.items[i];
          break;
        }
      }
    }
    return prevEnabledOption;
  }

  private _findNextOption(index) {
    let nextEnabledOption;
    if (this.items && this.items.length) {

      if (index == null && this.items.length >= 1) {
        nextEnabledOption = this.items[0];
      } else {
        for (let i = (index + 1); index < (this.items.length - 1); i++) {
          nextEnabledOption = this.items[i];
          break;
        }
      }
    }

    return nextEnabledOption;
  }

  prepareFilterPredicateFromArray() {
    const self = this;
    return (item) => {
      for (const filteringProperty of self.filterBy) {
        if (item?.hasOwnProperty(filteringProperty)) {
          if (item[filteringProperty]?.toLowerCase().indexOf(self.filteredText?.toLowerCase()) > -1) {
            return true;
          }
        }
      }
      return false;
    };
  }
}
