
<div class="m-5">
  <h2>Výkazy práce</h2>
  <form *appHasRole="['WorkReportsFilterNoWorker']" class="form-inline" #form ="ngForm" (ngSubmit)="loadWorkReports()" novalidate>
      <input class="form-control" type="text" [(ngModel)]="filterParams.wpTitle" name="wpTitle" placeholder="Filtr dle nadpisu">
    <div class="form-group px-2">
      <label for="company">Pracovník: </label>
      <select class="form-control ml-1" style="width: 120px" id="userId" [(ngModel)]="filterParams.userId" name="userId">
        <option value="0"></option>
        <option *ngFor="let user of users" [value] = "user.id">
          {{user.firstName}} {{user.lastName}}
        </option>
      </select>
    </div>

    <div  *appHasRole="['CompanyFilter']" class="form-group px-2 ">
      <label for="company">Firma: </label>
      <select class="form-control ml-1" style="width: 120px" id="companyId" [(ngModel)]="filterParams.companyId" name="companyId">
        <option value="0"></option>
        <option *ngFor="let company of companies" [value] = "company.id">
          {{company.companyName}}
        </option>
      </select>
    </div>

    <div class="form-group ">
      <label for="month">Za měsíc: </label>
      <input class="form-control ml-1" style="width: 180px;"  type="month" [(ngModel)]="filterParams.month" name="month">
    </div>

    <button type="submit" class="btn btn-primary" style="margin-left:10px">Filtrovat</button>
    <button type="button" class="btn btn-info" style="margin-left:10px" (click)="resetFilters()" >Reset Filtrů</button>
  </form>
  <br> 
  <div class="form-inline">
    <div class="mt-5">
      <ngx-charts-bar-vertical  style="min-width: 100%" 
      [view]="[500,250]"
      [results]="monthHoursData"
      [xAxisLabel]="''"
      [legendTitle]="'Chart'"
      [yAxisLabel]="'hodiny'"
      [legend]="false"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [xAxis]="true"
      [yAxis]="true"
      [gradient]="true">
      </ngx-charts-bar-vertical>
    </div>
    <div class="mb-3" >

    <ngx-charts-advanced-pie-chart  style="min-width: 100%" (select)="pieChartClicked($event)"
    [view]="[500,300]"
    [results]="companyPercentsData"
    [gradient]="true"
    
    >
    </ngx-charts-advanced-pie-chart>


  </div>
  </div>
<div class=" ">
    <div *ngIf="workreports !== undefined &&workreports.length == 0" style="text-align: center">
      <h1>Zatím nejsou přidané žádné výkazy</h1>
    <button *appHasRole="['WorkReportsEdit']" class="btn btn-primary m-3" (click)="createModal()" >Vytvořit nový výkaz</button>
    </div>

    

  <div class="row m-5" *ngIf="workreports !== undefined && workreports.length > 0">
    <table class="table table-hover" style="cursor: pointer">
      <button *appHasRole="['WorkReportsEdit']" class="btn btn-primary" (click)="createModal()" >Přidat</button>
      <tr>
        <th style="width: 15%">Admin</th>
        <th style="width: 15%">Nadpis</th>
        <th style="width: 15%">Firma</th>
        <th style="width: 5%">Hod</th>
        <th style="width: 5%">Typ</th>
        <th style="width:5%">Km</th>
        <th style="width: 10%">Výkaz za den</th>
        <th style="width: 5%">Ticket</th>
        <th style="width: 5%">Stav</th>
        <th style="width: 15%">Interní poznámka</th>
        <th *appHasRole="['InvoiceEdit']" style="width: 5%">Zkontrolováno</th>
        <th *appHasRole="['InvoiceEdit']" style="width: 5%">Fakturovat pouze km</th>
        <th style="width: 5%">Fakturovat</th>
        
        <th style="width: 15%;"></th>
      </tr>
      <tr *ngFor="let workreport of workreports" >
        <td (click)="getWorkReportDetailModal(workreport)">{{workreport.user.firstName}} {{workreport.user.lastName}}</td>
        <td (click)="getWorkReportDetailModal(workreport)">{{workreport.title | slice:0:20}}</td>
        <td (click)="getWorkReportDetailModal(workreport)">{{workreport.company.companyName}}</td>
        <td (click)="getWorkReportDetailModal(workreport)">{{workreport.hours}}</td>
        <td (click)="getWorkReportDetailModal(workreport)">{{workreport.supportType}}</td>
        <td (click)="getWorkReportDetailModal(workreport)">{{workreport.transportKm}}</td>
        <td (click)="getWorkReportDetailModal(workreport)">{{workreport.forDay | date:"dd.MM.yyyy"}}</td>
        <td (click)="getTicketDetailModal(workreport.ticketId)"  >{{workreport.ticketId}}</td>
        <!-- [routerLink]="['/ticketsdetail/',workreport.ticketId]" -->
        <td>
          <b *ngIf="!workreport.checked && workreport.invoiceId === 0" style="color:green">V</b>
          <b *ngIf="workreport.checked  && workreport.invoiceId === 0" style="color:orange">K</b>
          <b *ngIf="workreport.invoiceId !== 0" style="color:red">F</b>
        </td>
        <td 
          (click)="getWorkReportDetailModal(workreport)" 
          style="max-width:60px; overflow: hidden; text-overflow: ellipsis">{{workreport.internalNote}}
        </td>
        <td *appHasRole="['InvoiceEdit']">
          <input *ngIf="workreport.invoiceId === 0" type="checkbox" class="form-check-input ml-4 mt-2" value="{{workreport.checked}}" [checked]="workreport.checked"
          (change)="setAsChecked(workreport)">
          <input *ngIf="workreport.invoiced === true" type="checkbox" class="form-check-input ml-4 mt-2" value="{{workreport.checked}}" [checked]="workreport.checked" onclick="return false;">
        </td>  
        <td>
          <b *ngIf="workreport.onlyTransport" style="color:rgb(10, 194, 10)">✓</b>
          <b *ngIf="!workreport.onlyTransport" style="color:red">✗</b>
        </td>
    
        <td>
          <div *ngIf="workreport.invoiceId === 0">

            <div *ngIf="workreport.invoiced === true ">
              <button  *appHasRole="['WorkReportsEdit']" class="btn" (click)="$event.stopPropagation()" (click)="turnOffWorkReport(workreport.id,false)" ><i class="fa fa-toggle-off" aria-hidden="true"></i></button>
            </div>
            <div *ngIf="workreport.invoiced === false ">
              <button *appHasRole="['WorkReportsEdit']" class="btn " (click)="$event.stopPropagation()" (click)="turnOffWorkReport(workreport.id,true)" ><i class="fa fa-toggle-on" aria-hidden="true"></i></button>
            </div>
          </div>
          <div *ngIf="workreport.invoiceId != 0">
            <button  *appHasRole="['WorkReportsEdit']" class="btn" (click)="$event.stopPropagation()"><i class="fa fa-toggle-on" aria-hidden="true"></i></button>
          </div> 

        </td>
       
        <td>
          <div *ngIf="workreport.invoiceId === 0" class="form-inline">
              <button *appHasRole="['WorkReportsEdit']" class="btn btn-primary" (click)="$event.stopPropagation()" (click)="getEditModal(workreport)" ><i class="fa fa-edit ml-1"></i></button>
              <button *appHasRole="['WorkReportsEdit']" class="btn btn-danger" (click)="$event.stopPropagation()" (click)="deleteWorkReport(workreport.id)" ><i class="fa fa-trash"></i></button>
          </div>
        </td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td *ngIf="hkm!==undefined">{{hkm[1]}}</td>
        <td *ngIf="hkm!==undefined">{{hkm[0]}}</td>
        <td></td>
        <td></td>
        <td></td>

      </tr>
    </table>

  </div>

</div>

<div class="d-flex justify-content-center">
    <pagination [maxSize]="3"
                [boundaryLinks]="true" 
                [totalItems]="pagination.totalItems"
                [itemsPerPage]="pagination.itemsPerPage"
                [(ngModel)]="pagination.currentPage"
                (pageChanged)="pageChanged($event)"
                previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
  </pagination>
  </div>
  </div>