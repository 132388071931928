import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Company } from 'src/app/_models/company';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { CompanyDialService } from 'src/app/_services/companyDial.service';

@Component({
  selector: 'app-companycreate-modal',
  templateUrl: './companycreate-modal.component.html',
  styleUrls: ['./companycreate-modal.component.scss']
})
export class CompanycreateModalComponent implements OnInit {
  @Output() createNewCompany = new EventEmitter();
  company: Company;
  companyAddForm: FormGroup;

  constructor(public bsModalRef: BsModalRef, private fb: FormBuilder, private companyService: CompanyDialService, private alertify: AlertifyService) {}

  ngOnInit() {
    this.createForm();
  }


  createForm(){
    this.companyAddForm = this.fb .group({
      companyName: ['', Validators.required],
      ico: ['', Validators.required],
      dico: [''],
      phoneNumber: ['', Validators.required],
      email: ['', Validators.required],
      adressPSC: ['', Validators.required],
      adressOkres: [''],
      adressObec: ['', Validators.required],
      adressCastObce: [''],
      // adressMop: [null, Validators.required],
      adressUlice: ['', Validators.required],
      adressCisloPopisne: ['', Validators.required],
      adressCisloOr: [''],
      daysToPay: [7], 
      isPrimary: [false],
      accountNumber: [''],
      invoiceFooterText: [''],
      client: [true],
      supplier: [false],
      rasHours:[0],
      onsHours:[0],
      hours:[0],
      prepaidKm:[0],
      km:[0],
      standardKms:[0],
      splitHours:[false],
      
      note:['']


    });
  }

  createCompany()
  {
    if (this.companyAddForm.valid)
    {
      if(this.companyAddForm.value.isPrimary)
      {
        this.alertify.confirm('Opravdu chcete nastavit tuto firmu jako primární ? Pokud je již tento status přidělen, bude od dané firmy odebrán ', () => {
          this.company = Object.assign({}, this.companyAddForm.value);
          this.createNewCompany.emit(this.company);
          this.bsModalRef.hide();
        });
      }
      else
      {
        this.company = Object.assign({}, this.companyAddForm.value);
        this.createNewCompany.emit(this.company);
        this.bsModalRef.hide();
      }
    }
  }

  FindInAres()
  {
    const x = this.companyService.getDataFromAres(this.companyAddForm.get('ico').value).subscribe( (data: Company) =>
      {
        this.company = data;

        this.companyAddForm = this.fb .group({
          companyName: [this.company.companyName, Validators.required],
          ico: [this.company.ico, Validators.required],
          dico: [this.company.dico],
          phoneNumber: [this.company.phoneNumber],
          email: [this.company.email, Validators.required],
          adressPSC: [this.company.adressPSC, Validators.required],
          adressOkres: [this.company.adressOkres],
          adressObec: [this.company.adressObec, Validators.required],
          adressCastObce: [this.company.adressCastObce],
          // adressMop: [this.company.adressMop, Validators.required],
          adressUlice: [this.company.adressUlice, Validators.required],
          adressCisloPopisne: [this.company.adressCisloPopisne, Validators.required],
          adressCisloOr: [this.company.adressCisloOr],

          daysToPay: [7], 
          isPrimary: [false],
          accountNumber: [''],
          invoiceFooterText: [''],
          client: [true],
          supplier: [false],
          rasHours:[0],
          onsHours:[0],
          hours:[0],
          splitHours:[false],
          
          note:['']
       
        });
      });
  }
}
