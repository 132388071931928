import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { InvoiceDetailModalComponent } from 'src/app/invoice/invoicedetail-modal/invoicedetail-modal.component';
import { Company } from 'src/app/_models/company';
import { ItemOrder } from 'src/app/_models/itemOrder';
import { Pagination } from 'src/app/_models/pagination';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { InventoryItemService } from 'src/app/_services/inventoryItem.service';
import { InvoiceService } from 'src/app/_services/invoice.service';
import { ItemOrderService } from 'src/app/_services/itemOrder.service';
import { ItemOrderCreateModalComponent } from '../itemOrderCreate-modal/itemOrderCreate-modal.component';
import { ItemOrderDetailModalComponent } from '../itemOrderDetail-modal/itemOrderDetail-modal.component';
import { ItemOrderEditModalComponent } from '../itemOrderEdit-modal/itemOrderEdit-modal.component';

@Component({
  selector: 'app-itemOrderInvoiceOverview',
  templateUrl: './itemOrderInvoiceOverview.component.html',
  styleUrls: ['./itemOrderInvoiceOverview.component.scss']
})
export class ItemOrderInvoiceOverviewComponent implements OnInit {
  bsModalRef: BsModalRef;
  itemOrders: ItemOrder[];
  pagination: Pagination;
  companies: Company[];
  filterParams: any = {};
  stepsviewflag: any;
  a:Date;
  b:Date;

 

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private companyService: CompanyDialService,
    private itemOrderService: ItemOrderService,
    private invService: InventoryItemService,
    private invoiceService: InvoiceService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.companyService.getCompanies(1,10000,"",'true',false,true).subscribe((data) => {
      this.companies = data.result;
    });
    this.route.data.subscribe((data) => {
      this.pagination = data['orders'].pagination;
      this.itemOrders = data['orders'].result.filter(x=>x.invId != 0);
    });
    this.route.params.subscribe(params => {
      
      this.filterParams.companyId = params['companyId'];
     
      this.filterParams.dateFrom = '1990-01-01';
      this.filterParams.dateTo = '1990-01-01';
      this.filterParams.filterType=3;
      this.filterParams.invoicePaid=0;
      this.filterParams.docId ="";

      if(params['invoiceId'] != 0)
      {
        this.filterParams.invoiceId = params['invoiceId'];
        this.loadOrders();
      }
      else
      {
        this.filterParams.invoiceId = 0;

      }
      // this.filterParams.docId="";
    });
  }

  createModal()
  {
    const initialState: any= {
      companyId: this.filterParams.companyId
    };
    this.bsModalRef = this.modalService.show(ItemOrderCreateModalComponent, {initialState, backdrop:'static', class:'modal-lg'});

    this.bsModalRef.content.createItemOrder.subscribe((values) =>
    {
      this.itemOrderService.addOrder(values).subscribe((data) => {
        this.itemOrderService.getOrderById(data).subscribe(x=>{
          this.getEditModal(x);
        });
          this.alertify.success('Úspěšně vytvořeno');
      }, error => {
        this.alertify.error('Objednávku se nepodařilo vytvořit');
      }, () => {
        this.loadOrders();
      });
      
    });
  }
resetFilters()
{
  this.filterParams.companyId = "0";
  this.filterParams.dateFrom = '1990-01-01';
  this.filterParams.dateTo = '1990-01-01';
  this.filterParams.filterType=3;
  this.filterParams.invoicePaid=0;
  this.filterParams.docId="";
  this.filterParams.invoiceId=0;
  // this.filterParams.orderDateTime = "";
  // this.filterParams.orderDateTime = "";
  this.loadOrders();
}
  loadOrders()
  {
    this.filterParams.isSupplier=true;
  if(this.filterParams.dateFrom === "")
  this.filterParams.dateFrom = '1990-01-01';
  if(this.filterParams.dateTo === "")
  this.filterParams.dateTo = '1990-01-01';

  this.itemOrderService.getOrders(this.pagination.currentPage, this.pagination.itemsPerPage,this.filterParams).subscribe(data => {
      this.pagination = data.pagination;
      this.itemOrders = data.result.filter(x=>x.invId != 0);

      if(this.filterParams.dateFrom === '1990-01-01')
      this.filterParams.dateFrom = "";
      if(this.filterParams.dateTo === '1990-01-01')
      this.filterParams.dateTo = "";

    });
    // var filterParams: any= {};
    // filterParams.orderDateTime = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    // filterParams.companyId = 0;
    
    // return this.itemOrderService.getOrders(this.pageNumber, this.pageSize,filterParams).subscribe(x=>
    //   {

    //   });
  }
  pageNumber(pageNumber: any, pageSize: any, filterParams: any) {
    throw new Error('Method not implemented.');
  }
  pageSize(pageNumber: any, pageSize: any, filterParams: any) {
    throw new Error('Method not implemented.');
  }

  setStepView(state, viewState)
  {
    if(viewState === "new order")
    {
      this.stepsviewflag = true;
    }

    if(state === viewState)
    {
      this.stepsviewflag = false;
      return "step current"
    }
    if(this.stepsviewflag)
    {
      return "step current done"
    }
    else
    {
      return "step"
    }
    

    if(viewState === "issued invoice")
    {
      this.stepsviewflag = false;
    }
  }


  getEditModal(itemOrder: ItemOrder)
  {
      const initialState: any= {
        itemOrder,
        companyId: this.filterParams.companyId,
      };
      this.bsModalRef = this.modalService.show(ItemOrderEditModalComponent, {initialState, backdrop:'static', class:'modal-lg'});
      this.bsModalRef.content.reloadItems.subscribe((data) =>{this.loadOrders(); });
      this.bsModalRef.content.editItemOrder.subscribe((values) =>
      {
        this.itemOrderService.updateOrder(itemOrder.id, values).subscribe(() => {
          this.alertify.success('Úspěšně upraveno');
          this.loadOrders();
        }, error => {
          this.alertify.error('Fakturu se nepodařilo upravit');
        }, () => {
          this.loadOrders();
        });
      });
   
  }

  getDetailModal(itemOrder: ItemOrder, viewName)
  {
    
      const initialState: any= {
        itemOrder,
        viewName: viewName,
        companyId: this.filterParams.companyId,
      };
      this.bsModalRef = this.modalService.show(ItemOrderDetailModalComponent, {initialState, backdrop:'static', class:'modal-lg'});
  }

  checkInvPaid(itemOrder: ItemOrder)
  {
    if(itemOrder.inv.paid === false)
    {
      this.a = new Date(itemOrder.inv.dateToPay);
      this.b = new Date(Date.now());
     
      return this.a <= this.b;
    }
    return false;
  }
  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.loadOrders();
  }
  

  getInvoiceDetailModal(itemOrder: ItemOrder)
  {
    // itemOrder.supplier = this.companies.filter(x => x.id.toString() === itemOrder.inv.supplierCompanyId.toString())[0];
    
    const initialState: any= {
      invoice: itemOrder.inv,
    };
    this.bsModalRef = this.modalService.show(InvoiceDetailModalComponent, {initialState, backdrop:'static', class:'modal-lg'});
  }

  // deleteInvoice(invoiceId:number)
  // {

  // }

  deleteInvoice(itemOrder: ItemOrder)
  {
    this.itemOrderService.canDeleteOrderItems(itemOrder.id).subscribe(res => {
      if(res['content'] === "")
      {
      this.alertify.confirm('Opravdu chcete vymazat tuto fakturu ?', () => {
        var invId = 0;
        if(itemOrder.inv !== null)
          invId = itemOrder.inv.id;
  
        this.invoiceService.deleteInvoice(invId)
        .subscribe(() => {
          this.itemOrderService.deleteOrder(itemOrder.id).subscribe(data =>
            {
              this.loadOrders()
              this.alertify.success('Úspěšně odstraněno');
            });
        
        }, error => {
          this.alertify.error('Fakturu se nepodařilo odstranit');
        });
      });
     }
     else
     {
       this.alertify.warning(res['content']);
     }

    });
   
  }

}
