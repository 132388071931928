import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {Company} from '../_models/company';
import {PaginatedResult} from '../_models/pagination';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CompanyDialService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }


  addCompany(company: Company) {
    return this.http.post(this.baseUrl + 'CompanyDial/', company);
  }

  deleteCompany(companyId: number) {
    return this.http.delete(this.baseUrl + 'CompanyDial/' + companyId);
  }

  getCompanies(page?, itemsPerPage?, companyName?, deleted?, supplier?, client?) {
    const paginatedResult: PaginatedResult<Company[]> = new PaginatedResult<Company[]>();
    let params = new HttpParams();
    if (supplier === undefined || client === undefined) {
      supplier = false;
      client = false;
    }
    params = params.append('Supplier', supplier);
    params = params.append('Client', client);
    if (page != null && itemsPerPage != null) {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
      params = params.append('CompanyName', companyName);
      params = params.append('Deleted', deleted);
    }

    return this.http.get<Company[]>(this.baseUrl + 'companydial', {observe: 'response', params})
      .pipe(
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') !== null) {
            paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
          }

          return paginatedResult;
        })
      );
  }

  updateCompany(id: number, company: Company) {
    return this.http.put(this.baseUrl + 'CompanyDial/' + id, company);
  }

  getDataFromAres(ico: string) {
    return this.http.get(this.baseUrl + 'CompanyDial/GetDataFromAres/' + ico);
  }

  getCompaniesForInvoice() {
    // return this.http.get(this.baseUrl + 'CompanyDial/GetCompaniesForInvoice');
    var result: Company[];
    return this.http.get<Company[]>(this.baseUrl + 'CompanyDial/GetCompaniesForInvoice', {observe: 'response'})
      .pipe(
        map(response => {
          result = response.body;
          return result;
        })
      );
  }

  GetCompany(id: number) {
    // return this.http.get(this.baseUrl + 'CompanyDial/GetCompaniesForInvoice');
    var result: Company;
    return this.http.get<Company>(this.baseUrl + 'CompanyDial/' + id, {observe: 'response'})
      .pipe(
        map(response => {
          result = response.body;
          return result;
        })
      );
  }


}
