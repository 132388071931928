import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {DomainModel} from '../../_models/domain';
import {AlertifyService} from "../../_services/alertify.service";
import {isNullOrEmpty} from "../../core/_helpers/common-helpers";

@Component({
  selector: 'app-domainEdit-modal',
  templateUrl: './domainEdit-modal.component.html',
  styleUrls: ['./domainEdit-modal.component.scss']
})
export class DomainEditModalComponent implements OnInit {
  @Output() editDomain = new EventEmitter();
  companyId: number;
  domain: DomainModel;
  filterParams: any = {};
  instanceName: string;
  lastUsedIndex: number;

  constructor(
    public bsModalRef: BsModalRef,
    public alertify: AlertifyService,
  ) {
  }

  ngOnInit() {
    this.lastUsedIndex = null;
    this.instanceName = null;
  }

  domainEdit() {
    this.editDomain.emit({domain: this.domain});
    this.bsModalRef.hide();
  }

  setCompanyToDomain($event: number) {
    this.domain.companyId = $event;
  }

  addSqlInstance() {
    if (isNullOrEmpty(this.instanceName)) {
      return;
    }
    if (this.domain.sqlInstancies.indexOf(this.instanceName) > -1) {
      if (this.instanceName === this.domain.sqlInstancies[this.lastUsedIndex]) {
        this.lastUsedIndex = null;
        this.instanceName = null;
        return;
      }

      this.alertify.warning('SQL instance s daným názvem již existuje');
      return;
    }

    if (this.lastUsedIndex !== null) {
      this.domain.sqlInstancies[this.lastUsedIndex] = this.instanceName;
      this.lastUsedIndex = null;
      this.instanceName = null;
      return;
    }

    this.domain.sqlInstancies.push(this.instanceName);

    this.instanceName = null;
  }

  editSqlInstance(index: number) {
    this.lastUsedIndex = index;
    this.instanceName = this.domain.sqlInstancies[index];
  }

  deleteSqlInstance(index: number) {
    this.domain.sqlInstancies.splice(index, 1);
  }
}
