import {NgModule, Pipe, PipeTransform} from '@angular/core';
import {isNull} from '../utils/utils';
import {isNullOrEmpty} from '../../../_helpers/common-helpers';


@Pipe({
  name: 'isNull',
})
export class IsNullPipe implements PipeTransform {
  transform(value: any): boolean {
    return isNull(value);
  }
}

@NgModule({
  declarations: [IsNullPipe],
  exports: [IsNullPipe],
})
export class IsNullPipeModule {
}


@Pipe({
  name: 'isNullOrEmpty',
})
export class IsNullOrEmptyPipe implements PipeTransform {
  transform(value: any): boolean {
    return isNull(value) || isNullOrEmpty(value);
  }
}

@NgModule({
  declarations: [IsNullOrEmptyPipe],
  exports: [IsNullOrEmptyPipe],
})
export class IsNullOrEmptyPipeModule {
}
