import { Company } from './company';

export class Licence{
    id: number;
    name: string;
    supplierCompanyId: number;
    supplierCompany: Company;
    payFrequence: number;
    licenceCode: string;
    licencePrice: number;
    lastPayment: Date;
    amount: number;
}
