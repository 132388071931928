<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <form [formGroup]="categoryAddForm" (ngSubmit)="createCategory()">
    <div class="form-group">
      <input  type="text" [ngClass]="{'is-invalid': categoryAddForm.get('name').errors && categoryAddForm.get('name').touched }" class="form-control is-invalid" 
      formControlName="name" placeholder="Název" />
      <div class="invalid-feedback">Název je povinné pole</div>
    </div>
    <div class="form-group">
      <select formControlName="companyid" class="form-control" id="solverSelect"  placeholder="Firma"  name="solverSelect" 
      [ngClass]="{ 'is-invalid': categoryAddForm.get('companyid').errors && categoryAddForm.get('companyid').touched}">
      <option value="">Vyberte firmu</option>
      <option *ngFor="let company of companies" [value] = "company.id">
         {{company.companyName}} 
       </option>
      </select>
      <div class="invalid-feedback">Firma je povinné pole</div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button [disabled]="!categoryAddForm.valid" type="button" class="btn btn-success" form="categoryAddForm" (click)="createCategory()">Uložit</button>
</div>