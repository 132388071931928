import { Company } from './company';

export class DomainModel {
    id: number;
    name: string;
    domain: string;
    companyId: number;
    company: Company;
    sqlInstancies: string[];
    deleted: boolean;
}
