import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { UserService } from '../_services/user.service';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';
import { InventoryItem } from '../_models/inventoryItem';
import { InventoryItemService } from '../_services/inventoryItem.service';
@Injectable()
export class InventoryItemsResolver implements Resolve<InventoryItem[]> {
  pageNumber = 1;
  pageSize = 15;

  constructor(
    private invItemService: InventoryItemService,
    private router: Router,
    private alertify: AlertifyService,
    private authService: AuthService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<InventoryItem[]> {
    var filterParams: any= {};
    filterParams.serialNumber = '';
    filterParams.name = '';
    filterParams.isContained = false;
    filterParams.hasContained = false;
    filterParams.delivered = false;
    filterParams.invoicedToClient = false;
    filterParams.reklamace = false;
    filterParams.inWarehouse = true;
    filterParams.clientId = 0;
    filterParams.supplierId = 0;
    filterParams.isForInvoice = false;
    filterParams.getPrimaryCompany = false;


    return this.invItemService.getInvenotryItems(this.pageNumber, this.pageSize,0,filterParams).pipe(
      catchError((error) => {
        this.alertify.error('Problem retrieving messages');
        this.router.navigate(['//adminHome']);
        return of(null);
      })
    );
  }
}
