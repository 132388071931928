import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Category } from '../_models/category';
import { Company } from '../_models/company';
import { PaginatedResult, Pagination } from '../_models/pagination';
import { AlertifyService } from '../_services/alertify.service';
import { AuthService } from '../_services/auth.service';
import { CategoryService } from '../_services/category.service';
import { CompanyDialService } from '../_services/companyDial.service';
import { CategoryCreateModalComponent } from './categorycreate-modal/categorycreate-modal.component';
import { CategoryEditModalComponent } from './categoryedit-modal/categoryedit-modal.component';

@Component({
  selector: 'app-priority',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit {
  bsModalRef: BsModalRef;
  categories: Category[];
  pagination: Pagination;
  companies: Company[];
  filterParams: any = {};

  constructor(
    private categoryService: CategoryService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private companyService: CompanyDialService
  ) {}

  ngOnInit() {
    
    this.route.data.subscribe((data) => {
      this.pagination = data['categories'].pagination;
    });
    this.companyService.getCompanies(1, 1,null,null,false,true).subscribe((data) => {
      this.companies = data.result;
      this.route.params.subscribe(params => {
        this.filterParams.companyId = params['filterParam'];
        if(this.authService.decodedToken.IsPrimary !== 'True')
        {
          this.filterParams.companyId = this.authService.decodedToken.CompanyId;
        }
        if(this.filterParams.companyId === '0')
         {
           this.filterParams.companyId = this.authService.decodedToken.CompanyId;
         }
        this.loadCategories();
       
      });
    });
  }

  deleteCategory(id: number)
  {
    this.alertify.confirm('Opravdu chcete vymazat tuto kategorii ?', () => {
      this.categoryService.deleteCategory(id)
      .subscribe(() => {
        this.categories.splice(this.categories.findIndex(m => m.id === id ), 1);
        this.alertify.success('Úspěšně odstraněno');
      }, error => {
        this.alertify.error('Kategorii se nepodařilo odstranit');
      });
    });
  }

  loadCategories() {
    this.categoryService.getCategories( this.pagination.currentPage, this.pagination.itemsPerPage, this.filterParams)
    .subscribe((res: PaginatedResult<Category[]>) => {
      this.categories = res.result;
      this.pagination.totalPages =res.pagination.totalPages;
      this.pagination.itemsPerPage =res.pagination.itemsPerPage;
      this.pagination.totalItems =res.pagination.totalItems;
    }, error => {
      this.alertify.error(error);
    });
   }

  pageChanged(event: any): void{
    this.pagination.currentPage = event.page;
    this.loadCategories();
  }

  createModal()
  {
    const initialState: any= {
      companyId: this.filterParams.companyId
    };
    this.bsModalRef = this.modalService.show(CategoryCreateModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.createNewCategory.subscribe((values) =>
    {
      this.categoryService.addCategory(values).subscribe(() => {
        this.alertify.success('Úspěšně přidáno');
      }, error => {
        this.alertify.error('Kategorii se nepodařilo přidat');
      }, () => {
        this.loadCategories();
      });
    });
  }

  getEditModal(category: Category)
  {
    const initialState: any= {
      category,
      companyId: this.filterParams.companyId
    };
    this.bsModalRef = this.modalService.show(CategoryEditModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.editcategory.subscribe((values) =>
    {
      this.categoryService.updateCategory(category.id, values).subscribe(() => {
        this.alertify.success('Úspěšně upraveno');
      }, error => {
        this.alertify.error('Kategorii se nepodařilo upravit');
      }, () => {
        this.loadCategories();
      });
    });
  }

  resetFilters()
  {
    // this.filterParams.companyId = '0';
    this.filterParams.companyId = this.authService.decodedToken.CompanyId;
    this.loadCategories();
  }

  compareIds(id1, id2)
  {
    return id1.toString()===id2;
  }

}
