import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Company } from 'src/app/_models/company';

@Component({
  selector: 'app-companydetail-modal',
  templateUrl: './companydetail-modal.component.html',
  styleUrls: ['./companydetail-modal.component.scss']
})
export class CompanydetailModalComponent implements OnInit {
  @Output() createNewCompany = new EventEmitter();
  company: Company;
  accountNumber:string;

  constructor(public bsModalRef: BsModalRef, private fb: FormBuilder) {}

  ngOnInit() {
    this.accountNumber = Number(this.company.accountNumber.substring(this.company.accountNumber.length-12)).toString();
    var x = Number(this.company.accountNumber.substring(4,8)).toString();
    this.accountNumber = this.accountNumber+"/"+x;
    
  }


}
