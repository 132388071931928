import { Company } from './company';
import { InventoryItem } from './inventoryItem';
import { Invoice } from './invoice';
import { OrderedItem } from './orderedItem';
import { User } from './user';

export class ItemOrder{
    id: number;
    orderState: string;
    dateOfOrder: Date;
    dateOfDeliveryList: Date;
    createdById: number;
    createdBy: User;
    supplierId: number;
    supplier: Company;
    customerId: number;
    companyId: number;
    customer: Company;
    items: InventoryItem[]; // add product number and serial number
    orderedItems: OrderedItem[]; // add product number and serial number
    deleted: boolean;
    amountError: boolean;
    invId: number;
    inv: Invoice;
}




