<app-select3 (change)="updateValue($event)" filterBy="companyName" [(value)]="value" [disabled]="disabled" [items]="companies"
             autoClose="outside" container="body" primaryKey="id">
  <ng-template #itemTemplate let-item>
    <span>
      {{ item.companyName }}
    </span>
  </ng-template>
  <ng-template #selectedItemTemplate let-selectedItem>
    <span>{{ selectedItem.companyName }}</span>
  </ng-template>
</app-select3>
