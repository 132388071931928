import { DatePipe } from '@angular/common';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, ElementRef, Input, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { tick } from '@angular/core/testing';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { WorkReportDetailModalComponent } from 'src/app/workreports/workreportdetail-modal/workreportdetail-modal.component';
import { WorkReportEditModalComponent } from 'src/app/workreports/workreportedit-modal/workreportedit-modal.component';
import { Message } from 'src/app/_models/message';
import { Ticket } from 'src/app/_models/ticket';
import { WorkReport } from 'src/app/_models/workReport';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { TicketService } from 'src/app/_services/ticket.service';
import { UserService } from 'src/app/_services/user.service';
import { WorkreportService } from 'src/app/_services/workreport.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-ticket-messages',
  templateUrl: './ticket-messages.component.html',
  styleUrls: ['./ticket-messages.component.scss']
})
export class TicketMessagesComponent implements OnInit {
  @Input() ticket: Ticket;
  @Input() changeEventFlag: boolean;
  @Input() isSolver: boolean;
  ticketIdValue = '';
  bsModalRef: BsModalRef;
  // @ViewChild('scrollframe', {static: false}) scrollFrame: ElementRef;
  @ViewChild('scrollframe', { read: ElementRef }) public scroll: ElementRef<any>;
  isIssuer
  container: HTMLElement;

  messages: Message[];
  newMessage: any = {};
  currentUserId: number;
  scrolled:boolean=false;

  private scrollContainer: any;

    constructor(private ticketSerice: TicketService, private authService: AuthService, private alertify: AlertifyService,private http: HttpClient,
      private workreportService: WorkreportService,
      private datePipe: DatePipe,
    private modalService: BsModalService) { }

    // tslint:disable-next-line: typedef
    ngOnInit() {
      this.isIssuer = this.ticket.issuer.id.toString() === this.authService.decodedToken.nameid;
       this.loadMessages();
      this.currentUserId = this.authService.decodedToken.nameid;
      this.ticketIdValue = this.ticket.id.toString();
    }

      ngAfterViewChecked() {
        if(!this.scrolled)
        {
          this.scrollBottom();
          this.scrolled=true;
        }
      }

      public scrollBottom() {
        this.scroll.nativeElement.scrollTop = this.scroll.nativeElement.scrollHeight;
      }

      ngOnChanges(changes: SimpleChanges)
      {
        for (const propName in changes) {
          if (changes.hasOwnProperty(propName)) {
            switch (propName) {
              case 'changeEventFlag': {
                this.loadMessages();

              }
              default: {}
            }
          }
        }
      }

    // tslint:disable-next-line: typedef
    loadMessages(){
      const currentUserId = this.authService.decodedToken.nameid;
      this.ticketSerice.getMessageThread(this.authService.decodedToken.nameid, this.ticket.id)
      // .pipe(
      //   tap(messages => {
      //     // tslint:disable-next-line: prefer-for-of
      //     for (let i = 0;  i < messages.length; i++ )
      //     {
      //       if (messages[i].isRead === false && messages[i].senderId !== this.authService.decodedToken.nameid)
      //       {
      //         // this.ticketSerice.markAsRead(currentUserId, messages[i].id );
      //       }
      //     }
      //   })
      // )
      .subscribe(messages => {
        this.messages = messages;
        this.scrollBottom();
      }, error => {
        this.alertify.error(error);
      });
    }

    // tslint:disable-next-line: typedef
    sendMessage(){
      this.newMessage.senderId = this.authService.decodedToken.nameid;
      this.newMessage.ticketId = this.ticket.id;
      this.ticketSerice.sendMessage(this.authService.decodedToken.nameid, this.newMessage)
      .subscribe((message: Message) => {
        this.messages.push(message);
        this.scrolled= false;
        this.newMessage.content = '';
      }, error => {
        this.alertify.error(error);
      });
    }


    getFile(message: Message) {
      if(message.filePath !== null)
      {
        let headers = new HttpHeaders();
        this.http.post(environment.apiUrl+'upload/Download/'+message.id, {}, { headers: headers, responseType: 'blob' }).subscribe(data => this.downloadFile(data,message));
      }
      if( message.content.startsWith("Na tento ticket byl vytvořen výkaz práce s id"))
      {
        var id = message.content.split('id ')[1];
        this.workreportService.getWorkReport(id).subscribe(data =>{
        if(this.authService.roleMatch(["WorkReportsEdit"]))
        {
          this.getWorkReportEditModal(data);
        }
        else
        {
          this.getWorkReportDetailModal(data);
        }
      })
      }
  }

  getWorkReportDetailModal(workReport: WorkReport)
  {
    const initialState: any= {
      workReport
    };
    this.bsModalRef = this.modalService.show(WorkReportDetailModalComponent, {initialState, backdrop:'static'});
  }



  getWorkReportEditModal(workreport: WorkReport)
  {
    const initialState: any= {
      workreport
    };
    this.bsModalRef = this.modalService.show(WorkReportEditModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.editworkreport.subscribe((values) =>
    {
      values.workFrom = this.datePipe.transform(values.workFrom, 'yyyy-MM-dd HH:mm')
      values.workTo = this.datePipe.transform(values.workTo, 'yyyy-MM-dd HH:mm')
      this.workreportService.updateWorkReport(workreport.id, values).subscribe(() => {
        this.alertify.success('Úspěšně upraveno');
      }, error => {
        this.alertify.error('Výkaz se nepodařilo upravit');
      }, () => {
      });
    });
  }

    downloadFile(blob: Blob,message:Message) {
      const fileName = message.content;
      if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(blob, fileName);
      } else {
          const link = document.createElement('a');
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
      }
  }

  autoGrowTextZone(e) {
    e.target.style.height = "0px";
    e.target.style.height = (e.target.scrollHeight + 25)+"px";
  }



  }
