<div class="m-5">
  <h2>Nákupní oddělení</h2>

  <form *appHasRole="['CompanyFilter']" class="form-inline" #form ="ngForm" (ngSubmit)="loadPds()" novalidate>
    <div class="form-group px-2">
      <label for="company">Objednavatel: </label>
      <select class="form-control ml-1" style="width: 130px" id="orderedById" [(ngModel)]="filterParams.orderedById" name="orderedById">
        <option *ngFor="let user of coreUsers" [value] = "user.id">
          {{user.firstName}} {{user.lastName}}
        </option>
      </select>
      <label class="ml-3" for="company">Nákupčí: </label>
      <select class="form-control ml-1" style="width: 130px" id="orderedFromId" [(ngModel)]="filterParams.orderedFromId" name="orderedFromId">
        <option *ngFor="let user of coreUsers" [value] = "user.id">
          {{user.firstName}} {{user.lastName}}
        </option>
      </select>
    </div>
    <button type="submit" class="btn btn-primary" style="margin-left:10px">Filtrovat</button>
    <button type="button" class="btn btn-info" style="margin-left:10px" (click)="resetFilters()" >Reset Filtrů</button>
  </form>
  <br> 
  <div *ngIf="pds !==undefined" class="m-5">
    <div *ngIf="pds.length === 0" style="text-align: center">
      <h1>Zatím nejsou přidané žádné požadavky na nákup</h1>
    <button class="btn btn-primary m-3" (click)="createModal()" >Vytvořit nový požadavek na nákup</button>
    </div>

  <div class="row" *ngIf="pds.length > 0">
    <table class="table table-hover" style="cursor: pointer">
      <button *appHasRole="['Invoice']" class="btn btn-primary" (click)="createModal()" >Přidat</button>
      <tr>
        <th style="width: 20%">Objednavatel</th>
        <th style="width: 20%">Nákupčí</th>
        <th style="width: 20%">Datum zadání požadavku</th>
        <th style="width: 20%"></th>
      </tr>
      <tr (click)="getDetailModal(pd)" *ngFor="let pd of pds" >
        <td>{{getCoreUser(pd.orderedById)}}</td>
        <td>{{getCoreUser(pd.orderedFromId)}}</td>
        <td>{{pd.dateOfOrder | date:"dd.MM.yyyy"}}</td>
        <td>
            <div *ngIf="userId.toString() === pd.orderedById.toString()">
              <button *appHasRole="['InvoiceEdit']" class="btn btn-primary" (click)="$event.stopPropagation()" (click)="getEditModal(pd)" ><i class="fa fa-edit ml-1"></i></button>
              <button  *appHasRole="['InvoiceEdit']" class="btn btn-danger" (click)="$event.stopPropagation()" (click)="deleteCategory(pd.id)" ><i class="fa fa-trash"></i></button>
            </div>
        </td>
      </tr>
    </table>

  </div>

</div>

<div class="d-flex justify-content-center">
    <pagination [maxSize]="3"
                [boundaryLinks]="true" 
                [totalItems]="pagination.totalItems"
                [itemsPerPage]="pagination.itemsPerPage"
                [(ngModel)]="pagination.currentPage"
                (pageChanged)="pageChanged($event)"
              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
  </pagination>
  </div>