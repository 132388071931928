import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Company } from 'src/app/_models/company';
import { ItemOrder } from 'src/app/_models/itemOrder';
import { PurchaseDepartmentItem } from 'src/app/_models/purchaseDepartmentItem';
import { PurchaseDepartmentOrder } from 'src/app/_models/purchaseDepartmentOrder';
import { User } from 'src/app/_models/user';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-purchaseDepartmentCreate-Modal',
  templateUrl: './purchaseDepartmentCreate-Modal.component.html',
  styleUrls: ['./purchaseDepartmentCreate-Modal.component.css']
})
export class PurchaseDepartmentCreateModalComponent implements OnInit {
  @Output() createNewPd = new EventEmitter();
  purchaseDepartmentOrder: PurchaseDepartmentOrder;
  purchaseDepartmentOrderAddForm: FormGroup;
  coreUsers: User[];
  itemsValid :boolean=true;

  
  purchaseDepartmentItems: PurchaseDepartmentItem[]= [];

  constructor(public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private companyService: CompanyDialService,
    private authService: AuthService,
    private alertify: AlertifyService,
    private userService:UserService,
    private datePipe: DatePipe) {}

  ngOnInit() {
    this.createForm();
    this.userService.getCoreUsers().subscribe((data) => {this.coreUsers = data;});
  }


  // tslint:disable-next-line: typedef
  createForm(){
    this.purchaseDepartmentOrderAddForm = this.fb .group({
      dateOfOrder: [this.datePipe.transform(new Date(), 'yyyy-MM-dd'), Validators.required],
      orderedById: [this.authService.decodedToken.nameid, Validators.required],
      orderedFromId: [0, Validators.required],
      ticketId: [0],
      deleted: [false],
      
    });

    // if(this.authService.decodedToken.IsPrimary !== 'True')
    //   {
    //     this.itemOrderAddForm.patchValue({supplierId: this.companies.filter(x => x.isPrimary)[0].id})
    //   }
  
  }
  createOrder()
  {
    if (this.purchaseDepartmentOrderAddForm.valid)
    {

      this.purchaseDepartmentOrder = Object.assign({}, this.purchaseDepartmentOrderAddForm.value);
       this.purchaseDepartmentOrder.pdis = this.purchaseDepartmentItems;
      this.createNewPd.emit(this.purchaseDepartmentOrder);
      this.bsModalRef.hide();
    }
  }

  addEmptyItem()
  {
    let  emptyItem = {} as PurchaseDepartmentItem;
    emptyItem.amount =0;
    emptyItem.name ="";
    emptyItem.pDOId =0;
    emptyItem.deleted =false;
    emptyItem.dynamicId = this.purchaseDepartmentItems.reduce((op, item) => op = op > item.dynamicId ? op : item.dynamicId, 0)+1;
    this.purchaseDepartmentItems.push(emptyItem);
    this.checkItems();

    
  }

  removeItem(id: number)
  {
    this.purchaseDepartmentItems = this.purchaseDepartmentItems.filter(x=>x.dynamicId != id);
    this.checkItems();
  }

  checkItems()
  {
    if(this.purchaseDepartmentItems.length<=0)
    {
      this.itemsValid = true;
      return;
    }

    var error = this.purchaseDepartmentItems.filter(x=>
      x.amount === 0 || x.amount === null ||
       x.name === undefined || x.name === '' || x.name === null 
      //  x.flag === undefined || x.flag ===''
      ).length>0;

    this.itemsValid = !error;
    console.log(this.itemsValid)
  }

 
}
