import { group } from '@angular/animations';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Group } from 'src/app/_models/group';
import { GroupService } from 'src/app/_services/group.service';

@Component({
  selector: 'app-editGroup',
  templateUrl: './editGroup.component.html',
  styleUrls: ['./editGroup.component.css']
})
export class EditGroupComponent implements OnInit {
  @Output() editGroup = new EventEmitter();
  group: Group;
  roles = [];
  rolesToSend = [];
  constructor(public bsModalRef: BsModalRef, private groupService: GroupService) {}

  ngOnInit() {
    this.groupService.getRoles().subscribe(data=>{
      
      data.forEach(element => {
        this.roles.push({checked:false,name: element})
      });

      this.groupService.getRolesForGroup(this.group.groupName).subscribe(data=>{
        data.forEach(element => {
          this.roles.filter(x=>x.name == element)[0].checked = true;
        });
      })

    })
  }


  updateRows()
  {
    this.roles.filter(x=>x.checked === true).forEach(element => {
      this.rolesToSend.push(element.name);
    });
    this.editGroup.emit({roles: this.rolesToSend, gn: this.group.groupName, gId: this.group.id});
    this.bsModalRef.hide();
  }
}
