<div class="modal-header ">
  <h4 class="modal-title pull-left">Správa řešitelů ticketu {{ticket.id}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form #solversForm="ngForm" id="solversForm">
    <div class="form-check" *ngFor="let solver of coreUsers">
      <input type="checkbox" class="form-check-input" value="{{solver.id}}" [checked]="solver.checked"
      (change)="solver.checked =! solver.checked" >
      <label>{{solver.firstName+" "+solver.lastName}}</label>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button type="button" class="btn btn-success" form="solversForm" (click)="updateRows()">Potvrdit</button>
</div>