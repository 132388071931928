<div class="row">
  <table class="table bg-dark">
    <tr>
      <th style="width: 30%;">Uživatel</th>
      <th style="width: 20%;">Skupiny rolí</th>
      <th style="width: 20%;">Firma</th>
      <th style="width: 30%;"></th>
    </tr>
    <tr *ngFor="let user of users">
      <td (click)="getUserDataDetail(user)">{{user.userName}}</td>
      <td (click)="getUserDataDetail(user)">{{user.groups}}</td>
      <td (click)="getUserDataDetail(user)">{{user.company.companyName}}</td>
      <td >
        <div  class="form-inline" style="float: right;">
          <button *appHasRole="['NotificationSet']" class="btn btn-light"  (click)="editNotificationsModal(user)" >Notifikace</button>
          <button *appHasRole="['RolesEdit']" class="btn btn-info"  (click)="editRolesModal(user)" >Role</button>
          <button *appHasRole="['UserDataEdit']" class="btn btn-primary"  (click)="editUserDataModal(user)" >Údaje</button>
        <button *appHasRole="['UserPsswdChange']" class="btn btn-warning"  (click)="editUserPasswordModal(user)" >Heslo</button>
        </div>
    </td>
    </tr>
  </table>
</div>

<div class="d-flex justify-content-center">
  <pagination [maxSize]="3"
              [boundaryLinks]="true" 
              [totalItems]="pagination.totalItems"
              [itemsPerPage]="pagination.itemsPerPage"
              [(ngModel)]="pagination.currentPage"
              (pageChanged)="pageChanged($event)"
            previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
</pagination>
</div>