import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PaginatedResult } from '../_models/pagination';
import { map } from 'rxjs/operators';
import { Ticket } from '../_models/ticket';
import { Priority } from '../_models/priority';
import { Observable } from 'rxjs';
import { Solver } from '../_models/solver';
import { Message } from '../_models/message';
import { add } from 'ngx-bootstrap/chronos';
import { tick } from '@angular/core/testing';

@Injectable({
  providedIn: 'root'
})
export class TicketService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}


  addTicket(ticket: Ticket )
  {
    return this.http.post<Ticket>(this.baseUrl + 'Ticket/', ticket );
  }
  editTicket(id: number, ticket: Ticket)
  {
    return this.http.put(this.baseUrl + 'Ticket/Edit/' + id, ticket);
  }

  deleteTicket(ticketid: number)
  {
    return this.http.delete(this.baseUrl + 'Ticket/' + ticketid );
  }
  // getPriorities(companyId: number): Observable<Priority[]>
  // {
  //   return this.http.get<Priority[]>(this.baseUrl + 'Ticket/GetPriorities/' + companyId);
  // }

  getSolvers(): Observable<Solver[]>
  {
    return this.http.get<Solver[]>(this.baseUrl + 'Ticket/GetSolvers');
  }

  addSolver(userId: number, ticketId: number, addedById: number)// userId: number, ticketId: number, addedById: number
  {
    
    return this.http.post(this.baseUrl + 'Ticket/AddSolver/' + userId + '/ticket/' + ticketId + '/by/' + addedById, {} ); // + userId + '/ticket/' + ticketId + '/by/' + addedById
  }

  getSolver(id): Observable<Solver>
  {
    return this.http.get<Solver>(this.baseUrl + 'Ticket/GetSolver/' + id);
  }

  getTicket(id): Observable<Ticket>
  {
    return this.http.get<Ticket>(this.baseUrl + 'Ticket/GetTicket/' + id);
  }

  getTickets( page?, itemsPerPage?, ticketParams?, userId?, ticketPage?)
  {
    const paginatedResult: PaginatedResult<Ticket[]> = new PaginatedResult<Ticket[]>();
    let params = new HttpParams();

    if (page != null && itemsPerPage != null)
    {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
   
    if ( ticketParams != null)
    {
      if(ticketParams.repeat == undefined)
      {
        ticketParams.repeat=false;
      }

      params = params.append('CompanyId', ticketParams.companyId);
      params = params.append('State', ticketParams.state);
      params = params.append('Solver', ticketParams.solver);
      params = params.append('Issuer', ticketParams.issuer);
      params = params.append('TicketPage', ticketPage);
      params = params.append('UserId', userId);
      params = params.append('OrderBy', ticketParams.orderBy);
      params = params.append('CategoryId', ticketParams.categoryId);
      params = params.append('DateFrom', ticketParams.dateFrom);
      params = params.append('DateTo', ticketParams.dateTo);
      params = params.append('Repeat', ticketParams.repeat);
      params = params.append('TicketId', ticketParams.ticketId);

    }
    return this.http.get<Ticket[]>(this.baseUrl + 'ticket', {observe: 'response', params} )
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') !== null)
        {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }

        return paginatedResult;
      })
    );
  }

  updateTicket(id: number, ticket: Ticket)
  {
    return this.http.put(this.baseUrl + 'ticket/' + id, ticket);
  }



  getMessages(id: number, page?, itemsPerPage?, messageContainer?)
  {
    const paginatedResult: PaginatedResult<Message[]> = new PaginatedResult<Message[]>();

    let params = new HttpParams();
    params = params.append('MessageContainer', messageContainer);

    if (page != null && itemsPerPage != null)
    {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }

    return this.http.get<Message[]>(this.baseUrl  + 'messages', {observe: 'response', params} )//+ 'Ticket/' + id
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') !== null)
        {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }

        return paginatedResult;
      })
    );
  }

  // tslint:disable-next-line: typedef
  getMessageThread(userId: number, ticketId: number){
    return this.http.get<Message[]>(this.baseUrl+ 'messages/thread/' + ticketId+"/user/"+userId);//+ 'Ticket/' + userId + me
    // api/ticket/{userId}/[controller]/thread/ticketid
  }

  sendMessage(userId: number, message: Message)
  {
    // let x = this.http.post(this.baseUrl + 'Ticket/' + userId + '/messages', message);
    return this.http.post(this.baseUrl + 'messages/'+userId, message);//+ 'Ticket/' + userId 
  }

  markAsRead(userId: number, messageId: number)
  {
    return this.http.post(this.baseUrl  + 'messages/' + messageId + '/read'+userId, {})//+ 'Ticket/' + userId
    .subscribe();

  }


  updateSolvers(editingUserId: number, ticketId: number, solvers: {})
  {
    return this.http.post(this.baseUrl + 'Ticket/editSolvers/' + editingUserId + '/ticket/' + ticketId, solvers);
  }
}
