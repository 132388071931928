<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="ml-3 mr-3 mt-2 ">

  <form [formGroup]="purchaseDepartmentOrderAddForm" (ngSubmit)="createOrder()">
    <div class="form-group">
      <select  formControlName="orderedFromId" class="form-control" id="orderedFromId"  placeholder="Nákupčí"  name="orderedFromId" 
      [ngClass]="{ 'is-invalid': purchaseDepartmentOrderAddForm.get('orderedFromId').errors && purchaseDepartmentOrderAddForm.get('orderedFromId').touched}">
      <option  value="0">Vyberte nákupčího</option>
      <!-- <option *ngIf="" value="">Vyberte dodavatele</option> -->
      <option *ngFor="let user of coreUsers" [value] = "user.id">
         {{user.firstName}} {{user.lastName}}  
      </option>
      </select>
      <div class="invalid-feedback">Nákupčí je povinné pole</div>
    </div>

    <div class="form-group form-inline ml-1">
      <h4 class="mr-2">Datum objednávky : </h4>
      <input type="date" [ngClass]="{'is-invalid': purchaseDepartmentOrderAddForm.get('dateOfOrder').errors && purchaseDepartmentOrderAddForm.get('dateOfOrder').touched }" class="form-control is-invalid" 
      formControlName="dateOfOrder" />
    </div>
  </form>


    <div class="form-group form-inline ml-1">
      <div style="width: 90%;">
        <div  class="form-inline">
          <strong style="width: 50%;">Název</strong>
          <strong style="width: 50%;">Obj. mn</strong>
        </div>
      </div>
      </div>

    <div *ngFor="let orderedItem of purchaseDepartmentItems" >
      <div *ngIf="orderedItem.deleted === false" class="form-group form-inline ml-1">
      <div style="width: 90%;">
        <div  class="form-inline">
          <input (change)="checkItems()" [ngClass]="{'border-danger' : orderedItem.name === undefined || orderedItem.name === ''}"  style="width: 50%;" class="form-control" placeholder="Název"  [(ngModel)]="orderedItem.name" name="name"/>
          <div class="invalid-feedback" *ngIf="">Název je povinné pole</div>
          <input (change)="checkItems()" [ngClass]="{'border-danger' : orderedItem.amount === 0 || orderedItem.amount === null}" style="width: 50%;" type="number" min="0" class="form-control" placeholder="obj. množství"  [(ngModel)]="orderedItem.amount" />
        </div>
      
      </div>
      <a></a>
      <button  type="button" class="btn btn-primary " style="width: 10%; " (click)="removeItem(orderedItem.id,orderedItem.dynamicId)"><strong>X</strong></button>
      </div>
  </div>

  <button type="button" class="btn btn-primary w-100" (click)="addEmptyItem()">Přidat položku</button>

 
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button [disabled]="!(purchaseDepartmentOrderAddForm.valid  && itemsValid)" type="button" class="btn btn-success" form="purchaseDepartmentOrderAddForm" (click)="createOrder()">Uložit</button>
  <!-- && orderedItems != undefined && orderedItems.length > 0 -->
</div>