import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Invoice} from 'src/app/_models/invoice';
import {DomainsReportEntity, MonthlyReport} from 'src/app/_models/monthlyReport';
import {MonthlyReportEntity} from 'src/app/_models/monthlyReportEntity';
import {MonthlyReportService} from 'src/app/_services/monthlyReport.service';

import {Mailbox} from 'src/app/_models/mailbox';
import {SqlItem} from 'src/app/_models/sqlItem';
import {FileReportItem} from 'src/app/_models/fileReportItem';
import {AlertifyService} from "../../_services/alertify.service";

@Component({
  selector: 'app-invoice-invoiceMonthlyReport-modal',
  templateUrl: './invoiceMonthlyReport-modal.component.html'
})
export class InvoiceMonthlyReportModalComponent implements OnInit {
  @Output() Detailinvoice = new EventEmitter();
  invoice: Invoice;
  monthlyReport: MonthlyReport = new MonthlyReport();
  monthlyReportEntities: MonthlyReportEntity[];
  monthlyServerReportEntities: MonthlyReportEntity[] = [];
  monthlyPCReportEntities: MonthlyReportEntity[] = [];
  PCCount = 0;
  ServerCount = 0;
  mailboxes: DomainsReportEntity[];
  sqlItems: DomainsReportEntity[];
  fileReportItems: DomainsReportEntity[];
  private blob: Blob;
  selectedTab: string;

  constructor(
    public bsModalRef: BsModalRef,
    private reportService: MonthlyReportService,
    private alertify: AlertifyService) {
  }

  ngOnInit() {
    this.selectedTab = 'servers';

    const date = new Date(this.invoice.forMonth);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    this.reportService.getBasicReport(this.invoice.companyId, month, year)
      .subscribe((data: MonthlyReport) => {
        console.log(data)

        this.monthlyReport = data;
        this.monthlyReport.month = data.pcReport.reportedEntities[0]?.month;
        this.monthlyReport.year = data.pcReport.reportedEntities[0]?.year;
        this.monthlyReportEntities = data.pcReport.reportedEntities;
        this.mailboxes = data.mailboxes;
        this.sqlItems = data.sqlItems;
        this.fileReportItems = data.fileReportItems;

        this.monthlyServerReportEntities = [];
        this.monthlyPCReportEntities = [];
        this.ServerCount = 0;
        this.PCCount = 0;

        data.pcReport.reportedEntities.forEach(x => {
          const isServer = x.name.startsWith('S', 0);

          if (isServer) {
            this.monthlyServerReportEntities.push(x);
            this.ServerCount++;
          } else {
            this.monthlyPCReportEntities.push(x);
            this.PCCount++;
          }
        });

      }, error => {
        this.alertify.error(error);
      });

    // this.mrService.getMonthlyReport(this.invoice).subscribe(data => {
    //   this.monthlyReport = data;
    //   this.monthlyReport.month = data.pcReport.reportedEntities[0]?.month;
    //   this.monthlyReport.year = data.pcReport.reportedEntities[0]?.year;
    //   this.monthlyReportEntities = data.pcReport.reportedEntities;
    //   // this.mailboxes = data.mailboxes;
    //   this.sqlItems = data.sqlItems;
    //   // this.fileReportItems = data.fileReportItems;
    //
    //   data.pcReport.reportedEntities.forEach(x => {
    //     const isServer = x.name.startsWith('S', 0);
    //
    //     if (isServer) {
    //       this.monthlyServerReportEntities.push(x);
    //       this.ServerCount++;
    //     } else {
    //       this.monthlyPCReportEntities.push(x);
    //       this.PCCount++;
    //     }
    //
    //   });
    // });
  }

  downloadAsPDF() {
    this.reportService.getMonthlyPdfReport(this.invoice)
      .subscribe(data => {
        this.blob = new Blob([data], {type: 'application/pdf'});
        const downloadURL = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = downloadURL;
        link.download = 'monthly_report.pdf';
        link.click();
      });
  }

  selectTab(tabName: string) {
    this.selectedTab = tabName;
  }
}
