import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CompanydetailModalComponent } from 'src/app/company/companydetail-modal/companydetail-modal.component';
import { CompanyeditModalComponent } from 'src/app/company/companyedit-modal/companyedit-modal.component';
import { TicketaddModalComponent } from 'src/app/tickets/ticketadd-modal/ticketadd-modal.component';
import { WorkPostCreateModalComponent } from 'src/app/workPost/workPostCreate-modal/workPostCreate-modal.component';
import { WorkReportCreateModalComponent } from 'src/app/workreports/workreportcreate-modal/workreportcreate-modal.component';
import { WorkReportDetailModalComponent } from 'src/app/workreports/workreportdetail-modal/workreportdetail-modal.component';
import { Company } from 'src/app/_models/company';
import { PaginatedResult, Pagination } from 'src/app/_models/pagination';
import { Ticket } from 'src/app/_models/ticket';
import { WorkReport } from 'src/app/_models/workReport';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { TicketService } from 'src/app/_services/ticket.service';
import { WorkreportService } from 'src/app/_services/workreport.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'app-adminHome',
  templateUrl: './adminHome.component.html',
  styleUrls: ['./adminHome.component.scss']
})
export class AdminHomeComponent implements OnInit {
  filterParams: any = {};
  filterChangedFlag: boolean;
 
  ticketAddFlag = false;
  issuerticket = 'issuerTickets';
  solverticket = 'solverTickets';
  bsModalRef: any;
  companies: Company[];
  // unfilteredcompanies: Company[];
  companypagination: Pagination;
  tickets: Ticket[];
  ticketpagination: Pagination;
  monthHoursData: any;
  comapnyPercentsData: any;
  companyFilter:string;
  workreports: WorkReport[];
  odFilter:string;


  constructor(
    private companyDialService: CompanyDialService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private ticketService: TicketService,
    private workreportService: WorkreportService,
    private router: Router,
    private datePipe: DatePipe
    ) { }

  ngOnInit() {
    this.odFilter ="";
    this.setCharts();
    this.route.data.subscribe((data) => {
      this.companies = data['resolver']['Companies'].result;
      this.companypagination = data['resolver']['Companies'].pagination;
      this.tickets =  data['resolver']['Tickets'].result;
      this.ticketpagination = data['resolver']['Tickets'].pagination;
      this.filterParams.companyId = 0;
      this.filterParams.ticketId=0;
      // this.unfilteredcompanies = JSON.parse(JSON.stringify(this.companies));
    });
  }

  pageChanged(event: any): void{
    this.companypagination.currentPage = event.page;
    this.loadCompanies();
  }
  getTicketAddModal()
  {
    // this.uploadSuccess.emit(true);
    const initialState: any= {
      parentTicketId:0
    };
    this.bsModalRef = this.modalService.show(TicketaddModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.createNewTicket.subscribe((values) =>
    {
      this.ticketService.addTicket(values).subscribe(data => {
        this.alertify.success('Ticket úspěšně vytvořen');
      }, error => {
        this.alertify.error('Ticket se nepodařilo vytvořit');
      }, () => {
        // this.uploadSuccess.emit(true);
        this.ticketAddFlag = !this.ticketAddFlag;
      });
    });
  }

  anyCompanies()
  {
    if (this.companies === undefined)
    {
        return true;
    }
    if (this.companies.length === 0)
    {
        return false;
    }
    return true;
  }

  getCompanyDetailModal(company: Company)
  {
    const initialState: any= {
      company
    };
    this.bsModalRef = this.modalService.show(CompanydetailModalComponent, {initialState, backdrop:'static'});
  }

  loadCompanies() {
    var supplierBool= (this.odFilter ==="OD" || this.odFilter ==="D");
    var clientBool= (this.odFilter ==="OD" || this.odFilter ==="O");
    this.companyDialService.getCompanies( this.companypagination.currentPage, this.companypagination.itemsPerPage,this.companyFilter,false,supplierBool,clientBool)
    .subscribe((res: PaginatedResult<Company[]>) => {
      this.companies = res.result;
      this.companypagination = res.pagination;
    }, error => {
      this.alertify.error(error);
    });
   }


   setCharts()
  {
    this.setMonthHoursChart();
    this.setCompanyPercentChart();
  }
  setMonthHoursChart()
  {
    this.workreportService.getMonthHours(0).subscribe( datas =>
      {
        this.monthHoursData = [
          { name: 'Leden', value: datas[1] },
          { name: 'Únor', value: datas[2] },
          { name: 'Březen', value: datas[3] },
          { name: 'Duben', value: datas[4] },
          { name: 'Květen', value: datas[5] },
          { name: 'Červen', value: datas[6] },
          { name: 'Červenec', value: datas[7] },
          { name: 'Srpen', value: datas[8] },
          { name: 'Září', value: datas[9] },
          { name: 'Říjen', value: datas[10] },
          { name: 'Listopad', value: datas[11] },
          { name: 'Prosinec', value: datas[12] }
        ];
      });
  }

  setCompanyPercentChart()
  {
    this.workreportService.getCompanyPercent(0).subscribe( datas =>
      {
        this.comapnyPercentsData = [];
        Object.keys(datas).forEach( (key) => {
         this.comapnyPercentsData.push( { name: key, value: datas[key] });
        });
      });
  }

  setCompanyFilter()
  {
    // if(this.companyFilter.trim() === '')
    // {
    //   this.companies = this.unfilteredcompanies;
    // }
    // else
    // {
    //   this.companies = this.unfilteredcompanies.filter(x=>x.companyName.toLowerCase().startsWith(this.companyFilter.toLowerCase()));
    // }
    this.loadCompanies();
  }

  getWorkReportDetailModal(workReport: WorkReport)
  {
    const initialState: any= {
      workReport
    };
    this.bsModalRef = this.modalService.show(WorkReportDetailModalComponent, {initialState, backdrop:'static'});
  }

  createModal()
  {
    const initialState: any= {
    };
    this.bsModalRef = this.modalService.show(WorkReportCreateModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.createNewWorkReport.subscribe((values) =>
    {
      values.workFrom = this.datePipe.transform(values.workFrom, 'yyyy-MM-dd HH:mm')
      values.workTo = this.datePipe.transform(values.workTo, 'yyyy-MM-dd HH:mm')
      this.workreportService.addWorkReport(values).subscribe(() => {
        this.alertify.success('Úspěšně přidáno');
      }, error => {
        this.alertify.error('Výkaz se nepodařilo přidat');
      }, () => {
      });
    });
  }
}
