import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Company } from '../_models/company';
import { InventoryItem } from '../_models/inventoryItem';
import { PaginatedResult, Pagination } from '../_models/pagination';
import { AlertifyService } from '../_services/alertify.service';
import { AuthService } from '../_services/auth.service';
import { CompanyDialService } from '../_services/companyDial.service';
import { InventoryItemService } from '../_services/inventoryItem.service';
import { InventoryItemCreateModalComponent } from './inventoryItemCreate-modal/inventoryItemCreate-modal.component';
import { InventoryItemDetailModalComponent } from './inventoryItemDetail-modal/inventoryItemDetail-modal.component';
import { InventoryItemEditModalComponent } from './inventoryItemEdit-modal/inventoryItemEdit-modal.component';


@Component({
  selector: 'app-inventoryItems',
  templateUrl: './inventoryItems.component.html',
  styleUrls: ['./inventoryItems.component.css']
})
export class InventoryItemsComponent implements OnInit {
  bsModalRef: BsModalRef;
  invItems: InventoryItem[];
  companies: Company[];
  pagination: Pagination;
  filterParams: any = {};
  // companyFilter:string;

  constructor(
    private inventoryItemService: InventoryItemService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private companyService: CompanyDialService
  ) {}

  ngOnInit() {
    this.companyService.getCompanies(1,1).subscribe(data=>{this.companies =data.result; });
    this.route.data.subscribe((data) => {
      this.invItems = data['invItems'].result;
      this.pagination = data['invItems'].pagination;
   this.resetFilters();

    });
  }

  deleteObject(id: number)
  {
  //   this.alertify.confirm('Opravdu chcete vymazat tuto firmu ?', () => {
  //     this.companyDialService.deleteCompany(id)
  //     .subscribe(() => {
  //       this.companies.splice(this.companies.findIndex(m => m.id === id ), 1);
  //       this.alertify.success('Úspěšně odstraněno');
  //     }, error => {
  //       this.alertify.error('Firmu se nepodařilo odstranit');
  //     });
  //   });
  }

  loadItems() {

    this.inventoryItemService.getInvenotryItems( this.pagination.currentPage, this.pagination.itemsPerPage,0,this.filterParams)
    .subscribe((res: PaginatedResult<InventoryItem[]>) => {
      this.invItems = res.result;
      if(res.pagination !== undefined)
      {
        this.pagination.totalPages =res.pagination.totalPages;
        this.pagination.itemsPerPage =res.pagination.itemsPerPage;
        this.pagination.totalItems =res.pagination.totalItems;
      }

    }, error => {
      this.alertify.error(error);
    });
   }

  pageChanged(event: any): void{
    this.pagination.currentPage = event.page;
    this.loadItems();
  }


  getDetailModal(invItem: InventoryItem)
  {
    const initialState: any= {
      invItem
    };
    this.bsModalRef = this.modalService.show(InventoryItemDetailModalComponent, {initialState, backdrop:'static'});
  }

  getEditModal(invItem: InventoryItem)
  {
    const initialState: any= {
      invItem
    };
    this.bsModalRef = this.modalService.show(InventoryItemEditModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.editInventoryItem.subscribe((values) =>
    {

    this.inventoryItemService.EditCompositeItem(values.invItem,values.invItemsToAdd).subscribe((data) => {
        this.alertify.success('Úspěšně přidáno');
    }, error => {
      this.alertify.error('Složenou položku se nepodařilo přidat');
    }, () => {
      this.loadItems();
    });
     
    });
  }

  
  createNewItem()
  {
      const initialState: any= {
        companyId: this.filterParams.companyId
      };
      this.bsModalRef = this.modalService.show(InventoryItemCreateModalComponent, {initialState, backdrop:'static'});
  
      this.bsModalRef.content.createNewInventoryItem.subscribe((values) =>
      {
        this.inventoryItemService.AddCompositeItem(values.invItem,values.invItemsToAdd).subscribe((data) => {
            this.alertify.success('Úspěšně přidáno');
        }, error => {
          this.alertify.error('Složenou položku se nepodařilo přidat');
        }, () => {
          this.loadItems();
        });

      });
  }
  resetFilters()
  {
    this.filterParams.name = '';
    this.filterParams.serialNumber = '';
    this.filterParams.isContained = false;
    this.filterParams.hasContained = false;
    this.filterParams.delivered = false;
    this.filterParams.invoicedToClient = false;
    this.filterParams.reklamace = false;
    this.filterParams.inWarehouse = false;
    this.filterParams.clientId = 0;
    this.filterParams.supplierId = 0;
    this.filterParams.flag ='';
    
    this.filterParams.isForInvoice = false;
    this.filterParams.getPrimaryCompany = false;
    this.loadItems();
  }

  setFilters()
  {
    this.loadItems();
  }

}
