<div (click)="toggle()"
     (keydown)="onKeyDown($event)"
     [attr.aria-expanded]="opened"
     [attr.aria-disabled]="disabled"
     [class.disabled]="disabled"
     [class.inverted]="opened"
     role="listbox"
     aria-haspopup="listbox"
     class="form-select form-control select3 d-flex"
     tabindex="0"
     #customSelectElement
     [attr.id]="selectId">

  <div *ngIf="loadingData" class="b-form__custom-dropdown--loading">
    <i class="fas fa-spinner fa-pulse me-2"></i>
  </div>

  <ng-container *ngIf="selectedItem == null || selectedItem.length === 0">
    <div class="selected-value__container selected-value__placeholder">
      {{emptyLabel}}
    </div>
  </ng-container>

  <ng-container *ngIf="multiple">
    <ng-container *ngIf="selectedItem != null && selectedItem.length > 0">
      <div class="selected-value__container">
        <ng-template
          [ngTemplateOutletContext]="{$implicit: selectedItem, multiple: multiple}"
          [ngTemplateOutlet]="selectedItemCustomTemplate ? selectedItemCustomTemplate : defaultSelectedItemTemplate"></ng-template>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!multiple">
    <ng-container *ngIf="selectedItem != null">
      <div class="selected-value__container">
        <ng-template
          [ngTemplateOutletContext]="{$implicit: selectedItem, multiple: multiple}"
          [ngTemplateOutlet]="selectedItemCustomTemplate ? selectedItemCustomTemplate : defaultSelectedItemTemplate"></ng-template>
      </div>
    </ng-container>
  </ng-container>

</div>

<ng-template #defaultSelectedItemTemplate let-selectedItem let-multiple="multiple">
  <ng-container *ngIf="multiple">
    <ng-container *ngFor="let item of selectedItem">
      {{item.name}}
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!multiple">
    {{selectedItem.name}}
  </ng-container>
</ng-template>
