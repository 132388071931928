<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <form [formGroup]="workPostEditForm" (ngSubmit)="editWorkPost()">
    <div class="form-group">
      <input  type="text" [ngClass]="{'is-invalid': workPostEditForm.get('name').errors && workPostEditForm.get('name').touched }" class="form-control is-invalid" 
      formControlName="name" placeholder="Název" />
      <div class="invalid-feedback">Název je povinné pole</div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button [disabled]="!workPostEditForm.valid" type="button" class="btn btn-success" form="workPostEditForm" (click)="editWorkPost()">Uložit</button>
</div>