import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from 'src/app/_models/company';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RegisterUserModalComponent } from './registerUser-modal/registerUser-modal.component';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { WorkPostService } from 'src/app/_services/workPost.service';
import { WorkPost } from 'src/app/_models/workPost';
import { UserNotificationService } from 'src/app/_services/userNotification.service';
import { User } from 'src/app/_models/user';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss']
})
export class AdminPanelComponent implements OnInit {
  filterParams: any = {};
  changeEventFlag: boolean;
  companies: Company[];
  bsModalRef: BsModalRef;
  wps: WorkPost[];
  
  constructor(
    private companyService: CompanyDialService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private alertify: AlertifyService,
    private router: Router,
    private wpService: WorkPostService,
    private notifService: UserNotificationService,
  ) {}

  ngOnInit() {
    this.wpService.getWorkPosts().subscribe(x=>{this.wps = x.result})
    this.companyService.getCompanies(1,1).subscribe((data) => {
      this.companies = data.result;
      this.filterParams.wokrPost ="";

      this.route.params.subscribe(params => {
        this.filterParams.companyId = params['filterParam'];
        if(this.filterParams.companyId === '0')
        {
          this.filterParams.companyId = this.authService.decodedToken.CompanyId;
          this.filterParams.workPost = "";
        }
        this.changeEventFlag = !this.changeEventFlag;
      });

    });
  }

  setFilters()
  {
    this.changeEventFlag = !this.changeEventFlag;
  }

  resetFilters()
  {
    this.filterParams.companyId = this.authService.decodedToken.CompanyId;
    this.filterParams.workPost = "";
    this.changeEventFlag = !this.changeEventFlag;
  }

  getRegisterUserModal()
  {
    // this.uploadSuccess.emit(true);
    const initialState: any= {
      filterParams: this.filterParams
    };
    this.bsModalRef = this.modalService.show(RegisterUserModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.registerNewUser.subscribe((values) =>
    {
      this.authService.register(values).subscribe((user: User) => {
         this.notifService.addDefaultUserNotifications(user.id).subscribe(()=>
         {
            this.alertify.success('Registrace proběhla úspěšně');
            this.router.navigate(['/admin/'+this.filterParams.companyId]);
            this.changeEventFlag = !this.changeEventFlag;
         },error=>{
            this.alertify.error(error);
          }, () =>{
            this.router.navigate(['/admin/'+this.filterParams.companyId]);
          }
          );
      });
    });
  }

}
