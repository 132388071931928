<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <form [formGroup]="licenceEditForm" (ngSubmit)="createLicence()">
    <div class="form-group">
      <input  type="text" [ngClass]="{'is-invalid': licenceEditForm.get('licenceCode').errors && licenceEditForm.get('licenceCode').touched }" class="form-control is-invalid" 
      formControlName="licenceCode" placeholder="Kód licence" />
      <div class="invalid-feedback">Kód licence je povinné pole</div>
    </div>
    <div class="form-group">
      <input  type="text" [ngClass]="{'is-invalid': licenceEditForm.get('name').errors && licenceEditForm.get('name').touched }" class="form-control is-invalid" 
      formControlName="name" placeholder="Název" />
      <div class="invalid-feedback">Název je povinné pole</div>
    </div>
    <div class="form-group">
      <input  type="number" [ngClass]="{'is-invalid': licenceEditForm.get('licencePrice').errors && licenceEditForm.get('licencePrice').touched }" class="form-control is-invalid" 
      formControlName="licencePrice" placeholder="Cena" />
      <div class="invalid-feedback">Cena je povinné pole</div>
    </div>
    <div class="form-group">
      <input  type="number" [ngClass]="{'is-invalid': licenceEditForm.get('payFrequence').errors && licenceEditForm.get('payFrequence').touched }" class="form-control is-invalid" 
      formControlName="payFrequence" placeholder="Frekvence" />
      <div class="invalid-feedback">Frekvence je povinné pole</div>
    </div>
    <div class="form-group">
      <select formControlName="supplierCompanyId" class="form-control" id="solverSelect"  placeholder="Firma"  name="solverSelect" 
      [ngClass]="{ 'is-invalid': licenceEditForm.get('supplierCompanyId').errors && licenceEditForm.get('supplierCompanyId').touched}">
      <option value="">Vyberte firmu</option>
      <option *ngFor="let company of companies" [value] = "company.id">
         {{company.companyName}} 
       </option>
      </select>
      <div class="invalid-feedback">Firma je povinné pole</div>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button [disabled]="!licenceEditForm.valid" type="button" class="btn btn-success" form="licenceEditForm" (click)="createLicence()">Uložit</button>
</div>