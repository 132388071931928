import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Company} from 'src/app/_models/company';
import {Invoice} from 'src/app/_models/invoice';
import {CompanyDialService} from 'src/app/_services/companyDial.service';
import {AuthService} from 'src/app/_services/auth.service';
import {PriceListItem} from 'src/app/_models/priceListItem';
import {PriceListService} from 'src/app/_services/pricelist.service';
import {AlertifyService} from 'src/app/_services/alertify.service';
import {DatePipe} from '@angular/common';
import {OrderService} from 'src/app/_services/order.service';
import {NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels} from '@techiediaries/ngx-qrcode';
import {InventoryItemService} from 'src/app/_services/inventoryItem.service';
import {InventoryItem} from 'src/app/_models/inventoryItem';
import {WorkreportService} from 'src/app/_services/workreport.service';
import {UserService} from 'src/app/_services/user.service';
import {SazbaDPH} from 'src/app/_models/sazbaDPH';
import {ToolsService} from 'src/app/_services/tools.service';
import {FormBuilder} from '@angular/forms';
import {ReportItemsBag} from 'src/app/_models/reportItemsBag';
import {InvoiceKeys} from 'src/app/constants/constants';

@Component({
  selector: 'app-invoiceDetail-modal',
  templateUrl: './invoicedetail-modal.component.html',
  styleUrls: ['./invoicedetail-modal.component.css']
})

export class InvoiceDetailModalComponent implements OnInit {
  @Output() Detailinvoice = new EventEmitter();
  invoice: Invoice;
  companyId: string;
  priceList: PriceListItem[];
  totalPrice: number;
  priceListMonthHours: PriceListItem;
  subscriber: Company;
  supplier: Company;
  listSazeb: SazbaDPH[] = [];
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = '';
  invItems: InventoryItem[];
  hoursToInvoice: ReportItemsBag[] = [];
  priceRas: number;
  priceOns: number;
  pricePC: number;
  priceProPC: number;
  priceServer: number;
  priceSQL: number;
  priceFiles: number;
  priceExchange: number;
  priceKm: number;
  invoiceConstants = InvoiceKeys;

  constructor(public bsModalRef: BsModalRef, private fb: FormBuilder, private companyService: CompanyDialService, private authService: AuthService,
              private wpService: WorkreportService, private userService: UserService, private toolsService: ToolsService,
              private priceListService: PriceListService, private alertify: AlertifyService, private datePipe: DatePipe, private orderService: OrderService, private inventoryService: InventoryItemService) {
  }

  ngOnInit() {
    this.userService.getUser(this.invoice.userId).subscribe(x => {
      this.invoice.user = x;
    });

    this.companyService.getCompanies(1, 10000, "", 'true').subscribe(data => {
      this.subscriber = data.result.filter(x => x.id.toString() === this.invoice.companyId.toString())[0];
      this.supplier = data.result.filter(x => x.id.toString() === this.invoice.supplierCompanyId.toString())[0];
      this.getOrders();
    });
  }

  roundTo(num) {
    return this.toolsService.roundTo(num);
  }

  onBtnPrintClick() {
    window.print();
  }

  getOrders() {
    this.wpService.getWpsForInvoice(
      this.invoice.companyId,
      this.invoice.forMonth,
      true,
      false,
      true,
      this.invoice.id)
      .subscribe(data => {
        this.hoursToInvoice = [];
        data.forEach((item) => this.hoursToInvoice.push(item));

        const filterParams: any = {};
        filterParams.companyId = this.invoice.companyId;
        filterParams.month = this.invoice.forMonth;
        filterParams.filterInvoiced = true;
        filterParams.deductHours = true;
        filterParams.getHours = false;

        this.priceListService.getPriceListItemsForOrderList(filterParams)
          .subscribe((res) => {
              if (res.body.length > 0) {
                this.priceRas = this.getPrice(res, InvoiceKeys.RASCode);
                this.priceOns = this.getPrice(res, InvoiceKeys.ONSCode);
                this.pricePC = this.getPrice(res, InvoiceKeys.PCCode);
                this.priceProPC = this.getPrice(res, InvoiceKeys.PCProCode);
                this.priceServer = this.getPrice(res, InvoiceKeys.ServerCode);
                this.priceSQL = this.getPrice(res, InvoiceKeys.SQLCode);
                this.priceFiles = this.getPrice(res, InvoiceKeys.FileCode);
                this.priceExchange = this.getPrice(res, InvoiceKeys.ExchangeCode);
                this.priceKm = this.getPrice(res, InvoiceKeys.KmCode);
              } else {
                this.priceRas = 0;
                this.priceOns = 0;
              }

              this.priceList = res.body.filter(x => x.order.amount > 0);

              console.log(this.priceList);

              this.setOriginalDicounts();
              this.getTotalPrice();
              this.getDPHSazby();
              this.priceListMonthHours = res.body.filter(x => x.companyName.includes('Vykázané hodiny') && x.order.amount > 0 && x.order.invoiceId === this.invoice.id)[0];
            },
            error => {
              this.alertify.error(error);
            });
        this.getInventoryItems();
      });
  }

  getPrice(res: any, key: string) {
    var values = res.body.filter(x => x.code === key);
    if (values && values.length > 0) {
      return values[0]?.price ?? 0;
    }
    return 0
  }

  setOriginalDicounts() {
    this.priceList.forEach(element => {
      element.price = element.price * ((100 - element.order.discountPercent) / 100);
    });
  }

  getTotalPrice() {
    this.totalPrice = 0;
    if (this.priceList != undefined) {
      this.priceList.forEach(element => {
        this.totalPrice = this.totalPrice + element.order.amount * element.price
      });
    }
    if (this.invItems != undefined) {
      if (this.invoice.recievedInvoice) {
        this.invItems.forEach(element => {
          this.totalPrice = this.totalPrice + element.amount * element.buyingPrice
        });
      } else {
        this.invItems.forEach(element => {
          this.totalPrice = this.totalPrice + element.amount * element.sellingPrice
        });
      }

    }
    if (this.hoursToInvoice !== undefined && this.hoursToInvoice.length > 0) {
      const hourSum = this.GetHoursSumForAllItems();
      this.totalPrice = this.totalPrice + hourSum;
    }

    this.value = 'SPD*1.0*ACC:' + this.supplier.accountNumber + '*AM:' + (this.totalPrice * 1.21).toString() + '*CC:CZK*DT:' + this.datePipe.transform(this.invoice.dateToPay, 'yyyyMMdd').toString() + '*MSG:*X-KS:' + this.invoice.constantSymbol + '*X-SS:*X-VS:' + this.invoice.invoiceNumber;
  }

  getInventoryItems() {
    const filterParams: any = {};
    filterParams.name = '';
    filterParams.hasContained = null;
    filterParams.delivered = null;
    filterParams.supplierId = 0;
    filterParams.reklamace = null;
    filterParams.inWarehouse = null;
    filterParams.clientId = 0;
    filterParams.isForInvoice = false;
    filterParams.isContained = false;
    filterParams.getPrimaryCompany = false;

    if (!this.invoice.recievedInvoice) {
      filterParams.clientInvoiceId = this.invoice.id;
      filterParams.invoiceId = 0;
      filterParams.invoicedToClient = true;
    } else {
      filterParams.invoiceId = this.invoice.id;
      filterParams.clientInvoiceId = 0;
      filterParams.invoicedToClient = null;
      filterParams.isForInvoice = null;
    }
    this.inventoryService.getInvenotryItems(1, 1, 0, filterParams)
      .subscribe(data => {
      this.invItems = data.result;
      this.getTotalPrice();
    });
  }

  getTotalPriceWithDPH() {
    let res = 0;
    if (this.priceList != undefined) {
      this.priceList.forEach(element => {
        res = res + element.order.amount * element.price
      });
    }
    if (this.hoursToInvoice !== undefined && this.hoursToInvoice.length > 0) {
      const hourSum = this.GetHoursSumForAllItems();
      res = res + hourSum;
    }

    res = res * 1.21;
    if (this.invItems != undefined) {
      this.invItems.forEach(element => {
        if (this.invoice.recievedInvoice) {
          res = res + ((element.amount * element.buyingPrice) * (1 + (element.dphBuying / 100)));
        } else {
          if (element.invoicedToClient) {
            res = res + ((element.amount * element.sellingPrice) * (1 + (element.dphSelling / 100)));
          }
        }
      });
    }
    return res;
  }

  getDPHSazby() {
    if (this.invItems != undefined) {
      const sazby: any[] = [];
      this.invItems.forEach(element => {
        if (this.invoice.recievedInvoice) {
          sazby.push(element.dphBuying);
        } else {
          sazby.push(element.dphSelling);
        }
      });

      sazby.forEach(sazba => {
        const sz = new SazbaDPH();
        sz.price = 0;
        sz.sazbaDPH = sazba;

        this.invItems.forEach(item => {
          if (this.invoice.recievedInvoice) {
            if (item.dphBuying === sazba) {
              sz.price = sz.price + item.buyingPrice
            }
          } else {
            if (item.dphSelling === sazba) {
              sz.price = sz.price + item.sellingPrice
            }
          }

        });

        this.listSazeb.push(sz);
      });
    }
    this.addOthersToSazba();
  }

  GetHoursSumForAllItems(): number {
    var hourSum = ((this.hoursToInvoice.filter(x => x.code === InvoiceKeys.SQLCode)[0].count / 100) * this.priceSQL)
      + ((this.hoursToInvoice.filter(x => x.code === InvoiceKeys.ExchangeCode)[0].count / 100) * this.priceExchange)
      + ((this.hoursToInvoice.filter(x => x.code === InvoiceKeys.FileCode)[0].count / 100) * this.priceFiles)
      + (this.hoursToInvoice.filter(x => x.code === InvoiceKeys.PCCode)[0].count * this.pricePC)
      + (this.hoursToInvoice.filter(x => x.code === InvoiceKeys.PCProCode)[0].count * this.priceProPC)
      + (this.hoursToInvoice.filter(x => x.code === InvoiceKeys.ServerCode)[0].count * this.priceServer)
      + (this.hoursToInvoice.filter(x => x.code === InvoiceKeys.RASCode)[0].count * this.priceRas)
      + (this.hoursToInvoice.filter(x => x.code === InvoiceKeys.ONSCode)[0].count * this.priceOns)
      + (this.hoursToInvoice.filter(x => x.code === InvoiceKeys.KmCode)[0].count * this.priceKm)
    ;

    return hourSum;
  }

  addOthersToSazba() {
    let others = 0;
    if (this.priceList != undefined) {
      this.priceList.forEach(element => {
        others = others + element.order.amount * element.price
      });
    }
    if (this.hoursToInvoice !== undefined && this.hoursToInvoice.length > 0) {
      const hourSum = this.GetHoursSumForAllItems();
      others = others + hourSum;
    }
    if (others != 0) {
      const sazba21other = this.listSazeb.filter(x => x.sazbaDPH === 21)[0];
      if (sazba21other === undefined) {
        const sz = new SazbaDPH();
        sz.price = others;
        sz.sazbaDPH = 21;
        this.listSazeb.push(sz);
      } else {
        sazba21other.price = sazba21other.price + others;
      }
    }
  }
}
