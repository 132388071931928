import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { retry } from 'rxjs/operators';
import { Company } from 'src/app/_models/company';
import { InventoryItem } from 'src/app/_models/inventoryItem';
import { ItemOrder } from 'src/app/_models/itemOrder';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CompanyDialService } from 'src/app/_services/companyDial.service';

@Component({
  selector: 'app-itemOrderCreate-modal',
  templateUrl: './itemOrderCreate-modal.component.html',
  styleUrls: ['./itemOrderCreate-modal.component.scss']
})
export class ItemOrderCreateModalComponent implements OnInit {
  @Output() createItemOrder = new EventEmitter();
  itemOrder: ItemOrder;
  itemOrderAddForm: FormGroup;
  primaryComp: Company;
  companies: Company[];
  companiesClient: Company[];
  itemsValid :boolean=false;

  companyId: string;
  // month: string;
  invItems: InventoryItem[]= [];
  // totalPrice: number;

  constructor(public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private companyService: CompanyDialService,
    private authService: AuthService,
    // private priceListService: PriceListService,
    // private inventoryService: InventoryItemService,
    private alertify: AlertifyService,
    private datePipe: DatePipe) {}

  ngOnInit() {
    this.createForm();
    this.companyService.getCompanies(1,1).subscribe(data => {
      this.companies = data.result;
      this.companiesClient = data.result.filter(x=>x.client ==true || x.isPrimary == true);

      this.primaryComp =data.result.filter(x=>x.isPrimary)[0];
      if (this.companyId !== '0')
      {
        this.companies = data.result.filter(x => x.id.toString() === this.companyId.toString());
      }
      else
      {
        this.companies = data.result;
      }
      this.companies = this.companies.filter(x=>x.supplier ==true);
    this.addEmptyInvoiceItem();
    });
  }


  // tslint:disable-next-line: typedef
  createForm(){
    this.itemOrderAddForm = this.fb .group({
      dateOfOrder: [this.datePipe.transform(Date.now(), 'yyyy-MM-dd'), Validators.required],

      createdById: [this.authService.decodedToken.nameid, Validators.required],
      supplierId: ['', Validators.required],
      customerId: [0, Validators.required],
      orderState: ['new order'],
      deleted: [false],
      
    });

   if(this.companyId !='0')
   {
    this.itemOrderAddForm.patchValue({supplierId: this.companyId})
   }
  
  }
  createOrder()
  {
    if (this.itemOrderAddForm.valid)
    {

      this.itemOrder = Object.assign({}, this.itemOrderAddForm.value);
    
      this.itemOrder.items = this.invItems;

      this.createItemOrder.emit(this.itemOrder);
      this.bsModalRef.hide();
    }
  }

  addEmptyInvoiceItem()
  {
    let  emptyInvItem = {} as InventoryItem;
    emptyInvItem.clientCompanyId =this.primaryComp.id;
    emptyInvItem.deliveredToClient=false;
    emptyInvItem.isContainedInAnotherItem=false;
    emptyInvItem.isInWarehouse=true;
    emptyInvItem.isOnComplaint=false;
    emptyInvItem.deleted=false;
    emptyInvItem.idOfItemInWitchContained=0;
    emptyInvItem.invoiceId = this.invItems.reduce((op, item) => op = op > item.invoiceId ? op : item.invoiceId, 0)+1;
    emptyInvItem.invoicedToClient =false;
    emptyInvItem.clientInvoiceId =0;

    emptyInvItem.amount =0;
    emptyInvItem.orderedAmount =0;
    emptyInvItem.flag ="";
    emptyInvItem.serialNumber ="";
    emptyInvItem.productNumber ="";
    emptyInvItem.dphBuying =21;
    emptyInvItem.dphSelling =21;
    

    
    this.invItems.push(emptyInvItem);
  }

  removeInvoiceItem(id: number)
  {
    this.invItems = this.invItems.filter(x=>x.invoiceId != id);
  }

  checkInvItems()
  {
    var error = this.invItems.filter(x=>
      x.orderedAmount === 0 || x.orderedAmount === null ||
       x.buyingPrice === undefined || x.buyingPrice === null ||
       x.name === undefined || x.name === '' || x.name === null ||
       x.flag === undefined || x.flag ==='' || x.dphBuying === undefined
      ).length>0;

    this.itemsValid = !error;
  }

 
}
