import { DatePipe } from '@angular/common';
import { unescapeIdentifier } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { WorkReportCreateModalComponent } from 'src/app/workreports/workreportcreate-modal/workreportcreate-modal.component';
import { Message } from 'src/app/_models/message';
import { Ticket } from 'src/app/_models/ticket';
import { User } from 'src/app/_models/user';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { EmailService } from 'src/app/_services/email.service';
import { TicketService } from 'src/app/_services/ticket.service';
import { WorkreportService } from 'src/app/_services/workreport.service';
import { TicketaddModalComponent } from '../ticketadd-modal/ticketadd-modal.component';
import { TicketDataEditModalComponent } from '../ticketDataEdit-modal/ticketDataEdit-modal.component';
import { TicketSolverEditModalComponent } from '../ticketSolverEdit-modal/ticketSolverEdit-modal.component';

@Component({
  selector: 'app-ticket-detail-modal',
  templateUrl: './ticket-detail-modal.component.html',
  styleUrls: ['./ticket-detail-modal.component.css']
})
export class TicketDetailModalComponent implements OnInit {
  ticket: Ticket;
  ticketId: number;
  isAdmin: boolean;
  alreadySolver: boolean;
  coreUsers: User[];
  currentUserId: number;
  changeEventFlag: boolean;
  emailNotificationsOn: boolean;
  bsModalRef: BsModalRef;
  newMessage: any = {};
  isInSolverGroup:boolean= false;
  isFromCompany: boolean;
  @ViewChild('memberTabs', {static: true}) memberTabs: TabsetComponent;

  constructor(public bsModalRefDetail: BsModalRef,
    private route: ActivatedRoute,
    private authService: AuthService,
    private ticketService: TicketService,
    private modalService: BsModalService,
    private alertifyService: AlertifyService,
    private emailNotificationService: EmailService,
    private datePipe: DatePipe,
    private alertify: AlertifyService,
    private workreportService: WorkreportService,
    ) { }

  ngOnInit() {
    this.route.data.subscribe(data => {
      // this.ticket = data['ticket'];
      // if(this.ticket === undefined )
      // {
        this.ticketService.getTicket(this.ticketId).subscribe(x=>{ this.ticket = x})
      // }
      this.currentUserId = this.authService.decodedToken.nameid;
    });
    this.setIsAdmin();
    this.setAlreadySolver();
    this.setMyEmailNotification();
    this.setIsFromCompany();
    this.setIsInSolverGroup()
  }

  setIsFromCompany()
  {
    this.isFromCompany =   this.authService.decodedToken.CompanyId ===  this.ticket.issuer.company.id.toString();
  }
  setIsAdmin()
  {
    const role: string[] = this.authService.decodedToken.role;
    if (typeof(role) === 'string')
    {
      if(role === 'TicketAdmin')
      {
        this.isAdmin = true;
      }
      else
      {
        this.isAdmin = false;
      }
    }
    else
    {
      this.isAdmin = role.filter(x => x === 'TicketAdmin').length === 1;
    }
  }

  setIsInSolverGroup()
  {
    
   var a = this.ticket.solvers.filter(x=>x.userId.toString() === this.authService.decodedToken.nameid.toString())[0];
   this.isInSolverGroup= a !== undefined; 
  }

  setAlreadySolver()
  {
    if (this.ticket.solvers.length > 0)
    {
      if (this.ticket.issuerId.toString() !== this.authService.decodedToken.nameid.toString())
      {
        this.alreadySolver = this.ticket.solvers.filter(x => x.userId.toString() === this.authService.decodedToken.nameid.toString()).length >= 1;
      }
      else
      {
        this.alreadySolver = true;
      }
    }
    else
    {
      if (this.ticket.issuerId.toString() !== this.authService.decodedToken.nameid.toString())
      {
        this.alreadySolver = false;
      }
      else
      {
        this.alreadySolver = true;
      }
    }
  }

  addUserAsSolver()
  {
    this.ticketService.addSolver(this.authService.decodedToken.nameid, this.ticket.id, this.authService.decodedToken.nameid).subscribe(data => {});
    // location.reload();
    this.ticketService.getTicket(this.ticket.id).subscribe(x=>{this.ticket = x; this.setAlreadySolver();});
  }

  selectTab(tabId: number)
  {
     this.memberTabs.tabs[tabId].active = true;
  }

  editSolversModal()
  {
      const initialState: any= {
        ticket: this.ticket
      };
      this.bsModalRef = this.modalService.show(TicketSolverEditModalComponent, {initialState, backdrop:'static'});
      this.bsModalRef.content.updateSelectedSolvers.subscribe((values) => {
        const solversToUpdate = {
          solverIds: [...values.filter(el => el.checked === true).map(el => el.id)]
        };
        if (solversToUpdate)
        {
          this.ticketService.updateSolvers(this.authService.decodedToken.nameid, this.ticket.id, solversToUpdate).subscribe(() => {
            // location.reload();
            this.ticketService.getTicket(this.ticket.id).subscribe(x=>{this.ticket = x;});
          }, error => {
            this.alertifyService.error(error);
          });
        }
      });

  }


  editTicketValues()
  {
    const initialState: any= {
      ticket: this.ticket
    };
    this.bsModalRef = this.modalService.show(TicketDataEditModalComponent, {initialState, backdrop:'static'});
      this.bsModalRef.content.editTicketEvent.subscribe((value:Ticket) => {
        this.ticketService.updateTicket(value.id, value).subscribe(() => {
          let message: any = {};
          message.senderId = this.authService.decodedToken.nameid;
          message.ticketId = this.ticket.id;
          message.content = this.getTextBasedOnChange(this.ticket,value)//'Změna některých z hodnot ticketu';
          this.ticketService.sendMessage(this.authService.decodedToken.nameid, message).subscribe(() => {
            // this.ticket = value;
           this.ticketService.getTicket(this.ticket.id).subscribe(x=> this.ticket =x );
            this.changeEventFlag = !this.changeEventFlag;
            }, error => {
            }, () => {
            });
        }, error => {
          this.alertifyService.error(error);
        });
      });
  }

  getTextBasedOnChange(oldTicket:Ticket, newTicket:Ticket)
  {
  
    if(oldTicket.deadLine != newTicket.deadLine)
    {
      return 'Změna deadline ticketu na ' + this.datePipe.transform(newTicket.deadLine, 'yyyy-MM-dd');
    }
    if(oldTicket.issuer.id != newTicket.issuer.id )
    {
      return 'Změna vlastníka ticketu na ' + newTicket.issuer.firstName + ' ' + newTicket.issuer.lastName;
    }
    if(oldTicket.issuerCompanyId != newTicket.issuerCompanyId)
    {
      return 'Změna firmy na ' + this.datePipe.transform(newTicket.deadLine, 'yyyy-MM-dd');
    }
    if(oldTicket.categoryId != newTicket.categoryId)
    {
      return 'Změna kategorie na ' + newTicket.category.companyName;
    }
    if(oldTicket.title != newTicket.title)
    {
      return 'Změna nadpisu na ' + newTicket.title;
    }
  }
  
  

 

  changeTicketStatus(newStatus: string)
  {
    this.ticket.status = newStatus;
    this.ticketService.updateTicket(this.ticket.id, this.ticket).subscribe(() => {
      let message: any = {};
      message.senderId = this.authService.decodedToken.nameid;
      message.ticketId = this.ticket.id;
      message.content = 'Změna stavu ticketu na ' + newStatus;
      this.ticketService.sendMessage(this.authService.decodedToken.nameid, message).subscribe(() => {
        if(newStatus === 'Vyřešeno')
        {
          this.sendMessage('Podůkol s číslem ticketu'+this.ticket.id+'byl označen jako vyřešený',this.ticket.parentTicketId);
          
        }
        this.changeEventFlag = !this.changeEventFlag;
        }, error => {
        }, () => {
        });
    }, error => {
    }, () => {
    });
  }

  createSecondaryTicket()
  {

  }

  getTicketAddModal()
  {
    // this.uploadSuccess.emit(true);
    const initialState: any= {
      // filterParams:this.filterParams
      parentTicketId:this.ticket.id,
      parentTicket:this.ticket
    };
    this.bsModalRef = this.modalService.show(TicketaddModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.createNewTicket.subscribe((values) =>
    {
      this.ticketService.addTicket(values).subscribe(data => {
        this.sendMessage('Vytvoření podůkolu ticket č.'+data.id,this.ticket.id);
        this.alertify.success('Ticket úspěšně vytvořen');
      }, error => {
        this.alertify.error('Ticket se nepodařilo vytvořit');
      }, () => {
        // this.uploadSuccess.emit(true);
        // this.ticketAddFlag = !this.ticketAddFlag;
      });
    });
  }

  sendMessage( message,ticketId){
    this.newMessage.senderId = this.authService.decodedToken.nameid;
    this.newMessage.ticketId = ticketId;
    this.newMessage.content = message;
    this.ticketService.sendMessage(this.authService.decodedToken.nameid, this.newMessage)
    .subscribe((message: Message) => {
      this.changeEventFlag = !this.changeEventFlag;
    }, error => {
      this.alertify.error(error);
    });
  }

  addEmailNotification()
  {
    const valueToSet: any = {};
    valueToSet.enabled = true;
    valueToSet.ticketId = this.ticket.id;
    valueToSet.userId = this.authService.decodedToken.nameid;

    this.emailNotificationService.updateEmailNotification(valueToSet).subscribe(data =>
      {
        this.emailNotificationsOn = true;
      });
  }

  removeEmailNotification()
  {
    const valueToSet: any = {};
    valueToSet.enabled = false;
    valueToSet.ticketId = this.ticket.id;
    valueToSet.userId = this.authService.decodedToken.nameid;

    this.emailNotificationService.updateEmailNotification(valueToSet).subscribe(data =>
      {
        this.emailNotificationsOn = false;
      });
  }

  setMyEmailNotification()
  {
    // this.emailNotificationService.getEmailNotifications(1,5).subscribe((data) =>
    // {
    // });
    this.emailNotificationService.getIsEnabled(this.authService.decodedToken.nameid, this.ticket.id).subscribe((data: boolean) =>
      {
        this.emailNotificationsOn = data;
      });
  }



  createWPModal()
  {
    let workreport: any = {};
    workreport.companyId = this.ticket.issuer.company.id;
    workreport.title = 'Ticket: ' + this.ticket.title;
    workreport.companyId = this.ticket.issuerCompanyId;
    if(workreport.title.length >40)
    {
      workreport.title = workreport.title.substring(0,37)+"...";
    }
    workreport.ticketId = this.ticket.id;

    const initialState: any= {
      workreport
    };
    this.bsModalRef = this.modalService.show(WorkReportCreateModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.createNewWorkReport.subscribe((values) =>
    {
      this.workreportService.addWorkReport(values).subscribe(() => {
        this.alertify.success('Úspěšně přidáno');
        this.changeEventFlag = !this.changeEventFlag;
      }, error => {
        this.alertify.error('Výkaz se nepodařilo přidat');
      }, () => {
      });
    });
  }

  checkSolversIsIssuer(ticket: Ticket, currentUserId:number)
  {
    if(ticket.issuerId == currentUserId && ticket.solvers.filter(x=>x.userId === ticket.issuerId).length > 0)
    {
      return true;
    }
    return false;
  }

}
