<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h4><strong>Nadpis:</strong> {{workReport.title}}</h4>
  <h4 *ngIf="workReport.transport"><strong>Cesta:</strong> <i class="fa fa-check-circle-o" style="color:grey"></i></h4>
  <h4 *ngIf="!workReport.transport"><strong>Cesta:</strong> <i class="fa fa-times-circle-o" style="color:grey"></i></h4>
  <h4 *ngIf="workReport.transport"><strong>Cesta km:</strong> {{workReport.transportKm}}</h4>
  <h4><strong>Od:</strong> {{workReport.workFrom | date:"HH:mm"}}</h4>
  <h4><strong>Do:</strong> {{workReport.workTo | date:"HH:mm"}}</h4>
  <h4><strong>Hodin:</strong> {{workReport.hours}}</h4>
  <h4><strong>Firma:</strong> {{workReport.company.companyName}}</h4>
  <h4 [routerLink]="['/ticketsdetail/', workReport.ticketId]"><strong>Ticket id:</strong> {{workReport.ticketId}}</h4>
  <h4><strong>Admin:</strong> {{workReport.user.firstName}} {{workReport.user.lastName}}</h4>
  <h4><strong>Vytvořeno:</strong> {{workReport.created | date:"dd.MM.yyyy"}}</h4>
  <h4><strong>Vytvořeno uživatelem:</strong> {{createdByuser?.firstName}} {{createdByuser?.lastName}}</h4>
  <div *ngIf="workReport.updatedByUser === null;">
    <h4><strong>Upraveno uživatelem:</strong></h4>
    <h4><strong>Poslední úprava:</strong></h4>
  </div>
  <div *ngIf="workReport.updatedByUser !== null;">
      <h4><strong>Upraveno uživatelem:</strong> {{workReport.updatedByUser.firstName}} {{workReport.updatedByUser.lastName}}</h4>
      <h4><strong>Poslední úprava:</strong> {{workReport.lastUpdate | date:'dd.MM.yyyy HH:mm'}}</h4>
  </div>
  <h4><strong>Za den:</strong> {{workReport.forDay | date:"dd.MM.yyyy"}}</h4>
  <h4 *ngIf="workReport.supportType === 'ONS'" > <strong>Km:</strong> {{workReport.transportKm}}</h4>
  <h4> <strong>Typ:</strong> {{workReport.supportType}}</h4>
  <h4><strong>Popis:</strong></h4>  
  <textarea readonly class="form-control" style="min-width: 100%" rows="8">{{workReport.description}}</textarea>
  <h4><strong>Interní poznámka:</strong></h4>
  <textarea readonly class="form-control" style="min-width: 100%" rows="8">{{workReport.internalNote}}</textarea>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
</div>