import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {BasePriceListItem} from 'src/app/_models/basePriceListItem';
import {Licence} from 'src/app/_models/licence';
import {LicenceService} from 'src/app/_services/licence.service';
import {deepCopy} from '../../core/_helpers/deep-copy';

@Component({
  selector: 'app-base-price-list-item-create-modal',
  templateUrl: './basepriceListItemCreate-modal.component.html',
  styleUrls: ['./basepriceListItemCreate-modal.component.scss']
})
export class BasePriceListItemCreateModalComponent implements OnInit {
  @Output() createNewBasePriceListItem = new EventEmitter();
  basePriceListItem: BasePriceListItem;
  filteredLicences: Licence[];
  licences: Licence[];
  selectedLicence: Licence;
  setLicences: Licence[];
  selectedLicenceId: number;
  minPrice: number = 0;

  constructor(public bsModalRef: BsModalRef,
              private licenceService: LicenceService
  ) {
  }

  ngOnInit() {
    this.setLicences = [];
    this.basePriceListItem = new BasePriceListItem();
    this.basePriceListItem.price = 0;
    this.basePriceListItem.priceInLicences = 0;
    this.basePriceListItem.unitCount = 1;
    this.basePriceListItem.isMonthly = true;

    // this.createForm();

    this.licenceService.getLicencesNoPagination().subscribe(data => {
      this.licences = data;

      for (const item of this.licences) {
        item.amount = 1;
      }

      this.filteredLicences = deepCopy(this.licences);

      if (this.filteredLicences.length > 0) {
        this.selectedLicence = this.filteredLicences[0];
        this.selectedLicenceId = this.filteredLicences[0].id;
      } else {
        this.selectedLicence = new Licence();
      }
    });
  }

  createBasePriceListItem() {
    this.createNewBasePriceListItem.emit({values: this.basePriceListItem, licence: this.setLicences});
    this.bsModalRef.hide();
  }

  recalculatePrice() {
    let licencePrice = 0;

    for (const item of this.setLicences) {
      licencePrice = licencePrice + item.amount * item.licencePrice;
    }

    this.basePriceListItem.priceInLicences = licencePrice;

    // this.setMinPrice();
  }
  addLicence(selectedLicence: Licence) {
    if (selectedLicence.amount === undefined || selectedLicence.amount === 0) {
      return;
    }

    this.setLicences.push(selectedLicence);
    this.recalculatePrice();

    this.filterLicences();
  }

  filterLicences() {
    this.filteredLicences = deepCopy(this.licences);
    for (const item of this.setLicences) {
      this.filteredLicences = this.filteredLicences.filter(x => x.id !== item.id);
    }

    if (this.filteredLicences.length > 0) {
      this.selectedLicence = this.filteredLicences[0];
      this.selectedLicenceId = this.filteredLicences[0].id;
    } else {
      this.selectedLicence = null;
    }
  }

  deleteLicence(index: number) {
    this.setLicences.splice(index, 1);
    this.recalculatePrice();
    this.filterLicences();
  }

  changeSelectedLicenceId($event: Event) {
    this.selectedLicence = this.licences.find(x => x.id === +this.selectedLicenceId);
  }

  changeUnitIsVariable($event: any) {
    if ($event) {
      this.basePriceListItem.variableType = 1;
    } else {
      this.basePriceListItem.variableType = null;
    }
  }
}
