import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Company } from '../_models/company';
import { PaginatedResult, Pagination } from '../_models/pagination';
import { Priority } from '../_models/priority';
import { AlertifyService } from '../_services/alertify.service';
import { AuthService } from '../_services/auth.service';
import { CompanyDialService } from '../_services/companyDial.service';
import { PriorityService } from '../_services/priority.service';
import { PriorityCreateModalComponent } from './prioritycreate-modal/prioritycreate-modal.component';
import { PriorityEditModalComponent } from './priorityedit-modal/priorityedit-modal.component';

@Component({
  selector: 'app-priority',
  templateUrl: './priority.component.html',
  styleUrls: ['./priority.component.scss']
})
export class PriorityComponent implements OnInit {
  bsModalRef: BsModalRef;
  priorities: Priority[];
  pagination: Pagination;
  companies: Company[];
  filterParams: any = {};

  constructor(
    private priorityDialService: PriorityService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private companyService: CompanyDialService
  ) {}

  ngOnInit() {
    
    this.route.data.subscribe((data) => {
      //  this.priorities = data['priorities'].result;
      this.pagination = data['priorities'].pagination;
    });
    this.companyService.getCompanies(1, 1,null,null,false,true).subscribe((data) => {
      this.companies = data.result;

      // if (this.filterParams.companyId !== '0')
      //   {
      //     this.companies = this.companies.filter(x => x.id.toString() === this.filterParams.companyId);
      //   }
      //   if(this.authService.decodedToken.IsPrimary === 'true')
      //   {
      //     this.companies = this.companies.filter(x => x.id.toString() === this.authService.decodedToken.CompanyId);
      //   }

        

      this.route.params.subscribe(params => {
        this.filterParams.companyId = params['filterParam'];
        if(this.authService.decodedToken.IsPrimary !== 'True')
        {
          this.filterParams.companyId = this.authService.decodedToken.CompanyId;
        }
        if(this.filterParams.companyId === '0')
         {
           this.filterParams.companyId = this.authService.decodedToken.CompanyId;
         }
         this.loadPriorities();
      });
    });
  }

  deletePriority(id: number)
  {
    this.alertify.confirm('Opravdu chcete vymazat tuto prioritu ?', () => {
      this.priorityDialService.deletePriority(id)
      .subscribe(() => {
        this.priorities.splice(this.priorities.findIndex(m => m.id === id ), 1);
        this.alertify.success('Úspěšně odstraněno');
      }, error => {
        this.alertify.error('Prioritu se nepodařilo odstranit');
      });
    });
  }

  loadPriorities() {
    this.priorityDialService.getPriorities( this.pagination.currentPage, this.pagination.itemsPerPage, this.filterParams)
    .subscribe((res: PaginatedResult<Priority[]>) => {
      this.priorities = res.result;
      // this.pagination = res.pagination;
      this.pagination.totalPages =res.pagination.totalPages;
      this.pagination.itemsPerPage =res.pagination.itemsPerPage;
      this.pagination.totalItems =res.pagination.totalItems;
    }, error => {
      this.alertify.error(error);
    });
   }

  pageChanged(event: any): void{
    this.pagination.currentPage = event.page;
    this.loadPriorities();
  }

  createModal()
  {
    const initialState: any= {
      companyId: this.filterParams.companyId
    };
    this.bsModalRef = this.modalService.show(PriorityCreateModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.createNewPriority.subscribe((values) =>
    {
      this.priorityDialService.addPriority(values).subscribe(() => {
        this.alertify.success('Úspěšně přidáno');
      }, error => {
        this.alertify.error('Prioritu se nepodařilo přidat');
      }, () => {
        this.loadPriorities();
      });
    });
  }

  getEditModal(priority: Priority)
  {
    const initialState: any= {
      priority,
      companyId: this.filterParams.companyId
    };
    this.bsModalRef = this.modalService.show(PriorityEditModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.editpriority.subscribe((values) =>
    {
      this.priorityDialService.updatePriority(priority.id, values).subscribe(() => {
        this.alertify.success('Úspěšně upraveno');
      }, error => {
        this.alertify.error('Prioritu se nepodařilo upravit');
      }, () => {
        this.loadPriorities();
      });
    });
  }

  resetFilters()
  {
    // this.filterParams.companyId = '0';
    this.filterParams.companyId = this.authService.decodedToken.CompanyId;
    this.loadPriorities();
  }

  compareIds(id1, id2)
  {
    return id1.toString()===id2;
  }

}
