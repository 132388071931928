<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRefDetail.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="container mt-4">
    <div class="row"> 
    <div class="col-sm-4">
      <div class="card">
        <div class="form-inline  m-4">
          <h3 class="m-0 mr-10">Ticket #{{ticket.id}} </h3>
          <button *ngIf="isFromCompany"  class="btn btn-sm  ml-3 btn-primary" (click)="createWPModal()">Vykázat</button>
          <button   class="btn btn-sm  ml-3 btn-primary" (click)="editTicketValues()"><i class="fa fa-edit ml-1"></i></button>
        </div>
        <div class="card-body">
          <div>
            <strong>Nadpis:</strong>
            <p>{{ticket.title}}</p>
  
            <strong>Zakladatel:</strong>
            <div class="form-inline mb-3">
              <p class="m-0 mr-10">{{ticket.issuer.firstName}} {{ticket.issuer.lastName}}</p>
              <!-- <button *ngIf="isFromCompany"  class="btn btn-sm  ml-3 btn-primary" (click)="changeIssuerModal()">Změnit</button> -->
            </div>
  
            <strong class="mt-3">Firma:</strong>
            <p>{{ticket.issuerCompany.companyName}}</p>
  
            <strong>Řešitelé:</strong>
            <div class="form-inline" *ngIf="ticket.solvers[0] !== null">
            <p *ngFor="let solver of ticket.solvers"> {{solver.user.firstName}} {{solver.user.lastName}}, </p>
            </div>
            <div class="btn-group d-flex">
              <button *ngIf="!alreadySolver" class="btn btn-primary w-100" (click)="addUserAsSolver()">Přidat se</button>
              <button *appHasRole="['TicketAdmin']" class="btn btn-sm btn-primary w-100" (click)="editSolversModal()" >Správa řešitelů</button>
            </div>
            <strong>Priorita:</strong>
            <p>{{ticket.priority.name}}</p>
  
            <strong>Kategorie:</strong>
            <p>{{ticket.category.name}}</p>
  
            <strong>Založeno:</strong>
            <p>{{ticket.assigned | date:"dd.MM.yyyy "}}</p>
            <!-- HH:MM -->
            <strong>DeadLine:</strong>
            <div class="form-inline mb-3">
              <p class="m-0">{{ticket.deadLine  | date:"dd.MM.yyyy"}}</p>
              <!-- <button  class="btn btn-sm  ml-3 btn-primary" (click)="editDeadlineModal()"><i class="fa fa-edit ml-1"></i></button> -->
            </div>
            
  
            <strong class="mt-3">Status:</strong>
            <p>{{ticket.status}}</p>
  
          </div>
          
        </div>
        <div class="card-footer">
          <div *ngIf="ticket.status === 'Nový ticket' && (currentUserId.toString() !== ticket.issuerId.toString() || checkSolversIsIssuer(ticket,currentUserId)) && alreadySolver" class="btn-group d-flex">
            <button class="btn btn-success w-100" (click)="changeTicketStatus('Řeší se')" >Řeší se</button>
            <button class="btn btn-primary w-100" (click)="changeTicketStatus('Vyřešeno')">Vyřešeno</button>
          </div>
          <div *ngIf="(ticket.status === 'Řeší se'|| ticket.status === 'Odpovězeno') 
          && (currentUserId.toString() !== ticket.issuerId.toString() || checkSolversIsIssuer(ticket,currentUserId)) && alreadySolver" class="btn-group d-flex">
            <button class="btn btn-success w-100" (click)="changeTicketStatus('Čeká se na odpověď zákazníka')" >Čeká se na odpověď zákazníka</button>
            <button class="btn btn-primary w-100" (click)="changeTicketStatus('Vyřešeno')">Vyřešeno</button>
          </div>
          <div *ngIf="(ticket.status === 'Čeká se na odpověď zákazníka') && 
                        (currentUserId.toString() !== ticket.issuerId.toString() || checkSolversIsIssuer(ticket,currentUserId)) && alreadySolver" class="btn-group d-flex">
            <button class="btn btn-primary w-100" (click)="changeTicketStatus('Vyřešeno')">Vyřešeno</button>
          </div>
  
          <div *ngIf="ticket.status === 'Čeká se na odpověď zákazníka' && (currentUserId.toString() === ticket.issuerId.toString() || isAdmin || checkSolversIsIssuer(ticket,currentUserId))" class="btn-group d-flex">
            <button class="btn btn-primary w-100" (click)="changeTicketStatus('Uzavřený')">Uzavřít ticket</button>
            <button class="btn btn-success w-100" (click)="changeTicketStatus('Odpovězeno')" >Odpovězeno</button>
          </div>
  
          <div *ngIf="ticket.status === 'Vyřešeno' && (currentUserId.toString() === ticket.issuerId.toString() || isAdmin|| checkSolversIsIssuer(ticket,currentUserId))" class="btn-group d-flex">
            <button class="btn btn-primary w-100" (click)="changeTicketStatus('Uzavřený')">Uzavřít ticket</button>
            <button class="btn btn-success w-100" (click)="changeTicketStatus('Řeší se')" >Vrátit k řešení</button>
          </div>
  
          <div *ngIf="(ticket.status === 'Nový ticket' ||  ticket.status === 'Řeší se' )&& (currentUserId.toString() === ticket.issuerId.toString() || checkSolversIsIssuer(ticket,currentUserId))" class="btn-group d-flex">
            <button class="btn btn-primary w-100" (click)="changeTicketStatus('Uzavřený')">Uzavřít ticket</button>
          </div>
  
          <div *ngIf="ticket.status === 'Uzavřený' && (currentUserId.toString() === ticket.issuerId.toString()|| checkSolversIsIssuer(ticket,currentUserId))" class="btn-group d-flex">
            <button class="btn btn-primary w-100" (click)="changeTicketStatus('Nový ticket')">Znovu otevřít</button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-8">
      <div *ngIf="isInSolverGroup" >
        <button class="btn btn-primary pull-right ml-1" (click)="getTicketAddModal()" >Vytvořit podůkol</button>
      </div>
      <div>
        <div *ngIf="emailNotificationsOn === true">
          <button class="btn btn-primary pull-right" (click)="removeEmailNotification()" >Vypnout emailové notifikace</button>
        </div>
        <div *ngIf="emailNotificationsOn === false">
          <button class="btn btn-success pull-right" (click)="addEmailNotification()" >Zapnout emailové notifikace</button>
        </div>
      </div>
      
  
        <tabset class="member-tabset" #memberTabs >
         
          <tab heading="Messages" style="height: 170%;">
           <app-ticket-messages [ticket]="ticket" [changeEventFlag] = "changeEventFlag" [isSolver]="alreadySolver"></app-ticket-messages>
          </tab>
        </tabset>
    </div>
  </div>
  </div>
  
</div>