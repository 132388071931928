<div class="modal-header">
  <h4>{{header}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="ml-3 mr-3 mt-2 ">

  <form [formGroup]="itemOrderEditForm" (ngSubmit)="createOrder()">
    <div *ngIf="itemOrder.orderState === 'new order'" class="form-group">
      <select  formControlName="supplierId" class="form-control" id="solverSelect"  placeholder="Firma"  name="solverSelect" 
      [ngClass]="{ 'is-invalid': itemOrderEditForm.get('supplierId').errors && itemOrderEditForm.get('supplierId').touched}">
      <option value="">Vyberte dodavatele</option>
      <option *ngFor="let company of companies" [value] = "company.id">
         {{company.companyName}} 
       </option>
      </select>
      <div class="invalid-feedback">Dodavatel je povinné pole</div>
    </div>
    <input  *ngIf="itemOrder.orderState !== 'new order'" hidden [ngClass]="{'is-invalid': itemOrderEditForm.get('supplierId').errors && itemOrderEditForm.get('supplierId').touched }" class="form-control is-invalid" >


    <div class="form-group form-inline ml-1">
      <h4 class="mr-2">Datum objednávky : </h4>
      <input type="date" [ngClass]="{'is-invalid': itemOrderEditForm.get('dateOfOrder').errors && itemOrderEditForm.get('dateOfOrder').touched }" class="form-control is-invalid" 
      formControlName="dateOfOrder" />
    </div>
  </form>

  <form *ngIf="itemOrder.orderState.includes('invoice') && invoiceAddForm !== undefined" [formGroup]="invoiceAddForm" >
    <div class="form-group form-inline ml-1">
      <h4 class="mr-2">Měsíc fakturace: </h4>
      <input   type="month" [ngClass]="{'is-invalid': invoiceAddForm.get('forMonth').errors && invoiceAddForm.get('forMonth').touched }" class="form-control is-invalid" 
      formControlName="forMonth" />
    </div>

    <div class="form-group">
      <input  type="text" [ngClass]="{'is-invalid': invoiceAddForm.get('invoiceNumber').errors && invoiceAddForm.get('invoiceNumber').touched }" class="form-control is-invalid" 
      formControlName="invoiceNumber" placeholder="Číslo faktury" />
      <div class="invalid-feedback">Číslo faktury je povinné pole</div>
    </div>

    <div class="form-group form-inline ml-1">
      <h4 class="mr-2">Datum splatnosti : </h4>
      <input  type="date" [ngClass]="{'is-invalid': invoiceAddForm.get('dateToPay').errors && invoiceAddForm.get('dateToPay').touched }" class="form-control is-invalid" 
      formControlName="dateToPay" />
    </div>

    <div class="form-group form-inline ml-1">
      <h4 class="mr-2">DÚZP : </h4>
      <input  type="date" [ngClass]="{'is-invalid': invoiceAddForm.get('duzp').errors && invoiceAddForm.get('duzp').touched }" class="form-control is-invalid" 
      formControlName="duzp" />
    </div>

    <div class="form-group">
      <input  type="text" [ngClass]="{'is-invalid': invoiceAddForm.get('constantSymbol').errors && invoiceAddForm.get('constantSymbol').touched }" class="form-control is-invalid" 
      formControlName="constantSymbol" placeholder="Konstantní symbol" />
      <div class="invalid-feedback">Konstantní symbol je povinné pole</div>
    </div>

    <div class="form-group">
      <input  type="text" [ngClass]="{'is-invalid': invoiceAddForm.get('variableSymbol').errors && invoiceAddForm.get('variableSymbol').touched }" class="form-control is-invalid" 
      formControlName="variableSymbol" placeholder="Variabilní symbol" />
      <div class="invalid-feedback">Variabilní symbol je povinné pole</div>
    </div>

    <div class="form-group">
      <select formControlName="formOfPayment" class="form-control" id="formOfPaymenSelect"  placeholder="Forma úhrady"  name="formOfPaymenSelect" 
      [ngClass]="{ 'is-invalid': invoiceAddForm.get('formOfPayment').errors && invoiceAddForm.get('formOfPayment').touched}" >
      <option value="Bankovní převod">Bankovní převod</option>
      <option value="Hotovost">Hotovost</option>
      <option value="Dobírka">Dobírka</option>
      <option value="Záloha">Záloha</option>
      </select>
      <div class="invalid-feedback">Forma úhrady je povinné pole</div>
    </div>


    <div class="form-group form-inline ml-1">
      <h4 class="mr-2">Zaplaceno : </h4>
      <input type="checkbox" [ngClass]="{'is-invalid': invoiceAddForm.get('paid').errors && invoiceAddForm.get('paid').touched }" class="form-control is-invalid" 
      formControlName="paid" />
    </div>

    <div *ngIf="invoiceAddForm.get('paid').value" class="form-group form-inline ml-1">
      <h4 class="mr-2">Datum zaplacení : </h4>
      <input  type="date" [ngClass]="{'is-invalid': invoiceAddForm.get('paidDate').errors && invoiceAddForm.get('paidDate').touched }" class="form-control is-invalid" 
      formControlName="paidDate" />
    </div>

  </form>

    <div class="form-group form-inline ml-1">
      <div style="width: 90%;">
        <div  class="form-inline">
          <strong style="width: 20%;">Název</strong>
          <strong style="width: 26%;">Product number</strong>
          <strong style="width: 26%;">Serial number</strong>
          <strong style="width: 10%;">Obj. mn</strong>
          <strong style="width: 18%;">Nákupní cena</strong>
        </div>
        <div  class="form-inline">
          <strong style="width: 30%;">Klient</strong>
          <strong style="width: 15%;">Příznak</strong>
          <strong style="width: 25%;">Záruka platná do</strong>
          <strong style="width: 15%;">Sazba DPH</strong>
          <strong *ngIf="itemOrder.orderState !== 'new order'" style="width: 15%;">Přij. mn.</strong>
           
        </div>
      </div>
      </div>
      <div *ngIf="duplicityFlag">SerialNumber nesmí být duplicitní</div>
      
      
    <div   *ngFor="let invItem of invItems" >
      <div *ngIf="!invItem.deleted" class="form-group form-inline ml-1">
      <div style="width: 90%;">
        <div  class="form-inline">
          <input (input)="checkInvItems()" [ngClass]="{'border-danger' : invItem.name === undefined || invItem.name === '' || invItem.name === null}" [disabled]="itemOrder.orderState.includes('invoice') " style="width: 20%;" type="text" class="form-control" placeholder="Název"  [(ngModel)]="invItem.name" name="name"/>
          <input (input)="checkInvItems()" [ngClass]="{'border-danger' : itemOrder.orderState === 'receipt'&& ( invItem.productNumber === undefined || invItem.productNumber === '' || invItem.productNumber === null)}" [disabled]="itemOrder.orderState.includes('invoice') " style="width: 26%;" type="text"  class="form-control" placeholder="Product No."  [(ngModel)]="invItem.productNumber"  />
          <input (input)="checkInvItems()" [ngClass]="{'border-danger' :itemOrder.orderState === 'receipt' &&( invItem.serialNumber=== undefined || invItem.serialNumber === '' || invItem.serialNumber === null)}" [disabled]="itemOrder.orderState.includes('invoice') " style="width: 26%;" type="text"  class="form-control" placeholder="Serial No."  [(ngModel)]="invItem.serialNumber" (input)="checkDuplicty()"/>

          <input (input)="checkInvItems()" [ngClass]="{'border-danger' : invItem.orderedAmount === 0 || invItem.orderedAmount === null}"  [disabled]="itemOrder.orderState.includes('invoice') " style="width: 10%;" type="number" min="0" class="form-control" placeholder="obj. množství"  [(ngModel)]="invItem.orderedAmount" />
          <input (input)="checkInvItems()" [ngClass]="{'border-danger' : invItem.buyingPrice === undefined || invItem.buyingPrice === null}"[disabled]="itemOrder.orderState.includes('invoice') " style="width: 18%;" type="number" min="0" class="form-control" placeholder="Nákupní cena"  [(ngModel)]="invItem.buyingPrice" />
        </div>
        <div  class="form-inline">
          
            <div style="width: 30%;" class="form-group">
              <select [disabled]="itemOrder.orderState.includes('invoice') " style="width: 100%;" class="form-control" [(ngModel)]="invItem.clientCompanyId" >
              <option value="0">Vyberte klienta</option>
              <option *ngFor="let company of companiesClient" [value] = "company.id">
                {{company.companyName}} 
              </option>
              </select>
              <div class="invalid-feedback">Klient je povinné pole</div>
            </div>
            <div style="width:15%;"  class="form-group">
              <select [disabled]="itemOrder.orderState.includes('invoice') " (input)="checkInvItems()" [ngClass]="{'border-danger' : invItem.flag === undefined || invItem.flag === ''}" style="width: 100%;" class="form-control" [(ngModel)]="invItem.flag" >
                <option value="">Vyberte příznak</option>
                <option value="r">Režie</option>
                <option value="zb">Zboží</option>
                <option value="sl">Služba</option>
                <option value="slr">Služba režie</option>
            
              </select>
              <div class="invalid-feedback">Klient je povinné pole</div>
            </div>
          <input type="date"  (input)="checkInvItems()" [ngClass]="{'border-danger' : invItem.warrantyTo === undefined || invItem.warrantyTo === null}"[disabled]="itemOrder.orderState.includes('invoice') " class="form-control"  
          [ngModel] ="invItem.warrantyTo | date:'yyyy-MM-dd'"  (input)="invItem.warrantyTo=$event.target.value"/>
          
          <input (input)="checkInvItems()" [ngClass]="{'border-danger' :  invItem.dphBuying === null}"  [disabled]="itemOrder.orderState.includes('invoice') " style="width: 10%;" type="number" min="0" class="form-control" placeholder="sazba DPH"  [(ngModel)]="invItem.dphBuying" />
          
          <input (input)="checkInvItems()"  [ngClass]="{'border-danger' : itemOrder.orderState !== 'receipt'&& ( invItem.amount === undefined ||invItem.amount === null)}" [disabled]="itemOrder.orderState.includes('invoice') " *ngIf="itemOrder.orderState !== 'new order'" style="width: 10%;" type="number" min="0" class="form-control" placeholder="přij. množství"  [(ngModel)]="invItem.amount" />

          
        </div>
      </div>
      <button *ngIf="itemOrder.orderState === 'new order'" type="button" class="btn btn-primary " style="width: 10%; height: 55pt;" (click)="removeInvoiceItem(invItem.id)"><strong>X</strong></button>
      </div>
  </div>

    



  <button *ngIf="itemOrder.orderState === 'new order'" type="button" class="btn btn-primary w-100" (click)="addEmptyInvoiceItem()">Přidat položku</button>

 
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button *ngIf="!itemOrder.orderState.includes('invoice')" [disabled]="(!(itemOrderEditForm.valid && invItems != undefined && invItems.length > 0 && itemsValid)) || duplicityFlag" type="button" class="btn btn-success" form="itemOrderEditForm" (click)="createOrder()">Uložit</button>
  <button *ngIf="itemOrder.orderState === 'accepted invoice'"[disabled]="(!(invoiceAddForm.valid && invItems != undefined && invItems.length > 0)) || duplicityFlag" type="button" class="btn btn-success" form="itemOrderEditForm" (click)="createInvoice()">Vytvořit fakturu</button>
  <button *ngIf="itemOrder.orderState === 'filled invoice'"[disabled]="(!(invoiceAddForm.valid && invItems != undefined && invItems.length > 0 )) || duplicityFlag" type="button" class="btn btn-success" form="itemOrderEditForm" (click)="saveInvoice()">Uložit fakturu</button>
  <button *ngIf="itemOrder.orderState === 'new order'" [disabled]="(!(itemOrderEditForm.valid && invItems != undefined && invItems.length > 0 && itemsValid)) || duplicityFlag" type="button" class="btn btn-primary" form="itemOrderEditForm" (click)="forwardToReceipt()"><i class="fa fa-forward" aria-hidden="true"></i> Příjemka</button>
  <button *ngIf="itemOrder.orderState === 'receipt'" [disabled]="(!(itemOrderEditForm.valid && invItems != undefined && invItems.length > 0 && itemsValid)) || duplicityFlag" type="button" class="btn btn-primary" form="itemOrderEditForm" (click)="forwardToInvoice()"><i class="fa fa-forward" aria-hidden="true"></i> Faktura</button>
</div>