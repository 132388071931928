<form #f="ngForm" (ngSubmit)="f.form.valid && createOrder()">
  <div class="modal-header">
    <h4>Vytvoření nové objednávky</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="ml-3 mr-3 mt-2 ">
    <div class="form-group">
      <label for="companyId">Zákazník:</label>
      <app-company-picker style="width: 250px"
                          id="companyId"
                          name="companyId"
                          [(ngModel)]="itemOrder.companyId"
                          #companyId="ngModel"
                          (change)="setCompanyToModel($event)"></app-company-picker>
      <div class="invalid-feedback">Firma je povinné pole</div>
    </div>

    <div class="form-group form-inline ml-1">
      <label for="dateOfOrder">Datum objednávky:</label>
      <input type="date" class="form-control ml-2" name="dateOfOrder" id="dateOfOrder"
             [(ngModel)]="itemOrder.dateOfOrder" #dateOfOrder="ngModel" required/>
      <div *ngIf="f.submitted && dateOfOrder.errors?.required" class="text-danger">Datum objednávky je povinné pole</div>
    </div>

    <table class="table table-hover" style="cursor: pointer">
      <tr>
        <!-- <th style="width: 15%"></th> -->
        <th style="width: 40%">Název</th>
        <th style="width: 20%">Obj. mn</th>
        <th style="width: 20%">Cena</th>
        <th style="width: 20%"></th>
      </tr>
      <tr *ngFor="let item of itemOrder.orderedItems; index as i">
        <td>{{item.name}}</td>
        <td>{{item.amount}}</td>
        <td>{{item.price}}</td>
        <td>
          <button class="btn btn-danger" (click)="removeInvoiceItem(i)"><i class="fa fa-trash"></i></button>
        </td>
      </tr>

      <tr *ngIf="selectedOrderItem != null">
        <!-- <th style="width: 15%"></th> -->
        <td>
          <input type="text" class="form-control" placeholder="Název"
                 [(ngModel)]="selectedOrderItem.name" name="name" id="name" #name="ngModel"/>
        </td>
        <td>
          <input min="0" type="number" class="form-control" placeholder="Množství"
                 [(ngModel)]="selectedOrderItem.amount" name="amount" id="amount" #amount="ngModel"/>
        </td>
        <td>
          <input min="0" type="number" class="form-control" placeholder="Cena"
                 [(ngModel)]="selectedOrderItem.price" name="price" id="price" #amount="ngModel"/>
        </td>
      </tr>
      <tr>
        <td>
          <button *ngIf="selectedOrderItem != null" type="button" class="btn btn-default"
                  (click)="addOrderItem(selectedOrderItem)"><i class="fa fa-plus"></i>&nbsp;Přidat položku
          </button>
        </td>
      </tr>
    </table>



<!--    <ng-container *ngIf="itemOrder.orderedItems.length > 0">-->
<!--    <div class="form-group form-inline ml-1">-->
<!--      <div style="width: 90%;">-->
<!--        <div class="form-inline">-->
<!--          <strong style="width: 50%;">Název</strong>-->
<!--          <strong style="width: 25%;">Obj. mn</strong>-->
<!--          <strong style="width: 25%;">Cena</strong>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    </ng-container>-->
<!--    <div *ngFor="let orderedItem of itemOrder.orderedItems; index as i">-->
<!--      <div class="form-group form-inline ml-1">-->
<!--        <div style="width: 90%;">-->
<!--          <div class="form-inline">-->
<!--&lt;!&ndash;            <input (change)="checkInvItems()"                   &ndash;&gt;-->
<!--&lt;!&ndash;                   style="width: 50%;" class="form-control" placeholder="Název" [(ngModel)]="orderedItem.name"&ndash;&gt;-->
<!--&lt;!&ndash;                   name="name"/>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;            <div class="invalid-feedback">Název je povinné pole</div>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;            <input (change)="checkInvItems()"                   &ndash;&gt;-->
<!--&lt;!&ndash;                   style="width: 25%;" type="number" min="0" class="form-control" placeholder="obj. množství"&ndash;&gt;-->
<!--&lt;!&ndash;                   [(ngModel)]="orderedItem.amount"/>&ndash;&gt;-->
<!--&lt;!&ndash;            <input (change)="checkInvItems()"                   &ndash;&gt;-->
<!--&lt;!&ndash;                   style="width: 25%;" type="number" min="0" class="form-control" placeholder="Cena"&ndash;&gt;-->
<!--&lt;!&ndash;                   [(ngModel)]="orderedItem.price"/>&ndash;&gt;-->


<!--&lt;!&ndash;            <input (change)="checkInvItems()"&ndash;&gt;-->
<!--&lt;!&ndash;                   [ngClass]="{'border-danger' : orderedItem.name === undefined || orderedItem.name === ''}"&ndash;&gt;-->
<!--&lt;!&ndash;                   style="width: 50%;" class="form-control" placeholder="Název" [(ngModel)]="orderedItem.name"&ndash;&gt;-->
<!--&lt;!&ndash;                   name="name"/>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="invalid-feedback">Název je povinné pole</div>&ndash;&gt;-->
<!--&lt;!&ndash;            <input (change)="checkInvItems()"&ndash;&gt;-->
<!--&lt;!&ndash;                   [ngClass]="{'border-danger' : orderedItem.amount === 0 || orderedItem.amount === null}"&ndash;&gt;-->
<!--&lt;!&ndash;                   style="width: 25%;" type="number" min="0" class="form-control" placeholder="obj. množství"&ndash;&gt;-->
<!--&lt;!&ndash;                   [(ngModel)]="orderedItem.amount"/>&ndash;&gt;-->
<!--&lt;!&ndash;            <input (change)="checkInvItems()"&ndash;&gt;-->
<!--&lt;!&ndash;                   [ngClass]="{'border-danger' : orderedItem.price === undefined || orderedItem.price === null}"&ndash;&gt;-->
<!--&lt;!&ndash;                   style="width: 25%;" type="number" min="0" class="form-control" placeholder="Cena"&ndash;&gt;-->
<!--&lt;!&ndash;                   [(ngModel)]="orderedItem.price"/>&ndash;&gt;-->
<!--          </div>-->

<!--        </div>-->
<!--        <a></a>-->
<!--        <button type="button" class="btn btn-primary " style="width: 10%; "-->
<!--                (click)="removeInvoiceItem(i)"><strong>X</strong></button>-->
<!--      </div>-->
<!--    </div>-->

<!--    <button type="button" class="btn btn-primary w-100" (click)="addEmptyInvoiceItem()">Přidat položku</button>-->


  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
    <button type="submit" class="btn btn-success">Uložit</button>
  </div>
</form>
