<div class=" m-5">
  <h2>Správa uživatelů</h2>

  <form *appHasRole="['CompanyFilter']" class="form-inline" #form ="ngForm" (ngSubmit)="setFilters()" novalidate>
    <div class="form-group px-2">
      <label for="company">Firma: </label>
      <select class="form-control ml-1" style="width: 130px" id="companyId" [(ngModel)]="filterParams.companyId" name="companyId">
        <option value="">Vyberte pracovníka</option>
        <option *ngFor="let company of companies" [value] = "company.id">
          {{company.companyName}}
        </option>
      </select>
    </div>
    <div class="form-group px-2">
      <!-- <label for="company">Pozice: </label> -->
      <select class="form-control ml-1" style="width: 150px" id="companyId" [(ngModel)]="filterParams.workPost" name="companyId">
        <option value="">Vyberte pozici</option>
        <option *ngFor="let wp of wps" [value] = "wp.name">
          {{wp.name}}
        </option>
      </select>
    </div>
    <button type="submit" class="btn btn-primary" style="margin-left:10px">Filtrovat</button>
    <button type="button" class="btn btn-info" style="margin-left:10px" (click)="resetFilters()" >Reset Filtrů</button>
    <button type="button" class="btn btn-primary pull right" style="margin-left:10px; margin-left: auto;" (click)="getRegisterUserModal()" >Registrace nového uživatele</button>

  </form>
  <br> 
<!-- on select change zavolam metodu ktera do flagu nastavi company a posle to jako input dal a tam se to vyfiltruje  -->


  <!-- <div class="tab-pannel"> -->
    <!-- <tabset class="member-tabset "> -->
      <!-- <tab heading="User Managemenet" > -->
        <!-- <div class="container"> -->
          <app-user-management  [filterParams] = "filterParams" [changeEventFlag] = "changeEventFlag"></app-user-management>
        <!-- </div> -->
      <!-- </tab> -->
    <!-- </tabset> -->
  <!-- </div> -->
</div>