<div class="modal-header ">
  <h4 class="modal-title pull-left">Úprava skupiny {{group.groupName}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div >
    <input  type="text" class="form-control" 
    placeholder="Název"  [(ngModel)]="group.groupName"/>
  </div>
  <form #rolesForm="ngForm" id="rolesForm">
    
<div class="form-inline">
  <div class="form-check w-50 d-flex flex-row " *ngFor="let role of roles">
      <input type="checkbox" class="form-check-input" value="{{role.name}}" [checked]="role.checked" (change)="role.checked =! role.checked">
      <label class="w-100 " style="justify-content: end;">{{role.name}}</label>
  </div>
  
</div>
  
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button type="button" class="btn btn-success" form="rolesForm" (click)="updateRows()">Uložit</button>
</div>