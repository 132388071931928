import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PaginatedResult, Pagination } from 'src/app/_models/pagination';
import { Solver } from 'src/app/_models/solver';
import { Ticket } from 'src/app/_models/ticket';
import { User } from 'src/app/_models/user';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { TicketService } from 'src/app/_services/ticket.service';
import { UserService } from 'src/app/_services/user.service';
import { TicketDetailModalComponent } from '../ticket-detail-modal/ticket-detail-modal.component';
import { TicketaddModalComponent } from '../ticketadd-modal/ticketadd-modal.component';

@Component({
  selector: 'app-my-tickets',
  templateUrl: './my-tickets.component.html',
  styleUrls: ['./my-tickets.component.scss']
})
export class MyTicketsComponent implements OnInit, OnChanges {
  @Input() ticketAddFlag: boolean;
  @Input() ticketFilter: string;
  @Input() filterParams: any;
  @Input() filterChangedFlag: boolean;
  @Input() adminHome: boolean;
  @Output() createTicketEvent = new EventEmitter();
  bsModalRef: BsModalRef;
  tickets: Ticket[];
  paginationShow: boolean = true;
  // solvers: Solver[];
  // users: User[];
  pagination: Pagination;
  private ngInitFlag = false ;
  // bindingObject: {id: number, name: string, status: string, issuer: string, solvernames: string[]}[] = [];

  constructor(
    private ticketService: TicketService,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    // private userService: UserService,
    private authService: AuthService,
    private datePipe: DatePipe,
    private modalService: BsModalService,
  ) {}

  ngOnInit() {

    this.route.data.subscribe((data) => {
      if (data['tickets'] === undefined)
      {
        //  this.tickets =  data['resolver']['Tickets'].result;
        this.pagination = data['resolver']['Tickets'].pagination;
        this.filterParams.companyId = 0;
        this.filterParams.state = '';
        this.filterParams.solver = '';
        this.filterParams.issuer = '';
        this.filterParams.orderBy = 'created';
        this.filterParams.categoryId = 0;
        this.filterParams.dateFrom = '1900-01-01';
        this.filterParams.dateTo = '2300-01-01';
        // this.loadTickets();
        // this.ngInitFlag = true;
        // const role: string[] = this.authService.decodedToken.role;
      }
      else
      {
        this.pagination = data['tickets'].pagination;
       
        this.route.params.subscribe(params => {
          this.filterParams.companyId = params['companyFilter'];
          if(this.filterParams.companyId === "1" && this.authService.decodedToken.IsPrimary)
        {
          this.filterParams.companyId = 0;
        }

          // this.loadTickets();
          // this.ngInitFlag = true;
          // const role: string[] = this.authService.decodedToken.role;
          // // this.loadTickets();
          // this.ngInitFlag = true;
        });
      }

      this.loadTickets();

        this.ngInitFlag = true;
        const role: string[] = this.authService.decodedToken.role;
    });
  }

 

  ngOnChanges(changes: SimpleChanges)
  {
    for (const propName in changes)
    {
      if (changes.hasOwnProperty(propName) && this.ngInitFlag) 
      {
        switch (propName) {
          case 'ticketAddFlag': {

            this.loadTickets();
           break;
          }
          case 'filterChangedFlag': {
            if (Object.keys(this.filterParams).length > 0)
            {
              this.loadTickets();
              break;
            }
        }
        default: {}
      }
    }
   }
  }

  loadTickets() {
    if(this.paginationShow)
    {

   
    this.paginationShow =false;
    if (this.pagination !== undefined)
    {
      this.ticketService.getTickets( this.pagination.currentPage, this.pagination.itemsPerPage, this.filterParams, this.authService.decodedToken.nameid, this.ticketFilter)
      .subscribe((res: PaginatedResult<Ticket[]>) => {
        
        this.pagination = res.pagination;

        // this.pagination.currentPage =res.pagination.currentPage;
        // this.pagination.totalPages =res.pagination.totalPages;
        // this.pagination.itemsPerPage =res.pagination.itemsPerPage;
        // this.pagination.totalItems =res.pagination.totalItems;
       
        this.tickets = res.result;
        this.paginationShow =true;
      }, error => {
        this.alertify.error(error);
      });
    }
  }
   }

  pageChanged(event: any): void{
    this.pagination.currentPage = event.page;
    this.loadTickets();
  }

  getTicketAddModal()
  {
    this.createTicketEvent.emit();
  }
  getTicketDetailModal(ticket)
  {
    const initialState:any = {
      ticket
    };
    this.bsModalRef = this.modalService.show(TicketDetailModalComponent, {initialState, class:'modal-xl', backdrop:'static'});
   
  }

  
}
