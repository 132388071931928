import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Company } from 'src/app/_models/company';
import { Licence } from 'src/app/_models/licence';
import { AuthService } from 'src/app/_services/auth.service';
import { CompanyDialService } from 'src/app/_services/companyDial.service';

@Component({
  selector: 'app-licencecreate-modal',
  templateUrl: './licencecreate-modal.component.html',
  styleUrls: ['./licencecreate-modal.component.css']
})
export class LicencecreateModalComponent implements OnInit {

  @Output() createNewLicence = new EventEmitter();
  licence: Licence;
  licenceAddForm: FormGroup;
  companies: Company[];
  companyId: string;


  constructor(public bsModalRef: BsModalRef, private fb: FormBuilder, private companyService: CompanyDialService, private authService: AuthService,private datePipe: DatePipe) {}

  ngOnInit() {
    this.createForm();
    this.companyService.getCompanies(1, 1,null,null,false,true).subscribe(data => {
      this.companies = data.result;
      // if (this.companyId !== '0')
      // {
      //   this.companies = data.result.filter(x => x.id.toString() === this.companyId);
      // }
      // else
      // {
        this.companies = data.result;
      // }
    });
  }


  // tslint:disable-next-line: typedef
  createForm(){
    this.licenceAddForm = this.fb .group({
      name: ['', Validators.required],
      supplierCompanyId: ['', Validators.required],
      payFrequence: ['',Validators.required],
      licenceCode: ['', Validators.required],
      licencePrice: ['', Validators.required],
      lastPayment: [this.datePipe.transform('1990-01-01', 'yyyy-MM-dd')],
    });
  }



  createLicence()
  {
    if (this.licenceAddForm.valid)
    {
      this.licence = Object.assign({}, this.licenceAddForm.value);
      this.createNewLicence.emit(this.licence);
      this.bsModalRef.hide();
    }
  }
}
