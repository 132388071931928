import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-uploadComponent',
  templateUrl: './uploadComponent.component.html',
  styleUrls: ['./uploadComponent.component.css']
})
export class UploadComponentComponent implements OnInit {
  public progress: number;
  public message: string;
  @Input() ticketId: string;
  @Output() public onUploadFinished = new EventEmitter();

  constructor(private http: HttpClient,private authService:AuthService) { }

  ngOnInit() {
  }

  // public uploadFile = (files) =>{
  //   if(files.length ===  0)
  //     return;

  //   let fileToUpload = <File>files[0];
  //   const formData = new FormData();
  //   formData.append('file',fileToUpload, fileToUpload.name);

  //   this.http.post(environment.apiUrl+'uploadCompone',formData,{ reportProgress: true, observe:'events'})
  //   .subscribe(event => {
  //     if(event.type === HttpEventType.UploadProgress)
  //     {
  //       this.progress = Math.round(100 * event.loaded / event.total);
  //     }
  //     else if(event.type === HttpEventType.Response)
  //     {
  //       this.uploadMessage  = 'Soubor byl úspěšně nahrán';
  //       this.onUploadFinisher.emit(event.body.toString());
  //     }
  //   });
  // }

  public uploadFile = (files) => {
    if (files.length === 0) {
      return;
    }
    for (let i = 0; i < files.length; i++) {
    let fileToUpload = <File>files[i];
    const formData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    this.http.post(environment.apiUrl+'upload/'+this.ticketId+'/user/'+this.authService.curretnUser.id, formData, {reportProgress: true, observe: 'events'})
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress)
          this.progress = Math.round(100 * event.loaded / event.total);
        else if (event.type === HttpEventType.Response) {
          this.message = 'Upload success.';
          this.onUploadFinished.emit(event.body);
        }
      });
    }

  }

}
