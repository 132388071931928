<div class="m-5">
  <h2>Domény a PC</h2>

  <form *appHasRole="['CompanyFilter']" class="form-inline" #form ="ngForm" (ngSubmit)="loadDomains()" novalidate>
    <div class="form-group px-2">
      <label for="companyId">Firma: </label>
      <app-company-picker style="width: 250px"
                          id="companyId"
                          name="companyId"
                          [(ngModel)]="filterParams.companyId"
                          #companyId="ngModel"
                          (change)="setCompanyToDomain($event)" required></app-company-picker>
    </div>
    <button type="submit" class="btn btn-primary" style="margin-left:10px">Filtrovat</button>
    <button type="button" class="btn btn-info" style="margin-left:10px" (click)="resetFilters()" >Reset Filtrů</button>
  </form>
  <br>
  <div *ngIf="domains !== undefined" class="m-5">
    <div *ngIf=" domains.length === 0" style="text-align: center">
      <h1>Zatím nejsou přidané žádné domény</h1>
    <button class="btn btn-primary m-3" (click)="createModal()" >Vytvořit novou doménu</button>
    </div>

  <div class="row" *ngIf="domains.length > 0">
    <table class="table table-hover" style="cursor: pointer">
      <button class="btn btn-primary" (click)="createModal()" >Přidat</button>
      <tr>
        <th style="width: 20%">Název</th>
        <th style="width: 20%">Doména</th>
        <th style="width: 40%">Firma</th>
        <!-- <th style="width: 20%">Počet Pc v doméně</th> -->
        <th style="width: 20%"></th>
      </tr>
      <tr *ngFor="let domain of domains" >
        <td (click)="getDetailModal(domain)">{{domain.name}}</td>
        <td (click)="getDetailModal(domain)">{{domain.domain}}</td>
        <td (click)="getDetailModal(domain)">
          <div>
                <strong>{{domain.company.companyName}}</strong>
          </div>
        </td>
        <!-- <td (click)="getDetailModal(domain)">{{domain.computers.length}}</td> -->

        <!-- <td *ngIf="domain.isSLA"><i class="fa fa-check-circle-o" style="color:grey"></i></td>
        <td *ngIf="!domain.isSLA"></td> -->
        <td>
          <div class="form-inline">
            <!-- <div *appHasRole="['PriorityEdit']"  >
              <button *ngIf="priority.isSLA" class="btn btn-info" (click)="$event.stopPropagation()" (click)="getEditModal(priority)" ><i class="fa fa-edit ml-1"></i></button>
            </div> -->
            <div >
              <button class="btn btn-primary mr-2" (click)="$event.stopPropagation()" (click)="getEditModal(domain)" ><i class="fa fa-edit ml-1"></i></button>
              <button class="btn btn-danger" (click)="$event.stopPropagation()" (click)="deleteDomain(domain.id)" ><i class="fa fa-trash"></i></button>
            </div>
          </div>
        </td>
      </tr>
    </table>

  </div>

</div>

<div class="d-flex justify-content-center">
    <pagination [maxSize]="3"
                [boundaryLinks]="true"
                [totalItems]="pagination.totalItems"
                [itemsPerPage]="pagination.itemsPerPage"
                [(ngModel)]="pagination.currentPage"
                (pageChanged)="pageChanged($event)"
              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
  </pagination>
  </div>
