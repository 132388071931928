import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { filter } from 'rxjs/operators';
import { Company } from 'src/app/_models/company';
import { PaginatedResult, Pagination } from 'src/app/_models/pagination';
import { User } from 'src/app/_models/user';
import { AdminService } from 'src/app/_services/admin.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { UserNotificationService } from 'src/app/_services/userNotification.service';
import { NotificationsSetModalComponent } from '../notificationsSet-modal/notificationsSet-modal.component';
import { PsswdChangeModalComponent } from '../psswdChange-modal/psswdChange-modal.component';
import { RolesModalComponent } from '../roles-modal/roles-modal.component';
import { UserDataDetailModalComponent } from '../userDataDetail-modal/userDataDetail-modal.component';
import { UserDataEditModalComponent } from '../userDataEdit-modal/userDataEdit-modal.component';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
  bsModalRef: BsModalRef;
  users: User[];
  @Input() filterParams: any;
  @Input() changeEventFlag: boolean;
  pagination: Pagination;

  constructor(private adminService: AdminService,
              private alertifyService: AlertifyService,
              private modalService: BsModalService,
              private route: ActivatedRoute,
              private authService: AuthService,
              private notifSerivce:UserNotificationService
              ) { }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      // this.users = data['users'].result;
      this.pagination = data['users'].pagination;
    });
    // this.getUsersWithRoles();
  }

  ngOnChanges(changes: SimpleChanges)
  {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'changeEventFlag': {
            if (Object.keys(this.filterParams).length > 0)
            {
              this.getUsersWithRoles();
            }
          }
          default: {}
        }
      }
    }
  }

  getUsersWithRoles (){
    if(this.authService.decodedToken.IsPrimary !== 'True')
    {
      this.filterParams.companyId = this.authService.decodedToken.CompanyId;
    }
    this.adminService.getUsersWithRoles(1, 5, this.filterParams).subscribe((res: PaginatedResult<User[]>) => {
      this.users = res.result;
       this.pagination.totalPages =res.pagination.totalPages;
      this.pagination.itemsPerPage =res.pagination.itemsPerPage;
      this.pagination.totalItems =res.pagination.totalItems;
    }, error => {
      this.alertifyService.error(error);
    });
  }

  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.loadUsers();
  }


  loadUsers(){
    this.adminService.getUsersWithRoles(this.pagination.currentPage, this.pagination.itemsPerPage, this.filterParams)
    .subscribe((res: PaginatedResult<User[]>) => {
      this.users = res.result;
       this.pagination.totalPages =res.pagination.totalPages;
      this.pagination.itemsPerPage =res.pagination.itemsPerPage;
      this.pagination.totalItems =res.pagination.totalItems;
    }, error => {
      this.alertifyService.error(error);
    });
  }

  editRolesModal(user: User, )
  {
    const initialState: any= {
      user,
      groups: this.getGroupsArray(user)
    };
    this.bsModalRef = this.modalService.show(RolesModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.updateSelectedRoles.subscribe((values) => {
      const groupsToUpdate = {
        groups: [...values.filter(el => el.checked === true).map(el => el.name)]
      };
     // tslint:disable-next-line: align
     if (groupsToUpdate)
     {
       this.adminService.updateUserRoles(user, groupsToUpdate).subscribe(() => {
         user.groups = [...groupsToUpdate.groups];
       }, error => {
         this.alertifyService.error(error);
       });
     }
    });
  }

  editNotificationsModal(user: User, )
  {
    const initialState: any= {
      user,
      groups: this.getGroupsArray(user)
    };
    this.bsModalRef = this.modalService.show(NotificationsSetModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.updateSelectedNotifSettings.subscribe((values) => {
      
       this.notifSerivce.addUserNotifications(values).subscribe(() => {
        this.alertifyService.success('přenastavení notifikací proběhlo úspěšně');
       }, error => {
         this.alertifyService.error(error);
       });
     
    });
  }

  editUserPasswordModal(user: User,)
  {
    const initialState: any= {
      user
    };
    this.bsModalRef = this.modalService.show(PsswdChangeModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.changePsswd.subscribe((values) => {
      let userForEdit:any = {userName: user.userName, oldPassword: '',newPassword:values,companyId:0};
      this.authService.editPasswordByAdmin(userForEdit).subscribe(
        (next) => {
          this.alertifyService.success('Upraveno');

        }, error => {
         this.alertifyService.error(error);
        },
        () => {
        }
      );
      
    });
  }

  getUserDataDetail(user: User)
  {
    const initialState: any= {
      user
    };
    this.bsModalRef = this.modalService.show(UserDataDetailModalComponent, {initialState, backdrop:'static'});
  }

  editUserDataModal(user: User,)
  {
    const initialState: any= {
      user,
      filterParams: this.filterParams
    };
    this.bsModalRef = this.modalService.show(UserDataEditModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.editUserData.subscribe((values) => {
      this.authService.editUser(values).subscribe(
        (next) => {
          this.alertifyService.success('Upraveno');

        }, error => {
         this.alertifyService.error(error);
        },
        () => {
        }
      );
    });
  }


  private IsExtSpravce()
  {
    const role: string[] = this.authService.decodedToken.role;
    if (typeof(role) === 'string')
    {
      if(role === 'ExtSpravce')
      {
        return true;
      }
      else
      {
        return false;
      }
    }
    else
    {
      return role.filter(x => x === 'ExtSpravce').length === 1;
    }
  }


  private getGroupsArray(user)
  {
    const groups = [];
    const userGroups = user.groups;
    let availableGroups: any[];
    if (this.IsExtSpravce())
    {
      availableGroups =
      [
        {name: 'Externí admin', value: 'Externí admin'},
        {name: 'Externí pracovník', value: 'Externí pracovník'},
      ];
    }
    else
    {
      availableGroups =
      [
        {name: 'Admin', value: 'Admin'},
        {name: 'Pracovník', value: 'Pracovník'},
        {name: 'Externí admin', value: 'Externí admin'},
        {name: 'Externí pracovník', value: 'Externí pracovník'},
      ];
    }

    for (let i = 0; i < availableGroups.length; i++)
    {
      let isMatch = false;
      for (let j = 0; j < userGroups.length; j++)
      {
        if (availableGroups[i].name === userGroups[j])
        {
          isMatch = true;
          availableGroups[i].checked = true;
          groups.push(availableGroups[i]);
          break;
        }
      }
      if (!isMatch)
      {
        availableGroups[i].checked = false;
        groups.push(availableGroups[i]);
      }
    }
    return groups;
  }

}
