<div class=" m-5">
  <div class="form-inline">
    <h2>Tickety</h2>
    <div class="col">
      <div class="btn-group float-right">
        <button type="button" name="orderBy" (click)="setFilters()" class="btn btn-primary"
                [(ngModel)]="filterParams.orderBy" btnRadio="created">Založeno
        </button>
        <button type="button" name="orderBy" (click)="setFilters()" class="btn btn-primary"
                [(ngModel)]="filterParams.orderBy" btnRadio="deadline">Bude končit
        </button>
      </div>
    </div>
  </div>
  <form #form="ngForm" (ngSubmit)="setFilters()" novalidate>
    <div class="form-inline mt-2">
      <div class="form-group px-2 mt-3 " style="width: 13%;">
        <label for="company">Č.t.: </label>
        <input type="number" class="form-control ml-1" style="width: 70%;" placeholder="Číslo ticketku"
               [(ngModel)]="filterParams.ticketId" name="ticketId"/>
      </div>
      <div *appHasRole="['CompanyFilter']" class="form-group px-2 mt-3">
        <label for="companyId">Firma:&nbsp;</label>
              <app-company-picker style="width: 250px" id="companyId"
                                  name="companyId"
                                  [(ngModel)]="filterParams.companyId"
                                  (change)="setCompanyForDynamicFilter($event)"></app-company-picker>

<!--        <select class="form-control ml-1" style="width: 130px" id="companyId" [(ngModel)]="filterParams.companyId"-->
<!--                name="companyId" (change)="setCompanyForDynamicFilter()">-->
<!--          <option value="0"></option>-->
<!--          <option *ngFor="let company of companies" [value]="company.id">-->
<!--            {{company.companyName}}-->
<!--          </option>-->
<!--        </select>-->
      </div>

      <div class="form-group px-2 mt-3">
        <label for="issuer">Zadavatel: </label>
        <select class="form-control ml-1" style="width: 130px" id="issuerId" [(ngModel)]="filterParams.issuer"
                name="issuerId">
          <option value="0"></option>
          <option *ngFor="let user of users" [value]="user.id">
            {{user.firstName}}  {{user.lastName}}
          </option>
        </select>
      </div>

      <div class="form-group px-2 mt-3">
        <label for="solver">Řešitel: </label>
        <select class="form-control ml-1" style="width: 130px" id="solverId" [(ngModel)]="filterParams.solver"
                name="solverId">
          <option value="0"></option>
          <option *ngFor="let user of users" [value]="user.id">
            {{user.firstName}}  {{user.lastName}}
          </option>
        </select>
      </div>
      <!-- <div class="form-group px-2 mt-3" style="width: 13%;">
        <input type="text" class="form-control" style="width: 100%;" placeholder="Zadavatel" [(ngModel)]="filterParams.issuer" name="issuer"/>
      </div>
      <div class="form-group px-1 mt-3"  style="width: 13%;">
        <input type="text" class="form-control"  style="width: 100%;" placeholder="Řešitel" [(ngModel)]="filterParams.solver" name="solver"/>
      </div> -->
      <div class="form-group px-2  mt-3">
        <label for="company">Status: </label>
        <select class="form-control ml-1" style="width: 130px" id="stateId" [(ngModel)]="filterParams.state"
                name="state" aria-placeholder="x">
          <option value=""></option>

          <option value="Nový ticket">Nový ticket</option>
          <option value="Řeší se">Řeší se</option>
          <option value="Čeká se na odpověď zákazníka">Čeká se na odpověď zákazníka</option>
          <option value="Uzavřený">Uzavřený</option>
          <option value="Odpovězeno">Odpovězeno</option>
        </select>
      </div>
      <div class="form-group px-2  mt-3">
        <label for="company">Kategorie: </label>
        <select class="form-control ml-1" style="width: 130px" id="categoryId" [(ngModel)]="filterParams.categoryId"
                name="categoryId">
          <option value="0"></option>
          <option *ngFor="let category of categories" [value]="category.id">
            {{category.name}}
          </option>
        </select>
      </div>

      <div class="form-group px-2 mt-3">
        <label for="company">Od: </label>
        <input class="ml-1" type="date" [(ngModel)]="filterParams.dateFrom" name="dateFrom">
      </div>
      <div class="form-group px-2  mt-3">
        <label for="company">Do: </label>
        <input class="ml-1" type="date" [(ngModel)]="filterParams.dateTo" name="dateTo">
      </div>
      <div class="mt-3">
        <button type="submit" class="btn btn-primary" style="margin-left:10px">Filtrovat</button>
        <button type="button" class="btn btn-info" style="margin-left:10px" (click)="resetFilters()">Reset Filtrů
        </button>
      </div>

    </div>

  </form>
  <br>
  <div class="tab-pannel" style="position: relative;">
    <tabset #ticketTabs class="member-tabset ">
      <tab heading="Moje Tickety">
        <div class="">
          <app-my-tickets [filterParams]="filterParams" [filterChangedFlag]="filterChangedFlag"
                          [ticketAddFlag]="ticketAddFlag" [ticketFilter]='issuerticket'
                          (createTicketEvent)="getTicketAddModal()"></app-my-tickets>
        </div>
      </tab>
      <tab heading="Tickety" *appHasRole="['AllTickets']">
        <div class="">
          <app-my-tickets [filterParams]="filterParams" [filterChangedFlag]="filterChangedFlag"
                          [ticketAddFlag]="ticketAddFlag" (createTicketEvent)="getTicketAddModal()"></app-my-tickets>
        </div>
      </tab>
      <tab heading="Tickety k řešení" *appHasRole="['TicketsToSolve']">
        <div class="">
          <app-my-tickets [filterParams]="filterParams" [filterChangedFlag]="filterChangedFlag"
                          [ticketAddFlag]="ticketAddFlag" [ticketFilter]='solverticket'
                          (createTicketEvent)="getTicketAddModal()"></app-my-tickets>
        </div>
      </tab>
      <tab heading="Nepřiřazené tickety" *appHasRole="['TicketsToTake']">
        <div class="">
          <app-my-tickets [filterParams]="filterParams" [filterChangedFlag]="filterChangedFlag"
                          [ticketAddFlag]="ticketAddFlag" [ticketFilter]='ticketsToSolve'
                          (createTicketEvent)="getTicketAddModal()"></app-my-tickets>
        </div>
      </tab>
    </tabset>
    <button style="position: absolute; top: 0%; right: 0%;" class="btn btn-primary " (click)="$event.stopPropagation()"
            (click)="getTicketAddModal()">Vytvořit nový ticket
    </button>
  </div>
</div>
