import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Company } from 'src/app/_models/company';
import { WorkReport } from 'src/app/_models/workReport';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { AuthService } from 'src/app/_services/auth.service';
import { DatePipe } from '@angular/common';
import { User } from 'src/app/_models/user';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-companycreate-modal',
  templateUrl: './workreportcreate-modal.component.html',
  styleUrls: ['./workreportcreate-modal.component.scss']
})
export class WorkReportCreateModalComponent implements OnInit {
  @Input() selectedCompanyId: number;

  @Output() createNewWorkReport = new EventEmitter();
  workreport: WorkReport;
  workreportAddForm: FormGroup;
  companies: Company[];
  hoursEditabel = true;
  users: User[];
  constructor(public bsModalRef: BsModalRef,private userService: UserService, private fb: FormBuilder, private companyService: CompanyDialService, private authService: AuthService, private datePipe: DatePipe) {}
  isFromTicket=false;

  ngOnInit() {
    if(this.workreport != null)
    {
      this.isFromTicket = this.workreport.ticketId != 0;
    }
    this.createForm();

    this.userService.getCoreUsers().subscribe((data) => {this.users = data;});

    this.companyService.getCompanies(1, 1,null,null,false,true).subscribe(data => {
      this.companies = data.result;

      if (this.selectedCompanyId > 0)
      {
        this.workreportAddForm.get('companyId').setValue(this.selectedCompanyId);
      }
      if (this.authService.decodedToken.IsPrimary !== 'True')
      {
        this.companies = this.companies.filter(x => x.id.toString() === this.authService.decodedToken.CompanyId);
      }
    });
  }

  createForm(){
    if (this.workreport === undefined)
    {
      this.workreportAddForm = this.fb .group({
        title: ['', Validators.required],
        description: [''],
        internalNote:[''],
        companyId: ['', Validators.required],
        transport: [false],
        onlyTransport: [false],
        transportKm: [''],
        hours: ['', Validators.required],
        ticketId: [0],
        userId: [this.authService.decodedToken.nameid],
        created: [''],
        forDay: ['', Validators.required],
        workFrom: [''],
        workTo: [''],
        supportType: ['', Validators.required],
        createdByUserId:[this.authService.decodedToken.nameid],
        checked:[false],
        updatedByUser: [''],
        updatedByUserId: [''],
        lastUpdate: Date ['']
      });
    }
    else
    {
      this.workreportAddForm = this.fb .group({
        title: [this.workreport.title, Validators.required],
        description: ['', Validators.required],
        internalNote:[''],
        companyId: [this.workreport.companyId, Validators.required],
        transport: [false],
        onlyTransport: [false],
        transportKm: [''],
        hours: ['', Validators.required],
        ticketId: [this.workreport.ticketId],
        userId: [this.authService.decodedToken.nameid],
        created: [''],
        forDay: ['', Validators.required],
        workFrom: [''],
        workTo: [''],
        supportType: ['', Validators.required],
        deleted: [false, Validators.required],
        createdByUserId:[this.authService.decodedToken.nameid],
        checked:[false],
        updatedByUser: [''],
        updatedByUserId: [''],
        lastUpdate: Date ['']
      });
    }

    var date = new Date().setHours(0,0,0);
    var datestring = this.datePipe.transform(date,'yyyy-MM-dd HH:mm')
        this.workreportAddForm.patchValue({workTo: datestring})
        this.workreportAddForm.patchValue({workFrom: datestring})
  }
  createWorkReport()
  {
    if (this.workreportAddForm.valid)
    {
      this.workreport = Object.assign({}, this.workreportAddForm.value);

      const stringforDay = this.datePipe.transform(this.workreport.created, 'yyyy-MM-dd');
      this.workreport.created = new Date(stringforDay);

      const stringcreatedDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.workreport.created = new Date(stringcreatedDate);

      if (!this.workreport.transport)
      {
        this.workreport.transportKm = 0;
      }
      // this.workreport.userId = this.authService.decodedToken.nameid;
      if(this.workreportAddForm.value.workFrom === '' && this.workreportAddForm.value.workTo === '')
      {
        var date = new Date().setHours(0,0,0);
        this.workreportAddForm.patchValue({workTo: date})
        this.workreportAddForm.patchValue({workreportAddForm: date})
      }

      this.createNewWorkReport.emit(this.workreport);
      this.bsModalRef.hide();
    }
  }

  setHours()
  {
    if(this.workreportAddForm.value.workFrom != '' && this.workreportAddForm.value.workTo != '')
    {
      let minutesX = this.workreportAddForm.value.workTo.getHours() *60;
      let minutesY = this.workreportAddForm.value.workFrom.getHours() *60;
      minutesX =minutesX+ this.workreportAddForm.value.workTo.getMinutes();
      minutesY =minutesY+ this.workreportAddForm.value.workFrom.getMinutes();


      var res:number = Math.floor((minutesX - minutesY)/60);
      var remainder = (minutesX - minutesY) % 60;
      if(remainder >0 && remainder<=15)
      {
        res = res +0.25;
      }
      if(remainder >15 && remainder<=30)
      {
        res = res +0.5;
      }
      if(remainder >30 && remainder<=45)
      {
        res = res +0.75;
      }
      if(remainder >45)
      {
        res = res +1;
      }
        if(res >= 0)
        {
          this.workreportAddForm.patchValue({hours: res})
        }
        this.hoursEditabel = false;
    }
  }

  setOnsKms()
  {
    if(this.workreportAddForm.get('supportType').value === "ONS")
    {
      var compId = this.workreportAddForm.get('companyId').value;
      if(compId !== null)
      {
        var standardKms = this.companies.filter(x=>x.id.toString() === compId)[0].standardKms;

        if(standardKms != 0)
        {
          this.workreportAddForm.patchValue({transportKm: standardKms});
          this.workreportAddForm.patchValue({transport: true});
        }
      }


    }
  }

}
