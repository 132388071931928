import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { InventoryItem } from 'src/app/_models/inventoryItem';

@Component({
  selector: 'app-inventoryItemDetail-modal',
  templateUrl: './inventoryItemDetail-modal.component.html',
  styleUrls: ['./inventoryItemDetail-modal.component.scss']
})
export class InventoryItemDetailModalComponent implements OnInit {
  invItem: InventoryItem;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

}
