import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PaginatedResult } from '../_models/pagination';
import { map } from 'rxjs/operators';
import { Invoice } from '../_models/invoice';
import { Observable } from 'rxjs';
import { InventoryItem } from '../_models/inventoryItem';
import { InventoryItemEditModalComponent } from '../inventoryItems/inventoryItemEdit-modal/inventoryItemEdit-modal.component';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}


  addInvoice(invoice: Invoice, invItems: InventoryItem[])
  {
    return this.http.post<Invoice>(this.baseUrl + 'Invoice/', {Invoice: invoice, InventoryItems: invItems} );
  }

  addRecievedInvoice(invoice: Invoice, invItems: InventoryItem[])
  {
    
    return this.http.post(this.baseUrl + 'Invoice/AddRecievedInvoice', {Invoice: invoice, InventoryItems: invItems} );
  }

  

  deleteInvoice(invoiceId: number)
  {
    return this.http.delete(this.baseUrl + 'Invoice/' + invoiceId );
  }

  deleteRecievedInvoice(invoiceId: number)
  {
    return this.http.delete(this.baseUrl + 'Invoice/recieved/' + invoiceId );
  }

  getInvoices( page?, itemsPerPage?, userParams?)
  {
    const paginatedResult: PaginatedResult<Invoice[]> = new PaginatedResult<Invoice[]>();
    let params = new HttpParams();

    if (page != null && itemsPerPage != null)
    {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
    if ( userParams != null)
    {
      params = params.append('CompanyId', userParams.companyId);
      params = params.append('RecievedInvoice', userParams.recievedInvoice);
    }
    return this.http.get<Invoice[]>(this.baseUrl + 'Invoice', {observe: 'response', params} )
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') !== null)
        {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })
    );
  }

  getLastInvoiceNumber(companyId,forMonth)
  {
    return this.http.get<string>(this.baseUrl + 'Invoice/GetLastInvoiceNumber/' + companyId+'/month/'+forMonth,{responseType: 'text' as 'json'});
  }

  updateInvoice(id: number, invoice: Invoice, items: InventoryItem[])
  {
    return this.http.put(this.baseUrl + 'Invoice/' + id, {Invoice: invoice, InventoryItems: items});
  }

  updateRecievedInvoice(invoice: Invoice, invItems: InventoryItem[], invoiceId)
  {
    return this.http.put<Invoice>(this.baseUrl + 'Invoice/UpdateRecievedInvoice/'+invoiceId, {Invoice: invoice, InventoryItems: invItems});
  }

  sendInvoiceLink(invoiceId,text)
  {
    return this.http.post(this.baseUrl + 'Invoice/SendInvoiceAndWpLinks/'+invoiceId+"/text/"+text,{} );
  }
  getInvoiceByGuid(guid)
  {
    return this.http.get<Invoice>(this.baseUrl + 'Invoice/GetInvoiceByGuid/'+guid);
  }

  getInvoiceById(id)
  {
    return this.http.get<Invoice>(this.baseUrl + 'Invoice/GetInvoiceById/'+id);
  }
 
}
