<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="ml-3 mr-3 mt-2 ">
  <h4><strong>Nákupčí:</strong> {{getCoreUser(pdo.orderedById)}}</h4>
  <h4><strong>Objednavatel:</strong> {{getCoreUser(pdo.orderedById)}}</h4>
  <h4><strong>Dne:</strong> {{pdo.dateOfOrder| date:'dd.mm.yyyy'}}</h4>
    <div class="form-group form-inline ml-1">
      <div style="width: 90%;">
        <div  class="form-inline">
          <strong style="width: 50%;">Název</strong>
          <strong style="width: 50%;">Obj. mn</strong>
        </div>
      </div>
      </div>

    <div *ngFor="let pdi of pdo.pdis" >
      <div  class="form-group form-inline ml-1">
        <div  style="width: 100%;" class="form-inline">
            <h4 style="width: 50%;"> {{pdi.name}}</h4>
            <h4 style="width: 50%;"> {{pdi.amount}}</h4>
        </div>
      </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <!-- && orderedItems != undefined && orderedItems.length > 0 -->
</div>