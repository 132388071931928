<form #f="ngForm" (ngSubmit)="f.form.valid && domainCreate()">
  <div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="ml-3 mr-3 mt-2 ">
    <div class="form-group">
      <label for="companyId">Vyberte firmu</label>
      <app-company-picker style="width: 250px"
                          id="companyId"
                          name="companyId"
                          [(ngModel)]="domain.companyId"
                          #companyId="ngModel"
                          (change)="setCompanyToDomain($event)" required></app-company-picker>

      <div *ngIf="f.submitted && companyId.errors?.required" class="text-danger">Firma je povinné pole</div>
    </div>

    <div class="form-group">
      <label for="name">Název</label>
      <input type="text" class="form-control" name="name" id="name" placeholder="Název"
             [(ngModel)]="domain.name" #name="ngModel" required/>
      <div *ngIf="f.submitted && name.errors?.required" class="text-danger">Název je povinné pole</div>
    </div>

    <div class="form-group">
      <label for="domainName">Doména</label>
      <input type="text" class="form-control" name="domainName" id="domainName" placeholder="Doména"
             [(ngModel)]="domain.domain" #domainName="ngModel" required/>
      <div *ngIf="f.submitted && domainName.errors?.required" class="text-danger">Doména je povinné pole</div>
    </div>

    <h6>SQL instance</h6>
    <ng-container *ngFor="let item of domain.sqlInstancies; let index = index;" >
      <div class="form-group">

        <div class="row">
          <div class="col-8">
            <span>{{item}}</span>
          </div>
          <div class="col-4 text-right">
            <button type="button" class="btn btn-primary mr-2" (click)="editSqlInstance(index)" ><i class="fa fa-pencil"></i></button>
            <button type="button" class="btn btn-danger" (click)="deleteSqlInstance(index)" ><i class="fa fa-trash"></i></button>
          </div>
        </div>
      </div>
    </ng-container>
    <hr />
    <label for="instance">Název SQL instance</label>
    <div class="row mb-3">
      <div class="col-8">
        <input type="text" class="form-control" id="instance" name="instance" placeholder="SQL instance"
               [(ngModel)]="instanceName" #instance="ngModel"  />
      </div>
      <div class="col-4 text-right">
        <button type="button" class="btn btn-primary" [disabled]="instanceName == null" (click)="addSqlInstance()" >
          <ng-container *ngIf="lastUsedIndex != null"><i class="fa fa-save"></i>&nbsp;Upravit</ng-container>
          <ng-container *ngIf="lastUsedIndex == null"><i class="fa fa-plus"></i>&nbsp;Přidat</ng-container>
        </button>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
    <button type="submit" class="btn btn-success">Uložit</button>
  </div>
</form>
