<div class="modal-header dontPrint">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<!-- dataToPrint -->
<div id="pdfTable" #pdfTable class=" pl-4 pr-4 mt-2" >
  <div class="tiny-line">
  <h5 class="pull-left">Rozpis fakturovaných hodin</h5>
  <div class="pull-right "><h6><strong>Za období {{invoice.forMonth | date:"MM.yyyy"}} </strong></h6>
    <br><h6><strong>Pro klienta {{companyName}}</strong></h6></div>
  </div> 

  <table class="table table-hover mb-0" style="cursor: pointer;" >
    <tr>
    </tr>
    <tr>
      <th style="width: 15%">Nadpis</th>
      <th style="width: 30%">Popis</th>
      <th style="width: 15%">Admin</th>
      <th style="width: 15%">Den</th>
      <th style="width: 15%">Hodin</th>
      <th style="width: 10%">Km</th>
    </tr>
    <tr *ngFor="let workReport of workReportsFiltered" >
      <td>{{workReport.title}}</td>
      <td>{{workReport.description}}</td>
      <td>{{workReport.admin}}</td>
      <td>{{workReport.forDay | date:"dd.MM.yyyy"}}</td>
      <td *ngIf="workReport.onlyTransport == true">N/A</td>
      <td *ngIf="workReport.onlyTransport == false">{{workReport.hours}}</td>
      <td *ngIf="workReport.supportType === 'ONS'" >{{workReport.transportKm}}</td>
      <td *ngIf="workReport.supportType === 'RAS' && workReport.transportKm > 0" > {{workReport.supportType}} ({{workReport.transportKm}})</td>
      <td *ngIf="workReport.supportType === 'RAS'  && workReport.transportKm === 0 " >{{workReport.supportType}}</td>
    </tr>
    <tfoot>
      <tr>
        <th></th>
        <th></th>
        <th></th>
        <th>Celkem</th>
        <th *ngIf="hkm!==undefined">{{hkm[1]}}</th>
        <th *ngIf="hkm!==undefined">{{hkm[0]}}</th>
      </tr>
  </tfoot>
  </table>

  <h6 class="ml-3 w-100" style="margin-bottom: 1cm;"></h6>  
</div>
<div class="modal-footer dontPrint">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button type="button" class="btn btn-primary" (click)="downloadAsPDF()">Tisk</button>
</div>