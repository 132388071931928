import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Company } from 'src/app/_models/company';
import { AlertifyService } from 'src/app/_services/alertify.service';
@Component({
  selector: 'app-companyedit-modal',
  templateUrl: './companyedit-modal.component.html',
  styleUrls: ['./companyedit-modal.component.css']
})
export class CompanyeditModalComponent implements OnInit {
  @Output() createNewCompany = new EventEmitter();
  company: Company;
  companyAddForm: FormGroup;

  constructor(public bsModalRef: BsModalRef, private fb: FormBuilder, private alertify: AlertifyService) {}

  ngOnInit() {
    this.createForm();
  }


  createForm(){
    this.companyAddForm = this.fb .group({
      companyName: [this.company.companyName, Validators.required],
      ico: [this.company.ico, Validators.required],
      dico: [this.company.dico],
      phoneNumber: [this.company.phoneNumber],
      email: [this.company.email, Validators.required],
      adressPSC: [this.company.adressPSC, Validators.required],
      adressOkres: [this.company.adressOkres],
      adressObec: [this.company.adressObec, Validators.required],
      adressCastObce: [this.company.adressCastObce],
      // adressMop: [this.company.adressMop, Validators.required],
      adressUlice: [this.company.adressUlice, Validators.required],
      adressCisloPopisne: [this.company.adressCisloPopisne, Validators.required],
      adressCisloOr: [this.company.adressCisloOr],
      daysToPay: [this.company.daysToPay, Validators.required],
      isPrimary:[this.company.isPrimary],
      accountNumber:[this.company.accountNumber],
      invoiceFooterText:[this.company.invoiceFooterText],

      client:[this.company.client],
      supplier:[this.company.supplier],
      rasHours:[this.company.rasHours],
      onsHours:[this.company.onsHours],
      hours:[this.company.hours],
      splitHours:[this.company.splitHours],
      prepaidKm:[this.company.prepaidKm],
      standardKms:[this.company.standardKms],
      note:[this.company.note],
    });
  }

  editCompany()
  {
    if (this.companyAddForm.valid)
    {

      if(this.companyAddForm.value.isPrimary)
      {
        this.alertify.confirm('Opravdu chcete nastavit tuto firmu jako primární ? Pokud je již tento status přidělen, jiné firmě bude od dané firmy odebrán ', () => {
          this.company = Object.assign({}, this.companyAddForm.value);
          this.createNewCompany.emit(this.company);
          this.bsModalRef.hide();
        });
      }
      else
      {
        this.company = Object.assign({}, this.companyAddForm.value);
      this.createNewCompany.emit(this.company);
      this.bsModalRef.hide();
      }


      
    }
  }
}

