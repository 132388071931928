import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Company } from '../_models/company';
import { PaginatedResult, Pagination } from '../_models/pagination';
import { AlertifyService } from '../_services/alertify.service';
import { AuthService } from '../_services/auth.service';
import { CompanyDialService } from '../_services/companyDial.service';
import { CompanycreateModalComponent } from './companycreate-modal/companycreate-modal.component';
import { CompanydetailModalComponent } from './companydetail-modal/companydetail-modal.component';
import { CompanyeditModalComponent } from './companyedit-modal/companyedit-modal.component';
import { PriceListService } from '../_services/pricelist.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  bsModalRef: BsModalRef;
  companies: Company[];
  pagination: Pagination;
  companyFilter:string;
  odFilter:string;

  constructor(
    private companyDialService: CompanyDialService,
    private pricelistService: PriceListService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.odFilter ="";
    this.route.data.subscribe((data) => {
      this.companies = data['companies'].result;
      this.pagination = data['companies'].pagination;
    });
  }

  deleteCompany(id: number)
  {
    this.alertify.confirm('Opravdu chcete vymazat tuto firmu ?', () => {
      this.companyDialService.deleteCompany(id)
      .subscribe(() => {
        this.companies.splice(this.companies.findIndex(m => m.id === id ), 1);
        this.alertify.success('Úspěšně odstraněno');
      }, error => {
        this.alertify.error('Firmu se nepodařilo odstranit');
      });
    });
  }

  loadCompanies() {
    var supplierBool= (this.odFilter ==="OD" || this.odFilter ==="D");
    var clientBool= (this.odFilter ==="OD" || this.odFilter ==="O");
    this.companyDialService.getCompanies( this.pagination.currentPage, this.pagination.itemsPerPage,this.companyFilter,false,supplierBool,clientBool)
    .subscribe((res: PaginatedResult<Company[]>) => {
      this.companies = res.result;
      this.pagination.totalPages =res.pagination.totalPages;
      this.pagination.itemsPerPage =res.pagination.itemsPerPage;
      this.pagination.totalItems =res.pagination.totalItems;
    }, error => {
      this.alertify.error(error);
    });
   }

  pageChanged(event: any): void{
    this.pagination.currentPage = event.page;
    this.loadCompanies();
  }

  createCompanyModal()
  {

    const initialState: any= {
    };
    this.bsModalRef = this.modalService.show(CompanycreateModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.createNewCompany.subscribe((values) =>
    {
      this.companyDialService.addCompany(values).subscribe(() => {
        this.alertify.success('Úspěšně přidáno');
      }, error => {
        this.alertify.error('Firmu se nepodařilo přidat');
      }, () => {
        this.loadCompanies();
      });
    });
  }

  getCompanyDetailModal(company: Company)
  {
    const initialState: any= {
      company
    };
    this.bsModalRef = this.modalService.show(CompanydetailModalComponent, {initialState, backdrop:'static'});
  }

  getCompanyEditModal(company: Company)
  {
    const initialState: any= {
      company
    };
    let message = 'Firmu se nepodařilo upravit';
    this.pricelistService.getPriceListItems(1, 15, {companyId: company.id}).subscribe((x) => {
      if( x.result.length === 0){
        message = 'Firma s id ' + company.id + ' nebyla v ceníku nalezena';
      }
    });
    this.bsModalRef = this.modalService.show(CompanyeditModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.createNewCompany.subscribe((values) =>
    {
      this.companyDialService.updateCompany(company.id, values).subscribe(() => {
        this.alertify.success('Úspěšně upraveno');
      }, error => {
        this.alertify.error(message);
      }, () => {
        this.loadCompanies();
      });
      // this.companyDialService.addCompany(values).subscribe(() => {
      //   this.alertify.success('Úspěšně přidáno');
      // }, error => {
      //   this.alertify.error('Firmu se nepodařilo přidat');
      // }, () => {
      //   this.loadCompanies();
      // });
    });
  }

  anyCompanies()
  {
    if (this.companies === undefined)
    {
        return true;
    }
    if (this.companies.length === 0)
    {
        return false;
    }
    return true;
  }

}
