import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { InventoryItem } from '../_models/inventoryItem';
import { OrderedItem } from '../_models/orderedItem';
import { PaginatedResult } from '../_models/pagination';

@Injectable({
  providedIn: 'root'
})
export class InventoryItemService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}


  addInventoryItem(invItem: InventoryItem)
  {
    return this.http.post(this.baseUrl + 'InventoryItem/', invItem );
  }
  addInventoryItems(invItems: InventoryItem[], supplierId: number, invoiceId:number )
  {
    return this.http.post(this.baseUrl + 'InventoryItem/addInventoryItems',{InventoryItems: invItems, InvoiceId: invoiceId,SupplierId:supplierId } );
  }

  AddCompositeItem(invItem: InventoryItem, ids:number[])
  {
    return this.http.post(this.baseUrl + 'InventoryItem/AddCompositeItem',{InventoryItem: invItem, CompositeItemIds: ids } );
  }

  EditCompositeItem(invItem: InventoryItem, ids:number[])
  {
    return this.http.post(this.baseUrl + 'InventoryItem/EditCompositeItem',{InventoryItem: invItem, CompositeItemIds: ids } );
  }

  GetOrderedItems(orderId: number)
  {
    // return this.http.get<OrderedItem[]>(this.baseUrl + 'InventoryItem/GetOrderedItems/' + orderId);

    var result: OrderedItem[];
    return this.http.get<OrderedItem[]>(this.baseUrl + 'InventoryItem/GetOrderedItems/' + orderId, {observe: 'response'} )
    .pipe(
      map(response => {
        result = response.body;
        return result;
      })
    );
  }

  deleteInventoryItem(id: number)
  {
    return this.http.delete(this.baseUrl + 'InventoryItem/' + id );
  }

  getInvenotryItems( page?, itemsPerPage?, invoiceId?,itemParams?)
  {
    const paginatedResult: PaginatedResult<InventoryItem[]> = new PaginatedResult<InventoryItem[]>();
    let params = new HttpParams();
    if (page != null && itemsPerPage != null)
    {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
      // params = params.append('Deleted', deleted);
    }

    if ( itemParams.name != null)
    {
      params = params.append('Name', itemParams.name);
    }
    if ( itemParams.flag != null)
    {
      params = params.append('Flag', itemParams.flag);
    }
    if ( itemParams.name != null)
    {
      params = params.append('SerialNumber', itemParams.serialNumber);
    }
    
    if ( itemParams.isContained != null)
    {
      params = params.append('IsContained', itemParams.isContained);
    }
    if ( itemParams.hasContained != null)
    {
      params = params.append('HasContained', itemParams.hasContained);
    }
    if ( itemParams.delivered != null)
    {
      params = params.append('Delivered', itemParams.delivered);
    }
    if ( itemParams.invoicedToClient != null)
    {
      params = params.append('InvoicedToClient', itemParams.invoicedToClient);
    }
    if ( itemParams.clientInvoiceId != null)
    {
      params = params.append('ClientInvoiceId', itemParams.clientInvoiceId);
    }
    if ( itemParams.reklamace != null)
    {
      params = params.append('Reklamace', itemParams.reklamace);
    }
    if ( itemParams.inWarehouse != null)
    {
      params = params.append('InWarehouse', itemParams.inWarehouse);

    }
    if ( itemParams.clientId != null)
    {
      params = params.append('ClientId', itemParams.clientId);

    }
    if ( itemParams.supplierId != null)
    {
      params = params.append('SupplierId', itemParams.supplierId);
    }
    if ( itemParams.isForInvoice != null)
    {
      params = params.append('IsForInvoice', itemParams.isForInvoice);
    }
    if ( itemParams.itemOrderId != null)
    {
      params = params.append('ItemOrderId', itemParams.itemOrderId);
    }
    if ( itemParams.invoiceId != null)
    {
      
      params = params.append('InvoiceId', itemParams.invoiceId);
    }

    
      params = params.append('GetPrimaryCompany', itemParams.getPrimaryCompany);

  
    
    params = params.append('InvoiceId', invoiceId);
    return this.http.get<InventoryItem[]>(this.baseUrl + 'InventoryItem', {observe: 'response', params} )
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') !== null)
        {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }

        return paginatedResult;
      })
    );
  }

  updateInventoryItem(id: number, invItem: InventoryItem)
  {
    return this.http.put(this.baseUrl + 'InventoryItem/' + id, invItem);
  }
 
}
