import { Pipe, PipeTransform, NgModule } from '@angular/core';
import { createRound, isString } from '../utils/utils';

@Pipe({
  name: 'min',
})
export class MinPipe implements PipeTransform {
  transform(value: any, precision: any = 0): any {
    if (isString(precision)) {
      precision = parseInt(precision);
    }

    return createRound('min')(value, precision);
  }
}

@NgModule({
  declarations: [MinPipe],
  exports: [MinPipe],
})
export class MinPipeModule {}
