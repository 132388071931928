<div class="modal-header ">
  <h4 class="modal-title pull-left">Data uživatele</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h4><strong>Jméno:</strong> {{user.firstName}}</h4>
  <h4><strong>Příjmení:</strong> {{user.lastName}}</h4>
  <h4><strong>Email:</strong> {{user.email}}</h4>
  <h4><strong>Tel. číslo:</strong> {{user.phoneNumber}}</h4>
  <h4><strong>Pracovní pozice:</strong> {{user.workPost}}</h4>
  <h4><strong>Firma:</strong> {{company.companyName}}</h4>
  <h4 *ngIf="user.sendInvoices"><strong>Zasílat faktury:</strong> <i class="fa fa-check-circle-o" style="color:green"></i></h4>
  <h4 *ngIf="!user.sendInvoices"><strong>Zasílat faktury:</strong> <i class="fa fa-times-circle-o" style="color:red"></i></h4>
  <h4><strong>Poznámky:</strong></h4>
  <textarea readonly class="form-control" style="min-width: 100%" rows="10">{{user.note}}</textarea>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
</div>