import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Company } from 'src/app/_models/company';
import { User } from 'src/app/_models/user';
import { WorkPost } from 'src/app/_models/workPost';
import { AuthService } from 'src/app/_services/auth.service';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { UserService } from 'src/app/_services/user.service';
import { WorkPostService } from 'src/app/_services/workPost.service';

@Component({
  selector: 'app-userDataEdit-modal',
  templateUrl: './userDataEdit-modal.component.html',
  styleUrls: ['./userDataEdit-modal.component.css']
})
export class UserDataEditModalComponent implements OnInit {
  @Output() editUserData = new EventEmitter();
  user: User;
  registerForm: FormGroup;
  bsConfig: Partial<BsDatepickerConfig>;
  // companyList: {value: number, display: string}[];
  companies: Company[];
  filterParams: any = {};
  workPosts : WorkPost[];

  constructor(public bsModalRef: BsModalRef,
    private authService: AuthService,
    private fb: FormBuilder,
    private companyService: CompanyDialService,
    private userService: UserService,
    private workPostService: WorkPostService
    ) { 
   
  }

  ngOnInit()
  {
    this.bsConfig = {
      containerClass: 'theme-red'
    },
    this.workPostService.getWorkPosts(1, 1,null).subscribe(x=>{this.workPosts= x.result} )
    this.createRegisterForm();
        this.companyService.getCompanies(1, 1,null,null,false,false).subscribe(x=>{
          this.companies = x.result;
          // if (this.filterParams.companyId !== '0')
          // {
          //   this.companies = this.companies.filter(x => x.id.toString() === this.filterParams.companyId);
          // }
          if(this.authService.decodedToken.IsPrimary !== 'True')
          {
            this.companies = this.companies.filter(x => x.id.toString() === this.authService.decodedToken.CompanyId);
          }
          this.createRegisterFormWithData();
        });
        

  }
  
  Save()
  {
    if (this.registerForm.valid)
    {
      this.user = Object.assign({}, this.registerForm.value);
      this.editUserData.emit(this.user);
      this.bsModalRef.hide();
    }
  }


  createRegisterFormWithData(){
   this.userService.getUser(this.user.id).subscribe(data=>{
    this.user = data
    this.createRegisterForm();
   })

   
   
  }


  createRegisterForm(){
    this.registerForm = this.fb .group({
      email: [this.user.userName, Validators.required],
      firstName: [this.user.firstName, Validators.required],
      lastName: [this.user.lastName, Validators.required],
      workPost: [this.user.workPost, Validators.required],
      phoneNumber: [this.user.phoneNumber, Validators.required],
      companyId: [this.user.companyId, Validators.required],
      userName: [this.user.userName, Validators.required],
      note: [this.user.note],
      sendInvoices: [this.user.sendInvoices],
    });
  }



 
}
