<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
 

  <form [formGroup]="workreportEditForm" (ngSubmit)="editWorkReport()">
    <div class="form-group">
      <input  type="text" [ngClass]="{'is-invalid': workreportEditForm.get('title').errors && workreportEditForm.get('title').touched }" class="form-control is-invalid" 
      formControlName="title" placeholder="Nadpis" maxlength="40" />
      <div class="invalid-feedback">Nadpis je povinné pole</div>
    </div>

    <div class="form-group">
      <textarea type="text" [ngClass]="{'is-invalid': workreportEditForm.get('description').errors && workreportEditForm.get('description').touched }" 
      class="form-control is-invalid"
      style="min-height: 200px;" 
      formControlName="description" placeholder="Popis" ></textarea>
      <div class="invalid-feedback">Popis je povinné pole</div>
    </div>

    <div class="form-group">
      <textarea type="text" 
      class="form-control"
      style="min-height: 50px;" 
      formControlName="internalNote" placeholder="Interní poznámka" ></textarea>
      <!-- <div class="invalid-feedback">Popis je povinné pole</div> -->
    </div>

    <div *appHasRole="['WorkReportUserSelect']"  class="form-group">
      <select formControlName="userId" class="form-control" id="solverSelect"  placeholder="Uživatel"  name="solverSelect" 
      [ngClass]="{ 'is-invalid': workreportEditForm.get('userId').errors && workreportEditForm.get('userId').touched}">
      <option value="">Vyberte uživatele</option>
      <option *ngFor="let user of users" [value] = "user.id">
         {{user.firstName }} {{user.lastName}} 
       </option>
      </select>
      <div class="invalid-feedback">Firma je povinné pole</div>
    </div>

    <div class="form-group">
      <select formControlName="companyId" class="form-control" id="solverSelect"  placeholder="Firma"  name="solverSelect" 
      [ngClass]="{ 'is-invalid': workreportEditForm.get('companyId').errors && workreportEditForm.get('companyId').touched}">
      <option value="">Vyberte firmu</option>
      <option *ngFor="let company of companies" [value] = "company.id">
         {{company.companyName}} 
       </option>
      </select>
      <div class="invalid-feedback">Firma je povinné pole</div>
    </div>
    <div class="form-group">
      <select formControlName="supportType" class="form-control" id="solverSelect"  placeholder="Typ podpory"  name="solverSelect" 
      [ngClass]="{ 'is-invalid': workreportEditForm.get('supportType').errors && workreportEditForm.get('supportType').touched}">
      <option value="">Vyberte typ podpory</option>
      <option value = "RAS">RAS</option>
      <option value = "ONS">ONS</option>
      </select>
      <div class="invalid-feedback">Typ podpory je povinné pole</div>
    </div>
    <div  class="form-group form-inline ml-1">
      <h4 class="mr-2">Cestovné : </h4>
      <input type="checkbox" [ngClass]="{'is-invalid': workreportEditForm.get('transport').errors && workreportEditForm.get('transport').touched }" class="form-control is-invalid" 
      formControlName="transport" />
      <div class="invalid-feedback">Cesta</div>
    </div>

    <div *ngIf="workreportEditForm.get('transport').value" class="form-group form-inline ml-1">
      <h4 class="mr-2">Fakturovat pouze km : </h4>
      <input type="checkbox" 
      formControlName="onlyTransport" />
      <div class="invalid-feedback">Cestakm</div>
    </div>

    <div *ngIf="workreportEditForm.get('transport').value" class="form-group">
      <input  type="number" [ngClass]="{'is-invalid': workreportEditForm.get('transportKm').errors && workreportEditForm.get('transportKm').touched }" class="form-control is-invalid" 
      formControlName="transportKm" placeholder="Počet Km" />
    </div>

    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <h4 class="mr-3 mt-1 alginCenterV">Od</h4>
        <timepicker formControlName="workFrom" [showMeridian]="false" (ngModelChange)="setHours()"></timepicker>
        <h4 class="mr-3 ml-5 mt-1 alginCenterV">Do</h4>
      </div>
      <timepicker formControlName="workTo" [showMeridian]="false" (ngModelChange)="setHours()"></timepicker>
    </div>

    <div class="form-group">
      <input  type="number" [ngClass]="{'is-invalid': workreportEditForm.get('hours').errors && workreportEditForm.get('hours').touched }" class="form-control is-invalid" 
      formControlName="hours" placeholder="Hodin" />
    </div>

    <div class="form-group form-inline ml-1">
      <h4 class="mr-2">Výkaz za den : </h4>
      <input  type="date" [ngClass]="{'is-invalid': workreportEditForm.get('forDay').errors && workreportEditForm.get('forDay').touched }" class="form-control is-invalid" 
      formControlName="forDay" placeholder="Hodin" />
    </div>

  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button [disabled]="!workreportEditForm.valid" type="button" class="btn btn-success" form="priorityEditForm" (click)="editWorkReport()">Uložit</button>
</div>