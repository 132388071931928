<div class="m-5">
  <h2>Měsíční reporty</h2>
  <form class="form-inline" #form="ngForm" (ngSubmit)="loadReport()" novalidate>
    <input class="ml-1" type="month" [(ngModel)]="filterParams.created" name="month" (change)="loadReport()">
    <div class="form-group px-2">
      <label for="companyId">Firma:&nbsp;</label>
      <app-company-picker style="width: 250px"
                          id="companyId"
                          name="companyId"
                          [(ngModel)]="filterParams.companyId"
                          #companyId="ngModel"
                          (change)="setCompanyToFilter($event)" required></app-company-picker>
    </div>
    <button type="submit" class="btn btn-primary" style="margin-left:10px">Filtrovat</button>
    <button type="button" class="btn btn-info" style="margin-left:10px" (click)="resetFilters()">Reset Filtrů</button>
  </form>

  <div class="row w-100" *ngIf="monthlyReport != null">
    <tabset #ticketTabs class="member-tabset w-75">
      <tab heading="Servery">
        <div class="container">
          <table class="table table-hover mb-0" style="cursor: pointer;">
            <thead>
            <tr>
              <td style="width: 30%"><strong>{{monthlyReport.companyName}}</strong></td>
              <td style="width: 30%"></td>
              <td style="width: 30%"></td>
              <td style="width: 10%"><strong>Celkem: {{ServerCount}}</strong></td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td style="width: 30%">Doména</td>
              <td style="width: 30%">Server</td>
              <td style="width: 30%">OS</td>
              <td style="width: 10%">Počet</td>
            </tr>
            <tr *ngFor="let entity of monthlyServerReportEntities">
              <td style="width: 30%">{{entity?.domain}}</td>
              <td style="width: 30%">{{entity?.name}}</td>
              <td style="width: 30%">{{entity?.operatingSystem}}</td>
              <td style="width: 10%">{{entity?.count}}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </tab>
      <tab heading="PC">
        <div class="container">
          <table class="table table-hover mb-0" style="cursor: pointer;">
            <thead>
            <tr>
              <td style="width: 30%"><strong>{{monthlyReport.companyName}}</strong></td>
              <td style="width: 30%"></td>
              <td style="width: 30%"></td>
              <td style="width: 10%"><strong>Celkem: {{PCCount}}</strong></td>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td style="width: 30%">Doména</td>
              <td style="width: 30%">PC</td>
              <td style="width: 30%">OS</td>
              <td style="width: 10%">Počet</td>
            </tr>
            <tr *ngFor="let entity of monthlyPCReportEntities">
              <td style="width: 30%">{{entity?.domain}}</td>
              <td style="width: 30%">{{entity?.name}}</td>
              <td style="width: 30%">{{entity?.operatingSystem}}</td>
              <td style="width: 10%">{{entity?.count}}</td>
            </tr>
            </tbody>
          </table>

        </div>
      </tab>
      <tab heading="E-mailové schránky">
        <div class="container">
          <ng-container *ngFor="let domain of mailboxes">
            <table class="table table-hover mb-0" style="cursor: pointer;">
              <thead>
              <tr>
                <td style="width: 30%">Doména:<strong>&nbsp;{{domain.domain}}</strong></td>
                <td style="width: 50%"></td>
                <td style="width: 20%">Celkem: {{domain.sizeMB  | fileSize}}</td>
              </tr>
              <tr>
                <td style="width: 30%"><strong>Doména</strong></td>
                <td style="width: 50%"><strong>Primární schránka</strong></td>
                <td style="width: 20%"><strong>Velikost schránky</strong></td>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let mailbox of domain.mailboxes">
                <td style="width: 30%">{{mailbox.domain}}</td>
                <td style="width: 50%">{{mailbox.primarySmtpAddress}}</td>
                <td style="width: 20%">{{mailbox.mailboxSizeMB | fileSize}}</td>
              </tr>
              </tbody>
            </table>
          </ng-container>
        </div>
      </tab>
      <tab heading="SQL databáze">
        <div class="container">
          <ng-container *ngFor="let domain of sqlItems">
          <table class="table table-hover mb-0" style="cursor: pointer;">
            <thead>
            <tr>
              <td style="width: 30%">Doména:<strong>&nbsp;{{domain.domain}}</strong></td>
              <td style="width: 50%"></td>
              <td style="width: 20%">Celkem: {{domain.sizeMB  | fileSize}}</td>
            </tr>
            <tr>
              <td style="width: 30%"><strong>Instance</strong></td>
              <td style="width: 50%"><strong>Jméno databáze</strong></td>
              <td style="width: 20%"><strong>Velikost dat</strong></td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let sqlItem of domain.sqlInstances">
              <td style="width: 30%">{{sqlItem.sqlInstance}}</td>
              <td style="width: 50%">{{sqlItem.databaseName}}</td>
              <td style="width: 20%">{{sqlItem.sizeMB | fileSize}}</td>
            </tr>
            </tbody>
          </table>
          </ng-container>
        </div>
      </tab>
      <tab heading="Report souborů">
        <div class="container">
          <ng-container *ngFor="let domain of fileReportItems">
            <table class="table table-hover mb-0" style="cursor: pointer;">
              <thead>
              <tr>
                <td style="width: 30%">Doména:<strong>&nbsp;{{domain.domain}}</strong></td>
                <td style="width: 50%"></td>
                <td style="width: 20%">Celkem: {{domain.sizeMB | fileSize}}</td>
              </tr>
              <tr>
                <td style="width: 30%"><strong>Instance</strong></td>
                <td style="width: 50%"><strong>Jméno</strong></td>
                <td style="width: 20%"><strong>Velikost dat</strong></td>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let fileItem of domain.fileReportItems">
                <td style="width: 30%">{{fileItem.instance}}</td>
                <td style="width: 50%">{{fileItem.folderName}}</td>
                <td style="width: 20%">{{fileItem.sizeMB | fileSize}}</td>
              </tr>
              </tbody>
            </table>
          </ng-container>
        </div>
      </tab>
    </tabset>
  </div>
  <div *ngIf="filterParams.companyId == 0 || monthlyReport == null" class="m-5">
    <div class="container ">
      <div style="text-align: center">
        <h1>Pro daný filtr jsme nenalezli žádná data</h1>
      </div>
    </div>
  </div>
</div>
