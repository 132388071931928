<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
 

  <form [formGroup]="companyAddForm" (ngSubmit)="editCompany()">
    <div class="form-group">
      <input type="text" [ngClass]="{'is-invalid': companyAddForm.get('companyName').errors && companyAddForm.get('companyName').touched }" class="form-control is-invalid" 
      formControlName="companyName" placeholder="Název firmy" />
      <div class="invalid-feedback">Název firmy je povinné pole</div>
    </div>
    <div class="form-group">
      <input type="text" [ngClass]="{'is-invalid': companyAddForm.get('ico').errors && companyAddForm.get('ico').touched }" class="form-control is-invalid" 
      formControlName="ico" placeholder="IČO" />
      <div class="invalid-feedback">IČO je povinné pole</div>
    </div>
    <div class="form-group">
      <input type="text" [ngClass]="{'is-invalid': companyAddForm.get('dico').errors && companyAddForm.get('dico').touched }" class="form-control is-invalid" 
      formControlName="dico" placeholder="DIČ" />
      <div class="invalid-feedback">DIČ je povinné pole</div>
    </div>
    <div class="form-group">
      <input type="text" [ngClass]="{'is-invalid': companyAddForm.get('adressPSC').errors && companyAddForm.get('adressPSC').touched }" class="form-control is-invalid" 
      formControlName="adressPSC" placeholder="PSČ" />
      <div class="invalid-feedback">PSČ je povinné pole</div>
    </div>
    <div class="form-group">
      <input type="text" [ngClass]="{'is-invalid': companyAddForm.get('adressOkres').errors && companyAddForm.get('adressOkres').touched }" class="form-control is-invalid" 
      formControlName="adressOkres" placeholder="Okres" />
      <div class="invalid-feedback">Okres je povinné pole</div>
    </div>
    <div class="form-group">
      <input type="text" [ngClass]="{'is-invalid': companyAddForm.get('adressObec').errors && companyAddForm.get('adressObec').touched }" class="form-control is-invalid" 
      formControlName="adressObec" placeholder="Obec" />
      <div class="invalid-feedback">Obec je povinné pole</div>
    </div>
    <div class="form-group">
      <input type="text" [ngClass]="{'is-invalid': companyAddForm.get('adressCastObce').errors && companyAddForm.get('adressCastObce').touched }" class="form-control is-invalid" 
      formControlName="adressCastObce" placeholder="Část obce" />
      <div class="invalid-feedback">Část obce je povinné pole</div>
    </div>
    <!-- <div class="form-group">
      <input type="text" [ngClass]="{'is-invalid': companyAddForm.get('adressMop').errors && companyAddForm.get('adressMop').touched }" class="form-control is-invalid" 
      formControlName="adressMop" placeholder="Mop" />
      <div class="invalid-feedback">Mop je povinné pole</div>
    </div> -->
    <div class="form-group">
      <input type="text" [ngClass]="{'is-invalid': companyAddForm.get('adressUlice').errors && companyAddForm.get('adressUlice').touched }" class="form-control is-invalid" 
      formControlName="adressUlice" placeholder="Ulice" />
      <div class="invalid-feedback">Ulice je povinné pole</div>
    </div>
    <div class="form-group">
      <input type="text" [ngClass]="{'is-invalid': companyAddForm.get('adressCisloPopisne').errors && companyAddForm.get('adressCisloPopisne').touched }" class="form-control is-invalid" 
      formControlName="adressCisloPopisne" placeholder="Číslo popisné" />
      <div class="invalid-feedback">Číslo popisné je povinné pole</div>
    </div>
    <div class="form-group">
      <input type="text" [ngClass]="{'is-invalid': companyAddForm.get('adressCisloOr').errors && companyAddForm.get('adressCisloOr').touched }" class="form-control is-invalid" 
      formControlName="adressCisloOr" placeholder="Číslo or" />
      <div class="invalid-feedback">Číslo or je povinné pole</div>
    </div>
    <div class="form-group">
      <input type="text" [ngClass]="{'is-invalid': companyAddForm.get('phoneNumber').errors && companyAddForm.get('phoneNumber').touched }" class="form-control is-invalid" 
      formControlName="phoneNumber" placeholder="Telefoní číslo" />
      <div class="invalid-feedback">Telefoní číslo je povinné pole</div>
    </div>
    <div class="form-group">
      <input type="text" [ngClass]="{'is-invalid': companyAddForm.get('email').errors && companyAddForm.get('email').touched }" class="form-control is-invalid" 
      formControlName="email" placeholder="Email" />
      <div class="invalid-feedback">Email je povinné pole</div>
    </div>
    <div class="form-group form-inline">
      <h5 class=" pl-1 pr-3 pt-1">Délka splatnosti ve dnech:</h5>
      <input type="text" [ngClass]="{'is-invalid': companyAddForm.get('daysToPay').errors && companyAddForm.get('daysToPay').touched }" class="form-control is-invalid" 
      formControlName="daysToPay" placeholder="Délka splatnosti" />
      <div class="invalid-feedback">Délka splatnosti je povinné pole</div>
    </div>
    <!-- [disabled]="!companyAddForm.valid" -->


    <div  class="form-group form-inline ml-1">
      <h4 class="mr-2">Primární firma: </h4>
      <input type="checkbox" [ngClass]="{'is-invalid': companyAddForm.get('isPrimary').errors && companyAddForm.get('isPrimary').touched }" class="form-control is-invalid" 
      formControlName="isPrimary" />
    </div>

    <div  class="form-group form-inline ml-1">
      <h4 class="mr-2">Klient: </h4>
      <input type="checkbox" [ngClass]="{'is-invalid': companyAddForm.get('client').errors && companyAddForm.get('client').touched }" class="form-control is-invalid" 
      formControlName="client" />
    </div>

    <div  class="form-group form-inline ml-1">
      <h4 class="mr-2">Dodavatel: </h4>
      <input type="checkbox" [ngClass]="{'is-invalid': companyAddForm.get('supplier').errors && companyAddForm.get('supplier').touched }" class="form-control is-invalid" 
      formControlName="supplier" />
    </div>

    <div  class="form-group">
      <input  type="text" [ngClass]="{'is-invalid': companyAddForm.get('accountNumber').errors && companyAddForm.get('accountNumber').touched }" class="form-control is-invalid" 
      formControlName="accountNumber" placeholder="IBAN" />
    </div>

    <div *ngIf="companyAddForm.get('isPrimary').value" class="form-group">
      <textarea  type="text" [ngClass]="{'is-invalid': companyAddForm.get('invoiceFooterText').errors && companyAddForm.get('invoiceFooterText').touched }" class="form-control is-invalid" 
      formControlName="invoiceFooterText" placeholder="Patička faktury"    style="min-height: 200px;" ></textarea>
    </div>

    <div  class="form-group form-inline ml-1">
      <h4 class="mr-2">Rozdělovat hodiny: </h4>
      <input type="checkbox" formControlName="splitHours" />
    </div>

    <div *ngIf="!companyAddForm.get('splitHours').value" class="form-group form-inline">
      <h5 class=" pl-1 pr-3 pt-1">Počet předplacených hodin:</h5>
      <input type="number" class="form-control w-25" 
      formControlName="hours" placeholder="hodiny" />
      <div class="invalid-feedback">Počet předplacených hodin je povinné pole</div>
    </div>

    <div *ngIf="companyAddForm.get('splitHours').value" class="form-group form-inline">
      <h5 class=" pl-1 pr-3 pt-1">Počet předplacených hodin RAS:</h5>
      <input type="number" class="form-control w-25" 
      formControlName="rasHours" placeholder="RAS hodiny" />
      <div class="invalid-feedback">Počet předplacených hodin RAS je povinné pole</div>
    </div>

    <div *ngIf="companyAddForm.get('splitHours').value" class="form-group form-inline">
      <h5 class=" pl-1 pr-3 pt-1">Počet předplacených hodin ONS:</h5>
      <input type="number" class="form-control w-25" 
      formControlName="onsHours" placeholder="ONS hodiny" />
      <div class="invalid-feedback">Počet předplacených hodin ONS je povinné pole</div>
    </div>

    <div  class="form-group form-inline">
      <h5 class=" pl-1 pr-3 pt-1">Počet předplacených km:</h5>
      <input type="number" class="form-control w-25" 
      formControlName="prepaidKm" placeholder="km" />
      <div class="invalid-feedback">Počet předplacených km je povinné pole</div>
    </div>

    <div  class="form-group form-inline">
      <h5 class=" pl-1 pr-3 pt-1">Počet standartně ujetých km:</h5>
      <input type="number" class="form-control w-25" 
      formControlName="standardKms" placeholder="km" />
      <div class="invalid-feedback">Počet předplacených km je povinné pole</div>
    </div>

    <div class="form-group">
      <textarea  type="text" [ngClass]="{'is-invalid': companyAddForm.get('note').errors && companyAddForm.get('note').touched }" class="form-control is-invalid" 
      formControlName="note" placeholder="Poznámka"    style="min-height: 200px;" ></textarea>
    </div>
  



  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button [disabled]="!companyAddForm.valid" type="button" class="btn btn-success" form="companyAddForm" (click)="editCompany()">Uložit</button>
</div>