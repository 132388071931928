<div class=" m-5">
  <div class=" m-5">

    <div *ngIf="!anyCompanies() && companyFilter ===''" style="text-align: center">
      <h1>Zatím nejsou přidané žádné firmy</h1>
    <button *appHasRole="['CompaniesEdit']" class="btn btn-primary m-3" (click)="createCompanyModal()" >Vytvořit novou firmu</button>
    </div>

  <div class="row" *ngIf="anyCompanies() || companyFilter !==''">
    <table class="table table-hover" style="cursor: pointer">
    <h2>Firmy</h2>
      <div class="form-inline" style="width: 500px;">
        <button *appHasRole="['CompaniesEdit']" class="btn btn-primary" (click)="createCompanyModal()" >Přidat</button>
        <input class=" form-control" type="text" [(ngModel)]="companyFilter" placeholder="Filtr dle názvu" (ngModelChange)="loadCompanies()">
        
        <div class="form-group px-2">
          <select class="form-control ml-1" id="odFilter" [(ngModel)]="odFilter" name="odFilter" (ngModelChange)="loadCompanies()"> 
            <option value="">Odběratel/Dodavatel</option>
            <option value="OD">Odběratel a Dodavatel</option>
            <option value="O">Odběratel</option>
            <option value="D">Dodavatel</option>
          </select>
        </div>

       
      
      </div>
      <tr>
        <th style="width: 20%">Název</th>
        <th style="width: 10%">O/D</th>
        <th style="width: 10%">Telefoní číslo</th>
        <th style="width: 20%">Email</th>
        <th style="width: 20%"></th>
      </tr>
      <tr *ngFor="let company of companies" >
        <td (click)="getCompanyDetailModal(company)" >{{company.companyName}}</td>
        <td ><strong *ngIf="company.client" class="text-success">O</strong> 
          <strong *ngIf="company.supplier"class="text-danger">D</strong></td>
        <td (click)="getCompanyDetailModal(company)">
          <div>
                <strong>{{company.phoneNumber}}</strong>
          </div>
        </td>
        <td (click)="getCompanyDetailModal(company)">{{company.email}}</td>
        <td>
          <div *appHasRole="['CompaniesEdit']"class="form-inline">
            <button class="btn btn-primary" (click)="$event.stopPropagation()" (click)="getCompanyEditModal(company)" ><i class="fa fa-edit ml-1"></i></button>
            <button class="btn btn-danger" (click)="$event.stopPropagation()" (click)="deleteCompany(company.id)" ><i class="fa fa-trash"></i></button>
          </div>
        </td>
      </tr>
    </table>

  </div>

</div>

<div *ngIf="anyCompanies()" class="d-flex justify-content-center">
    <pagination [maxSize]="3"
                [boundaryLinks]="true" 
                [totalItems]="pagination.totalItems"
                [itemsPerPage]="pagination.itemsPerPage"
                [(ngModel)]="pagination.currentPage"
                (pageChanged)="pageChanged($event)"
              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
  </pagination>
  </div>