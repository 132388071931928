<div class="m-5">
  <br> 
  <div *ngIf="workPosts !==undefined" class="m-5">
    <div *ngIf="workPosts.length === 0" style="text-align: center">
      <h1>Zatím nejsou přidané žádné pracovní pozice</h1>
    <button class="btn btn-primary m-3" (click)="createModal()" >Vytvořit novou pozici</button>
    </div>

  <div class="row" *ngIf="workPosts.length > 0">
    <table class="table table-hover" style="cursor: pointer">
    <h2>Pracovní pozice</h2>

      <button *appHasRole="['WorkPost']" class="btn btn-primary" (click)="createModal()" >Přidat</button>
      <tr>
        <th style="width: 20%">Název</th>
        <!-- <th style="width: 20%">Firma</th> -->
        <th style="width: 20%"></th>
      </tr>
      <tr *ngFor="let workpost of workPosts" >
        <td>{{workpost.name}}</td>
        <!-- <td>{{workpost.companyName}}</td> -->
        <td>
          <div class="form-inline">
            <div>
              <button *appHasRole="['WorkPost']" class="btn btn-primary" (click)="$event.stopPropagation()" (click)="getEditModal(workpost)" ><i class="fa fa-edit ml-1"></i></button>
              <button  *appHasRole="['WorkPost']" class="btn btn-danger" (click)="$event.stopPropagation()" (click)="deleteWorkPost(workpost.id)" ><i class="fa fa-trash"></i></button>
            </div>
          </div>
        </td>
      </tr>
    </table>

  </div>

</div>

<div class="d-flex justify-content-center">
    <pagination [maxSize]="3"
                [boundaryLinks]="true" 
                [totalItems]="pagination.totalItems"
                [itemsPerPage]="pagination.itemsPerPage"
                [(ngModel)]="pagination.currentPage"
                (pageChanged)="pageChanged($event)"
              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
  </pagination>
  </div>