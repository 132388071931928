import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Company} from '../_models/company';
import {Pagination} from '../_models/pagination';
import {AlertifyService} from '../_services/alertify.service';
import {AuthService} from '../_services/auth.service';
import {CompanyDialService} from '../_services/companyDial.service';
import {DomainService} from '../_services/domain.service';
import {DomainCreateModalComponent} from './domainCreate-modal/domainCreate-modal.component';
import {DomainDetailModalComponent} from './domainDetail-modal/domainDetail-modal.component';
import {DomainEditModalComponent} from './domainEdit-modal/domainEdit-modal.component';
import {DomainModel} from '../_models/domain';
import {isNullOrUndefined} from "../core/_helpers/common-helpers";

@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html',
  styleUrls: ['./domain.component.css']
})
export class DomainComponent implements OnInit {

  bsModalRef: BsModalRef;
  domains: DomainModel[];
  companies: Company[];
  pagination: Pagination;
  filterParams: any = {};

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private companyService: CompanyDialService,
    private domainService: DomainService
  ) {
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.pagination = data['domains'].pagination;
      this.domains = data['domains'].result;
    });

    this.companyService.getCompanies(1, 1, null, null, false, true).subscribe((data) => {
      this.companies = data.result;
      this.route.params.subscribe(params => {
        this.filterParams.companyId = params['filterParam'];
        if (this.authService.decodedToken.IsPrimary !== 'True') {
          this.filterParams.companyId = this.authService.decodedToken.CompanyId;
        }
        if (this.filterParams.companyId === '0') {
          this.filterParams.companyId = this.authService.decodedToken.CompanyId;
        }
        this.loadDomains();
      });
    });
  }

  deleteDomain(id: number) {
    this.alertify.confirm('Opravdu chcete vymazat tuto doménu ?', () => {
      this.domainService.deleteDomain(id)
        .subscribe(() => {
          this.domains.splice(this.domains.findIndex(m => m.id === id), 1);
          this.alertify.success('Úspěšně odstraněno');
        }, error => {
          this.alertify.error('Doménu se nepodařilo odstranit');
        });
    });
  }

  loadDomains() {
    this.domainService.getDomains(this.pagination.currentPage, this.pagination.itemsPerPage, this.filterParams)
      .subscribe(res => {
        this.domains = res.result;
        this.pagination.totalPages = res.pagination.totalPages;
        this.pagination.itemsPerPage = res.pagination.itemsPerPage;
        this.pagination.totalItems = res.pagination.totalItems;
      }, error => {
        this.alertify.error(error);
      });
  }

  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.loadDomains();
  }

  createModal() {
    const initialState: any = {
      companyId: this.filterParams.companyId
    };
    this.bsModalRef = this.modalService.show(DomainCreateModalComponent, {initialState, backdrop: 'static'});
    this.bsModalRef.content.createNewDomain.subscribe((values) => {
      this.domainService.addDomain(values.domain).subscribe(() => {// ,values.computersToAdd
        this.alertify.success('Úspěšně přidáno');
      }, error => {
        this.alertify.error('Doménu nebo počítače se nepodařilo přidat');
      }, () => {
        this.loadDomains();
      });
    });
  }

  getEditModal(domain: DomainModel) {
    const initialState: any = {
      domain,
      companyId: this.filterParams.companyId
    };
    this.bsModalRef = this.modalService.show(DomainEditModalComponent, {initialState, backdrop: 'static'});
    this.bsModalRef.content.editDomain.subscribe((values) => {
      this.domainService.updateDomain(domain.id, values.domain).subscribe(() => {// ,values.computersToAdd
        this.alertify.success('Úspěšně upraveno');
      }, error => {
        this.alertify.error('Doménu se nepodařilo upravit');
      }, () => {
        this.loadDomains();
      });
    });
  }

  getDetailModal(domain: DomainModel) {
    const initialState: any = {
      domain,
      companyId: this.filterParams.companyId,
      companies: this.companies
    };
    this.bsModalRef = this.modalService.show(DomainDetailModalComponent, {initialState, backdrop: 'static'});
  }

  resetFilters() {
    this.filterParams.companyId = this.authService.decodedToken.CompanyId;
    this.loadDomains();
  }

  setCompanyToDomain($event: number) {
    if (isNullOrUndefined($event)) {
      this.resetFilters();
      return;
    }

    this.filterParams.companyId = $event;
    this.loadDomains();
  }
}
