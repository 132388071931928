import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PaginatedResult } from '../_models/pagination';
import { map } from 'rxjs/operators';
import { Order } from '../_models/order';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}


  addOrder(order: Order)
  {
    return this.http.post(this.baseUrl + 'Order/', order );
  }

  deleteOrder(orderId: number)
  {
    return this.http.delete(this.baseUrl + 'Order/' + orderId );
  }

  getOrders( userParams?)
  {
    let params = new HttpParams();
    if ( userParams != null)
    {
      params = params.append('CompanyId', userParams.companyId);
      params = params.append('OrderDateTime', userParams.orderDateTime);
    }
    return this.http.get<Order[]>(this.baseUrl + 'Order', {observe: 'response', params} );
  }

  updateOrder(id: number, order: Order)
  {
    return this.http.put(this.baseUrl + 'Order/' + id, order);
  }
}
