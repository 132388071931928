<div class="row" style="margin: bottom 15px;">
  <div class="" >
    <input  type="file" #file placeholder="Choose file" (change)="uploadFile(file.files)" style="display: none" multiple >
    <button type="button" class="btn"  (click)="file.click()" [disabled]="progress === null"><i class="fa fa-upload fa-2x" style="color:#E95420 ;"></i></button>
  </div>
  <div>
    <span class="upload" *ngIf="progress >0 && progress<100">
      {{progress}}%
    </span>
    <!-- <span class="upload" *ngIf="message">
      {{message}}
    </span> -->
  </div>
  </div>