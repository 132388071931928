import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CategoryCreateModalComponent } from '../category/categorycreate-modal/categorycreate-modal.component';
import { CategoryEditModalComponent } from '../category/categoryedit-modal/categoryedit-modal.component';
import { Category } from '../_models/category';
import { Company } from '../_models/company';
import { Pagination, PaginatedResult } from '../_models/pagination';
import { WorkPost } from '../_models/workPost';
import { AlertifyService } from '../_services/alertify.service';
import { AuthService } from '../_services/auth.service';
import { CategoryService } from '../_services/category.service';
import { CompanyDialService } from '../_services/companyDial.service';
import { WorkPostService } from '../_services/workPost.service';
import { WorkPostCreateModalComponent } from './workPostCreate-modal/workPostCreate-modal.component';
import { WorkPostEditModalComponent } from './workPostEdit-modal/workPostEdit-modal.component';

@Component({
  selector: 'app-workPost',
  templateUrl: './workPost.component.html',
  styleUrls: ['./workPost.component.scss']
})
export class WorkPostComponent implements OnInit {
  bsModalRef: BsModalRef;
  workPosts: WorkPost[];
  pagination: Pagination;
  filterParams: any = {};

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private workPostService: WorkPostService
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.pagination = data['workPosts'].pagination;
      this.workPosts= data['workPosts'].result;
    });
  }

  deleteWorkPost(id: number)
  {
    this.alertify.confirm('Opravdu chcete vymazat tuto pozici ?', () => {
      this.workPostService.deleteWorkPost(id)
      .subscribe(() => {
        this.workPosts.splice(this.workPosts.findIndex(m => m.id === id ), 1);
        this.alertify.success('Úspěšně odstraněno');
      }, error => {
        this.alertify.error('Pozici se nepodařilo odstranit');
      });
    });
  }

  loadWorkPosts() {
    this.workPostService.getWorkPosts( this.pagination.currentPage, this.pagination.itemsPerPage, this.filterParams)
    .subscribe((res: PaginatedResult<WorkPost[]>) => {
      this.workPosts = res.result;
      // this.pagination = res.pagination;
      this.pagination.totalPages =res.pagination.totalPages;
      this.pagination.itemsPerPage =res.pagination.itemsPerPage;
      this.pagination.totalItems =res.pagination.totalItems;
    }, error => {
      this.alertify.error(error);
    });
   }

  pageChanged(event: any): void{
    this.pagination.currentPage = event.page;
    this.loadWorkPosts();
  }

  createModal()
  {
    const initialState: any= {
      // companyId: this.filterParams.companyId
    };
    this.bsModalRef = this.modalService.show(WorkPostCreateModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.createNewWorkPost.subscribe((values) =>
    {
      this.workPostService.addWorkPost(values).subscribe(() => {
        this.alertify.success('Úspěšně přidáno');
      }, error => {
        this.alertify.error('Pracovní pozici se nepodařilo přidat');
      }, () => {
        this.loadWorkPosts();
      });
    });
  }

  getEditModal(workPost: WorkPost)
  {
    const initialState: any= {
      workPost
    };
    this.bsModalRef = this.modalService.show(WorkPostEditModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.workPostEdit.subscribe((values) =>
    {
      this.workPostService.updateWorkPost(workPost.id, values).subscribe(() => {
        this.alertify.success('Úspěšně upraveno');
      }, error => {
        this.alertify.error('Pozici se nepodařilo upravit');
      }, () => {
        this.loadWorkPosts();
      });
    });
  }





}
