import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';
import { ReminderService } from '../_services/reminder.service';
import { Reminder } from '../_models/reminder';
@Injectable()
export class RemindersResolver implements Resolve<Reminder[]> {
  pageNumber = 1;
  pageSize = 15;

  constructor(
    private reminderService: ReminderService,
    private router: Router,
    private alertify: AlertifyService,
    private authService: AuthService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Reminder[]> {

    return this.reminderService.getReminders(this.pageNumber, this.pageSize).pipe(
      catchError((error) => {
        this.alertify.error('Problem retrieving reminders');
        this.router.navigate(['//adminHome']);
        return of(null);
      })
    );
  }
}
