import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ItemOrderDetailModalComponent } from 'src/app/itemOrders/itemOrderDetail-modal/itemOrderDetail-modal.component';
import { Company } from 'src/app/_models/company';
import { ItemOrder } from 'src/app/_models/itemOrder';
import { Pagination } from 'src/app/_models/pagination';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { InventoryItemService } from 'src/app/_services/inventoryItem.service';
import { InvoiceService } from 'src/app/_services/invoice.service';
import { ItemOrderService } from 'src/app/_services/itemOrder.service';

@Component({
  selector: 'app-IssuesDeliveryListOverview',
  templateUrl: './IssuesDeliveryListOverview.component.html',
  styleUrls: ['./IssuesDeliveryListOverview.component.scss']
})
export class IssuesDeliveryListOverviewComponent implements OnInit {
  bsModalRef: BsModalRef;
  itemOrders: ItemOrder[];
  pagination: Pagination;
  companies: Company[];
  filterParams: any = {};
  stepsviewflag: any;

 

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private companyService: CompanyDialService,
    private itemOrderService: ItemOrderService,
    private invService: InventoryItemService,
    private datePipe: DatePipe,
    private invoiceService: InvoiceService
  ) {}

  ngOnInit() {
    this.companyService.getCompanies(1,10000,"",'true',false,true).subscribe((data) => {
      this.companies = data.result;
    });
      this.route.data.subscribe((data) => {
        this.pagination = data['issues'].pagination;
        this.itemOrders = data['issues'].result;
      });
   
    this.route.params.subscribe(params => {
      this.filterParams.companyId = params['filterParam'];
      this.filterParams.dateFrom = '1990-01-01';
      this.filterParams.dateTo = '1990-01-01';
      this.filterParams.filterType=2;
      this.filterParams.invoicePaid=0;
      this.filterParams.docId="";
      this.filterParams.invoiceId = 0;
    });
  }

  
resetFilters()
{
  this.filterParams.companyId = "0";
  this.filterParams.dateFrom = '1990-01-01';
  this.filterParams.dateTo = '1990-01-01';
  this.filterParams.filterType=2;
  this.filterParams.invoicePaid=0;
  this.filterParams.docId="";
  this.filterParams.invoiceId=0;


  this.loadOrders();
}
  loadOrders()
  {
    this.filterParams.isSupplier=false;
    if(this.filterParams.dateFrom === "")
    this.filterParams.dateFrom = '1990-01-01';
    if(this.filterParams.dateTo === "")
    this.filterParams.dateTo = '1990-01-01';

    this.itemOrderService.getOrders(this.pagination.currentPage, this.pagination.itemsPerPage,this.filterParams).subscribe(data => {
      this.pagination.totalPages =data.pagination.totalPages;
      this.pagination.itemsPerPage =data.pagination.itemsPerPage;
      this.pagination.totalItems =data.pagination.totalItems;
      this.itemOrders = data.result;

      if(this.filterParams.dateFrom === '1990-01-01')
      this.filterParams.dateFrom = "";
      if(this.filterParams.dateTo === '1990-01-01')
      this.filterParams.dateTo = "";
    });
  
  }
  pageNumber(pageNumber: any, pageSize: any, filterParams: any) {
    throw new Error('Method not implemented.');
  }
  pageSize(pageNumber: any, pageSize: any, filterParams: any) {
    throw new Error('Method not implemented.');
  }

  deleteInvoice(itemOrder: ItemOrder)
  {
    this.alertify.confirm('Opravdu chcete vymazat tuto fakturu ?', () => {
      var invId = 0;
      if(itemOrder.inv !== null)
        invId = itemOrder.inv.id;

      this.invoiceService.deleteInvoice(invId)
      .subscribe(() => {
        this.itemOrderService.deleteOrder(itemOrder.id).subscribe(data =>
          {
            this.loadOrders()
            this.alertify.success('Úspěšně odstraněno');
          });
      
      }, error => {
        this.alertify.error('Fakturu se nepodařilo odstranit');
      });
    });
  }

  getDetailModal(itemOrder: ItemOrder, viewName)
  {
        // this.itemOrder.subscriber = data.result.filter(x => x.id.toString() === this.itemOrder.inv.companyId.toString())[0];
        // itemOrder.supplier = this.companies.filter(x => x.id.toString() === itemOrder.inv.supplierCompanyId.toString())[0];
     
        const initialState: any= {
          itemOrder,
          viewName: viewName,
          companyId: this.filterParams.companyId,
        };
        this.bsModalRef = this.modalService.show(ItemOrderDetailModalComponent, {initialState, backdrop:'static', class:'modal-lg'});
      
     
  }
 
  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.loadOrders();
  }

 

  

}
