import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CategoryCreateModalComponent } from '../category/categorycreate-modal/categorycreate-modal.component';
import { CategoryEditModalComponent } from '../category/categoryedit-modal/categoryedit-modal.component';
import { Category } from '../_models/category';
import { Company } from '../_models/company';
import { Pagination, PaginatedResult } from '../_models/pagination';
import { PurchaseDepartmentOrder } from '../_models/purchaseDepartmentOrder';
import { User } from '../_models/user';
import { AlertifyService } from '../_services/alertify.service';
import { AuthService } from '../_services/auth.service';
import { CompanyDialService } from '../_services/companyDial.service';
import { PurchaseDepartmentService } from '../_services/purchaseDepartment.service';
import { UserService } from '../_services/user.service';
import { PurchaseDepartmentCreateModalComponent } from './purchaseDepartmentCreate-Modal/purchaseDepartmentCreate-Modal.component';
import { PurchaseDepartmentDetailModalComponent } from './purchaseDepartmentDetail-Modal/purchaseDepartmentDetail-Modal.component';
import { PurchaseDepartmentEditModalComponent } from './purchaseDepartmentEdit-Modal/purchaseDepartmentEdit-Modal.component';

@Component({
  selector: 'app-purchaseDepartment',
  templateUrl: './purchaseDepartment.component.html',
  styleUrls: ['./purchaseDepartment.component.css']
})
export class PurchaseDepartmentComponent implements OnInit {
  bsModalRef: BsModalRef;
  pds: PurchaseDepartmentOrder[];
  pagination: Pagination;
  companies: Company[];
  filterParams: any = {};
  coreUsers: User[];
  userId: number;
  constructor(
    private pdService: PurchaseDepartmentService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private userService:UserService
  ) {}

  ngOnInit() {
    this.userId = this.authService.decodedToken.nameid;
    this.userService.getCoreUsers().subscribe((data) => {this.coreUsers = data;});
    this.route.data.subscribe((data) => {
      this.pagination = data['pds'].pagination;
      this.pds = data['pds'].result;
      this.resetFilterValues();
    });
    // this.loadPds();
  }

  deleteCategory(id: number)
  {
    this.alertify.confirm('Opravdu chcete vymazat tento požadavek ?', () => {
      this.pdService.removePDO(id)
      .subscribe(() => {
        this.pds.splice(this.pds.findIndex(m => m.id === id ), 1);
        this.alertify.success('Úspěšně odstraněno');
      }, error => {
        this.alertify.error('Požadavek se nepodařilo odstranit');
      });
    });
  }

  loadPds() {
    this.pdService.getPDOsPaginated( this.pagination.currentPage, this.pagination.itemsPerPage, this.filterParams)
    .subscribe((res: PaginatedResult<PurchaseDepartmentOrder[]>) => {
      this.pds = res.result;
      this.pagination.totalPages =res.pagination.totalPages;
      this.pagination.itemsPerPage =res.pagination.itemsPerPage;
      this.pagination.totalItems =res.pagination.totalItems;
    }, error => {
      this.alertify.error(error);
    });
   }

  pageChanged(event: any): void{
    this.pagination.currentPage = event.page;
    this.loadPds();
  }

  createModal()
  {
    const initialState: any= {
      // companyId: this.filterParams.companyId
    };
    this.bsModalRef = this.modalService.show(PurchaseDepartmentCreateModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.createNewPd.subscribe((values) =>
    {
      this.pdService.addPDO(values).subscribe(() => {
        this.alertify.success('Úspěšně přidáno');
      }, error => {
        this.alertify.error('Požadavek se nepodařilo přidat');
      }, () => {
        this.loadPds();
      });
    });
  }

  getEditModal(pdo: PurchaseDepartmentOrder)
  {
    const initialState: any= {
      pdo,
      // companyId: this.filterParams.companyId
    };
    this.bsModalRef = this.modalService.show(PurchaseDepartmentEditModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.editPDO.subscribe((values) =>
    {
      this.pdService.updatePDO(pdo.id, values).subscribe(() => {
        this.alertify.success('Úspěšně upraveno');
      }, error => {
        this.alertify.error('Kategorii se nepodařilo upravit');
      }, () => {
        this.loadPds();
      });
    });
  }
  getDetailModal(pdo: PurchaseDepartmentOrder)
  {
    const initialState: any= {
      pdo
    };
    this.bsModalRef = this.modalService.show(PurchaseDepartmentDetailModalComponent, {initialState, backdrop:'static'});
  }
  

  resetFilters()
  {
    this.resetFilterValues();
    this.loadPds();
  }
  resetFilterValues()
  {
    this.filterParams.orderedById = '0';
    this.filterParams.orderedFromId = '0';
    this.filterParams.name = '';
  }

  compareIds(id1, id2)
  {
    return id1.toString()===id2;
  }

  getCoreUser(id)
  {
    if(this.coreUsers !== undefined)
    {
      var res =  this.coreUsers.filter(x=>x.id == id)[0];
      return res.firstName+" "+res.lastName;
    }
  }


}
