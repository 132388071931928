import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Company } from 'src/app/_models/company';
import {DomainModel} from 'src/app/_models/domain';
import { Report } from 'src/app/_models/report';
import { DomainService } from 'src/app/_services/domain.service';

@Component({
  selector: 'app-domainDetail-modal',
  templateUrl: './domainDetail-modal.component.html',
  styleUrls: ['./domainDetail-modal.component.scss']
})
export class DomainDetailModalComponent implements OnInit {
  @Output() editDomain = new EventEmitter();
  domainEditForm: FormGroup;
  companies: Company[];
  companyId: number;
  domain: DomainModel;
  rParams: any = {};

  pcAndServer: Report[] = [];
  pcAndServerGroups: Report[] = [];
  sqlCount = 0 ;
  files = 0;
  exchange = 0;

  constructor(public bsModalRef: BsModalRef,
              private domainService: DomainService,
              private datePipe: DatePipe) {}

  ngOnInit() {
    this.rParams.domainName = this.domain.name; // "DOMOVURYBNIKA.CZ"
    this.rParams.date = this.datePipe.transform(new Date(), 'yyyy-MM');
    if (this.domain.sqlInstancies.length > 0) {
      this.rParams.sQLInstance = this.domain.sqlInstancies[0];
    }
    this.loadReportData();
  }

  loadReportData()
  {
    this.setDateParams();
    this.resetValues();

    this.domainService.getDomainReport(this.rParams, 'SQL').subscribe((data: Report) => {// SQL Files Exchange
      if (data.value !== 0)
      {
        this.sqlCount = data.value;
      }
    });
    this.domainService.getDomainReport(this.rParams, 'Files').subscribe((data: Report) => {// files exchange
      if (data.value !== 0)
      {
        this.files = data.value;
      }
    });
    this.domainService.getDomainReport(this.rParams, 'Exchange').subscribe((data: Report) => {// files exchange
      if (data.value !== 0)
      {
        this.exchange = data.value;
      }
    });
    this.domainService.getDomainPCReport(this.rParams).subscribe((data: Report[]) => {// files exchange
      if (data.length > 0 && (data[0].value !== 0 || data.length !== 1 ))
      {
        this.pcAndServer = data;

        const tempArray = [];
        this.pcAndServer.forEach(element => {
          if (tempArray.filter(x => x.name == element.name).length <= 0)
          {
            tempArray.push(element);
          }
          else
          {
            tempArray.filter(x => x.name == element.name)[0].value = tempArray.filter(x => x.name == element.name)[0].value + 1;
          }
        });
        this.pcAndServerGroups = JSON.parse(JSON.stringify( tempArray ));
      }
    });
  }

  private setDateParams() {
    const dt = new Date(this.rParams.date);
    this.rParams.month = dt.getMonth();
    this.rParams.year = dt.getFullYear();
  }
  public setPcName(name: string)
  {
    const found = this.pcAndServerGroups.filter(x => x.name === name)[0].pcName;

    if (found === '')
    {
      this.pcAndServerGroups.filter(x => x.name === name)[0].pcName = 'opened';
    }
    else
    {
      this.pcAndServerGroups.filter(x => x.name === name)[0].pcName = '';
    }
  }

  private resetValues() {
    this.sqlCount = 0;
    this.files = 0;
    this.exchange = 0;
    this.pcAndServer = [] as Report[];
    this.pcAndServerGroups = [] as Report[];
  }
}
