import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { InvoiceCreateModalComponent } from '../invoice/invoicecreate-modal/invoicecreate-modal.component';
import { InvoiceDetailModalComponent } from '../invoice/invoicedetail-modal/invoicedetail-modal.component';
import { InvoiceEditModalComponent } from '../invoice/invoiceedit-modal/invoiceedit-modal.component';
import { InvoiceMonthlyReportModalComponent } from '../invoice/invoiceMonthlyReport-modal/invoiceMonthlyReport-modal.component';
import { InvoiceWpsModalComponent } from '../invoice/invoiceWps-modal/invoiceWps-modal.component';
import { ItemOrderCreateModalComponent } from '../itemOrders/itemOrderCreate-modal/itemOrderCreate-modal.component';
import { ItemOrderDetailModalComponent } from '../itemOrders/itemOrderDetail-modal/itemOrderDetail-modal.component';
import { ItemOrderEditModalComponent } from '../itemOrders/itemOrderEdit-modal/itemOrderEdit-modal.component';
import { Company } from '../_models/company';
import { Invoice } from '../_models/invoice';
import { ItemOrder } from '../_models/itemOrder';
import { Pagination } from '../_models/pagination';
import { AlertifyService } from '../_services/alertify.service';
import { AuthService } from '../_services/auth.service';
import { CompanyDialService } from '../_services/companyDial.service';
import { InventoryItemService } from '../_services/inventoryItem.service';
import { InvoiceService } from '../_services/invoice.service';
import { ItemOrderService } from '../_services/itemOrder.service';
import { MonthlyReportService } from '../_services/monthlyReport.service';
import { ToolsService } from '../_services/tools.service';
import { IssueCreateModalComponent } from './issueCreate-modal/issueCreate-modal.component';
import { IssueEditModalComponent } from './issueEdit-modal/issueEdit-modal.component';
import { SendReminderModalComponent } from './sendReminder-modal/sendReminder-modal.component';

@Component({
  selector: 'app-Issues',
  templateUrl: './Issues.component.html',
  styleUrls: ['./Issues.component.scss']
})
export class IssuesComponent implements OnInit {
  bsModalRef: BsModalRef;
  itemOrders: ItemOrder[];
  pagination: Pagination;
  companies: Company[];
  filterParams: any = {};
  stepsviewflag: any;

 

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private companyService: CompanyDialService,
    private itemOrderService: ItemOrderService,
    private invService: InventoryItemService,
    private datePipe: DatePipe,
    private invoiceService: InvoiceService,
    private toolsService:ToolsService,
    private monthlyReportService: MonthlyReportService
  ) {}

  ngOnInit() {
    this.companyService.getCompanies(1,10000,"",'true',false,true).subscribe((data) => {
      this.companies = data.result;
    });
      this.route.data.subscribe((data) => {
        this.pagination = data['issues'].pagination;
        this.itemOrders = data['issues'].result;
      });
   
    this.route.params.subscribe(params => {
      this.filterParams.companyId = params['filterParam'];
      this.filterParams.dateFrom = '1990-01-01';
      this.filterParams.dateTo = '1990-01-01';
      this.filterParams.filterType=1;
      this.filterParams.invoicePaid=0;
      this.filterParams.docId="";
      if(params['invoiceId'] != 0)
      {
        this.filterParams.invoiceId = params['invoiceId'];
        this.filterParams.filterType=3;
        this.loadOrders();
      }
      else
      {
        this.filterParams.invoiceId = 0;
      }
    });
  }
  roundTo(num)
  {
    return this.toolsService.roundTo(num);
  }
  
  createModal()
  {
    const initialState: any= {
      companyId: this.filterParams.companyId
    };
    this.bsModalRef = this.modalService.show(IssueCreateModalComponent, {initialState, backdrop:'static', class:'modal-lg'});

    this.bsModalRef.content.createItemOrder.subscribe((values) =>
    {
      this.itemOrderService.addOrder(values).subscribe((data) => {
          this.alertify.success('Úspěšně vytvořeno');
          this.itemOrderService.getOrderById(data).subscribe(x=>{
            this.getEditModal(x);
          });
      }, error => {
        this.alertify.error('Objednávku se nepodařilo vytvořit');
      }, () => {
        this.loadOrders();
      });
      
    });
  }
resetFilters()
{
  this.filterParams.companyId = "0";
  this.filterParams.dateFrom = '1990-01-01';
  this.filterParams.dateTo = '1990-01-01';
  this.filterParams.filterType=1;
  this.filterParams.invoicePaid=0;
  this.filterParams.docId="";
  this.filterParams.invoiceId=0;


  this.loadOrders();
}
  loadOrders()
  {
    this.filterParams.isSupplier=false;
    if(this.filterParams.dateFrom === "")
    this.filterParams.dateFrom = '1990-01-01';
    if(this.filterParams.dateTo === "")
    this.filterParams.dateTo = '1990-01-01';

    this.itemOrderService.getOrders(this.pagination.currentPage, this.pagination.itemsPerPage,this.filterParams).subscribe(data => {
      // this.pagination = data.pagination;
      this.pagination.totalPages =data.pagination.totalPages;
      this.pagination.itemsPerPage =data.pagination.itemsPerPage;
      this.pagination.totalItems =data.pagination.totalItems;
      this.itemOrders = data.result;

      if(this.filterParams.dateFrom === '1990-01-01')
      this.filterParams.dateFrom = "";
      if(this.filterParams.dateTo === '1990-01-01')
      this.filterParams.dateTo = "";
    });
    // var filterParams: any= {};
    // filterParams.orderDateTime = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    // filterParams.companyId = 0;
    
    // return this.itemOrderService.getOrders(this.pageNumber, this.pageSize,filterParams).subscribe(x=>
    //   {

    //   });
  }
  pageNumber(pageNumber: any, pageSize: any, filterParams: any) {
    throw new Error('Method not implemented.');
  }
  pageSize(pageNumber: any, pageSize: any, filterParams: any) {
    throw new Error('Method not implemented.');
  }

  setStepView(state, viewState)
  {
    if(viewState === "new issue")
    {
      this.stepsviewflag = true;
    }

    if(state === viewState)
    {
      this.stepsviewflag = false;
      return "step current"
    }
    if(this.stepsviewflag)
    {
      return "step current done"
    }
    else
    {
      return "step"
    }
    

    if(viewState === "issued invoice")
    {
      this.stepsviewflag = false;
    }
  }


  getEditModal(itemOrder: ItemOrder)
  {
      const initialState: any= {
        itemOrder,
        companyId: this.filterParams.companyId,
      };
      this.bsModalRef = this.modalService.show(IssueEditModalComponent, {initialState, backdrop:'static', class:'modal-lg'});
      this.bsModalRef.content.reloadItems.subscribe((data) =>{this.loadOrders();});
      this.bsModalRef.content.openInvoice.subscribe((data) =>{
        this.getInvoiceModal(itemOrder);
      });
      
      this.bsModalRef.content.editItemOrder.subscribe((values) =>
      {
        this.itemOrderService.updateOrder(itemOrder.id, values).subscribe(() => {
          this.alertify.success('Úspěšně upraveno');
          this.loadOrders();
        }, error => {
          this.alertify.error('Fakturu se nepodařilo upravit');
        }, () => {
          this.loadOrders();
        });
      });
   
  }

  getInvoiceModal(itemOrder: ItemOrder)
  {
    // const initialState: any= {
    //   itemOrder,
    //   companyId: this.filterParams.companyId,
    // };
    // this.bsModalRef = this.modalService.show(InvoiceCreateModalComponent, {initialState, backdrop:'static', class:'modal-lg'});

    const initialState: any= {
      itemOrder,
      companyId: itemOrder.customerId
    };
    this.bsModalRef = this.modalService.show(InvoiceCreateModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.createNewInvoice.subscribe((values) =>
    {
      this.invoiceService.addInvoice(values.invoice,values.items).subscribe(data => {
        itemOrder.orderState = 'issued invoice filled';
        itemOrder.invId = data.id;
        itemOrder.dateOfDeliveryList = new Date();
        this.itemOrderService.updateOrder(itemOrder.id, itemOrder).subscribe(() => {
          this.loadOrders();
        });
        this.alertify.success('Úspěšně přidáno');
      }, error => {
        this.alertify.error('Fakturu se nepodařilo přidat');
      }, () => {
        // this.loadInvoices();
      });
    });
  }

  getInvoiceEditModal(invoice: Invoice)
  {
    const initialState: any= {
      invoice,
      companyId: invoice.companyId
    };
    this.bsModalRef = this.modalService.show(InvoiceEditModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.editinvoice.subscribe((values) =>
    {
      this.invoiceService.updateInvoice(invoice.id, values.invoice, values.items).subscribe(() => {
        this.alertify.success('Úspěšně upraveno');
      }, error => {
        this.alertify.error('Fakturu se nepodařilo upravit');
      }, () => {
        this.loadOrders();
      });
    });
  }

  deleteInvoice(itemOrder: ItemOrder)
  {
    this.alertify.confirm('Opravdu chcete vymazat tuto fakturu ?', () => {
      var invId = 0;
      if(itemOrder.inv !== null)
        invId = itemOrder.inv.id;

      this.invoiceService.deleteInvoice(invId)
      .subscribe(() => {
        this.itemOrderService.deleteOrder(itemOrder.id).subscribe(data =>
          {
            this.loadOrders()
            this.alertify.success('Úspěšně odstraněno');
          });
      
      }, error => {
        this.alertify.error('Fakturu se nepodařilo odstranit');
      });
    });
  }

  getDetailModal(itemOrder: ItemOrder, viewName)
  {
        // this.itemOrder.subscriber = data.result.filter(x => x.id.toString() === this.itemOrder.inv.companyId.toString())[0];
        // itemOrder.supplier = this.companies.filter(x => x.id.toString() === itemOrder.inv.supplierCompanyId.toString())[0];
     
        const initialState: any= {
          itemOrder,
          viewName: viewName,
          companyId: this.filterParams.companyId,
        };
        this.bsModalRef = this.modalService.show(ItemOrderDetailModalComponent, {initialState, backdrop:'static', class:'modal-lg'});
      
     
  }
  
  getInvoiceDetailModal(itemOrder: ItemOrder)
  {
    const initialState: any= {
      invoice: itemOrder.inv,
    };
    this.bsModalRef = this.modalService.show(InvoiceDetailModalComponent, {initialState, backdrop:'static', class:'modal-lg'});
  }

  sendInvoiceLink(invoice: Invoice)
  {
    const initialState: any= {
      invoice,
      companyId: invoice.companyId
    };
    this.bsModalRef = this.modalService.show(SendReminderModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.sendReminder.subscribe((values) =>
    {
      this.invoiceService.sendInvoiceLink(invoice.id,values).subscribe(() => {
        this.alertify.success('Úspěšně odesláno');
      }, error => {
        this.alertify.error('Připomínku se nepodařilo odeslat');
      }, () => {
        this.loadOrders();
      });
    });

  }

  getWpsModal(invoice: Invoice, companyName: string)
  {
    const initialState: any= {
      invoice,
      companyId: invoice.companyId,
      companyName: companyName,
    };
    this.bsModalRef = this.modalService.show(InvoiceWpsModalComponent, {initialState, backdrop:'static', class:'modal-lg'});
  }


  
  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.loadOrders();
  }

  getMonthlyReport(invoice: Invoice){
    const initialState: any= {
      invoice
    };
    this.monthlyReportService.getMonthlyReport(invoice).subscribe(x => x);
    this.bsModalRef = this.modalService.show(InvoiceMonthlyReportModalComponent, {initialState, backdrop:'static', class:'modal-lg'});
  }
}
