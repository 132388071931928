import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { WorkReport } from '../_models/workReport';
import { Company } from '../_models/company';
import { PaginatedResult, Pagination } from '../_models/pagination';
import { AlertifyService } from '../_services/alertify.service';
import { AuthService } from '../_services/auth.service';
import { WorkreportService } from '../_services/workreport.service';
import { CompanyDialService } from '../_services/companyDial.service';
import { WorkReportCreateModalComponent } from './workreportcreate-modal/workreportcreate-modal.component';
import { User } from '../_models/user';
import { UserService } from '../_services/user.service';
import { WorkReportEditModalComponent } from './workreportedit-modal/workreportedit-modal.component';
import { WorkReportDetailModalComponent } from './workreportdetail-modal/workreportdetail-modal.component';
import { NgxChartsModule } from '@swimlane/ngx-charts/lib/ngx-charts.module';
import { element, Key } from 'protractor';
import { DatePipe } from '@angular/common';
import { TicketDetailModalComponent } from '../tickets/ticket-detail-modal/ticket-detail-modal.component';

@Component({
  selector: 'app-workreports',
  templateUrl: './workreports.component.html',
  styleUrls: ['./workreports.component.scss']
})
export class WorkReportsComponent implements OnInit {
  bsModalRef: BsModalRef;
  workreports: WorkReport[];
  pagination: Pagination;
  users: User[];
  filterParams: any = {};
  monthHoursData: any;
  companyPercentsData: any;
  companies: Company[];
  hkm:number[]


  constructor(
    private workreportService: WorkreportService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private userService: UserService,
    private datePipe: DatePipe,
    private companyService: CompanyDialService,
  ) {}

  ngOnInit() {
    this.filterParams.companyId = 0;
    this.filterParams.wpTitle = '';
        this.filterParams.month = '1990-01-01';
    this.route.data.subscribe((data) => {
      this.pagination = data['workreports'].pagination;
    });
    this.userService.getCoreUsers().subscribe((data) => {
      this.users = data;


      this.route.params.subscribe(params => {
        this.filterParams.userId = params['filterParam'];
        this.companyService.getCompanies(1, 1,null,null,false,true).subscribe((data) => {
          this.companies = data.result;
        });


        // if (this.isCompanyFilter())
        // {
        //   this.filterParams.userId = 0;
        // }
        this.loadWorkReports();
      });
    });
  }

  shouldFilterUsers()
  {
    const role: string[] = this.authService.decodedToken.role;
    return role.filter(x => x === 'WorkReportsFilterNoWorker').length !== 1;
  }

  setCharts()
  {
    this.setMonthHoursChart();
    this.setCompanyPercentChart();
  }
  setMonthHoursChart()
  {
    if(this.shouldFilterUsers())
    {
      // this.users = this.users.filter(x=>x.id.toString() === this.authService.decodedToken.nameid.toString());
      this.filterParams.userId =  this.authService.decodedToken.nameid.toString();
    }
    this.workreportService.getMonthHours(this.filterParams).subscribe( datas =>
      {
        this.monthHoursData = [
          { name: 'Leden', value: datas[1] },
          { name: 'Únor', value: datas[2] },
          { name: 'Březen', value: datas[3] },
          { name: 'Duben', value: datas[4] },
          { name: 'Květen', value: datas[5] },
          { name: 'Červen', value: datas[6] },
          { name: 'Červenec', value: datas[7] },
          { name: 'Srpen', value: datas[8] },
          { name: 'Září', value: datas[9] },
          { name: 'Říjen', value: datas[10] },
          { name: 'Listopad', value: datas[11] },
          { name: 'Prosinec', value: datas[12] }
        ];
      });
  }

  setCompanyPercentChart()
  {
    if(this.shouldFilterUsers())
    {
      // this.users = this.users.filter(x=>x.id.toString() === this.authService.decodedToken.nameid.toString());
      this.filterParams.userId =  this.authService.decodedToken.nameid.toString();
    }
    this.workreportService.getCompanyPercent(this.filterParams).subscribe( datas =>
      {
        this.companyPercentsData = [];
        Object.keys(datas).forEach( (key) => {
         this.companyPercentsData.push( { name: key, value: datas[key] });
        });

      });
  }

  public pieChartClicked(e:any):void
  {
    this.filterParams.companyId = this.companies.filter(x=>x.companyName === e.name)[0].id;
    this.loadWorkReports();
    // if(e.active.length)
    // {
    //   window.scrollTo(0, document.body.scrollHeight);
    //   // this.isChartClicked = true;
    // }
  }
  isCompanyFilter()
  {
    const role: string[] = this.authService.decodedToken.role;
    if (typeof(role) === 'string')
    {
      if (role === 'CompanyFilter')
      {
        return true;
      }
      else
      {
        return false;
      }
    }
    else
    {
      return role.filter(x => x === 'CompanyFilter').length === 1;
    }
  }

  deleteWorkReport(id: number)
  {
    this.alertify.confirm('Opravdu chcete vymazat tento výkaz ?', () => {
      this.workreportService.deleteWorkReport(id)
      .subscribe(() => {
        this.workreports.splice(this.workreports.findIndex(m => m.id === id ), 1);
        this.alertify.success('Úspěšně odstraněno');
      }, error => {
        this.alertify.error('Výkaz se nepodařilo odstranit');
      });
    });
  }

  turnOffWorkReport(id: number, value: boolean)
  {
      this.workreportService.turnOffWorkReport(id,value)
      .subscribe(() => {
       var index = this.workreports.findIndex(m => m.id === id );
        this.workreports[index].invoiced = value;
        // this.workreports.splice(this.workreports.findIndex(m => m.id === id ), 1);
        // this.alertify.success('Úspěšně ');
      }, error => {
        // this.alertify.error('Výkaz se nepodařilo vypnout');
      });
  }

  loadWorkReports() {
    this.setCharts();
    if(this.shouldFilterUsers())
    {
      // this.users = this.users.filter(x=>x.id.toString() === this.authService.decodedToken.nameid.toString());
      this.filterParams.userId =  this.authService.decodedToken.nameid.toString();
    }

    // this.workreportService.getWpsForOrder
    this.workreportService.getWorkReports( this.pagination.currentPage, this.pagination.itemsPerPage, this.filterParams)
    .subscribe((res: PaginatedResult<WorkReport[]>) => {
      this.workreports = res.result;
      // this.pagination = res.pagination;
      this.pagination.totalPages =res.pagination.totalPages;
      this.pagination.itemsPerPage =res.pagination.itemsPerPage;
      this.pagination.totalItems =res.pagination.totalItems;

      this.workreportService.GetWorkReportsNoPaging(this.filterParams).subscribe(data=>
      {
          this.hkm = data;
          this.hkm[1] =  Math.ceil(this.hkm[1]);
      });

    }, error => {
      this.alertify.error(error);
    });
   }

  pageChanged(event: any): void{
    this.pagination.currentPage = event.page;
    this.loadWorkReports();
  }

  createModal()
  {
    const initialState: any= {
      selectedCompanyId: this.filterParams.companyId,
    };
    this.bsModalRef = this.modalService.show(WorkReportCreateModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.createNewWorkReport.subscribe((values) =>
    {
      values.workFrom = this.datePipe.transform(values.workFrom, 'yyyy-MM-dd HH:mm')
      values.workTo = this.datePipe.transform(values.workTo, 'yyyy-MM-dd HH:mm')
      this.workreportService.addWorkReport(values).subscribe(() => {
        this.alertify.success('Úspěšně přidáno');
      }, error => {
        this.alertify.error('Výkaz se nepodařilo přidat');
      }, () => {
        this.loadWorkReports();
      });
    });
  }

  getEditModal(workreport: WorkReport)
  {
    const initialState: any= {
      workreport
    };
    this.bsModalRef = this.modalService.show(WorkReportEditModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.editworkreport.subscribe((values) =>
    {
      values.workFrom = this.datePipe.transform(values.workFrom, 'yyyy-MM-dd HH:mm')
      values.workTo = this.datePipe.transform(values.workTo, 'yyyy-MM-dd HH:mm')
      this.workreportService.updateWorkReport(workreport.id, values).subscribe(() => {
        this.alertify.success('Úspěšně upraveno');
      }, error => {
        this.alertify.error('Výkaz se nepodařilo upravit');
      }, () => {
        this.loadWorkReports();
      });
    });
  }

  resetFilters()
  {
    this.filterParams.userId = 0;
    // this.filterParams.month = this.datePipe.transform(new Date(), 'yyyy-MM');
    this.filterParams.month = '1990-01-01';
    this.filterParams.companyId = 0;
    this.filterParams.wpTitle = '';

    this.loadWorkReports();
  }
  getWorkReportDetailModal(workReport: WorkReport)
  {
    const initialState: any= {
      workReport
    };
    this.bsModalRef = this.modalService.show(WorkReportDetailModalComponent, {initialState, backdrop:'static'});
  }
  getTicketDetailModal(ticketId: number)
  {
   if(ticketId !== 0)
   {
    const initialState:any = {
      ticketId
    };
    this.bsModalRef = this.modalService.show(TicketDetailModalComponent, {initialState, class:'modal-xl', backdrop:'static'});
   }

  }

  getFilteredHours()
  {
    const h = this.workreports.map(({ hours }) => hours);
    var res = h.reduce((a, b) => a + b, 0)
    return res;
  }

  getFilteredKm()
  {
    const km = this.workreports.map(({ transportKm }) => transportKm);
    var res = km.reduce((a, b) => a + b, 0)
    return res;
  }

  setAsChecked(wp:WorkReport)
  {
    wp.checked =! wp.checked;
    this.workreportService.updateWorkReport(wp.id,wp).subscribe(x=>{
      this.loadWorkReports();
    });
  }
}
