import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

constructor() { }
// updateUserRoles(user: User, groups:any)
// {
//   return this.http.post(this.baseUrl + 'admin/editroles/' + user.userName, groups);
// }

roundTo(num)
{
  return Math.round((num + Number.EPSILON) * 100) / 100;
}

}
