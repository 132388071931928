import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {PaginatedResult} from '../_models/pagination';
import {map} from 'rxjs/operators';
import {WorkReport} from '../_models/workReport';
import {Observable} from 'rxjs';
import {WorkReportNoInternalNote} from '../_models/workReportsNoInternalNote';
import {ReportItemsBag} from '../_models/reportItemsBag';
import {OrderItemModel} from "../_models/priceListItem";

@Injectable({
  providedIn: 'root'
})
export class WorkreportService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  addWorkReport(workreport: WorkReport) {
    return this.http.post(this.baseUrl + 'WorkReport/', workreport);
  }

  deleteWorkReport(workreportId: number) {
    return this.http.delete(this.baseUrl + 'WorkReport/' + workreportId);
  }

  turnOffWorkReport(workreportId: number, value: boolean) {
    return this.http.post(this.baseUrl + 'WorkReport/TurnOff/' + workreportId + "/value/" + value, null);
  }

  getWorkReports(page?, itemsPerPage?, userParams?) {
    return this.getWorkReportsInternal<WorkReport>('WorkReport', page, itemsPerPage, userParams);
  }

  getWorkReportsNoInternal(page?, itemsPerPage?, userParams?) {
    return this.getWorkReportsInternal<WorkReportNoInternalNote>('WorkReport/NoInternalNote', page, itemsPerPage, userParams);
  }

  getWorkReportsInternal<T>(path: string, page?, itemsPerPage?, userParams?) {
    const paginatedResult: PaginatedResult<T[]> = new PaginatedResult<T[]>();
    const params = this.createParams({ page: page, itemsPerPage: itemsPerPage}, userParams);

    return this.http.get<T[]>(this.baseUrl + path, {observe: 'response', params})
      .pipe(
        map(response => {
          paginatedResult.result = response.body;
          if (response.headers.get('Pagination') !== null) {
            paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
          }
          return paginatedResult;
        })
      );
  }

  GetWorkReportsNoPaging(userParams?) {
    let params = this.createParams(null, userParams);
    return this.http.get<number[]>(this.baseUrl + 'WorkReport/GetWorkReportsNoPaging', {
      observe: 'response',
      params
    }).pipe(
      map(response => {
        return response.body;
      })
    );
  }

  updateWorkReport(id: number, workreport: WorkReport) {
    return this.http.put(this.baseUrl + 'WorkReport/' + id, workreport);
  }

  getWorkReport(id): Observable<WorkReport> {
    return this.http.get<WorkReport>(this.baseUrl + 'WorkReport/' + id);
  }

  getMonthHours(userParams): Observable<Map<number, number>> {
    let params = this.createParams(null, userParams);

    return this.http.get<Map<number, number>>(this.baseUrl + 'WorkReport/GetMonthHours/', {
      observe: 'response',
      params
    }).pipe(
      map(response => {
        return response.body;
      })
    );
  }

  getWpsForInvoice(companyId, monthFor, filterInvoiced, deductHours, getInvoicedDomainData, invoiceId): Observable<ReportItemsBag[]> {

    let params = new HttpParams();
    params = params.append('MonthFor', monthFor);
    params = params.append('CompanyId', companyId);
    params = params.append('FilterInvoiced', filterInvoiced);
    params = params.append('DeductHours', deductHours);
    params = params.append('GetInvoicedDomainData', getInvoicedDomainData);
    params = params.append('InvoiceId', invoiceId);

    return this.http.get<ReportItemsBag[]>(this.baseUrl + 'WorkReport/GetWpsForInvoice/', {
      observe: 'response',
      params
    }).pipe(
      map(response => {
        return response.body;
      })
    );
  }

  getWorkReportsItemsForInvoice(companyId, monthFor, filterInvoiced, deductHours, getInvoicedDomainData, invoiceId): Observable<OrderItemModel[]> {

    let params = new HttpParams();
    params = params.append('MonthFor', monthFor);
    params = params.append('CompanyId', companyId);
    params = params.append('FilterInvoiced', filterInvoiced);
    params = params.append('DeductHours', deductHours);
    params = params.append('GetInvoicedDomainData', getInvoicedDomainData);
    params = params.append('InvoiceId', invoiceId);

    return this.http.get<OrderItemModel[]>(this.baseUrl + 'WorkReport/GetWpsForInvoice/', {
      observe: 'response',
      params
    }).pipe(
      map(response => {
        return response.body;
      })
    );
  }

  getWpsForOrder(companyId, monthFor): Observable<Map<string, number>> {

    let params = new HttpParams();
    params = params.append('MonthFor', monthFor);
    params = params.append('CompanyId', companyId);

    return this.http.get<Map<string, number>>(this.baseUrl + 'WorkReport/getWpsForOrder/', {
      observe: 'response',
      params
    }).pipe(
      map(response => {
        return response.body;
      })
    );
  }

  getCompanyPercent(userParams): Observable<Map<string, number>> {
    let params = new HttpParams();
    params = this.createParams(params, userParams);

    return this.http.get<Map<string, number>>(this.baseUrl + 'WorkReport/GetCompanyPercent/', {
      observe: 'response',
      params
    }).pipe(
      map(response => {
        return response.body;
      })
    );
  }

  createParams(paging, userParams) {
    let params = new HttpParams();

    if (paging != null && paging.page != null && paging.itemsPerPage != null) {
      params = params.append('pageNumber', paging.page);
      params = params.append('pageSize', paging.itemsPerPage);
    }
    return this.addUserParams(userParams, params);
  }

  addUserParams(userParams, params) {
    if (userParams != null && userParams != 'undefined') {
      if (userParams.userId != null) {
        params = params.append('UserId', userParams.userId);
      }
      if (userParams.month != null) {
        params = params.append('Month', userParams.month);
      }
      if (userParams.companyId != null) {
        params = params.append('CompanyId', userParams.companyId);
      }
      if (userParams.wpTitle != null) {
        params = params.append('WpTitle', userParams.wpTitle);
      }
      if (userParams.invoiceId != null) {
        params = params.append('InvoiceId', userParams.invoiceId);
      }
    }
    return params;
  }
  getPdfReport(invoiceId: number): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.http.get<any>(this.baseUrl + 'WorkReport/pdfReport/' + invoiceId, httpOptions);
  }
}
