import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PaginatedResult } from '../_models/pagination';
import { map } from 'rxjs/operators';
import { EmailNotification } from '../_models/emailNotification';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}


  addEmailNotification(emailEnalbed: EmailNotification)
  {
    return this.http.post(this.baseUrl + 'EmailNotifications/', emailEnalbed );
  }

  deleteEmailNotification(emnId: number)
  {
    return this.http.delete(this.baseUrl + 'EmailNotifications/' + emnId );
  }

  getEmailNotifications( page?, itemsPerPage?, userParams?)
  {
    const paginatedResult: PaginatedResult<EmailNotification[]> = new PaginatedResult<EmailNotification[]>();
    let params = new HttpParams();

    if (page != null && itemsPerPage != null)
    {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
    if ( userParams != null)
    {
      params = params.append('CompanyId', userParams.companyId);
    }
    return this.http.get<EmailNotification[]>(this.baseUrl + 'EmailNotifications', {observe: 'response', params} )
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') !== null)
        {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })
    );
  }

  updateEmailNotification(emailnotification: EmailNotification)
  {
    return this.http.put(this.baseUrl + 'EmailNotifications', emailnotification);
  }

  getIsEnabled(userId: number, ticketId: number)
  {
    return this.http.get(this.baseUrl + 'EmailNotifications/GetIsEnabled/' + userId + '/ticket/' + ticketId);
  }
}
