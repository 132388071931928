export function isNullOrUndefined(value: any) {
  return value === null || value === undefined;
}
export function isNullOrEmpty(value: string | number | Date): boolean {
  return value === undefined || value == null || value === '';
}

export function isString(value: any): value is string {
  return typeof value === 'string';
}

export function isObject(value: any): boolean {
  return value !== null && typeof value === 'object';
}
export function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

export function toString(value: any): string {
  return (value !== undefined && value !== null) ? `${value}` : '';
}


export function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

export function isInteger(value: any): value is number {
  return typeof value === 'number' && isFinite(value) && Math.floor(value) === value;
}

export function isDefined(value: any): boolean {
  return value !== undefined && value !== null;
}

export function isPromise<T>(v: any): v is Promise<T> {
  return v && v.then;
}

export function downloadFile(fileName: string, content: Blob) {
  const downloadLink = document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(content);
  downloadLink.setAttribute('download', fileName);
  document.body.appendChild(downloadLink);
  downloadLink.click();
  downloadLink.remove();
}

export function checkUrl(url: string): string {
  const regExp = /https?:.*/;
  if (regExp.test(url) || 'https://'.includes(url)) {
    return url;
  }
  return 'https://' + url;
}

export function checkUrlWithFtp(url: string): string {
  const regExp = /https?:\/\/.*|ftps?:\/\/.*/;
    if (regExp.test(url) || 'https://'.includes(url)) {
      return url;
    }
    return 'https://' + url;
}

export function hasProtocol(url: string): boolean {
  const regExp = /https?:\/\/.*|ftps?:\/\/.*|^mailto:.*|^tel:.*/;
  return regExp.test(url);
}
