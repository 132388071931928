import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Company } from 'src/app/_models/company';
import { Priority } from 'src/app/_models/priority';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
@Component({
  selector: 'app-priorityedit-modal',
  templateUrl: './priorityedit-modal.component.html',
  styleUrls: ['./priorityedit-modal.component.css']
})
export class PriorityEditModalComponent implements OnInit {
  @Output() editpriority = new EventEmitter();
  priority: Priority;
  companyId: string;
  priorityEditForm: FormGroup;
  companies: Company[];
  constructor(public bsModalRef: BsModalRef, private fb: FormBuilder, private companyService: CompanyDialService) {}

  ngOnInit() {
    this.createForm();
    this.companyService.getCompanies(1, 1,null,null,false,true).subscribe(data =>
    {
      if (this.companyId !== '0')
      {
        this.companies = data.result.filter(x => x.id.toString() === this.companyId);
      }
      else
      {
        this.companies = data.result;
      }
    });
  }


  createForm(){
    this.priorityEditForm = this.fb .group({
      name: [this.priority.name, Validators.required],
      deadline: [this.priority.deadline, Validators.required],
      companyid: [this.priority.companyId, Validators.required],
      isSLA: [this.priority.isSLA, Validators.required],
    });
  }

  editPriority()
  {
    if (this.priorityEditForm.valid)
    {
      this.priority = Object.assign({}, this.priorityEditForm.value);
      this.editpriority.emit(this.priority);
      this.bsModalRef.hide();
    }
  }
}

