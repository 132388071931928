import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {Company} from '../../../_models/company';
import {CompanyDialService} from '../../../_services/companyDial.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-company-picker',
  templateUrl: './company-picker.component.html',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CompanyPickerComponent), multi: true}
  ]
})
export class CompanyPickerComponent implements ControlValueAccessor, OnInit {

  get value(): number | null {
    return this.formControlValue;
  }

  set value(v: number | null) {
    if (v !== this.formControlValue) {
      this.formControlValue = v;
    }
  }

  constructor(private companyService: CompanyDialService) {
  }

  private loadingData: boolean;
  onTouchedCallback: any;
  onChangeCallback: any;

  public disabled: boolean;

  @Output() change: EventEmitter<number> = new EventEmitter<number>();
  @Output() onUnitChange: EventEmitter<Company> = new EventEmitter<Company>();

  // @Input()
  // multiple = false;

  @Input()
  placement: PlacementArray;

  @Input()
  container: null | 'body';

  private formControlValue: number | null;

  public companies: Company[];
  public selectedCompany: Company;

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value) {
    this.value = value;
  }

  ngOnInit(): void {
    this.loadCompanies();
  }

  onChange(): any {
    if (this.onChangeCallback) {
      this.onChangeCallback(this.formControlValue);
    }
    this.change.emit(this.formControlValue);
    this.onUnitChange.emit(this.selectedCompany);
  }

  updateValue(event: any) {
    this.formControlValue = event;
    this.selectedCompany = this.companies.find(x => x.id === event);
    this.onChange();
  }

  loadCompanies() {
    this.loadingData = true;
    this.companyService.getCompanies(1, 1).subscribe((data) => {
      this.companies = data.result;
    });
  }
}

export type Placement = 'auto' | 'top' | 'bottom' | 'left' | 'right' | 'top-left' | 'top-right' | 'bottom-left' |
  'bottom-right' | 'left-top' | 'left-bottom' | 'right-top' | 'right-bottom';

export type PlacementArray = Placement | Array<Placement> | string;
