import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Category } from 'src/app/_models/category';
import { Company } from 'src/app/_models/company';
import { Priority } from 'src/app/_models/priority';
import { Ticket } from 'src/app/_models/ticket';
import { User } from 'src/app/_models/user';
import { AuthService } from 'src/app/_services/auth.service';
import { CategoryService } from 'src/app/_services/category.service';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { PriorityService } from 'src/app/_services/priority.service';
import { TicketService } from 'src/app/_services/ticket.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-ticketadd-modal',
  templateUrl: './ticketadd-modal.component.html',
  styleUrls: ['./ticketadd-modal.component.scss']
})
export class TicketaddModalComponent implements OnInit {

  @Output() createNewTicket = new EventEmitter();
  ticket: Ticket;
  ticketAddForm: FormGroup;
  coreUsers: User[];
  priorities: Priority[];
  categories: Category[];
  repeatCreate: boolean=false;
  companies: Company[];
  filterParams: any ;
  companyForDynamicFilter: any;
  parentTicketId:number;
  parentTicket:Ticket;
  months:string[]=['Leden','Únor','Březen','Duben','Květen','Červen','Červenec','Srpen','Září','Říjen','Listopad','Prosinec'];
  days:string[]=['Po','Út','St','Čt','Pá','So','Ne'];
  order:string[]=['První','Druhý','Třetí','Čtvrtý'];

  constructor(public bsModalRef: BsModalRef,
              private fb: FormBuilder,
              private userService: UserService,
              private priorityServicve: PriorityService,
              private authService: AuthService,
              private categoryService: CategoryService,
              private companyService: CompanyDialService,
              private datePipe:DatePipe
              ) {}

  ngOnInit() {
    this.userService.getCoreUsers().subscribe((data) => {this.coreUsers = data;});
    this.companyForDynamicFilter =this.authService.decodedToken.CompanyId;
    this.categoryService.getCategories(1, 1, { companyId: this.companyForDynamicFilter} ).subscribe((data) => {this.categories = data.result; });
    this.priorityServicve.getPriorities(1, 1, { companyId: this.companyForDynamicFilter} ).subscribe((data) => {this.priorities = data.result; });
    this.createForm();
    this.companyService.getCompanies(1,1,null,null,false,false).subscribe(data =>{
      this.companies = data.result
      if (this.filterParams.companyId !== '0')
      {
        this.companies = this.companies.filter(x => x.id.toString() === this.filterParams.companyId);
      }
      if(this.authService.decodedToken.IsPrimary !== 'True')
      {
        this.companies = this.companies.filter(x => x.id.toString() === this.authService.decodedToken.CompanyId);
      }
    });

  }

  setCompanyForDynamicFilter()
  {
    this.companyForDynamicFilter = this.ticketAddForm.get('issuerCompanyId').value;
    this.categoryService.getCategories(1, 1, { companyId: this.companyForDynamicFilter} ).subscribe((data) => {this.categories = data.result; });
    this.priorityServicve.getPriorities(1, 1, { companyId: this.companyForDynamicFilter} ).subscribe((data) => {this.priorities = data.result;  });
  }





  createForm(){
    if(this.parentTicketId !== 0)
    {
      this.ticketAddForm = this.fb .group({
        title: ['', Validators.required],
        description: ['', Validators.required],
        priorityId: [this.parentTicket.priorityId,   Validators.required],
        categoryId: [this.parentTicket.categoryId, Validators.required],
        solverId: ['', Validators.required],
        issuerCompanyId: [this.parentTicket.issuerCompanyId, Validators.required],
        issuerId: [this.authService.decodedToken.nameid, Validators.required],
        repeat:[this.repeatCreate],
        dayCountForRepeat:[1],
        lastDateOfRepeat:[this.datePipe.transform(new Date(), 'yyyy-MM-dd'), Validators.required],
        parentTicketId:[this.parentTicketId],

        weekNo:[1],
        daysArray:[''],
        monthArray:[''],
        daysNoArray:[''],
        // dayNo:[this.ticket.dayNo],
        repeatType:[''],
        monthRepeatType:[''],
        daysArray2:[''],
        dayNoArray:[''],
        
      });
    }
    else
    {
      this.ticketAddForm = this.fb .group({
        title: ['', Validators.required],
        description: ['', Validators.required],
        priorityId: ['', Validators.required],
        categoryId: ['', Validators.required],
        solverId: ['', Validators.required],
        issuerCompanyId: ['', Validators.required],
        issuerId: [this.authService.decodedToken.nameid, Validators.required],
        repeat:[this.repeatCreate],
        dayCountForRepeat:[1],
        lastDateOfRepeat:[this.datePipe.transform(new Date(), 'yyyy-MM-dd'), Validators.required],
        parentTicketId:[this.parentTicketId],

        weekNo:[1],
        daysArray:[''],
        monthArray:[''],
        daysNoArray:[''],
        // dayNo:[this.ticket.dayNo],
        repeatType:['Denně'],
        monthRepeatType:['Dny'],
        daysArray2:[''],
        dayNoArray:[''],
        
      });
    }
    
  }

  createTicket()
  {
    if (this.ticketAddForm.valid)
    {
      this.ticket = Object.assign({}, this.ticketAddForm.value);
      this.createNewTicket.emit(this.ticket);
      this.bsModalRef.hide();
    }
  }

  SetArray(value,prop:string)
  {
   
    var values = this.ticketAddForm.get(prop).value;
    if(values.includes(value+','))
    {
      values= values.replace(value+',', ""); 
    }
    else
    {
      values = values +value+','
    }
    // this.ticketAddForm.patchValue({prop:values});
    if(prop==='daysArray')
      {
        this.ticketAddForm.patchValue({daysArray:values});
      }
      if(prop==='monthArray')
      {
        this.ticketAddForm.patchValue({monthArray:values});
      }
      if(prop==='daysNoArray')
      {
        this.ticketAddForm.patchValue({daysNoArray:values});
      }
      if(prop==='daysArray2')
      {
        this.ticketAddForm.patchValue({daysArray2:values});
      }
      if(prop==='dayNoArray')
      {
        this.ticketAddForm.patchValue({dayNoArray:values});
      }
  }

  SetCheck(value,prop)
  {
    if(this.ticketAddForm.get(prop).value === null)
    {
      if(prop==='daysArray')
      {
        this.ticketAddForm.patchValue({daysArray:''});
      }
      if(prop==='monthArray')
      {
        this.ticketAddForm.patchValue({monthArray:''});
      }
      if(prop==='daysNoArray')
      {
        this.ticketAddForm.patchValue({daysNoArray:''});
      }
      if(prop==='daysArray2')
      {
        this.ticketAddForm.patchValue({daysArray2:''});
      }
      if(prop==='dayNoArray')
      {
        this.ticketAddForm.patchValue({dayNoArray:''});
      }
      
    }
    return this.ticketAddForm.get(prop).value.includes(value+',');

  }
}
