<nav class="navbar navbar-expand-md navbar-dark bg-dark">

 <div class="container" style="min-width: 80%;">
  <button class="navbar-toggler navbar-toggler-right" type="button" (click)="navbarCollapsed = !navbarCollapsed" [attr.aria-expanded]="!navbarCollapsed"
   aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation">
   <span class="navbar-toggler-icon"></span>
 </button>
  <a  class="navbar-brand"[routerLink]="['/adminHome']" href="#">Core Help Desk</a><!--*ngIf="loggedIn() && isAdmin()"-->

  <div *ngIf="!navbarCollapsed " class=" navbar-collapse" id="navbarSupportedContent" >
  <ul *ngIf="loggedIn()" class="navbar-nav mr-auto" >
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" [routerLink]="['/tickets/'+companyId+'/'+0]"   href="#">Tickety</a>
    </li>
    <li class="nav-item" routerLinkActive="active">
      <a class="nav-link" *appHasRole="['WorkReports']" [routerLink]="['/workreports/'+userId]"   href="#">Výkaz práce</a>
    </li>

    <div *appHasRole="['Companies','Priority','RolesEdit','Category','UserRegistration','RoleGroupsEdit','UserEdit','DomainEdit']" class="dropdown" dropdown>
      <a  class="nav-link dropdown-toggle" dropdownToggle>Správa</a>

      <div  class="dropdown-menu mt-3" *dropdownMenu>
        <div *appHasRole="['Companies']">
          <a class="dropdown-item" [routerLink]="['/company']"   href="#">Firmy</a>
        </div>
        <div *appHasRole="['UserEdit']">
          <div class="dropdown-divider"></div>
          <a  class="dropdown-item" [routerLink]="['/admin/'+companyId]"   href="#">Uživatelé</a>
        </div>
        <div *appHasRole="['RoleGroupsEdit']">
          <div class="dropdown-divider"></div>
          <a  class="dropdown-item" [routerLink]="['/rolesgroups/'+companyId]"   href="#">Skupiny oprávnění</a>
        </div>
        <div *appHasRole="['Priority']">
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/priorities/'+companyId]"   href="#">Priority</a>
        </div>
        <div *appHasRole="['Priority']">
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/reminders/'+companyId]"   href="#">Připomínky</a>
        </div>
        <div *appHasRole="['Category']">
          <div class="dropdown-divider"></div>
          <a  class="dropdown-item" [routerLink]="['/category/'+companyId]"   href="#">Kategorie</a>
        </div>
        <div *appHasRole="['WorkPost']">
          <div class="dropdown-divider"></div>
          <a  class="dropdown-item" [routerLink]="['/workPost']"   href="#">Pracovní pozice</a>
        </div>
        <div *appHasRole="['WorkPost']">
          <div class="dropdown-divider"></div>
          <a  class="dropdown-item" [routerLink]="['/licence/0']"   href="#">Licence</a>
        </div>
        <div *appHasRole="['DomainEdit']">
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/domain/'+companyId]"   href="#">Domény a PC</a>
        </div>
      </div>
    </div>

    <div *appHasRole="['DefaultPriceList','PriceList','Order']" class="dropdown" dropdown>
      <a  class="nav-link dropdown-toggle" dropdownToggle>Služby</a>

      <div  class="dropdown-menu mt-3" *dropdownMenu>

        <div *appHasRole="['DefaultPriceList']">
          <a class="dropdown-item" [routerLink]="['/basepricelist/']"   href="#">Základní ceník</a>
        </div>
        <div *appHasRole="['PriceList']">
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/pricelist/']"   href="#">Ceník</a>
        </div>
        <div *appHasRole="['Order']">
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/orders/'+companyId]"   href="#">Objednávky služeb</a>
        </div>
        <div *appHasRole="['Order']">
          <div class="dropdown-divider"></div>
          <a  class="dropdown-item" [routerLink]="['/licenceReport/0']"   href="#">Report licencí</a>
        </div>
        <div *appHasRole="['Order']">
          <div class="dropdown-divider"></div>
          <a  class="dropdown-item" [routerLink]="['/reports']"   href="#">Měsíční reporty</a>
        </div>

      </div>
    </div>

    <div *appHasRole="['Invoice']" class="dropdown" dropdown>
      <a  class="nav-link dropdown-toggle" dropdownToggle>Doklady vydané</a>

      <div  class="dropdown-menu mt-3" *dropdownMenu>

        <div *ngIf="isPrimary === 'True'">
          <a class="dropdown-item" [routerLink]="['/issues/'+companyId+'/0']"   href="#">Přehled</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/issuesOrderOverview/'+companyId+'/0']"   href="#">Objednávky</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/issuesDeliveryListOverviewComponent/'+companyId+'/0']"   href="#">Dodací listy</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/issueInvoiceOverviewComponent/'+companyId+'/0']"   href="#">Faktury</a>
        </div>
      </div>
    </div>

    <div *appHasRole="['Invoice']" class="dropdown" dropdown>
      <a  class="nav-link dropdown-toggle" dropdownToggle>Doklady přijaté</a>
      <div  class="dropdown-menu mt-3" *dropdownMenu>
        <div *appHasRole="['PriceList']">
          <a class="dropdown-item" [routerLink]="['/itemOrders/'+companyId+'/0']"   href="#">Přehled</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/itemOrderOverviewComponent/'+companyId+'/0']"   href="#">Objednávky</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/itemOrderReceiptOverviewComponent/'+companyId+'/0']"   href="#">Příjemky</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" [routerLink]="['/itemOrderInvoiceOverviewComponent/'+companyId+'/0']"   href="#">Faktury</a>
        </div>
      </div>
    </div>

    <li *appHasRole="['Invoice']" class="nav-item" routerLinkActive="active">
      <a class="nav-link" [routerLink]="['/purchaseDepartment']"   href="#">Nákupní oddělení</a>
    </li>

  <li *appHasRole="['InventoryItems']" class="nav-item" routerLinkActive="active">
    <a class="nav-link" [routerLink]="['/inventoryItems']"   href="#">Sklad</a>
  </li>



  <li *appHasRole="['RepeatedTickets']" class="nav-item" routerLinkActive="active">
    <a class="nav-link" [routerLink]="['/ticketsForRepeat/'+companyId+'/0']"   href="#">Opakované úlohy</a>
  </li>

  </ul>
</div>
  <div *ngIf="loggedIn()" class="dropdown" dropdown>
    <span class="mr-1">
      <img src="{{photoUrl|| '../../assets/user.png'}}" alt="">
    </span>
    <a class=" dropdown-toggle text-light" dropdownToggle>
       {{authService.decodedToken?.unique_name | titlecase}}
    </a>

    <div class="dropdown-menu mt-3" *dropdownMenu>
      <a class="dropdown-item" [routerLink]="['/userEdit']" ><i class="fa fa-user"></i>&nbsp;Úprava uživatele</a>
      <div class="dropdown-divider"></div>
      <a class="dropdown-item" (click)="logout()"><i class="fa fa-sign-out"></i>&nbsp;Odhlášení</a>
    </div>
  </div>

  <form *ngIf="!loggedIn()" #loginForm="ngForm" class="form-inline my-2 my-lg-0" (ngSubmit)="login()">
    <input class="form-control mr-sm-2" type="text" name="Username" placeholder="Username" required [(ngModel)]="model.username">
    <input class="form-control mr-sm-2" type="password" name="password" placeholder="Password" required [(ngModel)]="model.password">
    <button [disabled]="!loginForm.valid" class="btn btn-success my-2 my-sm-0" type="submit">Login</button>
  </form>
 </div>
</nav>
