<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="ml-3 mr-3 mt-2 ">

  <form [formGroup]="itemOrderAddForm" (ngSubmit)="createOrder()">
    <div class="form-group">
      <select  formControlName="supplierId" class="form-control" id="solverSelect"  placeholder="Firma"  name="solverSelect" 
      [ngClass]="{ 'is-invalid': itemOrderAddForm.get('supplierId').errors && itemOrderAddForm.get('supplierId').touched}">
      <option value="">Vyberte dodavatele</option>
      <option *ngFor="let company of companies" [value] = "company.id">
         {{company.companyName}} 
       </option>
      </select>
      <div class="invalid-feedback">Dodavatel je povinné pole</div>
    </div>

    <div class="form-group form-inline ml-1">
      <h4 class="mr-2">Datum objednávky : </h4>
      <input type="date" [ngClass]="{'is-invalid': itemOrderAddForm.get('dateOfOrder').errors && itemOrderAddForm.get('dateOfOrder').touched }" class="form-control is-invalid" 
      formControlName="dateOfOrder" />
    </div>
  </form>

  <!-- <table class="table table-hover" style="cursor: pointer"> -->
    <!-- <tr>
      <th style="width: 25%">Název</th>

      <th style="width: 25%">Product number</th>
      <th style="width: 25%">Serial number</th>
      <th style="width: 10%">Objednané množství</th>
      <th style="width: 15%">Nákupní cena</th>
      <th style="width: 30%">Klient</th>
      <th style="width: 30%">Příznak</th>
      <th style="width: 5%"></th>



    </tr> -->
    <div class="form-group form-inline ml-1">
      <div style="width: 90%;">
        <div  class="form-inline">
          <strong style="width: 20%;">Název</strong>
          <strong style="width: 26%;">Product number</strong>
          <strong style="width: 26%;">Serial number</strong>
          <strong style="width: 10%;">Obj. mn</strong>
          <strong style="width: 18%;">Nákupní cena</strong>
        </div>
        <div  class="form-inline">
          <strong style="width: 30%;">Klient</strong>
          <strong style="width: 30%;">Příznak</strong>
          <strong style="width: 30%;">Sazba DPH</strong>
           
        </div>
      </div>
      </div>

    <div *ngFor="let invItem of invItems" >
      <div class="form-group form-inline ml-1">
      <div style="width: 90%;">
        <div  class="form-inline">
          <input (input)="checkInvItems()" [ngClass]="{'border-danger' : invItem.name === undefined || invItem.name === ''}"  style="width: 20%;" class="form-control" placeholder="Název"  [(ngModel)]="invItem.name" name="name"/>
          <div class="invalid-feedback" *ngIf="">Název je povinné pole</div>

          <input style="width: 26%;" type="text"  class="form-control" placeholder="Product No."  [(ngModel)]="invItem.productNumber" />
          <input style="width: 26%;" type="text"  class="form-control" placeholder="Serial No."  [(ngModel)]="invItem.serialNumber" />
          <input (input)="checkInvItems()" [ngClass]="{'border-danger' : invItem.orderedAmount === 0 || invItem.orderedAmount === null}" style="width: 10%;" type="number" min="0" class="form-control" placeholder="obj. množství"  [(ngModel)]="invItem.orderedAmount" />
          <input (input)="checkInvItems()" [ngClass]="{'border-danger' : invItem.buyingPrice === undefined || invItem.buyingPrice === null}" style="width: 18%;" type="number" min="0" class="form-control" placeholder="Nákupní cena"  [(ngModel)]="invItem.buyingPrice" />

          
          
          <!-- 
            <button type="button" class="btn btn-primary w-100" (click)="removeInvoiceItem(invItem.invoiceId)"><strong>X</strong></button>
           -->
          

        </div>
        <div  class="form-inline">
          
            <div style="width: 30%;" class="form-group">
              <select style="width: 100%;" class="form-control" [(ngModel)]="invItem.clientCompanyId"  >
              <option *ngFor="let company of companiesClient" [value] = "company.id">
                {{company.companyName}} 
              </option>
              </select>
              <div class="invalid-feedback">Klient je povinné pole</div>
            </div>
            <div style="width: 30%;"  class="form-group">
              <select style="width: 100%;" class="form-control" (input)="checkInvItems()" [ngClass]="{'border-danger' : invItem.flag === undefined || invItem.flag === ''}" [(ngModel)]="invItem.flag" >
                <option value="">Vyberte příznak</option>
                <option value="r">Režie</option>
                <option value="zb">Zboží</option>
                <option value="sl">Služba</option>
                <option value="slr">Služba režie</option>
            
              </select>
              <div class="invalid-feedback">Klient je povinné pole</div>
            </div>
          <input (input)="checkInvItems()" [ngClass]="{'border-danger' : invItem.dphBuying === undefined || invItem.dphBuying === null}" style="width: 18%;" type="number" min="0" class="form-control" placeholder="DPH sazba"  [(ngModel)]="invItem.dphBuying" />

          
        </div>
      </div>
      <a></a>
      <button type="button" class="btn btn-primary " style="width: 10%; height: 55pt;" (click)="removeInvoiceItem(invItem.invoiceId)"><strong>X</strong></button>
      </div>
  </div>

    



  <!-- </table> -->
  <button type="button" class="btn btn-primary w-100" (click)="addEmptyInvoiceItem()">Přidat položku</button>

 
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button [disabled]="!(itemOrderAddForm.valid && invItems != undefined && invItems.length > 0 && itemsValid)" type="button" class="btn btn-success" form="itemOrderAddForm" (click)="createOrder()">Uložit</button>
</div>