<div class="m-5">
  <h2>Přijaté objednávky</h2>
  <form *appHasRole="['CompanyFilter']" class="form-inline mt-4" #form ="ngForm" (ngSubmit)="loadOrders()" novalidate>
    <div class="form-group px-2">
      <div class="form-group px-2">
        <select class="form-control ml-1" style="width: 200px" id="companyId" [(ngModel)]="filterParams.companyId" name="companyId">
          <option value="0">Vyberte klienta</option>
          <option *ngFor="let company of companies" [value] = "company.id">
            {{company.companyName}}
          </option>
        </select>
      </div>
    </div>
   
    <div class="form-group ">
      <label for="dateFrom">Datum od: </label>
      <input *ngIf="filterParams.dateFrom !== '1990-01-01'" class="form-control ml-1" type="date" [(ngModel)]="filterParams.dateFrom" name="dateFrom">
      <input *ngIf="filterParams.dateFrom === '1990-01-01'" class="form-control ml-1" type="date" value="">
    </div>
    <div class="form-group ">
      <label for="dateTo">Datum do: </label>
      <input *ngIf="filterParams.dateTo !== '1990-01-01'" class="form-control ml-1" type="date" [(ngModel)]="filterParams.dateTo" name="dateTo">
      <input *ngIf="filterParams.dateTo === '1990-01-01'" class="form-control ml-1" type="date" value="">
    </div>

    <div class="form-group ">
      <input  class="form-control ml-1" [(ngModel)]="filterParams.docId" name="docId" placeholder="Id dokladu">
    </div>

    <button type="submit" class="btn btn-primary" style="margin-left:10px"(click)="loadOrders()" >Filtrovat</button>
    <button type="button" class="btn btn-info" style="margin-left:10px" (click)="resetFilters()">Reset Filtrů</button>
  </form>
  <br> 
<div  *ngIf="(itemOrders === undefined || itemOrders.length === 0)" style="text-align: center">
  <h1>Zatím nejsou vytvořené žádné objednávky</h1>
<button class="btn btn-primary m-3" (click)="createModal()" >Vytvořit novou objednávku</button>
</div>
<div *ngIf="itemOrders !== undefined && itemOrders.length > 0" >

 
  <div *ngIf="itemOrders !== undefined" class="m-5">
  <div class="row" *ngIf="itemOrders !== undefined && itemOrders.length > 0">
    <table class="table table-hover" style="cursor: default">
      <tr>
        <th>Číslo objednávky</th>
        <th>Objednavatel</th>
        <th>Datum objednávky</th>
        <!-- <th>Částka objednávky bez dph</th> -->
        <th style="width: 5%"></th>
      </tr>
      <ng-container *ngFor="let itemOrder of itemOrders">
      <tr  (click)="getDetailModal(itemOrder,'new issue')">
        <td>{{itemOrder.id}}</td>
        <td > <i *ngIf="!itemOrder.amountError === true" class="fa fa-circle pr-1" style="color:green"></i> <i *ngIf="itemOrder.amountError === true" class="fa fa-circle pr-1" style="color:red"></i>{{itemOrder.customer.companyName}}</td>
        <td >{{itemOrder.dateOfOrder | date:"dd.MM.yyyy"}}</td>
 
        <td>
          <div class="form-inline">
            <div>
                <button *appHasRole="['InvoiceEdit']" class="btn btn-danger" (click)="$event.stopPropagation()" (click)="deleteInvoice(itemOrder)" ><i class="fa fa-trash"></i></button>
            </div>
          </div>
        </td>
        
        
      </tr>
    
    </ng-container>
    </table>

  </div>

</div>

<div  class="d-flex justify-content-center">
    <pagination [maxSize]="3"
                [boundaryLinks]="true" 
                [totalItems]="pagination.totalItems"
                [itemsPerPage]="pagination.itemsPerPage"
                [(ngModel)]="pagination.currentPage"
                (pageChanged)="pageChanged($event)"
              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
  </pagination>
  </div>
</div>
</div>
