import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Group } from '../_models/group';
import { PaginatedResult } from '../_models/pagination';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}


  // addGroup(group: Group)
  // {
  //   return this.http.post(this.baseUrl + 'Group/', group );
  // }

  deleteGroup(groupId: number)
  {
    return this.http.delete(this.baseUrl + 'Group/' + groupId );
  }

  

  getGroups( page?, itemsPerPage?, userParams?)
  {
    const paginatedResult: PaginatedResult<Group[]> = new PaginatedResult<Group[]>();
    let params = new HttpParams();

    if (page != null && itemsPerPage != null)
    {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
    return this.http.get<Group[]>(this.baseUrl + 'Group', {observe: 'response', params} )
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') !== null)
        {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })
    );
  }

  getRoles(): Observable<string[]>
  {
    return this.http.get<string[]>(this.baseUrl + 'Group/GetRoles');
  }

  addGroup(groupName: string, roles:[])
  {
    return this.http.post(this.baseUrl + 'Group/addGroup/' + groupName, roles);
  }

  editGroup(groupName: string, roles:[], groupId: number)
  {
    return this.http.post(this.baseUrl + 'Group/editGroup/' + groupName+"/id/"+groupId, roles);
  }

  getRolesForGroup(groupName:string): Observable<string[]>
  {
    return this.http.get<string[]>(this.baseUrl + 'Group/GetRolesForGroup/' + groupName);
  }
  // updateGroup(id: number, group: Group)
  // {
  //   return this.http.put(this.baseUrl + 'Group/' + id, group);
  // }

}
