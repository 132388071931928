<div class="modal-header ">
  <h4 class="modal-title pull-left">Registrace</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form #rolesForm="ngForm" id="rolesForm">



    <form [formGroup]="registerForm" >
      <div class="form-group">
        <input
          type="text"
          [ngClass]="{
            'is-invalid':
              registerForm.get('email').errors &&
              registerForm.get('email').touched
          }"
          class="form-control is-invalid"
          formControlName="email"
          placeholder="Email"
        />
        <div class="invalid-feedback">Email je povinné pole</div>
      </div>

      <div class="form-group">
        <input
          [ngClass]="{
            'is-invalid':
              registerForm.get('firstName').errors &&
              registerForm.get('firstName').touched
          }"
          class="form-control"
          placeholder="Jméno"
          formControlName="firstName"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            registerForm.get('firstName').touched &&
            registerForm.get('firstName').hasError('required')
          "
        >
          Jméno je povinné pole
        </div>
      </div>
      <div class="form-group">
        <input
          [ngClass]="{
            'is-invalid':
              registerForm.get('lastName').errors &&
              registerForm.get('lastName').touched
          }"
          class="form-control"
          placeholder="Příjmení"
          formControlName="lastName"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            registerForm.get('lastName').touched &&
            registerForm.get('lastName').hasError('required')
          "
        >
          Příjmení je povinné pole
        </div>
      </div>

      <div class="form-group">
        <input
          [ngClass]="{
            'is-invalid':
              registerForm.get('phoneNumber').errors &&
              registerForm.get('phoneNumber').touched
          }"
          class="form-control"
          placeholder="Telefoní číslo"
          formControlName="phoneNumber"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            registerForm.get('phoneNumber').touched &&
            registerForm.get('phoneNumber').hasError('required')
          "
        >
          Telefoní číslo je povinné pole
        </div>
      </div>

      <!-- <div class="form-group">
        <input [ngClass]="{
            'is-invalid':
              registerForm.get('workPost').errors &&
              registerForm.get('workPost').touched
          }"
          class="form-control"
          placeholder="Pracovní pozice"
          formControlName="workPost"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            registerForm.get('workPost').touched &&
            registerForm.get('workPost').hasError('required')
          "
        >
          Pracovní pozice je povinné pole
        </div>
      </div> -->
      <div class="form-group">
        <select formControlName="workPost" class="form-control" id="companySelect"  placeholder="Firma"  name="companySelect" 
        [ngClass]="{ 'is-invalid': registerForm.get('workPost').errors && registerForm.get('workPost').touched}">
          <option value="">Vyberte pracovní pozici</option>
          <option *ngFor="let wp of workPosts" [value] = "wp.name">
            {{wp.name}}
          </option>
        </select>
      <div class="invalid-feedback" *ngIf="registerForm.get('workPost').touched && registerForm.get('workPost').hasError('required')">Pracovní pozice je povinné pole</div>
    </div>

      <div class="form-group">
          <select formControlName="companyId" class="form-control" id="companySelect"  placeholder="Firma"  name="companySelect" 
          [ngClass]="{ 'is-invalid': registerForm.get('companyId').errors && registerForm.get('companyId').touched}">
            <option value="">Vyberte firmu</option>
            <option *ngFor="let companyItem of companies" [value] = "companyItem.id">
              {{companyItem.companyName}}
            </option>
          </select>
        <!-- <input [ngClass]="{ 'is-invalid': registerForm.get('companyId').errors && registerForm.get('companyId').touched}"class="form-control" placeholder="Firma" formControlName="companyId"/> -->
        <div class="invalid-feedback" *ngIf="registerForm.get('companyId').touched && registerForm.get('companyId').hasError('required')">Firma je povinné pole</div>
      </div>

      <div  class="form-group form-inline ml-1">
        <h4 class="mr-2">Zasílat faktury : </h4>
        <input type="checkbox" [ngClass]="{'is-invalid': registerForm.get('sendInvoices').errors && registerForm.get('sendInvoices').touched }" class="form-control is-invalid" 
        formControlName="sendInvoices" />
      </div>

      <div class="form-group">
        <input
          type="password"
          [ngClass]="{
            'is-invalid':
              registerForm.get('password').errors &&
              registerForm.get('password').touched
          }"
          class="form-control"
          formControlName="password"
          placeholder="Heslo"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            registerForm.get('password').hasError('required') &&
            registerForm.get('password').touched
          "
        >
          Heslo je povinné pole
        </div>
        <div
          class="invalid-feedback"
          *ngIf="
            registerForm.get('password').hasError('minlength') &&
            registerForm.get('password').touched
          "
        >
          Heslo musí mít nejméně 4 znaky
        </div>
        <div
          class="invalid-feedback"
          *ngIf="
            registerForm.get('password').hasError('maxlength') &&
            registerForm.get('password').touched
          "
        >
          Heslo nesmí přesáhnout délku 50ti znaků
        </div>
        <div
        class="invalid-feedback"
        *ngIf="
          registerForm.get('password').hasError('pattern') &&
          registerForm.get('password').touched
        "
      >
        Heslo musí obsahovat alespoň jedno číslo, jedno malé a velké písmeno
      </div>
      </div>

      <div class="form-group">
        <input
          type="password"
          [ngClass]="{
            'is-invalid':
              (registerForm.get('confirmPassword').errors &&
                registerForm.get('confirmPassword').touched) ||
              (registerForm.get('confirmPassword').touched &&
                registerForm.hasError('mismatch'))
          }"
          class="form-control"
          formControlName="confirmPassword"
          placeholder="Potvrzení hesla"
        />
        <div
          class="invalid-feedback"
          *ngIf="
            registerForm.get('confirmPassword').hasError('required') &&
            registerForm.get('confirmPassword').touched
          "
        >
          Heslo je povinné pole
        </div>
        <div
          class="invalid-feedback"
          *ngIf="
            registerForm.hasError('mismatch') &&
            registerForm.get('confirmPassword').touched
          "
        >
          Hesla se neschodují
        </div>
      </div>

      <div class="form-group">
        <textarea type="text" [ngClass]="{'is-invalid': registerForm.get('note').errors && registerForm.get('note').touched }" 
        class="form-control is-invalid"
        style="min-height: 200px;" 
        formControlName="note" placeholder="Poznámka" ></textarea>
      </div>

    </form>

    
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button type="button" class="btn btn-success" form="rolesForm" (click)="Register()">Uložit</button>
</div>