<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="ml-3 mr-3 mt-2 ">

  <form [formGroup]="itemEditForm" (ngSubmit)="editItem()">
    <div class="form-group">
      <select (change)="loadItems()" formControlName="clientCompanyId" class="form-control" id="solverSelect"  placeholder="Firma"  name="solverSelect" 
      [ngClass]="{ 'is-invalid': itemEditForm.get('clientCompanyId').errors && itemEditForm.get('clientCompanyId').touched}">
      <option value="">Vyberte firmu</option>
      <option *ngFor="let company of companies" [value] = "company.id">
         {{company.companyName}} 
       </option>
      </select>
      <div class="invalid-feedback">Firma je povinné pole</div>
    </div>

    <div class="form-group">
      <input  type="text" [ngClass]="{'is-invalid': itemEditForm.get('name').errors && itemEditForm.get('name').touched }" class="form-control is-invalid" 
      formControlName="name" placeholder="Název" />
      <div class="invalid-feedback">Název je povinné pole</div>
    </div>

    <div *ngIf="invItemsToEdit != null && invItemsToEdit.length >0" class="form-group form-inline w-100">
      <h4 class="mr-2">Množství : </h4>
      <input  type="number" [ngClass]="{'is-invalid': itemEditForm.get('amount').errors && itemEditForm.get('amount').touched }" class="form-control is-invalid" style="width: auto;" 
      formControlName="amount" placeholder="Počet kusů" />
      <div class="invalid-feedback">Počet kusů  je povinné pole</div>
    </div>

    <div class="form-group form-inline w-100">
      <h4 class="mr-2 ">Prodejní cena : </h4>
      <input  type="number" [ngClass]="{'is-invalid': itemEditForm.get('sellingPrice').errors && itemEditForm.get('sellingPrice').touched }" class="form-control is-invalid" 
      formControlName="sellingPrice" placeholder="Prodejní cena" />
      <div class="invalid-feedback">Prodejní cena  je povinné pole</div>
    </div>

    <div class="form-group form-inline w-100">
      <h4 class="mr-2 ">Sériové číslo : </h4>
      <input [ngClass]="{'is-invalid': itemEditForm.get('serialNumber').errors && itemEditForm.get('serialNumber').touched }" class="form-control is-invalid" 
      formControlName="serialNumber" placeholder="Sériové číslo" />
      <div class="invalid-feedback">Sériové číslo je povinné pole</div>
    </div>

    <div class="form-group form-inline w-100">
      <h4 class="mr-2 ">Číslo produktu : </h4>
      <input   [ngClass]="{'is-invalid': itemEditForm.get('productNumber').errors && itemEditForm.get('productNumber').touched }" class="form-control is-invalid" 
      formControlName="productNumber" placeholder="Číslo produktu" />
      <div class="invalid-feedback">Číslo produktu je povinné pole</div>
    </div>

    <div class="form-group form-inline w-100">
      <h4 class="mr-2 ">Sazba DPH nákupu : </h4>
      <input   [ngClass]="{'is-invalid': itemEditForm.get('dphBuying').errors && itemEditForm.get('dphBuying').touched }" class="form-control is-invalid" 
      formControlName="dphBuying" placeholder="Sazba DPH nákupu" />
      <div class="invalid-feedback">Sazba DPH nákupu je povinné pole</div>
    </div>

    <div class="form-group form-inline w-100">
      <h4 class="mr-2 ">Sazba DPH prodeje : </h4>
      <input   [ngClass]="{'is-invalid': itemEditForm.get('dphSelling').errors && itemEditForm.get('dphSelling').touched }" class="form-control is-invalid" 
      formControlName="dphSelling" placeholder="Sazba DPH prodeje" />
      <div class="invalid-feedback">Sazba DPH prodeje je povinné pole</div>
    </div>

    <div class="form-group form-inline w-100">
      <h4 class="mr-2 ">Záruka do : </h4>
      <!-- <input type="date"  [ngClass]="{'is-invalid': itemEditForm.get('warrantyTo').errors && itemEditForm.get('warrantyTo').touched }" class="form-control is-invalid" 
      formControlName="dphSelling" placeholder="Sazba DPH prodeje" /> -->

      <input  type="date" [ngClass]="{'is-invalid': itemEditForm.get('warrantyTo').errors && itemEditForm.get('warrantyTo').touched }" class="form-control is-invalid" 
      formControlName="warrantyTo"/>
     
      <div class="invalid-feedback">Sazba DPH prodeje je povinné pole</div>
    </div>

    


    <!-- warrantyTo:[this.invItem.warrantyTo], -->

    <div class="form-group form-inline ml-1">
      <h4 class="mr-2">Reklamace : </h4>
      <input type="checkbox" [ngClass]="{'is-invalid': itemEditForm.get('isOnComplaint').errors && itemEditForm.get('isOnComplaint').touched }" class="form-control is-invalid" 
      formControlName="isOnComplaint" />
    </div>

    <div class="form-group form-inline ml-1">
      <h4 class="mr-2">Doručeno klientovi : </h4>
      <input type="checkbox" [ngClass]="{'is-invalid': itemEditForm.get('deliveredToClient').errors && itemEditForm.get('deliveredToClient').touched }" class="form-control is-invalid" 
      formControlName="deliveredToClient" />
    </div>

    <div class="form-group form-inline ml-1">
      <h4 class="mr-2">Na skladě : </h4>
      <input type="checkbox" [ngClass]="{'is-invalid': itemEditForm.get('isInWarehouse').errors && itemEditForm.get('isInWarehouse').touched }" class="form-control is-invalid" 
      formControlName="isInWarehouse" />
    </div>

  </form>
  <div *ngIf="invItemsToEdit != null && invItemsToEdit !=undefined&& invItemsToEdit.length > 0 && invItems != null && invItems.length>0 && invItems !=undefined">

  <table  class="table table-hover" style="cursor: pointer">
    <tr>
      <th style="width: 90%">Položky</th>
      <th style="width: 10%"></th>
    </tr>
    <tr *ngFor="let invItem of invItemsToEdit" >
        <td style="padding-left: 0; padding-right: 0;">
        <div class="form-group">
          <select (change)="filterItems()" class="form-control" [(ngModel)]="invItem.id" >
          <option value="0">Vyberte položku</option>
          <option [disabled] ="invItemToAdd.amount === 0" *ngFor="let invItemToAdd of invItems"  [value] = "invItemToAdd.id">
            <a>{{invItemToAdd.name}} ({{invItemToAdd.amount}}) ks</a>
          </option>
          </select>
          <div class="invalid-feedback">Klient je povinné pole</div>
        </div>
      </td>
      <td style="padding-left: 0; padding-right: 0;">
        <button type="button" class="btn btn-primary w-100" (click)="removeInvoiceItem(invItem.id)"><strong>X</strong></button>
      </td>
      

    </tr>
  </table>
  <button type="button" class="btn btn-primary w-100" (click)="addEmptyInvoiceItem()">Přidat položku</button>
</div>

  <hr/>
 
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button [disabled]="!(itemEditForm.valid)" type="button" class="btn btn-success" form="itemEditForm" (click)="editItem()">Uložit</button>
</div>