<div class="m-5">
  <h2>Licence</h2>

  <form *appHasRole="['CompanyFilter']" class="form-inline" #form ="ngForm" (ngSubmit)="loadLicences()" novalidate>
    <input class="form-control" type="text" [(ngModel)]="filterParams.licenceCode" name="licenceCode" placeholder="Kód" (ngModelChange)="loadLicences()">
    <!-- <input class=" form-control" type="text" [(ngModel)]="companyFilter" placeholder="Filtr dle názvu" (ngModelChange)="loadCompanies()"> -->
    <div class="form-group px-2">
      <label for="company">Dodavatel: </label>
      <select class="form-control ml-1" style="width: 130px" id="suplierId" [(ngModel)]="filterParams.suplierId" name="suplierId">
        <option *ngFor="let company of companies" [value] = "company.id">
          {{company.companyName}}
        </option>
      </select>
    </div>
    <button type="submit" class="btn btn-primary" style="margin-left:10px">Filtrovat</button>
    <button type="button" class="btn btn-info" style="margin-left:10px" (click)="resetFilters()" >Reset Filtrů</button>
  </form>
  <br>
  <div *ngIf="licences !==undefined" class="m-5">
    <div *ngIf="licences.length === 0" style="text-align: center">
      <h1>Zatím nejsou přidané žádné licence</h1>
    <button class="btn btn-primary m-3" (click)="createModal()" >Vytvořit novou licenci</button>
    </div>

  <div class="row" *ngIf="licences.length > 0">
    <table class="table table-hover" style="cursor: pointer">
      <button *appHasRole="['CategoryEdit']" class="btn btn-primary" (click)="createModal()" >Přidat</button>
      <tr>
        <th style="width: 20%">Kód</th>
        <th style="width: 20%">Název</th>
        <th style="width: 20%">Dodavatel</th>
        <th style="width: 20%">Cena</th>
        <!-- <th style="width: 20%">Frekvence ve dnech</th> -->
        <th style="width: 20%"></th>
      </tr>
      <tr *ngFor="let licence of licences" >
        <td>{{licence.licenceCode}}</td>
        <td>{{licence.name}}</td>
        <td>{{licence.supplierCompany.companyName}}</td>
        <td>{{licence.licencePrice}}</td>
        <td>
          <div class="form-inline" >
            <!-- *ngIf="compareIds( licence.companyId,filterParams.companyId)" -->
            <div>
              <button class="btn btn-primary" (click)="$event.stopPropagation()" (click)="getEditModal(licence)" ><i class="fa fa-edit ml-1"></i></button>
              <button class="btn btn-danger" (click)="$event.stopPropagation()" (click)="deleteLicence(licence)" ><i class="fa fa-trash"></i></button>
            </div>
          </div>
        </td>
      </tr>
    </table>

  </div>

</div>

<div class="d-flex justify-content-center">
    <pagination [maxSize]="3"
                [boundaryLinks]="true"
                [totalItems]="pagination.totalItems"
                [itemsPerPage]="pagination.itemsPerPage"
                [(ngModel)]="pagination.currentPage"
                (pageChanged)="pageChanged($event)"
              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
  </pagination>
  </div>
