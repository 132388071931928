<div class="m-5">
  <h2>Vydané faktury</h2>
  
  <form *appHasRole="['CompanyFilter']" class="form-inline mt-3" #form ="ngForm" (ngSubmit)="loadInvoices()" novalidate>
    <div class="form-group px-2">
      <!-- <label for="company">Faktury pro firmu: </label> -->
      <select class="form-control ml-1" style="width: 150px" id="companyId" [(ngModel)]="filterParams.companyId" name="companyId">
        <option value="0">Vyberte firmu</option>
        <option *ngFor="let company of companies" [value] = "company.id">
          {{company.companyName}}
        </option>
      </select>
    </div>
    <button type="submit" class="btn btn-primary" style="margin-left:10px">Filtrovat</button>
    <button type="button" class="btn btn-info" style="margin-left:10px" (click)="resetFilters()" >Reset Filtrů</button>
  </form>

<div class="m-5" *ngIf="invoices === undefined || invoices.length === 0" style="text-align: center">
  <h1>Zatím nejsou přidané žádné faktury</h1>
<button class="btn btn-primary m-3" (click)="createModal()" >Vytvořit novou fakturu</button>
</div>
<div *ngIf="invoices !== undefined && invoices.length > 0" class="container mt-3">
 
 
  <div *ngIf="invoices !== undefined" class="m-5">
  <div class="row" *ngIf="invoices !== undefined && invoices.length > 0">
    <table class="table table-hover" style="cursor: pointer">
      <button *appHasRole="['InvoiceEdit']" class="btn btn-primary" (click)="createModal()" >Přidat</button>
      <tr>
        <th style="width: 20%">Faktura pro</th>
        <th style="width: 15%">Číslo faktury</th>
        <th style="width: 10%">Částka</th>
        <th style="width: 10%">Částka (s DPH)</th>
        <th style="width: 5%">Zaplaceno</th>
        <th style="width: 40%"></th>
      </tr>
      <tr *ngFor="let invoice of invoices" >
        <td (click)="getDetailModal(invoice)">{{invoice.companyName}}</td>
        <td (click)="getDetailModal(invoice)">{{invoice.invoiceNumber}}</td>
   
        <td (click)="getDetailModal(invoice)">{{this.roundTo(invoice.price)}}</td>
        <td (click)="getDetailModal(invoice)">{{this.roundTo(invoice.price*1.21)}}</td>
        <td (click)="getDetailModal(invoice)">
           <i *ngIf="invoice.paid" class="fa fa-check-circle-o" style="color:green"></i>
           <i *ngIf="!invoice.paid" class="fa fa-times-circle-o" style="color:red"></i>
          </td>
        <td>
          <div class="form-inline">
            <div>
              <button class="btn btn-primary" (click)="$event.stopPropagation()" (click)="getWpsModal(invoice)" >Výkazy</button>
              <button class="btn btn-success" (click)="$event.stopPropagation()" (click)="sendInvoiceLink(invoice)" >Odeslat</button>
              <button *appHasRole="['InvoiceEdit']" class="btn btn-primary" (click)="$event.stopPropagation()" (click)="getEditModal(invoice)" ><i class="fa fa-edit ml-1"></i></button>
              <button *appHasRole="['InvoiceEdit']" class="btn btn-danger" (click)="$event.stopPropagation()" (click)="deleteInvoice(invoice.id)" ><i class="fa fa-trash"></i></button>
            </div>
          </div>
        </td>
      </tr>
    </table>

  </div>

</div>

<div  class="d-flex justify-content-center">
    <pagination [maxSize]="3"
                [boundaryLinks]="true" 
                [totalItems]="pagination.totalItems"
                [itemsPerPage]="pagination.itemsPerPage"
                [(ngModel)]="pagination.currentPage"
                (pageChanged)="pageChanged($event)"
              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
  </pagination>
  </div>
</div>
</div>
