import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PaginatedResult } from '../_models/pagination';
import { map } from 'rxjs/operators';
import { BasePriceListItem } from '../_models/basePriceListItem';

@Injectable({
  providedIn: 'root'
})
export class BasePriceListService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}


  addBasePriceListItem(basePriceListItem: BasePriceListItem)
  {
    return this.http.post<number>(this.baseUrl + 'BasePriceList/', basePriceListItem );
  }

  deleteBasePriceListItem(basePriceListItemId: number)
  {
    return this.http.delete(this.baseUrl + 'BasePriceList/' + basePriceListItemId );
  }

  getBasePriceListItems(page: number, itemsPerPage: number, userParams: any)
  {
    const paginatedResult: PaginatedResult<BasePriceListItem[]> = new PaginatedResult<BasePriceListItem[]>();
    let params = new HttpParams();

    if (page != null && itemsPerPage != null)
    {
      params = params.append('pageNumber', page.toString());
      params = params.append('pageSize', itemsPerPage.toString());
    }

    if (userParams != null) {
      if (userParams.companyId) {
        params = params.append('companyId', userParams.companyId);
      }
      if (userParams.name) {
        params = params.append('name', userParams.name);
      }
    }

    return this.http.get<BasePriceListItem[]>(this.baseUrl + 'BasePriceList', {observe: 'response', params} )
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') !== null)
        {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })
    );
  }

  getBasePriceListItemsForOrderList(userParams?)
  {
    let params = new HttpParams();
    if ( userParams != null)
    {
      params = params.append('Month', userParams.month);
      params = params.append('CompanyId', userParams.companyId);
      params = params.append('FilterInvoiced', userParams.filterInvoiced);
      params = params.append('DeductHours', userParams.deductHours);
      params = params.append('GetHours', userParams.getHours);

    }
    return this.http.get<BasePriceListItem[]>(this.baseUrl + 'BasePriceList/GetBasePriceListForOrderList', {observe: 'response', params});
  }

  getBasePriceListItemsByInvoiceId(invoiceId)
  {
    return this.http.get<BasePriceListItem[]>(this.baseUrl + 'BasePriceList/GetBasePriceListByInvoiceId/' + invoiceId);
  }

  updateBasePriceListItem(id: number, basePriceListItem: BasePriceListItem)
  {
    return this.http.put<number>(this.baseUrl + 'BasePriceList/' + id, basePriceListItem);
  }


  importDefaultPriceList(companyId: number)
  {
    return this.http.post(this.baseUrl + 'BasePriceList/ImportDefaultBasePriceList/' + companyId,{});
  }
}
