// idea from https://github.com/a8m/angular-filter

import {NgModule, Pipe, PipeTransform} from '@angular/core';
import {isString, isUndefined} from '../utils/utils';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml',
  pure: false
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer) {
  }

  transform(input: string): any {
    if (!isString(input) || isUndefined(input)) {
      return input;
    }

    return this.domSanitizer.bypassSecurityTrustHtml(input);
  }
}

@Pipe({
  name: 'safeHtmlPure',
  pure: true
})
export class SafeHtmlPurePipe implements PipeTransform {

  constructor(private domSanitizer: DomSanitizer) {
  }

  transform(input: string): any {
    if (!isString(input) || isUndefined(input)) {
      return input;
    }

    return this.domSanitizer.bypassSecurityTrustHtml(input);
  }
}

@NgModule({
  declarations: [SafeHtmlPipe, SafeHtmlPurePipe],
  exports: [SafeHtmlPipe, SafeHtmlPurePipe],
})
export class SafeHtmlPipeModule {
}
