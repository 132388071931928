<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <form [formGroup]="reminderAddForm" (ngSubmit)="createReminder()">
 
    <div class="form-group">
      <select formControlName="companyid" class="form-control" id="solverSelect"  placeholder="Firma"  name="solverSelect" 
      [ngClass]="{ 'is-invalid': reminderAddForm.get('companyid').errors && reminderAddForm.get('companyid').touched}">
      <option value="">Vyberte firmu</option>
      <option *ngFor="let company of companies" [value] = "company.id">
         {{company.companyName}} 
       </option>
      </select>
      <div class="invalid-feedback">Firma je povinné pole</div>
    </div>
    
    <div class="form-group">
      <textarea  [ngClass]="{'is-invalid': reminderAddForm.get('text').errors && reminderAddForm.get('text').touched }" class="form-control is-invalid" 
      formControlName="text" placeholder="Text přpomínky" ></textarea>
      <div class="invalid-feedback">Text připomínky je povinné pole</div>
    </div>
 
    
   


  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button [disabled]="!reminderAddForm.valid" type="button" class="btn btn-success" form="reminderAddForm" (click)="createReminder()">Uložit</button>
</div>