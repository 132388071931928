<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <form [formGroup]="priorityAddForm" (ngSubmit)="createPriority()">
    <div class="form-group">
      <input  type="text" [ngClass]="{'is-invalid': priorityAddForm.get('name').errors && priorityAddForm.get('name').touched }" class="form-control is-invalid" 
      formControlName="name" placeholder="Název" />
      <div class="invalid-feedback">Název je povinné pole</div>
    </div>
    <div class="form-group">
      <input type="number" [ngClass]="{'is-invalid': priorityAddForm.get('deadline').errors && priorityAddForm.get('deadline').touched }" class="form-control is-invalid" 
      formControlName="deadline" placeholder="Počet dnů deadline" />
      <div class="invalid-feedback">Počet dnů deadline je povinné pole</div>
    </div>
    <div class="form-group">
      <select formControlName="companyid" class="form-control" id="solverSelect"  placeholder="Firma"  name="solverSelect" 
      [ngClass]="{ 'is-invalid': priorityAddForm.get('companyid').errors && priorityAddForm.get('companyid').touched}">
      <option value="">Vyberte firmu</option>
      <option *ngFor="let company of companies" [value] = "company.id">
         {{company.companyName}} 
       </option>
      </select>
      <div class="invalid-feedback">Firma je povinné pole</div>
    </div>
    <div *appHasRole="['SetSLA']" class="form-group form-inline ml-1">
      <h4 class="mr-2">SLA : </h4>
      <input type="checkbox" [ngClass]="{'is-invalid': priorityAddForm.get('isSLA').errors && priorityAddForm.get('isSLA').touched }" class="form-control is-invalid" 
      formControlName="isSLA" placeholder="SLA" />
      <div class="invalid-feedback">SLA</div>
    </div>
   


  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button [disabled]="!priorityAddForm.valid" type="button" class="btn btn-success" form="priorityAddForm" (click)="createPriority()">Uložit</button>
</div>