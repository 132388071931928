<div class="modal-header dontPrint">
  <h4>{{header}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="ml-3 mr-3 mt-2 printView " id="toPDF">

  <ng-container *ngIf="itemOrder != null">
  <div *ngIf="this.itemOrder.supplier !==null"  class="" style="margin-left: auto; width: 30%;" >
    <h5>Dodavatel:</h5>
    <h5>{{itemOrder.supplier.companyName}}</h5>
    <h5>{{this.itemOrder.supplier.adressUlice}} {{this.itemOrder.supplier.adressCisloPopisne}}/{{this.itemOrder.supplier.adressCisloOr}}</h5>
    <h5>{{this.itemOrder.supplier.adressPSC}} {{this.itemOrder.supplier.adressObec}} {{this.itemOrder.supplier.adressCastObce}}</h5>
    <h5>Česká republika </h5>
    <h5>IČO {{this.itemOrder.supplier.ico}} </h5>
    <h5>DIČ {{this.itemOrder.supplier.dico}} </h5>
  </div>

  <div *ngIf="this.itemOrder.supplier ===null" class="" style="margin-left: 2%; width: 30%;" >
    <h5>Odběratel:</h5>
    <h5>{{itemOrder.customer.companyName}}</h5>
    <h5>{{this.itemOrder.customer.adressUlice}} {{this.itemOrder.customer.adressCisloPopisne}}/{{this.itemOrder.customer.adressCisloOr}}</h5>
    <h5>{{this.itemOrder.customer.adressPSC}} {{this.itemOrder.customer.adressObec}} {{this.itemOrder.customer.adressCastObce}}</h5>
    <h5>Česká republika </h5>
    <h5>IČO {{this.itemOrder.customer.ico}} </h5>
    <h5>DIČ {{this.itemOrder.customer.dico}} </h5>
  </div>
  <br>

  <table class="table table-hover mb-0 " style="cursor: pointer;" >
    <tr>
      <th style="width: 20%;">Název</th>
      <th style="width: 10%;">prod. č.</th>
      <th style="width: 10%;">ser. č.</th>
      <th style="width: 20%;">Záruka do</th>
      <th *ngIf="this.viewName === 'new order' || this.viewName==='new issue'" style="width: 10%;">Obj. mn</th>
      <th *ngIf="this.viewName === 'receipt'" style="width: 10%;">Přij. mn</th>
      <th style="width: 10%;">dph</th>
      <th style="width: 10%;">Cena mj</th>
      <th style="width: 10%;">Cena </th>
      <th style="width: 10%;">Cena s dph</th>
    </tr>
    <ng-container *ngFor="let item of invItems let i=index">
      <tr >
        <td>{{item.name}} </td>
        <td>{{item.productNumber}}</td>
        <td>{{item.serialNumber}}</td>
        <td>{{item.warrantyTo | date:"dd.MM.yyyy"}}</td>
        <td  *ngIf="this.viewName === 'new order'" >{{item.orderedAmount}}</td>
        <td  *ngIf="this.viewName === 'receipt'" >{{item.amount}}</td>
        <td>21%</td>
        <td  *ngIf="this.viewName !== 'delivery list'">{{item.buyingPrice}}</td>
        <td  *ngIf="this.viewName === 'delivery list'">{{item.sellingPrice}}</td>
        <td *ngIf="this.viewName === 'new order'">{{this.roundTo(item.orderedAmount*item.buyingPrice)}}</td>
        <td *ngIf="this.viewName === 'new order'">
          {{this.roundTo((item.orderedAmount*item.buyingPrice)/100*21+(item.orderedAmount*item.buyingPrice))}}
        </td>
        <ng-container *ngIf="(this.viewName !=='delivery list' )">
          <td *ngIf="(this.viewName !== 'new order')">
            {{this.roundTo((item.amount*item.buyingPrice))}}
          </td>
          <td *ngIf="(this.viewName !== 'new order')">
            {{this.roundTo((item.amount*item.buyingPrice)/100*21+(item.amount*item.buyingPrice))}}
          </td>
        </ng-container>
        <td *ngIf="this.viewName === 'delivery list'">{{this.roundTo((item.amount*item.sellingPrice))}}</td>
        <td *ngIf="this.viewName === 'delivery list'  ">{{this.roundTo((item.amount*item.sellingPrice)/100*21+(item.amount*item.sellingPrice))}}</td>
      </tr>
    </ng-container>
    <ng-container *ngFor="let item of orderedItems let i=index">
      <tr >
        <td>{{item.name}}</td>
        <td></td>
        <td></td>
        <td></td>
        <td >{{item.amount}}</td>
        <td>21%</td>
        <td>{{item.price}}</td>
        <td >{{this.roundTo(item.amount*item.price)}}</td>
        <td >{{this.roundTo(((item.amount*item.price)*1.21))}}</td>
      </tr>
    </ng-container>
    <tr>
      <td colspan="9" style="padding: 1%;">
        <h6 class="ml-3">Cena celkem {{this.roundTo(totalPrice)}} </h6>
      </td>
    </tr>
    <tr>
      <td colspan="1" style="padding: 1%;">
        <h6 class="ml-3">V</h6>
      </td>
      <td colspan="4" style="padding: 1%;">
        <h6 class="">Dne</h6>
      </td>
      <td colspan="4" style="padding: 1%;">
        <h6 class="ml-3">Podpis </h6>
      </td>
    </tr>
  </table>
  </ng-container>
</div>

<div class="modal-footer dontPrint">
  <button type="button" class="btn btn-default" (click)="closeModal()">Zrušit</button>
  <button type="button" class="btn btn-primary" (click)="onBtnPrintClick()">Tisk</button>
</div>
