import { DatePipe } from '@angular/common';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { InvoiceCreateModalComponent } from '../invoice/invoicecreate-modal/invoicecreate-modal.component';
import { Company } from '../_models/company';
import { Order } from '../_models/order';
import { PaginatedResult, Pagination } from '../_models/pagination';
import { PriceListItem } from '../_models/priceListItem';
import { Ticket } from '../_models/ticket';
import { User } from '../_models/user';
import { AlertifyService } from '../_services/alertify.service';
import { AuthService } from '../_services/auth.service';
import { CategoryService } from '../_services/category.service';
import { CompanyDialService } from '../_services/companyDial.service';
import { InvoiceService } from '../_services/invoice.service';
import { OrderService } from '../_services/order.service';
import { PriceListService } from '../_services/pricelist.service';
import { PriorityService } from '../_services/priority.service';
import { TicketService } from '../_services/ticket.service';
import { UserService } from '../_services/user.service';
import { WorkreportService } from '../_services/workreport.service';

@Component({
  selector: 'app-priority',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  bsModalRef: BsModalRef;
  priceList: PriceListItem[];
  originalPriceList: PriceListItem[];
  companies: Company[];
  filterParams: any = {};
  inputChanged = false;
  priceForAll = 0;
  curretnUser: User;
  isTodaysMonth = true;
  priceListMonthHours: PriceListItem;
  monthInvoiced: boolean = true;

  constructor(
    private orderService: OrderService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private companyService: CompanyDialService,
    private priceListService: PriceListService,
    private datePipe: DatePipe,
    private ticketService: TicketService,
    private userService: UserService,
    private invoiceService: InvoiceService,
    private categoryService: CategoryService,
    private priorityService: PriorityService,
    private wpService:WorkreportService
  ) {}

  ngOnInit() {
    // this.route.data.subscribe((data: PriceListItem[]) => {
    //   this.priceList = data;
    // });
    this.filterParams.month = this.datePipe.transform(new Date(), 'yyyy-MM');
    this.companyService.getCompanies(1, 1,null,null,false,true).subscribe((data) => 
    {
      this.companies = data.result.filter(x=>x.id != 1);
      this.route.params.subscribe(params => {
        this.filterParams.companyId = params['filterParam'];
        this.filterParams.orderDateTime = '1990-01-01';
        // this.loadOrders();
        if ( this.filterParams.companyId === '0' || this.filterParams.companyId === '1')
        {
          this.filterParams.companyId =this.companies.filter(x => x.id!=1)[0].id;
        }
        if(this.authService.decodedToken.IsPrimary !== 'True')
        {
          this.filterParams.companyId = this.authService.decodedToken.CompanyId;
        }
        this.loadPriceList();
      
      this.userService.getUser(this.authService.decodedToken.nameid).subscribe(x=> { this.curretnUser = x})
      });

    });
  }

  // loadOrders() {
  //   this.orderService.getOrders( this.filterParams)
  //   .subscribe((res) =>
  //   {
  //     this.orders = res.body;
  //   }, error => {
  //     this.alertify.error(error);
  //   });
  //  }

   loadPriceList() {
     this.isTodaysMonth =  this.filterParams.month === this.datePipe.transform(new Date(), 'yyyy-MM');
     this.filterParams.filterInvoiced =false;
     this.filterParams.deductHours = false;
     this.filterParams.getHours =true;
    this.priceListService.getPriceListItemsForOrderList(this.filterParams)
    .subscribe((res) => {
      this.priceList = res.body;//.filter(x=>x.companyName !== 'Vykázané hodiny');
      this.originalPriceList = JSON.parse(JSON.stringify(this.priceList));
      this.monthInvoiced = this.priceList.filter(x=> x.order.invoiceId !== 0).length > 0;
      this.setFullPrice();
      this.setWPS();

      // this.priceListMonthHours = this.priceList.filter(x=>x.companyName === 'Vykázané hodiny')[0];
    }, error => {
      this.alertify.error(error);
    });

   
   }

  resetFilters()
  {
    // this.filterParams.companyId = this.compa this.companies.filter(x => x.id!=1)[0].id;
    this.filterParams.month = this.datePipe.transform(new Date(), 'yyyy-MM');
    this.loadPriceList();

  }
  filter()
  {
    this.loadPriceList();
  }

  inputChange()
  {
    this.inputChanged = true;
  }

  plusOne(priceListItem: PriceListItem)
  {
    priceListItem.order.amount++;
    this.inputChanged = true;
    this.setFullPrice();
  }

  minusOne(priceListItem: PriceListItem)
  {
    if(priceListItem.order.amount > 0)
    {
      priceListItem.order.amount--;
      this.inputChanged = true;
      this.setFullPrice();
    }
  }

  setWPS()
  {
    this.wpService.getWpsForOrder(this.filterParams.companyId,this.filterParams.month).subscribe(data=>
      {

        var hoursToInvoice = [];
        Object.keys(data).forEach( (key) => {
         hoursToInvoice.push( { name: key, value: data[key] });
        });
        this.priceList.filter(x=>x.name == "Vykázané hodiny ONS")[0].order.amount = hoursToInvoice.filter(x=>x.name == "ONS")[0].value;
        this.priceList.filter(x=>x.name == "Vykázané hodiny RAS")[0].order.amount = hoursToInvoice.filter(x=>x.name == "RAS")[0].value;
        this.priceList.filter(x=>x.name == "Cena za km")[0].order.amount = hoursToInvoice.filter(x=>x.name == "km")[0].value;
      });
  }

  setFullPrice()
  {
    this.priceForAll = 0;
      this.priceList.forEach(element => {
        this.priceForAll = this.priceForAll+element.order.amount * element.price
      });
  }

  Order()
  {
    this.priceList.forEach(priceItem => {
      //&& priceItem.order.orderDate != 
      if(priceItem.order.id != 0 && (this.datePipe.transform(priceItem.order.orderDate, 'yyyy-MM') === this.datePipe.transform(new Date(), 'yyyy-MM')))
      {
        const any = this.originalPriceList.filter(x=>x.order.amount === priceItem.order.amount && x.id === priceItem.id).length > 0;
        if(!any)
        {
          //edit
        priceItem.order.orderDate = new Date(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
        this.createNewTicket(priceItem,'Změna počtu kusů objednávky služby' + priceItem.name + ' od firmy ' 
        + priceItem.companyName + ' na množství ' + priceItem.order.amount + ' od uživatele ' 
        + this.curretnUser.firstName + ' ' + this.curretnUser.lastName, 'Úprava objednávky ').subscribe( (x: Ticket) => {
          priceItem.order.ticketid = x.id

          this.orderService.updateOrder(priceItem.order.id,priceItem.order).subscribe(data => {
          }, error => { 
            this.alertify.error('Objednávku se nepodařilo upravit');
          }, () => {
          });

        }, error => { this.alertify.error('Ticket se nepodařilo vytvořit')});
        }
      }
      else
      {
        if(priceItem.order.amount !== 0 && !priceItem.name.includes("Vykázané hodiny")  )
        {
          if(this.datePipe.transform(priceItem.order.orderDate, 'yyyy-MM') !== this.datePipe.transform(new Date(), 'yyyy-MM'))
          {
            priceItem.order.id = 0;
          }
          //create
          priceItem.order.companyId = priceItem.companyId;
          priceItem.order.orderDate = new Date(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
          priceItem.order.priceListItemId = priceItem.id;
          this.createNewTicket(priceItem,'Nová objednávka služby ' + priceItem.name + ' od firmy ' 
          + priceItem.companyName + ' množství ' + priceItem.order.amount + ' od uživatele ' 
          + this.curretnUser.firstName + ' ' + this.curretnUser.lastName, 'Nová objednávka ').subscribe( (x: Ticket) => {
            priceItem.order.ticketid = x.id

            this.orderService.addOrder(priceItem.order).subscribe(data => {
            }, error => { 
              this.alertify.error('Objednávku se nepodařilo vytvořit');
            }, () => {
            });

          }, error => { this.alertify.error('Ticket se nepodařilo vytvořit')});
        }
      }
    });
    this.alertify.success('Objednávka úspěšně vytvořena');
    this.inputChanged = false;
  }

  createNewTicket(priceItem: PriceListItem,description: string, titlePrefix: string)
  {
    let result: any;
    const ticket: any = {
      assigned: priceItem.order.orderDate,
      categoryId: 1, 
      description: description,
      issuer: this.curretnUser,
      issuerId: this.curretnUser.id,
      priorityId: 3,
      title: titlePrefix + priceItem.name + ' ' + priceItem.companyName,
      issuerCompanyId: this.authService.decodedToken.CompanyId
    }
    return this.ticketService.addTicket(ticket)
  }

  createModal()
  {
    const initialState: any= {
      companyId: this.filterParams.companyId,
      
      month: this.filterParams.month
    };
    this.bsModalRef = this.modalService.show(InvoiceCreateModalComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.createNewInvoice.subscribe((values) =>
    {
      this.invoiceService.addInvoice(values.invoice,values.items).subscribe(() => {
        this.alertify.success('Úspěšně přidáno');
        this.monthInvoiced = true;
      }, error => {
        this.alertify.error('Fakturu se nepodařilo přidat');
      }, () => {
      });
    });
  }
}
