import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Company } from 'src/app/_models/company';
import { Licence } from 'src/app/_models/licence';
import { AuthService } from 'src/app/_services/auth.service';
import { CompanyDialService } from 'src/app/_services/companyDial.service';

@Component({
  selector: 'app-licenceedit-modal',
  templateUrl: './licenceedit-modal.component.html',
  styleUrls: ['./licenceedit-modal.component.css']
})
export class LicenceeditModalComponent implements OnInit {

  @Output() editLicence = new EventEmitter();
  licence: Licence;
  licenceEditForm: FormGroup;
  companies: Company[];
  companyId: string;


  constructor(public bsModalRef: BsModalRef, private fb: FormBuilder, private companyService: CompanyDialService, private authService: AuthService,private datePipe: DatePipe) {}

  ngOnInit() {
    this.createForm();
    this.companyService.getCompanies(1, 1,null,null,false,true).subscribe(data => {
      this.companies = data.result;
      // if (this.companyId !== '0')
      // {
      //   this.companies = data.result.filter(x => x.id.toString() === this.companyId);
      // }
      // else
      // {
        this.companies = data.result;
      // }
    });
  }


  // tslint:disable-next-line: typedef
  createForm(){
    this.licenceEditForm = this.fb .group({
      name: [this.licence.name, Validators.required],
      supplierCompanyId: [this.licence.supplierCompanyId, Validators.required],
      payFrequence: [this.licence.payFrequence],
      licenceCode: [this.licence.licenceCode, Validators.required],
      licencePrice: [this.licence.licencePrice, Validators.required],
      lastPayment: [this.licence.lastPayment],
    });
  }



  createLicence()
  {
    if (this.licenceEditForm.valid)
    {
      this.licence = Object.assign({}, this.licenceEditForm.value);
      this.editLicence.emit(this.licence);
      this.bsModalRef.hide();
    }
  }
}
