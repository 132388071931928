import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Ticket } from '../_models/ticket';
import { TicketService } from '../_services/ticket.service';
import { AuthService } from '../_services/auth.service';
@Injectable()
export class TicketsResolver implements Resolve<Ticket[]> {
  pageNumber = 1;
  pageSize = 10;
  filterParams: any=[];
  result: Observable<Ticket[]>;

  constructor(
    private ticketService: TicketService,
    private router: Router,
    private alertify: AlertifyService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Ticket[]> {
    //  this.route.params.subscribe(params => {
    //   this.filterParams.companyId = params['companyFilter'];
    //   this.result =  this.ticketService.getTickets( this.pageNumber, this.pageSize, this.filterParams, this.authService.decodedToken.nameid, "")
    //   .pipe(
    //     error => {
    //     this.alertify.error('Problem retrieving tickets');
    //     this.router.navigate(['//adminHome']);
    //     return of(null);
    //   });
    // });

    // return this.result;
    // this.filterParams.ticketId= route.params['ctFilter'];
    this.filterParams.companyId = '0';
    this.filterParams.ticketId= route.params['ctFilter'];
    // this.filterParams.companyId = route.params['companyFilter']
    this.filterParams.state = '';
    this.filterParams.solver = '';
    this.filterParams.issuer = '';
    this.filterParams.categoryId = 0;
    this.filterParams.dateFrom = '1990-01-01';
    this.filterParams.dateTo = '2100-01-01';
    return this.ticketService.getTickets(this.pageNumber, this.pageSize,this.filterParams,0,0).pipe(
      catchError((error) => {
        this.alertify.error('Problem retrieving tickets');
        this.router.navigate(['//adminHome']);
        return of(null);
      })
    );
  }
}
