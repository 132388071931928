<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <form [formGroup]="ticketAddForm" (ngSubmit)="createTicket()">
    <div class="form-group">
      <input type="text" [ngClass]="{'is-invalid': ticketAddForm.get('title').errors && ticketAddForm.get('title').touched }" class="form-control is-invalid" 
      formControlName="title" placeholder="Nadpis" maxlength="40"/>
      <div class="invalid-feedback">Nadpis je povinné pole</div>
    </div>
    <div class="form-group">
      <textarea type="text" [ngClass]="{'is-invalid': ticketAddForm.get('description').errors && ticketAddForm.get('description').touched }" 
      class="form-control is-invalid"
      style="min-height: 200px;" 
      formControlName="description" placeholder="Popis" ></textarea>
      <div class="invalid-feedback">Popis je povinné pole</div>
    </div>
    <div class="form-group">
      <select formControlName="solverId" class="form-control" id="solverSelect"  placeholder="Firma"  name="solverSelect" 
      [ngClass]="{ 'is-invalid': ticketAddForm.get('solverId').errors && ticketAddForm.get('solverId').touched}">
      <option value="">Vyberte řešitele</option>
      <option value="0">Nepřiřazovat</option>
       <option *ngFor="let coreUser of coreUsers" [value] = "coreUser.id">
          {{coreUser.firstName}} {{coreUser.lastName}}
        </option> -
      </select>
    <div class="invalid-feedback" *ngIf="ticketAddForm.get('solverId').touched && ticketAddForm.get('solverId').hasError('required')">Vyberte řešitele</div>
  </div>

  <div class="form-group">
    <select formControlName="issuerCompanyId" class="form-control" id="solverSelect"  placeholder="Firma"  name="solverSelect" 
    [ngClass]="{ 'is-invalid': ticketAddForm.get('issuerCompanyId').errors && ticketAddForm.get('issuerCompanyId').touched}" (change)="setCompanyForDynamicFilter()">
    <option value="">Vyberte firmu</option>
     <option *ngFor="let company of companies" [value] = "company.id">
       {{company.companyName}}
      </option> -
    </select>
    <div class="invalid-feedback" *ngIf="ticketAddForm.get('issuerCompanyId').touched && ticketAddForm.get('issuerCompanyId').hasError('required')">Vyberte řešitele</div>
  </div>

    <div *ngIf="parentTicketId === 0" class="form-group">
      <select formControlName="priorityId" class="form-control" id="prioritySelect"  placeholder="Firma"  name="prioritySelect" 
      [ngClass]="{ 'is-invalid': ticketAddForm.get('priorityId').errors && ticketAddForm.get('priorityId').touched}">
      <option value="">Vyberte prioritu</option>
      <option *ngFor="let priority of priorities" [value] = "priority.id">
          {{priority.name}}
        </option>
      </select>
    <div class="invalid-feedback" *ngIf="ticketAddForm.get('priorityId').touched && ticketAddForm.get('priorityId').hasError('required')">Priorita je povinné pole</div>
  </div>
  <div *ngIf="parentTicketId === 0" class="form-group">
    <select formControlName="categoryId" class="form-control" id="categorySelect"  placeholder="Kategorie"  name="categorySelect" 
    [ngClass]="{ 'is-invalid': ticketAddForm.get('categoryId').errors && ticketAddForm.get('categoryId').touched}">
    <option value="">Vyberte kategorii</option>
    <option *ngFor="let category of categories" [value] = "category.id">
      {{category.name}}
    </option>
    </select>
  <div class="invalid-feedback" *ngIf="ticketAddForm.get('categoryId').touched && ticketAddForm.get('categoryId').hasError('required')">Kategorie je povinné pole</div>
  </div>


  

  <div *ngIf="repeatCreate" class="form-group form-inline ml-1">
    <h4 class="mr-2">Od : </h4>
    <input type="date" [ngClass]="{'is-invalid': ticketAddForm.get('lastDateOfRepeat').errors && ticketAddForm.get('lastDateOfRepeat').touched }" class="form-control is-invalid" 
    formControlName="lastDateOfRepeat" />
  </div>
  
  <!-- <div  *ngIf="repeatCreate" class="form-group form-inline w-100">
    <h4 class="mr-2 ">Zopkaovat každých: </h4>
    <input class="w-25"  type="number" min="1" [ngClass]="{'is-invalid': ticketAddForm.get('dayCountForRepeat').errors && ticketAddForm.get('dayCountForRepeat').touched }" class="form-control is-invalid" 
    formControlName="dayCountForRepeat" placeholder="Počet dnů pro zopakování" />
    <h4 class="ml-2 ">dnů </h4>
  </div> -->
  <table *ngIf="repeatCreate" class="table table-hover" style="cursor: pointer">
    <tr>
      <th style="width: 25%">
        <div>
          <h4 id="example-radio-group-label">Opakování</h4>
          <h5><input class="mr-1" type="radio" value='Denně' formControlName="repeatType" >Denně</h5>
          <h5><input class="mr-1" type="radio" value='Týdně' formControlName="repeatType">Týdně</h5>
          <h5><input class="mr-1" type="radio" value='Měsíčně' formControlName="repeatType">Měsíčně</h5>
        </div>
      </th>
      <th style="width: 75%">
        <div *ngIf="ticketAddForm.value.repeatType === 'Denně'">
          <div  class="form-group form-inline w-100 " style="margin-top: 17%;">
            <h5 class="mr-2 ">Každých: </h5>
            <input style="width: 20%;"  type="number" min="0" [ngClass]="{'is-invalid': ticketAddForm.get('dayCountForRepeat').errors && ticketAddForm.get('dayCountForRepeat').touched }" class="form-control is-invalid" 
            formControlName="dayCountForRepeat" placeholder="Počet dnů pro zopakování" />
            <h5 class="ml-2 ">dnů </h5>
          </div>
        </div>
  
        <div *ngIf="ticketAddForm.value.repeatType === 'Týdně'" style="margin-top: 15%;">
          <div  class="form-group form-inline w-100 ">
            <h5 class="mr-2 ">Každý: </h5>
            <input style="width: 30%;"  type="number" min="0"  
            formControlName="weekNo" placeholder="Počet dnů pro zopakování" />
            <h5 class="ml-2 ">týden </h5>
          </div>
          <ng-container *ngFor="let item of days" >
            <input class="ml-2" type="checkbox" [checked]="SetCheck(item,'daysArray')" ng-model="check" (change)="SetArray(item,'daysArray')"/>{{item}}
          </ng-container>
        </div>
  
      <div class="form-inline" style="margin-top: 10%;">
        <div  style="width: 35%;" *ngIf="ticketAddForm.value.repeatType === 'Měsíčně'">
          <ng-container *ngFor="let item of months" >
            <h6><input class="ml-2" type="checkbox" [checked]="SetCheck(item,'monthArray')" ng-model="check" (change)="SetArray(item,'monthArray')"/>{{item}}</h6>
          </ng-container>
        </div>
  
        <div  style="height: 23EM; width: 59%;"  *ngIf="ticketAddForm.value.repeatType === 'Měsíčně'">
          <h6>
          <input class="mr-1" type="radio" value='Data' formControlName="monthRepeatType">Data
          <input class="mr-1" type="radio" value='Dny' formControlName="monthRepeatType">Dny
          </h6>
  
          <div *ngIf="ticketAddForm.value.monthRepeatType === 'Data'">
            <ng-container *ngFor="let item of [].constructor(31); let i = index" >
              <input class="ml-2" type="checkbox" [checked]="SetCheck(i+1,'daysNoArray')" ng-model="check" (change)="SetArray(i+1,'daysNoArray')"/>{{i+1}}
            </ng-container>
            <!-- <input class="ml-2" type="checkbox" [checked]="SetCheck('Last','daysNoArray')" ng-model="check" (change)="SetCheck('Last','daysNoArray')"  />Last -->
          </div>
  
          <div class="form-inline" *ngIf="ticketAddForm.value.monthRepeatType === 'Dny'">
            <div class="w-50">
              <ng-container *ngFor="let item of order" >
                <h6><input class="ml-2" type="checkbox" [checked]="SetCheck(item,'dayNoArray')" ng-model="check" (change)="SetArray(item,'dayNoArray')"/>{{item}}</h6>
              </ng-container>
            </div>
            <div class="w-50">
            <ng-container *ngFor="let item of days" >
              <h6><input class="ml-2" type="checkbox" [checked]="SetCheck(item,'daysArray2')" ng-model="check" (change)="SetArray(item,'daysArray2')"/>{{item}}</h6>
            </ng-container>
          </div>
          </div>
         
        </div>
  
      </div>
  
      </th>
    </tr>
  
  </table>
  
  
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button [disabled]="!ticketAddForm.valid" type="button" class="btn btn-success" form="ticketAddForm" (click)="createTicket()">Uložit</button>
</div>