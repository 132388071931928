<div class="m-5">
  <h2>Priority</h2>

  <form *appHasRole="['CompanyFilter']" class="form-inline" #form ="ngForm" (ngSubmit)="loadPriorities()" novalidate>
    <div class="form-group px-2">
      <label for="company">Firma: </label>
      <select class="form-control ml-1" style="width: 130px" id="companyId" [(ngModel)]="filterParams.companyId" name="companyId">
        <option *ngFor="let company of companies" [value] = "company.id">
          {{company.companyName}}
        </option>
      </select>
    </div>
    <button type="submit" class="btn btn-primary" style="margin-left:10px">Filtrovat</button>
    <button type="button" class="btn btn-info" style="margin-left:10px" (click)="resetFilters()" >Reset Filtrů</button>
  </form>
  <br> 
  <div *ngIf="priorities !== undefined" class="m-5">
    <div *ngIf=" priorities.length === 0" style="text-align: center">
      <h1>Zatím nejsou přidané žádné priority</h1>
    <button class="btn btn-primary m-3" (click)="createModal()" >Vytvořit novou prioritu</button>
    </div>

  <div class="row" *ngIf="priorities.length > 0">
    <table class="table table-hover" style="cursor: pointer">
      <button *appHasRole="['PriorityEdit']" class="btn btn-primary" (click)="createModal()" >Přidat</button>
      <tr>
        <th style="width: 20%">Název</th>
        <th style="width: 15%">DeadLine (počet dní)</th>
        <th style="width: 20%">Firma</th>
        <th style="width: 20%">SLA</th>
        <th style="width: 20%"></th>
      </tr>
      <tr *ngFor="let priority of priorities" >
        <td>{{priority.name}}</td>
        <td>
          <div>
                <strong>{{priority.deadline}}</strong>
          </div>
        </td>
        <td>{{priority.companyName}}</td>

        <td *ngIf="priority.isSLA"><i class="fa fa-check-circle-o" style="color:grey"></i></td>
        <td *ngIf="!priority.isSLA"></td>
        <td>
          <div class="form-inline" *ngIf="compareIds( priority.companyId,filterParams.companyId)">
            <div *appHasRole="['PriorityEdit']"  >
              <button *ngIf="priority.isSLA" class="btn btn-primary" (click)="$event.stopPropagation()" (click)="getEditModal(priority)" ><i class="fa fa-edit ml-1"></i></button>
            </div>
            <div *ngIf="!priority.isSLA" >
              <button *appHasRole="['PriorityEdit']" class="btn btn-primary" (click)="$event.stopPropagation()" (click)="getEditModal(priority)" ><i class="fa fa-edit ml-1"></i></button>
              <button *appHasRole="['PriorityEdit']" class="btn btn-danger" (click)="$event.stopPropagation()" (click)="deletePriority(priority.id)" ><i class="fa fa-trash"></i></button>
            </div>
          </div>
        </td>
      </tr>
    </table>

  </div>

</div>

<div class="d-flex justify-content-center">
    <pagination [maxSize]="3"
                [boundaryLinks]="true" 
                [totalItems]="pagination.totalItems"
                [itemsPerPage]="pagination.itemsPerPage"
                [(ngModel)]="pagination.currentPage"
                (pageChanged)="pageChanged($event)"
              previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
  </pagination>
  </div>