import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PurchaseDepartmentItem } from 'src/app/_models/purchaseDepartmentItem';
import { PurchaseDepartmentOrder } from 'src/app/_models/purchaseDepartmentOrder';
import { User } from 'src/app/_models/user';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-purchaseDepartmentEdit-Modal',
  templateUrl: './purchaseDepartmentEdit-Modal.component.html',
  styleUrls: ['./purchaseDepartmentEdit-Modal.component.css']
})
export class PurchaseDepartmentEditModalComponent implements OnInit {
  @Output() editPDO = new EventEmitter();
  purchaseDepartmentOrder: PurchaseDepartmentOrder;
  purchaseDepartmentOrderAddForm: FormGroup;
  coreUsers: User[];
  itemsValid :boolean=true;
  pdo:PurchaseDepartmentOrder

  
  purchaseDepartmentItems: PurchaseDepartmentItem[]= [];

  constructor(public bsModalRef: BsModalRef,
    private fb: FormBuilder,
    private companyService: CompanyDialService,
    private authService: AuthService,
    private alertify: AlertifyService,
    private userService:UserService,
    private datePipe: DatePipe) {}

  ngOnInit() {
    this.createForm();
    this.userService.getCoreUsers().subscribe((data) => {this.coreUsers = data;});
    this.purchaseDepartmentItems = this.pdo.pdis;
  }


  // tslint:disable-next-line: typedef
  createForm(){
    this.purchaseDepartmentOrderAddForm = this.fb .group({
      dateOfOrder: [this.datePipe.transform(this.pdo.dateOfOrder, 'yyyy-MM-dd'), Validators.required],
      orderedById: [this.pdo.orderedById, Validators.required],
      orderedFromId: [this.pdo.orderedFromId, Validators.required],
      ticketId: [this.pdo.ticketId, Validators.required],
      deleted: [false],
      
    });
  }

  createOrder()
  {
    if (this.purchaseDepartmentOrderAddForm.valid)
    {

      this.purchaseDepartmentOrder = Object.assign({}, this.purchaseDepartmentOrderAddForm.value);
       this.purchaseDepartmentOrder.pdis = this.purchaseDepartmentItems;
      this.editPDO.emit(this.purchaseDepartmentOrder);
      this.bsModalRef.hide();
    }
  }

  addEmptyItem()
  {
    let  emptyItem = {} as PurchaseDepartmentItem;
    emptyItem.amount =0;
    emptyItem.name ="";
    emptyItem.pDOId =0;
    emptyItem.deleted =false;
    emptyItem.dynamicId = this.purchaseDepartmentItems.reduce((op, item) => op = op > item.dynamicId ? op : item.dynamicId, 0)+1;
    this.purchaseDepartmentItems.push(emptyItem);
    this.checkItems();

    
  }

  removeItem(id: number,dID:number)
  {
    // console.log(id)
    if(id !=0)
    {
      this.purchaseDepartmentItems.filter(x=>x.id === id)[0].deleted =true;
    }
    if(dID !=0)
    {
      // this.purchaseDepartmentItems.filter(x=>x.dynamicId === dID)[0].deleted =true;
      this.purchaseDepartmentItems = this.purchaseDepartmentItems.filter(x=>x.dynamicId != id);
    }
    // console.log(this.purchaseDepartmentItems);
    // this.purchaseDepartmentItems = this.purchaseDepartmentItems.filter(x=>x.dynamicId != id);
    this.checkItems();
  }

  checkItems()
  {
    if(this.purchaseDepartmentItems.length<=0)
    {
      this.itemsValid = true;
      return;
    }

    var error = this.purchaseDepartmentItems.filter(x=>
      x.amount === 0 || x.amount === null ||
       x.name === undefined || x.name === '' || x.name === null 
      ).length>0;

    this.itemsValid = !error;
    console.log(this.itemsValid)
  }

 
}
