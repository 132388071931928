<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <h4><strong>Název:</strong> {{invItem.name}}</h4>
  <h4><strong>Množství:</strong> {{invItem.amount}}</h4>
  <h4><strong>Nákupní cena:</strong> {{invItem.buyingPrice}}</h4>
  <h4><strong>Prodejní cena:</strong> {{invItem.sellingPrice}}</h4>
  <h4><strong>Prodejní cena (s DPH):</strong> {{invItem.sellingPrice*1.21}}</h4>
  <h4><strong>Dodavatel:</strong> {{invItem.supplierCompany.companyName}}</h4>
  <h4><strong>SerialNo:</strong> {{invItem.serialNumber}}</h4>
  <h4><strong>ProductNo:</strong> {{invItem.productNumber}}</h4>
  <h4><strong>Záruka do:</strong> {{invItem.warrantyTo | date:"dd.MM.yyyy"}} </h4>

  <h4 *ngIf="invItem.forClient"><strong>Pro klienta:</strong> <i class="fa fa-check-circle-o" style="color:grey"></i></h4>
  <h4 *ngIf="!invItem.forClient"><strong>Pro klienta:</strong> <i class="fa fa-times-circle-o" style="color:grey"></i></h4>
  <div *ngIf="invItem.forClient">
    <h4><strong>Klient:</strong> {{invItem.clientCompany.companyName}}</h4>
    <h4 *ngIf="invItem.deliveredToClient"><strong>Doručeno Klientovi:</strong> <i class="fa fa-check-circle-o" style="color:grey"></i></h4>
    <h4 *ngIf="!invItem.deliveredToClient"><strong>Doručeno Klientovi:</strong> <i class="fa fa-times-circle-o" style="color:grey"></i></h4>
    <h4 *ngIf="invItem.invoicedToClient"><strong>Fakturováno klientovi:</strong> <i class="fa fa-check-circle-o" style="color:grey"></i></h4>
    <h4 *ngIf="!invItem.invoicedToClient"><strong>Fakturováno klientovi:</strong> <i class="fa fa-times-circle-o" style="color:grey"></i></h4>
  </div>
  
  <h4 *ngIf="invItem.isContainedInAnotherItem"><strong>Je obsaženo v jiné položce:</strong> <i class="fa fa-check-circle-o" style="color:grey"></i></h4>
  <h4 *ngIf="!invItem.isContainedInAnotherItem"><strong>Je obsaženo v jiné položce:</strong> <i class="fa fa-times-circle-o" style="color:grey"></i></h4>

  <h4 *ngIf="invItem.isInWarehouse"><strong>Na skladě:</strong> <i class="fa fa-check-circle-o" style="color:grey"></i></h4>
  <h4 *ngIf="!invItem.isInWarehouse"><strong>Na skladě:</strong> <i class="fa fa-times-circle-o" style="color:grey"></i></h4>

  <h4 *ngIf="invItem.isOnComplaint"><strong>Reklamace:</strong> <i class="fa fa-check-circle-o" style="color:grey"></i></h4>

  <h4 *ngIf="!invItem.isOnComplaint"><strong>Obsažené položky:</strong></h4>
  <div *ngFor="let consistItem of invItem.consistOfItems">
    <h4 *ngIf="!invItem.isOnComplaint">{{consistItem.name}}</h4>
  </div>

  
</div>
<div class="modal-footer">
  <!-- <a>{{invItem.invoiceId}}</a> -->
  <!-- <a>{{invItem.clientInvoiceId}}</a> -->
  <a *ngIf="invItem.clientInvoiceId!=0" [routerLink]="['/issues', 0,invItem.clientInvoiceId]" target="_blank" class=" btn btn-primary FullViewLink">Doklady výdej</a>
  <a *ngIf="invItem.invoiceId!=0" [routerLink]="['/itemOrders', 0,invItem.invoiceId]" target="_blank" class=" btn btn-primary FullViewLink">Doklady příjem</a>
  <!-- <a [routerLink]="['/priorities', invItem.itemOrderId]" target="_blank" class=" btn btn-primary FullViewLink">Objednávka</a> -->
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
</div>