import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Company} from 'src/app/_models/company';
import {InventoryItem} from 'src/app/_models/inventoryItem';
import {ItemOrder} from 'src/app/_models/itemOrder';
import {OrderedItem} from 'src/app/_models/orderedItem';
import {CompanyDialService} from 'src/app/_services/companyDial.service';
import {InventoryItemService} from 'src/app/_services/inventoryItem.service';
import {ToolsService} from 'src/app/_services/tools.service';
import * as pdfMake from 'pdfmake/build/pdfmake';

const htmlToPdfmake = require("html-to-pdfmake");


@Component({
  selector: 'app-itemOrderDetail-modal',
  templateUrl: './itemOrderDetail-modal.component.html',
  styleUrls: ['./itemOrderDetail-modal.component.scss']
})

export class ItemOrderDetailModalComponent implements OnInit {
  @Output() editItemOrder = new EventEmitter();
  itemOrder: ItemOrder;
  companies: Company[];
  companiesClient: Company[];
  filterParams: any = {};
  header: string;
  companyId: string;
  invItems: InventoryItem[] = [];
  orderedItems: OrderedItem[] = [];
  totalPrice = 0;
  viewName;

  constructor(
    private bsModalRef: BsModalRef,
    private companyService: CompanyDialService,
    private invService: InventoryItemService,
    private toolsService: ToolsService) {
  }

  ngOnInit() {

    console.log(this.itemOrder);

    this.setHeader();
    this.companyService.getCompanies(1, 1).subscribe(data => {
      this.companies = data.result;
      if (this.companyId !== '0') {
        this.companies = data.result.filter(x => x.id.toString() === this.companyId.toString());
      } else {
        this.companies = data.result;
      }
      this.companiesClient = this.companies.filter(x => x.client == true || x.isPrimary == true);
      this.companies = this.companies.filter(x => x.supplier == true);
    });
    if (this.itemOrder.supplier === null) {
      this.getInvItemsInvoices();
    }

    if (this.itemOrder.supplier !== null) {
      this.getInvItemsItemOrders();
    }
  }

  roundTo(num) {
    return this.toolsService.roundTo(num);
  }

  getInvItemsItemOrders() {
    this.filterParams.name = '';
    this.filterParams.isContained = false;
    this.filterParams.clientId = this.itemOrder.customerId;
    this.filterParams.supplierId = 0;
    this.filterParams.getPrimaryCompany = false;
    this.filterParams.itemOrderId = this.itemOrder.id;
    this.invService
      .getInvenotryItems(1, 1, 0, this.filterParams)
      .subscribe(x => {
        this.invItems = x.result
        this.getTotalPrice();
      })
  }

  getInvItemsInvoices() {
    if (this.viewName === 'new issue') {
      //todo nacis objednane veci orderedItems
      this.invService.GetOrderedItems(this.itemOrder.id).subscribe(data => {
        this.orderedItems = data;
      })
    } else if (this.viewName === 'delivery list') {
      var filterParams: any = {};
      filterParams.name = '';
      filterParams.hasContained = null;
      filterParams.delivered = null;
      filterParams.supplierId = 0;
      filterParams.reklamace = null;
      filterParams.inWarehouse = null;

      // filterParams.invoicedToClient = true;
      filterParams.clientId = this.itemOrder.inv.companyId;
      filterParams.isForInvoice = false;
      filterParams.isContained = false;
      filterParams.clientInvoiceId = this.itemOrder.inv.id;
      filterParams.getPrimaryCompany = false;
      this.filterParams.itemOrderId = this.itemOrder.id;

      this.invService
        .getInvenotryItems(1, 1, 0, filterParams)
        .subscribe(data => {
          this.invItems = data.result;
          this.getTotalPrice();
        })
    }
  }

  setHeader() {
    switch (this.viewName) {
      case 'new issue': {
        this.header = "Nová přijatá objednávka"
        break;
      }
      case 'delivery list': {
        this.header = "Dodací list"
        break;
      }
      case 'new order': {
        this.header = "Nová vydaná objednávka"
        break;
      }
      case 'receipt': {
        this.header = "Příjemka"
        break;
      }
      case 'accepted invoice': {
        this.header = "Přijatá faktura"
        break;
      }
      case 'accepted order': {
        this.header = "Přijatá objednávka"
        break;
      }
      case 'issued invoice': {
        this.header = "Vydaná faktura"
        break;
      }
      default: {
        this.header = '';
        break;
      }
    }
  }

  @ViewChild('pdfTable')
  pdfTable!: ElementRef;

  public downloadAsPDF() {
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);
    const documentDefinition = {content: html, pageOrientation: 'landscape'};
    pdfMake.createPdf(documentDefinition).download();

  }

  onBtnPrintClick() {
    window.print();
  }

  getTotalPrice() {
    if (this.invItems !== null) {
      this.invItems.forEach(invItem => {
        if (this.viewName === 'new order') {
          this.totalPrice = this.totalPrice + (invItem.orderedAmount * invItem.buyingPrice);
        } else {
          this.totalPrice = this.totalPrice + (invItem.amount * invItem.buyingPrice);
        }
      });
      this.totalPrice = this.totalPrice * 1.21;
    }
  }

  public closeModal(): void {
    this.bsModalRef.hide();
  }
}
