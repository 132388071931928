import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Ticket } from '../_models/ticket';
import { TicketService } from '../_services/ticket.service';
import { Solver } from '../_models/solver';
import { UserService } from '../_services/user.service';
import { tick } from '@angular/core/testing';
@Injectable()
export class TicketResolver implements Resolve<Ticket> {
  ticket: Ticket;

  constructor(private ticketservice: TicketService, private userService: UserService , private router: Router, private alertify: AlertifyService){}
  resolve(route: ActivatedRouteSnapshot): Observable<Ticket>{

    // this.ticketservice.getTicket(route.params['id']).subscribe((data) =>
    // {
    //   this.ticket = data;
    // });

    // this.userService.getUser(this.ticket.issuerId).subscribe((data) =>
    // {
    //   this.ticket.issuer = data;
    // });
    return this.ticketservice.getTicket(route.params['id']).pipe(
          catchError(error => {
              this.alertify.error('Problem retrieving data');
              this.router.navigate(['/tickets']);
              return of (null);
          })
      );
  }
}
