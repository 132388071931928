import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Company } from 'src/app/_models/company';
import { Reminder } from 'src/app/_models/reminder';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { ReminderService } from 'src/app/_services/reminder.service';

@Component({
  selector: 'app-reminderEdit-modal',
  templateUrl: './reminderEdit-modal.component.html',
  styleUrls: ['./reminderEdit-modal.component.scss']
})
export class ReminderEditModalComponent implements OnInit {
  @Output() editreminder = new EventEmitter();
  reminder: Reminder;
  reminderEditForm: FormGroup;
  companies: Company[];
  companyId: string;

  constructor(public bsModalRef: BsModalRef, private fb: FormBuilder, private reminderService: ReminderService, private companyService: CompanyDialService) {}

  ngOnInit() {
    this.createForm();
    this.companyService.getCompanies(1, 1,null,null,false,true).subscribe(data =>{
      this.companies = data.result;
      if (this.companyId !== '0')
      {
        this.companies = data.result.filter(x => x.id.toString() === this.companyId);
      }
      else
      {
        this.companies = data.result;
      }
    });
  }


  createForm(){
    this.reminderEditForm = this.fb .group({
      text: [this.reminder.text, Validators.required],
      companyid: [this.reminder.companyId, Validators.required],
      deleted: [this.reminder.deleted],
    });
  }
  editReminder()
  {
    if (this.reminderEditForm.valid)
    {
      this.reminder = Object.assign({}, this.reminderEditForm.value);
      this.editreminder.emit(this.reminder);
      this.bsModalRef.hide();
    }
  }
}