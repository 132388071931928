<div class="m-5">
  <h2>Opakované úlohy</h2>
  <form  #form ="ngForm" (ngSubmit)="setFilters()" novalidate>
 
  <div class="form-inline mt-2">
    <!-- <div class="form-group px-2 mt-3 " style="width: 13%;">
      <label for="company">Č.t.: </label>
      <input type="number" class="form-control ml-1" style="width: 70%;"  placeholder="Číslo ticketku" [(ngModel)]="filterParams.ticketId" name="ticketId"/>
    </div> -->
    <div  *appHasRole="['CompanyFilter']" class="form-group px-2 mt-3">
      <label for="company">Firma: </label>
      <select class="form-control ml-1" style="width: 130px" id="companyId" [(ngModel)]="filterParams.companyId" name="companyId">
        <option value="0"></option>
        <option *ngFor="let company of companies" [value] = "company.id">
          {{company.companyName}}
        </option>
      </select>
    </div>
    <div class="form-group px-2 mt-3">
      <input type="text" class="form-control" placeholder="Zadavatel" [(ngModel)]="filterParams.issuer" name="issuer"/>
    </div>
    <div class="form-group px-1 mt-3">
      <input type="text" class="form-control" placeholder="Řešitel" [(ngModel)]="filterParams.solver" name="solver"/>
    </div>
 
    <div class="form-group px-2  mt-3">
      <label for="company">Kategorie: </label>
      <select class="form-control ml-1" style="width: 130px" id="categoryId" [(ngModel)]="filterParams.categoryId" name="categoryId">
        <option value="0"></option>
        <option *ngFor="let category of categories" [value] = "category.id">
          {{category.name}}
        </option>
      </select>
    </div>
    <div class="mt-3">
      <button type="submit" class="btn btn-primary" style="margin-left:10px" (click)="loadTickets()">Filtrovat</button>
      <button type="button" class="btn btn-info" style="margin-left:10px" (click)="resetFilters()" >Reset Filtrů</button>
    </div>
  
  </div>
    
  </form>
  <br> 
    <div >
    <button  class="btn btn-primary" (click)="$event.stopPropagation()" (click)="getTicketAddModal()" >Vytvořit novou úlohu</button>
    <div>

      <div class="m-5">
        <div *ngIf="tickets !== undefined && tickets.length === 0 " style="text-align: center">
          <h1>Zatím jste nevytvořil žádnou opakovanou úlohu ticket</h1>
        <button class="btn btn-primary m-3" (click)="$event.stopPropagation()" (click)="getTicketAddModal()" >Vytvořit novou úlohu</button>
        </div>
       
        <div class="row" *ngIf="tickets !== undefined && tickets.length > 0">
          <table class="table table-hover" style="cursor: pointer">
            <tr>
              <th  style="width: 30%">Název</th>
              <th  style="width: 10%">Firma</th>
              <th  style="width: 15%">Zadavatel</th>
              <th  style="width: 15%">Řešitel</th>
              <th  style="width: 10%">Kategorie</th>
              <th  style="width: 10%">opakování</th>
              <th  style="width: 10%"></th>
      
            </tr>
              <tr *ngFor="let ticket of tickets">
                <td>{{ticket.title | slice:0:20}}</td>
                <td ><strong>{{ticket.issuer.company.companyName}} </strong></td>
                <td ><strong>{{ticket.issuer.firstName}} {{ticket.issuer.lastName}}</strong></td>
                <td ><strong *ngFor="let solver of ticket.solvers">{{solver.user.firstName}} {{solver.user.lastName}}</strong></td>
                <td ><strong>{{ticket.category.name}}</strong></td>
                <td ><strong>{{ticket.dayCountForRepeat}} dnů</strong></td>
                <td ><button class="btn btn-primary " (click)="$event.stopPropagation()" (click)="getTicketEditModal(ticket.id)" ><i class="fa fa-edit ml-1"></i></button></td>
            </tr>
          </table>
        </div>
      </div>

      <div class="d-flex justify-content-center">
        <pagination [maxSize]="3"
                    [boundaryLinks]="true" 
                    [totalItems]="pagination.totalItems"
                    [itemsPerPage]="pagination.itemsPerPage"
                    [(ngModel)]="pagination.currentPage"
                    (pageChanged)="pageChanged($event)"
                  previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
      </pagination>
      </div>

    </div>
    </div>
</div>