import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { AuthService } from '../_services/auth.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  model: any = {};
  photoUrl: string;
  companyId: string;
  userId: string;
  isPrimary: string;
  navbarCollapsed = false;
  mobile = false;
  constructor(
    public authService: AuthService,
    private alertify: AlertifyService,
    private router: Router
  ) {}
  // tslint:disable-next-line: typedef
  ngOnInit() {
    this.baseInit();
  }

  baseInit(): void {
    if (window.screen.width === 375) { // 768px portrait
      this.mobile = true;
      if(this.mobile)
      {this.navbarCollapsed =true}
    }
    this.isPrimary = this.authService.decodedToken.IsPrimary;
    this.authService.currentPhotoUrl.subscribe(photoUrl => this.photoUrl = photoUrl);
    this.companyId = this.authService.decodedToken.CompanyId;
    if (this.companyId === '1')
    {
      this.companyId = '0';
    }
    this.userId = this.authService.decodedToken.nameid;

  }

  // tslint:disable-next-line: typedef
  login() {
    this.authService.login(this.model).subscribe(
      (data) => {

        console.log(data)

        this.baseInit();
        this.alertify.success('Přihlášení proběhlo úspěšně');
      }, error => {


        console.log(error);
        this.alertify.error('Přihlášení se nezdařilo');
      },
      () => {
        if (this.isAdmin())
        {
          this.router.navigate(['/adminHome']);
        }
        else
        {
          this.router.navigate(['/tickets/' + this.authService.decodedToken.nameid]);
        }
      }
    );
  }

  isAdmin()
  {
    const role: string[] = this.authService.decodedToken.role;
    if (typeof(role) === 'string')
    {
      if(role === 'Companies' || role === 'WorkReports')
      {
        return true;
      }
      else
      {
        return false;
      }
    }
    else
    {
      return role.filter(x => x === 'Companies' || x === 'WorkReports').length === 1;
    }
  }

  // tslint:disable-next-line: typedef
  loggedIn() {
    return this.authService.loggedIn();
  }

  // tslint:disable-next-line: typedef
  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    this.authService.decodedToken = null;
    this.authService.curretnUser = null;
    this.alertify.message('logged out');
    this.router.navigate(['/home']);
  }
}
