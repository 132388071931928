import { NgModule } from '@angular/core';

import { NgIsEqualPipeModule } from './is-equal.pipe';
import { NgIsGreaterOrEqualPipeModule } from './is-greater-or-equal.pipe';
import { NgIsGreaterPipeModule } from './is-greater.pipe';
import { NgIsIdenticalPipeModule } from './is-identical.pipe';
import { NgIsLessOrEqualPipeModule } from './is-less-or-equal.pipe';
import { NgIsLessPipeModule } from './is-less.pipe';
import { NgIsNotEqualPipeModule } from './is-not-equal.pipe';
import { NgIsNotIdenticalPipeModule } from './is-not-identical.pipe';
import { IsNullPipeModule } from './is-null.pipe';
import { IsUndefinedPipeModule } from './is-undefined.pipe';
import { IsNilPipeModule } from './is-nil.pipe';
import { IsFunctionPipeModule } from './is-function.pipe';
import { IsNumberPipeModule } from './is-number.pipe';
import { IsStringPipeModule } from './is-string.pipe';
import { IsArrayPipeModule } from './is-array.pipe';
import { IsObjectPipeModule } from './is-object.pipe';
import { IsDefinedPipeModule } from './is-defined.pipe';

@NgModule({
  imports: [
    IsNullPipeModule,
    IsUndefinedPipeModule,
    IsNilPipeModule,
    IsFunctionPipeModule,
    IsNumberPipeModule,
    IsStringPipeModule,
    IsArrayPipeModule,
    IsObjectPipeModule,
    IsDefinedPipeModule,
  ],
})
export class NgTypesPipesModule {}

@NgModule({
  exports: [
    NgIsEqualPipeModule,
    NgIsGreaterOrEqualPipeModule,
    NgIsGreaterPipeModule,
    NgIsIdenticalPipeModule,
    NgIsLessOrEqualPipeModule,
    NgIsLessPipeModule,
    NgIsNotEqualPipeModule,
    NgIsNotIdenticalPipeModule,
  ],
})
export class NgConditionsPipesModule {}

@NgModule({
  imports: [NgConditionsPipesModule, NgTypesPipesModule],
})
export class CommonBooleanPipesModule {}
