import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';
import { CompanyDialService } from '../_services/companyDial.service';
import { Company } from '../_models/company';
import { TicketService } from '../_services/ticket.service';
import { Ticket } from '../_models/ticket';
@Injectable()
export class AdminHomeResolver implements Resolve<any> {
  pageNumber = 1;
  pageSize = 9;

  constructor(
    private router: Router,
    private alertify: AlertifyService,
    private companyDialService: CompanyDialService,
    private ticketService: TicketService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {

    let companies = this.companyDialService.getCompanies(this.pageNumber, this.pageSize).pipe(
      catchError((error) => {
        this.alertify.error('Problem retrieving messages');
        this.router.navigate(['//adminHome']);
        return of(null);
      })
    );
    let tickets  = this.ticketService.getTickets(this.pageNumber, 5).pipe(
      catchError((error) => {
        this.alertify.error('Problem retrieving tickets');
        this.router.navigate(['//adminHome']);
        return of(null);
      })
    );

    let join = forkJoin([companies, tickets]).pipe(map((allResponses) => {
      return {
        Companies: allResponses[0],
        Tickets: allResponses[1]
      };
    }));
    return join;
  }
}
