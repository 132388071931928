import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Licence } from '../_models/licence';
import { LicenceToOrder } from '../_models/licenceToOrder';
import { PaginatedResult } from '../_models/pagination';

@Injectable({
  providedIn: 'root'
})
export class LicenceService {
  baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) {}


  addLicence(licence: Licence)
  {
    return this.http.post(this.baseUrl + 'Licence/', licence );
  }

  deleteLicence(licenceId: number)
  {
    return this.http.delete(this.baseUrl + 'Licence/' + licenceId );
  }

  getLicences( page?, itemsPerPage?, userParams?)
  {
    const paginatedResult: PaginatedResult<Licence[]> = new PaginatedResult<Licence[]>();
    let params = new HttpParams();

    if (page != null && itemsPerPage != null)
    {
      params = params.append('pageNumber', page);
      params = params.append('pageSize', itemsPerPage);
    }
    if ( userParams != null)
    {
      params = params.append('SuplierId', userParams.suplierId);
      params = params.append('LicenceCode', userParams.licenceCode);
    }
    return this.http.get<Licence[]>(this.baseUrl + 'Licence', {observe: 'response', params} )
    .pipe(
      map(response => {
        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') !== null)
        {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      })
    );
  }

  getLicencesNoPagination( filterParams?)
  {
    let params = new HttpParams();
   
    if ( filterParams != null)
    {
      params = params.append('SuplierId', "0");
      params = params.append('LicenceCode', "");
    }
    return this.http.get<Licence[]>(this.baseUrl + 'Licence/GetLicencesNoPagination', {observe: 'response', params} ).pipe(
      map(response => {
        return response.body;
      })
    );
   
  }

  getLto( priceListItemId)
  {
    let params = new HttpParams();
    return this.http.get<LicenceToOrder[]>(this.baseUrl + 'Licence/GetLto/'+priceListItemId, {observe: 'response', params} ).pipe(
      map(response => {
        return response.body;
      })
    );
   
  }

  

  updateLicence(id: number, licence: Licence)
  {
    return this.http.put(this.baseUrl + 'Licence/' + id, licence);
  }

  addLTO(licence: LicenceToOrder)
  {
    return this.http.post(this.baseUrl + 'Licence/lto', licence );
  }

  deleteLTO(licenceId: number)
  {
    return this.http.delete(this.baseUrl + 'Licence/lto/' + licenceId );
  }

  getLicenceReport( filterParams?)
  {
    let params = new HttpParams();
   
    if ( filterParams != null)
    {
      params = params.append('CompanyId', filterParams.companyId);
      params = params.append('OrderDate', filterParams.orderDate);
      params = params.append('SuplierId', "0");
      params = params.append('LicenceCode', "");
    }
    return this.http.get<Licence[]>(this.baseUrl + 'Licence/GetLicenceReport', {observe: 'response', params} ).pipe(
      map(response => {
        return response.body;
      })
    );
   
  }
}
