import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Company } from 'src/app/_models/company';
import { Category } from 'src/app/_models/category';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { AuthService } from 'src/app/_services/auth.service';
@Component({
  selector: 'app-categoryedit-modal',
  templateUrl: './categoryedit-modal.component.html',
  styleUrls: ['./categoryedit-modal.component.css']
})
export class CategoryEditModalComponent implements OnInit {
  @Output() editcategory = new EventEmitter();
  category: Category;
  categoryEditForm: FormGroup;
  companies: Company[];
  companyId: string;
  constructor(public bsModalRef: BsModalRef, private fb: FormBuilder, private companyService: CompanyDialService, private authService: AuthService) {}

  ngOnInit() {
    this.createForm();
    this.companyService.getCompanies(1, 1,null,null,false,true).subscribe(data =>
    {
      this.companies = data.result;
      if (this.companyId !== '0')
        {
          this.companies = data.result.filter(x => x.id.toString() === this.companyId);
        }
      else
        {
          this.companies = data.result;
        }
    });
  }


  createForm(){
    this.categoryEditForm = this.fb .group({
      name: [this.category.name, Validators.required],
      companyid: [this.category.companyId, Validators.required],
    });
  }

  editCategory()
  {
    if (this.categoryEditForm.valid)
    {
      this.category = Object.assign({}, this.categoryEditForm.value);
      this.editcategory.emit(this.category);
      this.bsModalRef.hide();
    }
  }
}

