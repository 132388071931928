export const ConstantsPrintPDF = {
   modifiedpageWidth: 200,
   pageHeight: 297
  };

export const InvoiceKeys = {
   KmCode: "Cenazakm",
   PCCode: "Pcvdomene",
   PCProCode: "Pcprovdomene",
   ServerCode: "Servervdomene",
   SQLCode: "SQL100MB",
   FileCode: "Soubory100MB",
   ExchangeCode: "Exchange100MB",
   ONSCode: "VykazanehodinyONS",
   RASCode: "VykazanehodinyRAS"
}