<div class="modal-header ">
  <h4 class="modal-title pull-left">Správa uživatele {{user.userName}}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form #rolesForm="ngForm" id="rolesForm">
    <div class="form-check" *ngFor="let group of groups">
      <input type="checkbox" class="form-check-input" value="{{group.name}}" [checked]="group.checked"
      (change)="group.checked =! group.checked"
      [disabled]="group.checked && group.name === 'Admin' && user.userName === 'Admin'">
      <label>{{group.name}}</label>
    </div>
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button type="button" class="btn btn-success" form="rolesForm" (click)="updateRows()">Uložit</button>
</div>