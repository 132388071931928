import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { filter } from 'rxjs/operators';
import { Company } from 'src/app/_models/company';
import { PaginatedResult, Pagination } from 'src/app/_models/pagination';
import { User } from 'src/app/_models/user';
import { AdminService } from 'src/app/_services/admin.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { RolesModalComponent } from '../admin/admin-panel/roles-modal/roles-modal.component';
import { Group } from '../_models/group';
import { GroupService } from '../_services/group.service';
import { AddGroupComponent } from './addGroup/addGroup.component';
import { EditGroupComponent } from './editGroup/editGroup.component';

@Component({
  selector: 'app-rolegroups',
  templateUrl: './rolegroups.component.html',
  styleUrls: ['./rolegroups.component.css']
})
export class RolegroupsComponent implements OnInit {
  bsModalRef: BsModalRef;
  groups: Group[];
  pagination: Pagination;
  filterParams: any = {};

  constructor(
    // private priorityDialService: PriorityService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private groupService: GroupService
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
       this.groups = data['groups'].result;
      this.pagination = data['groups'].pagination;
    });
  }



  loadGroups() {
    this.groupService.getGroups( this.pagination.currentPage, this.pagination.itemsPerPage, this.filterParams)
    .subscribe((res: PaginatedResult<Group[]>) => {
      this.groups = res.result;
      // this.pagination = res.pagination;
      this.pagination.totalPages =res.pagination.totalPages;
      this.pagination.itemsPerPage =res.pagination.itemsPerPage;
      this.pagination.totalItems =res.pagination.totalItems;
    }, error => {
      this.alertify.error(error);
    });
   }

  pageChanged(event: any): void{
    this.pagination.currentPage = event.page;
    this.loadGroups();
  }

  createModal()
  {
    const initialState: any= {
    };
    this.bsModalRef = this.modalService.show(AddGroupComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.addNewGroup.subscribe((values) =>
    {
      this.groupService.addGroup(values.gn,values.roles)
      .subscribe(() => {
        this.alertify.success('Úspěšně přidáno');
      }, error => {
        this.alertify.error('Skupinu se nepodařilo přidat');
      }, () => {
        this.loadGroups();
      });
    });
  }

  getEditModal(group: Group)
  {
    const initialState: any= {
      group
    };
    this.bsModalRef = this.modalService.show(EditGroupComponent, {initialState, backdrop:'static'});
    this.bsModalRef.content.editGroup.subscribe((values) =>
    {
      this.groupService.editGroup(values.gn,values.roles,values.gId)
      .subscribe(() => {
        this.alertify.success('Úspěšně upraveno');
      }, error => {
        this.alertify.error('Skupinu se nepodařilo upravit');
      }, () => {
        this.loadGroups();
      });
    });
  }

  deleteObject(id: number)
  {
    this.alertify.confirm('Opravdu chcete vymazat tuto skupinu ?', () => {
      this.groupService.deleteGroup(id)
      .subscribe(() => {
        this.groups.splice(this.groups.findIndex(m => m.id === id ), 1);
        this.alertify.success('Úspěšně odstraněno');
      }, error => {
        this.alertify.error('Skupinu se nepodařilo odstranit');
      });
    });
  }
}
