<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <form [formGroup]="ticketEditForm" (ngSubmit)="saveChagnes()">
    <div class="form-group">
      <input type="text" [ngClass]="{'is-invalid': ticketEditForm.get('title').errors && ticketEditForm.get('title').touched }" class="form-control is-invalid" 
      formControlName="title" placeholder="Nadpis" />
      <div class="invalid-feedback">Nadpis je povinné pole</div>
    </div>
    
    
    <div class="form-group">
      <select formControlName="issuerId" class="form-control" id="solverSelect"  placeholder="Firma"  name="solverSelect" 
      [ngClass]="{ 'is-invalid': ticketEditForm.get('issuerId').errors && ticketEditForm.get('issuerId').touched}" >
      <!-- <option hidden value="0"></option> -->
       <option *ngFor="let user of users" [value] = "user.id">
         {{user.firstName}}   {{user.lastName}}
        </option> -
      </select>
      <div class="invalid-feedback" *ngIf="ticketEditForm.get('issuerId').touched && ticketEditForm.get('issuerId').hasError('required')">Vyberte řešitele</div>
    </div>


  <div class="form-group">
    <select formControlName="issuerCompanyId" class="form-control" id="solverSelect"  placeholder="Firma"  name="solverSelect" 
    [ngClass]="{ 'is-invalid': ticketEditForm.get('issuerCompanyId').errors && ticketEditForm.get('issuerCompanyId').touched}" (change)="setCompanyForDynamicFilter()">
    <option value="">Vyberte firmu</option>
     <option *ngFor="let company of companies" [value] = "company.id">
       {{company.companyName}}
      </option> -
    </select>
    <div class="invalid-feedback" *ngIf="ticketEditForm.get('issuerCompanyId').touched && ticketEditForm.get('issuerCompanyId').hasError('required')">Vyberte řešitele</div>
  </div>

  
  <div *ngIf="directCatView">
    <h4 class="mr-2">Kategorie: {{this.ticket.category.name}} </h4>
    
  </div>
  <div  *ngIf="!directCatView" class="form-group">
    
    <select formControlName="categoryId" class="form-control" id="categorySelect"  placeholder="Kategorie"  name="categorySelect" 
    [ngClass]="{ 'is-invalid': ticketEditForm.get('categoryId').errors && ticketEditForm.get('categoryId').touched}">
    <option value="">Vyberte kategorii</option>
    <option *ngFor="let category of categories" [value] = "category.id">
      {{category.name}}
    </option>
    </select>
  <div class="invalid-feedback" *ngIf="ticketEditForm.get('categoryId').touched && ticketEditForm.get('categoryId').hasError('required')">Kategorie je povinné pole</div>
  </div>
  
  <div class="form-group form-inline ml-1">
    <h4 class="mr-2">Deadline : </h4>
    <input type="date" [ngClass]="{'is-invalid': ticketEditForm.get('deadLine').errors && ticketEditForm.get('deadLine').touched }" class="form-control is-invalid" 
    formControlName="deadLine" />
  </div>
  
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="bsModalRef.hide()">Zrušit</button>
  <button [disabled]="!ticketEditForm.valid || ticketEditForm.value.categoryId ===''" type="button" class="btn btn-success" form="ticketEditForm" (click)="saveChagnes()">Uložit</button>
</div>



