<div class="m-5">
  <h2>Doklady přijaté</h2>
  <form *appHasRole="['CompanyFilter']" class="form-inline mt-4" #form ="ngForm" (ngSubmit)="loadOrders()" novalidate>
    <div class="form-group px-2">
      <select class="form-control ml-1" style="width: 200px" id="companyId" [(ngModel)]="filterParams.companyId" name="companyId">
        <option value="0">Vyberte dodavatele</option>
        <option *ngFor="let company of companies" [value] = "company.id">
          {{company.companyName}}
        </option>
      </select>
    </div>
    <select class="form-control ml-1" style="width: 200px" id="filterType" [(ngModel)]="filterParams.filterType" name="filterType">
      <option value="1">Objednávky</option>
      <option value="2">Příjemky</option>
      <option value="3">Faktury</option>
    </select>
    <select  class="form-control ml-1" style="width: 200px" id="invoicePaid" [(ngModel)]="filterParams.invoicePaid" name="invoicePaid">
      <option value="0">Uhrazeno/Neuhrazeno</option>
      <option value="1">Uhrazeno</option>
      <option value="2">Neuhrazeno</option>
    </select>
    <div class="form-group ">
      <label for="dateFrom">Datum od: </label>
      <input *ngIf="filterParams.dateFrom !== '1990-01-01'" class="form-control ml-1" type="date" [(ngModel)]="filterParams.dateFrom" name="dateFrom">
      <input *ngIf="filterParams.dateFrom === '1990-01-01'" class="form-control ml-1" type="date" value="">
    </div>
    <div class="form-group ">
      <label for="dateTo">Datum do: </label>
      <input *ngIf="filterParams.dateTo !== '1990-01-01'" class="form-control ml-1" type="date" [(ngModel)]="filterParams.dateTo" name="dateTo">
      <input *ngIf="filterParams.dateTo === '1990-01-01'" class="form-control ml-1" type="date" value="">
    </div>

    <div class="form-group ">
      <input  class="form-control ml-1" [(ngModel)]="filterParams.docId" name="docId" placeholder="Id dokladu">
    </div>
    <button type="submit" class="btn btn-primary" style="margin-left:10px"(click)="loadOrders()" >Filtrovat</button>
    <button type="button" class="btn btn-info" style="margin-left:10px" (click)="resetFilters()">Reset Filtrů</button>
  </form>
  <br> 
  <div  *ngIf="itemOrders === undefined || itemOrders.length === 0" style="text-align: center">
    <h1>Zatím nejsou vytvořené žádné objednávky</h1>
    <button class="btn btn-primary m-3" (click)="createModal()" >Vytvořit novou objednávku</button>
  </div>
  <div *ngIf="itemOrders !== undefined && itemOrders.length > 0" > 
    <div *ngIf="itemOrders !== undefined" class="m-5">
      <div class="row" *ngIf="itemOrders !== undefined && itemOrders.length > 0">
        <table class="table table-hover" style="cursor: default">
          <button *appHasRole="['InvoiceEdit']" class="btn btn-primary" (click)="createModal()">Přidat</button>
          <tr>
            <th>Dodavatel</th>
            <th>Datum objednávky</th>
            <th>Číslo faktury</th>
            <th>Datum vystavení faktury</th>
            <th>Částka</th>
            <th>Částka (s DPH)</th>
            <th style="width: 5%">Zaplaceno</th>
            <th style="width: 10%"></th>
          </tr>
          <ng-container *ngFor="let itemOrder of itemOrders">
            <tr >
              <td > <i *ngIf="!itemOrder.amountError === true" class="fa fa-circle pr-1" style="color:green"></i> <i *ngIf="itemOrder.amountError === true" class="fa fa-circle pr-1" style="color:red"></i>{{itemOrder.supplier.companyName}}</td>
              <td >{{itemOrder.dateOfOrder | date:"dd.MM.yyyy"}}</td>            
              <td *ngIf="itemOrder.inv !== null">{{itemOrder.inv.invoiceNumber}}</td>
              <td *ngIf="itemOrder.inv === null"></td>          
              <td *ngIf="itemOrder.inv !== null">{{itemOrder.inv.dateOfCreation | date:"dd.MM.yyyy"}} </td>
              <td *ngIf="itemOrder.inv === null"></td>
              <td *ngIf="itemOrder.inv !== null">{{itemOrder.inv.price}}</td>
              <td *ngIf="itemOrder.inv !== null">{{(itemOrder.inv.priceWithDPH)}}</td>
              <td *ngIf="itemOrder.inv === null"></td>
              <td *ngIf="itemOrder.inv === null"></td>
              <td *ngIf="itemOrder.inv !== null" (click)="getDetailModal(invoice)">
                <i *ngIf="itemOrder.inv.paid" class="fa fa-check-circle-o" style="color:green"></i>
                <i *ngIf="!itemOrder.inv.paid" class="fa fa-times-circle-o" style="color:red"></i>
                <i  *ngIf="checkInvPaid(itemOrder)" class="fa fa-exclamation-circle ml-1" style="color:red"></i>
              </td>
              <td *ngIf="itemOrder.inv === null">
              </td>
              <td>
                <div class="form-inline">
                  <div>
                    <button  class="btn btn-primary" (click)="$event.stopPropagation()" (click)="getEditModal(itemOrder)" ><i class="fa fa-edit ml-1"></i></button>
                    <button  class="btn btn-danger" (click)="$event.stopPropagation()" (click)="deleteInvoice(itemOrder)" ><i class="fa fa-trash"></i></button>
                  </div>
                </div>
              </td>       
            </tr>
            <tr class="noBorder">
              <td colspan="5">
                <div class="containerSteps w-100">	
                  <div class="arrow-steps clearfix">
                    <div (click)="getDetailModal(itemOrder,'new order')" class="{{setStepView(itemOrder.orderState,'new order')}}"> <span> Objednávka</span> 
                    </div>
                    <div (click)="getDetailModal(itemOrder,'receipt')" class="{{setStepView(itemOrder.orderState,'receipt')}}"> <span>Příjemka</span> 
                    </div>
                    <div (click)="getInvoiceDetailModal(itemOrder,'accepted invoice')" class="{{setStepView(itemOrder.orderState,'accepted invoice')}}"> <span>Přijatá faktura</span> 
                    </div>
                  </div>  
                </div>
              </td>
            </tr>
          </ng-container>
        </table>
      </div>
    </div>
    <div  class="d-flex justify-content-center">
      <pagination [maxSize]="3"
                  [boundaryLinks]="true" 
                  [totalItems]="pagination.totalItems"
                  [itemsPerPage]="pagination.itemsPerPage"
                  [(ngModel)]="pagination.currentPage"
                  (pageChanged)="pageChanged($event)"
                previousText="&lsaquo;" nextText="&rsaquo;" firstText="&laquo;" lastText="&raquo;">
      </pagination>
    </div>
  </div>
</div>
