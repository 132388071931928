import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { AlertifyService } from '../_services/alertify.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';
import { WorkPost } from '../_models/workPost';
import { WorkPostService } from '../_services/workPost.service';
@Injectable()
export class WorkPostResolver implements Resolve<WorkPost[]> {
  pageNumber = 1;
  pageSize = 15;

  constructor(
    private workpostService: WorkPostService,
    private router: Router,
    private alertify: AlertifyService,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<WorkPost[]> {

    return this.workpostService.getWorkPosts(this.pageNumber, this.pageSize).pipe(
      catchError((error) => {
        this.alertify.error('Problem retrieving workPosts');
        this.router.navigate(['//adminHome']);
        return of(null);
      })
    );
  }
}
