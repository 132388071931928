import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Category } from 'src/app/_models/category';
import { Company } from 'src/app/_models/company';
import { Ticket } from 'src/app/_models/ticket';
import { User } from 'src/app/_models/user';
import { AuthService } from 'src/app/_services/auth.service';
import { CategoryService } from 'src/app/_services/category.service';
import { CompanyDialService } from 'src/app/_services/companyDial.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-ticketDataEdit-modal',
  templateUrl: './ticketDataEdit-modal.component.html',
  styleUrls: ['./ticketDataEdit-modal.component.scss']
})
export class TicketDataEditModalComponent implements OnInit {
  @Output() editTicketEvent = new EventEmitter();
  ticket: Ticket;
  newTicket: Ticket;
  filterParams: any;
  users: User[];
  ticketEditForm: FormGroup;
  categories:Category[]
  companies: Company[];
  directCatView = false;

 constructor(public bsModalRef: BsModalRef, 
  private userService: UserService,
   private authService: AuthService,
   private fb: FormBuilder,
   private companyService: CompanyDialService,
   private datePipe: DatePipe,
   private categoryService: CategoryService) {}

  ngOnInit() {
    this.createForm();
    this.userService.getUsers().subscribe((data) => {
      this.users = data.result;//.filter(x => x.companyId === this.ticket.issuerCompanyId );
    });
    this.companyService.getCompanies(1, 1,null,null,false,false).subscribe(data =>{
      this.companies = data.result
      if(this.authService.decodedToken.IsPrimary !== 'True')
      {
        this.companies = this.companies.filter(x => x.id.toString() === this.authService.decodedToken.CompanyId);
      }
    });
    this.categoryService.getCategories(1, 1, { companyId: this.ticket.issuerCompanyId} ).subscribe((data) => {this.categories = data.result;  
    if(this.categories.filter(x=>x.id == this.ticket.categoryId).length <1)
    {
      this.directCatView =true;
    }
    });

  }

  setCompanyForDynamicFilter()
  {
    this.categoryService.getCategories(1, 1, { companyId: this.ticketEditForm.get('issuerCompanyId').value} ).subscribe((data) => {this.categories = data.result;});
    this.ticketEditForm.value.categoryId = "";
    // this.userService.getUsers().subscribe((data) => {
    //   this.users = data.result;
      // this.users = data.result.filter(x => x.companyId.toString() === this.ticketEditForm.get('issuerCompanyId').value );
      // if((this.users.filter(x=>x.id == this.ticketEditForm.get('issuerId').value)).length === 0)
      // {
      //   this.ticketEditForm.value.issuerId = 0;
      // }
    // });
  }


  saveChagnes()
  {
     this.newTicket = Object.assign({}, this.ticket);

    this.ticketEditForm.value.deadLine = new Date(this.ticketEditForm.value.deadLine);
    this.newTicket.title = this.ticketEditForm.value.title;
    this.newTicket.categoryId = this.ticketEditForm.value.categoryId;
    this.newTicket.issuerCompanyId = this.ticketEditForm.value.issuerCompanyId;
    this.newTicket.issuerId = this.ticketEditForm.value.issuerId;
    this.newTicket.deadLine = this.ticketEditForm.value.deadLine;
    this.editTicketEvent.emit(this.newTicket);
    this.bsModalRef.hide();
  }


  createForm(){
    this.ticketEditForm = this.fb .group({
      title: [this.ticket.title, Validators.required],
      categoryId: [this.ticket.categoryId, Validators.required],
      issuerCompanyId: [this.ticket.issuerCompanyId, Validators.required],
      issuerId: [this.ticket.issuerId, Validators.required],
      deadLine: [this.datePipe.transform(this.ticket.deadLine, 'yyyy-MM-dd'), Validators.required],
      repeat:[false],


      description: [this.ticket.description, Validators.required],
      priorityId: [this.ticket.priorityId, Validators.required],
      dayCountForRepeat:[this.ticket.dayCountForRepeat],
      id:[this.ticket.id],
    });
  }

}
